import { FloatingBubble, Popup } from "antd-mobile";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import CustomModal from "../CustomModal";
import Lobby from "./icons/Lobby";

import "./style.scss";

function CustomGamePopup({ visible, onClose = () => {}, renderContent = () => {} }) {
  const [showBackModal, setShowBackModal] = useState(false);
  const { t } = useTranslation();
  return (
    <Popup
      visible={visible}
      onMaskClick={onClose}
      position="right"
      bodyStyle={{ width: "100vw" }}
      destroyOnClose={true}
      bodyClassName={"mg-custom-popup"}
    >
      {renderContent()}
      <FloatingBubble
        axis="xy"
        magnetic="x"
        style={{
          "--initial-position-bottom": "24px",
          "--initial-position-right": "24px",
          "--edge-distance": "24px",
          "--background": "var(--color-floating-bubble-bg)",
          "--z-index": 100000,
        }}
        onClick={() => {
          setShowBackModal(true);
        }}
      >
        <Lobby />
      </FloatingBubble>
      <CustomModal
        isModalVisible={showBackModal}
        setIsModalVisible={setShowBackModal}
        content={t("backToLobby.modal.hint")}
        okBtnText={t("backToLobby.modal.stay")}
        cancelBtnText={t("backToLobby.modal.back")}
        onOk={() => {
          setShowBackModal(false);
        }}
        onCancel={() => {
          setShowBackModal(false);
          onClose();
        }}
        needIcon={true}
        okBtnStyle={{
          "--ant-primary-color": "var(--color-mg-continue-btn-bg)",
        }}
      />
    </Popup>
  );
}

export default CustomGamePopup;
