import { useEffect, useState } from "react";
import { useGameWebsocket } from "../../../../../context/WebsocketGameContext";
import webSocketActionEnum from "../../../../../enumerations/webSocketActionEnum";
import gameStateEnum from "../../enumeration/gameStateEnum";
import BalanceViewer from "../BalanceViewer";
import BallBackgroundAnimation from "../BallBackgroundAnimation";
import animationPhaseEnum from "../BallBackgroundAnimation/enumeration/animationPhaseEnum";
import BallCollection from "../BallCollection";
import PreparingCountDown from "../PreparingCountDown";
import ResultViewer from "../ResultViewer";
import Live from "./images/live.gif";
import Logo from "./images/logo1.png";
import styles from "./style.module.scss";

const AnimatedPanel = ({
  walletAmount,
  setWalletAmount,
  currentGameState,
  setCurrentGameState,
  resultDefaultTimer,
  animationStartPosition = 0,
  isSwitchChannel,
  setRuleVisible,
  handleDepositCallback = () => {},
  selectedGameList,
  latestNumber = undefined,
  refreshData = () => {},
  latestNumberRefetching = false,
}) => {
  const [resultTimer, setResultTimer] = useState(resultDefaultTimer);
  const [currentAnimationState, setCurrentAnimationState] = useState(animationPhaseEnum.ROLLING);
  const { prevPeriod } = useGameWebsocket();

  useEffect(() => {
    setResultTimer(resultDefaultTimer);
  }, [resultDefaultTimer]);

  useEffect(() => {
    let timer = null;
    switch (currentGameState.key) {
      case gameStateEnum.BET_PHASE.key:
        // case gameStateEnum.PENDING_PHASE:
        setCurrentAnimationState(animationPhaseEnum.ROLLING);
        break;
      case gameStateEnum.START_PHASE.key: {
        if (latestNumberRefetching || latestNumber[1]?.lottery_number === null) return;
        setCurrentAnimationState(animationPhaseEnum.ZOOMING);
        setTimeout(() => {
          setCurrentAnimationState(animationPhaseEnum.DRAW_BALL);
          setCurrentGameState(gameStateEnum.PAUSE_RESULT_PHASE);
        }, 3000);

        break;
      }
      case gameStateEnum.PAUSE_RESULT_PHASE.key: {
        setTimeout(() => {
          setCurrentAnimationState(animationPhaseEnum.RESET);
          setCurrentGameState(gameStateEnum.RESULT_PHASE);
        }, 3000);
        break;
      }
      case gameStateEnum.RESULT_PHASE.key: {
        if (timer) {
          clearInterval(timer);
        }
        break;
      }
      default:
        break;
    }
    return () => {
      clearInterval(timer);
    };
  }, [currentGameState, latestNumberRefetching]);

  useEffect(() => {
    switch (prevPeriod.action) {
      case webSocketActionEnum.PENDING:
        if (currentGameState.key === gameStateEnum.DISABLE_BET.key)
          setCurrentGameState(gameStateEnum.PENDING_PHASE);
        break;
      case webSocketActionEnum.END:
        if (currentGameState.key === gameStateEnum.PENDING_PHASE.key)
          setCurrentGameState(gameStateEnum.START_PHASE);
        break;
      default:
        break;
    }
  }, [prevPeriod.action, currentGameState.key]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img className={styles.logo} src={Logo} alt={"logo"} />
        <img className={styles.live} src={Live} alt={"live"} />
      </div>
      <BallBackgroundAnimation currentAnimationState={currentAnimationState} />
      <BallCollection currentAnimationState={currentAnimationState} latestNumber={latestNumber} />
      <PreparingCountDown
        visible={currentGameState?.showCountDown}
        setCurrentGameState={setCurrentGameState}
        isSwitchChannel={isSwitchChannel}
        currentGameState={currentGameState}
        latestNumber={latestNumber}
        refreshData={refreshData}
        latestNumberRefetching={latestNumberRefetching}
        selectedGameList={selectedGameList}
      />
      <BalanceViewer
        walletAmount={walletAmount}
        setWalletAmount={setWalletAmount}
        refetchWaitTime={15}
        onRulesOpen={() => {
          setRuleVisible(true);
        }}
        handleDepositCallback={handleDepositCallback}
      />
      {currentGameState?.showResult === true ? (
        <ResultViewer
          setCurrentGameState={setCurrentGameState}
          resultTimer={resultTimer}
          latestNumber={latestNumber}
        />
      ) : null}
    </div>
  );
};

export default AnimatedPanel;
