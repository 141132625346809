import { CaretDownFilled } from "@ant-design/icons";
import { Collapse } from "antd";
import { Image } from "antd-mobile";
import React from "react";
import styles from "./style.module.scss";
import "./style.scss";

function CustomCollapse({
  collapseItems,
  pc = false,
  ghost = true,
  bordered = true,
  accordion = false,
  onHandleChange = () => {},
}) {
  const { Panel } = Collapse;
  return (
    <div className={`custom-collapse ${pc ? "pc" : ""}`}>
      <Collapse
        accordion={accordion}
        ghost={ghost}
        bordered={bordered}
        expandIconPosition="end"
        onChange={onHandleChange}
        expandIcon={({ isActive }) => {
          return (
            <CaretDownFilled
              rotate={isActive ? 180 : 0}
              className={`icon ${isActive ? "active" : ""}`}
            />
          );
        }}
      >
        {collapseItems?.map((item, index) => {
          return (
            <Panel header={item.title} key={item.id}>
              {item.img ? (
                <div className="custom-collapse-img-container">
                  <Image src={item.img} alt={item.title} />
                </div>
              ) : (
                ""
              )}
              <div className={styles.content} dangerouslySetInnerHTML={{ __html: item.content }} />
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
}

export default CustomCollapse;
