import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { PCEmailForm, PCModal } from "../index.js";

import { usePostEmailVerify, usePutForgetPassword } from "../../hooks/verification/email.hook.js";
function PCBindEmailModal({
  visible,
  setVisible,
  onBeforeFinish,
  isForgetPassword = false,
  account,
  title = null,
}) {
  const { mutate: emailVerify } = usePostEmailVerify({
    onSuccess: onBeforeFinish,
  });
  const { mutate: forgetPassword } = usePutForgetPassword({
    onSuccess: onBeforeFinish,
  });
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const onFinish = async (form) => {
    // console.log("form", form);
    // if (isForgetPassword) {
    //   forgetPassword({ ...form, type: "email", account });
    // } else {
    emailVerify(form);
    // }
  };

  return (
    <>
      <PCModal
        isForm={true}
        formRef={form}
        isModalVisible={visible}
        setIsModalVisible={setVisible}
        title={title}
        onOk={onFinish}
      >
        <PCEmailForm isForgetPassword={isForgetPassword} account={account} formRef={form} />
      </PCModal>
    </>
  );
}

export default PCBindEmailModal;
