import { RightOutlined } from "@ant-design/icons";
import { Avatar, Badge, Button } from "antd";
import React from "react";
import styles from "./style.module.scss";

import "./style.scss";

const MenuItem = ({
  onClick = () => {},
  title = "",
  disabled = false,
  hideBottomBorder = false,
  titleColor = "var(--color-text-main)",
  style = {},
  hideArrow = false,
  badge = false,
  icon = <Avatar size={25} />,
  hideItem = false,
  customContainer = "",
}) => {
  return hideItem ? (
    ""
  ) : (
    <div className={`personal-menu-item ${customContainer}`} style={style}>
      <Button type={"text"} disabled={disabled} className={`${styles.container}`} onClick={onClick}>
        <div className={`${styles.iconBox}`}>{icon}</div>
        <div
          style={{
            borderBottom: hideBottomBorder ? "" : "1px solid #e5ebf1",
          }}
          className={`${styles.rightBox}`}
        >
          <div className={`${styles.titleBox}`}>
            <div style={{ color: titleColor }}>{title}</div>
            <Badge dot={badge} offset={[-24, 7]} style={{ width: "10px", height: "10px" }}>
              {hideArrow ? "" : <RightOutlined style={{ color: "#a0a0a0" }} />}
            </Badge>
          </div>
        </div>
      </Button>
    </div>
  );
};

export default MenuItem;
