import { RightOutlined } from "@ant-design/icons";
import { Form, Select } from "antd";
import React from "react";
import "./style.scss";

const CustomSelect = ({
  placeholder = "",
  // value,
  // defaultValue,
  style = {},
  label = "",
  name = "",
  rules = [],
  items = [],
  disabled = false,
  bordered = true,
  onClick = () => {},
  initialValue,
  tooltip,
  type = "mobile",
  customStyle = "",
  showArrow = false,
  onChange = () => {},
}) => {
  const { Option } = Select;

  return (
    <div style={style} className={"container"}>
      <Form.Item
        label={label}
        name={name}
        rules={rules}
        className={`${type === "pc" ? "pc-select-container" : "select-container"} ${customStyle}`}
        initialValue={initialValue}
        tooltip={tooltip}
      >
        <Select
          bordered={bordered}
          disabled={disabled}
          showArrow={showArrow}
          onClick={disabled ? () => {} : onClick}
          dropdownStyle={type === "pc" ? {} : { display: "none" }}
          onChange={onChange}
          placeholder={placeholder}
        >
          {items.map((item) => {
            return (
              <Option selected key={item.value} value={item.value}>
                {`${item.label} ${item.subtitle ? `(${item.subtitle})` : ""}`}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      {showArrow === false ? (
        <RightOutlined className={`icon${label === "" ? " borderless" : ""}`} />
      ) : null}
    </div>
  );
};

export default CustomSelect;
