import Scrollbars from "react-custom-scrollbars";
import { FormModal } from "..";
// import { useLogout } from "../../hooks/personal/personal.hook";
import VerificationForm from "../../layouts/components/PC/HeaderComponent/components/VerificationForm";
import styles from "./style.module.scss";

const PCVerificationPopup = ({ visible = true, setVisible = () => {}, account = undefined }) => {
  // const { mutate: logout } = useLogout();
  const onCloseVerification = () => {
    // logout();
    setVisible(false);
  };

  const renderModalSider = () => {
    return <div className={styles.siderContainer} />;
  };

  const renderBody = () => {
    return <VerificationForm account={account} setVisible={setVisible} />;
  };

  return (
    <FormModal
      visible={visible}
      siderContent={renderModalSider()}
      setVisible={setVisible}
      closeModalCallback={onCloseVerification}
    >
      <div className={styles.container}>
        <Scrollbars autoHide className={styles.bodyContainer}>
          {renderBody()}
        </Scrollbars>
      </div>
    </FormModal>
  );
};

export default PCVerificationPopup;
