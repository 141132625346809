import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import Spritesheet from "react-responsive-spritesheet";
import animationPhaseEnum from "./enumeration/animationPhaseEnum";
import RollingBall from "./image/rollingball.png";
import styles from "./style.module.scss";

const BallBackgroundAnimation = forwardRef(({ currentAnimationState }, ref) => {
  const ballRef = useRef();
  useImperativeHandle(ref, () => ({
    rolling() {
      rolling();
    },
    zoomIn() {
      zoomIn();
    },
    getCurrentFrame() {
      return getCurrentAnimationFrame();
    },
  }));

  const rolling = () => {
    setTimeout(() => {
      ballRef.current.pause();
      ballRef.current.setStartAt(1);
      ballRef.current.setEndAt(7);
      ballRef.current.goToAndPlay(1);
      ballRef.current.setFps(12);
    }, 500);
  };

  const zoomIn = () => {
    setTimeout(() => {
      ballRef.current.setStartAt(8);
      ballRef.current.setEndAt(14);
      ballRef.current.goToAndPlay(8);
      ballRef.current.setFps(10);
    }, 500);
  };

  const getCurrentAnimationFrame = () => {
    return ballRef.current.getInfo("frame");
  };

  useEffect(() => {
    switch (currentAnimationState) {
      case animationPhaseEnum.ROLLING:
        rolling();
        break;
      case animationPhaseEnum.ZOOMING:
        zoomIn();
        break;
      default:
        break;
    }
  }, [currentAnimationState]);

  return (
    <div className={styles.container}>
      <Spritesheet
        ref={ballRef}
        image={RollingBall}
        widthFrame={720}
        heightFrame={720}
        startAt={0}
        endAt={6}
        steps={14}
        fps={16}
        loop={true}
        autoplay={false}
        onLoopComplete={(spritesheet) => {
          if (currentAnimationState === animationPhaseEnum.ZOOMING) {
            spritesheet.pause();
          }
        }}
      />
    </div>
  );
});

export default BallBackgroundAnimation;
