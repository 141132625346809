import { Progress } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Arrowright from "../images/Arrowright";
import styles from "./style.module.scss";
import "./style.scss";

function RemainingSpins({ remaingTimes, neededChurnForNext, churnStandard, pc = false }) {
  const { t } = useTranslation();
  const [percentage, setPercentage] = useState(0);
  useEffect(() => {
    if (neededChurnForNext > 0 && churnStandard > 0) {
      const percent = 100 - (neededChurnForNext / churnStandard) * 100;
      setPercentage(percent);
    }
  }, [neededChurnForNext, churnStandard]);

  return (
    <div className={`${styles.remainingSpins} ${pc === true ? styles.pc : ""}`}>
      <div className={styles.subContainer}>
        <span className={`${styles.text}`}>{t("bonusWheel.remaining.spins")}</span>
        <div className={styles.times}>{remaingTimes > 0 ? remaingTimes : 0}</div>
      </div>

      <div className={styles.hintBox}>
        <div
          className={`${styles.hint}`}
          dangerouslySetInnerHTML={{
            __html: t("bonusWheel.spinchance.hint", {
              num: neededChurnForNext,
            }),
          }}
        />
        <Arrowright />
      </div>
      {/* TODO */}
      <Progress
        className="remainingSpins-progress"
        percent={percentage}
        // percent={(levelInfo?.total_deposit / depositRequired) * 100}
        showInfo={false}
        strokeColor={"var(--text-green)"}
        trailColor="var(--background-05)"
        size={"default"}
      />
    </div>
  );
}

export default RemainingSpins;
