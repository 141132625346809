import { createContext, useContext, useState } from "react";
import { SearchPopup } from "../components";
import { useSearchGame } from "./hooks/search.hooks";

const SearchContext = createContext({
  showSearchPopup: false,
  setShowSearchPopup: () => {},
  selectedGame3th: { game_3th_code: "search_all" },
  setSelectedGame3th: () => {},
  setEnablePopup: () => {},
  searchValue: "",
  setSearchValue: () => {},
  searchResult: undefined,
  searchError: false,
  hasNextPage: false,
  fetchNextPage: () => {},
  isFetchingNextPage: false,
  queryEnabled: true,
  setQueryEnabled: () => {},
});

export const SearchContextProvider = ({ children }) => {
  const [showSearchPopup, setShowSearchPopup] = useState(false);
  const [selectedGame3th, setSelectedGame3th] = useState({ game_3th_code: "search_all" });
  const [searchValue, setSearchValue] = useState("");
  const [enablePopup, setEnablePopup] = useState(true);
  const [queryEnabled, setQueryEnabled] = useState(true);

  const handleQueryEnable = () => {
    if (enablePopup) {
      return showSearchPopup;
    } else {
      return queryEnabled;
    }
  };

  const {
    data: searchResult,
    isError: searchError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useSearchGame(
    {
      enabled: handleQueryEnable() && !!selectedGame3th,
      getNextPageParam: (lastPage, pages) => {
        if (pages.length < lastPage.last_page) {
          return pages.length + 1;
        } else {
          return undefined;
        }
      },
    },
    {
      keyword: searchValue,
      game_3th_code:
        selectedGame3th.game_3th_code === "search_all" ? "" : selectedGame3th.game_3th_code,
      page_size: 20,
    },
  );

  return (
    <SearchContext.Provider
      value={{
        showSearchPopup,
        setShowSearchPopup,
        selectedGame3th,
        setSelectedGame3th,
        setEnablePopup,
        searchValue,
        setSearchValue,
        searchResult,
        searchError,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
        queryEnabled,
        setQueryEnabled,
      }}
    >
      {children}
      <SearchPopup
        visible={showSearchPopup}
        onClose={() => {
          setShowSearchPopup(false);
        }}
        game3thCode={selectedGame3th.game_3th_code}
        setSearchValue={setSearchValue}
        searchResult={searchResult}
        searchError={searchError}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
      />
    </SearchContext.Provider>
  );
};
export const useSearch = () => {
  const SearchValue = useContext(SearchContext);
  return SearchValue;
};
