import { PlusOutlined, SyncOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDevice } from "../../../../../context/DeviceContext";
import localStorageKey from "../../../../../enumerations/localStorageKey";
import { useGetUserWalletCache } from "../../../../../hooks/wallet/wallet.hooks";
import { moneyFormat } from "../../../../../plugins/numberFormat";
import { getRandomInt } from "../../../../../plugins/utils";
import styles from "./style.module.scss";

const CURRENCY = localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);

const BalanceViewer = ({
  walletAmount,
  setWalletAmount = () => {},
  refetchWaitTime = 10,
  onRulesOpen = () => {},
  handleDepositCallback = () => {},
}) => {
  const [localWalletLoading, setLocalWalletLoading] = useState(false);
  const [fakeLoading, setFakeLoading] = useState(false);
  const [isFakeReload, setIsFakeReload] = useState(false);
  const timeoutRef = useRef();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobile } = useDevice();
  const {
    data: cacheWalletData,
    refetch: refetchCache,
    isFetching: isFetchingCache,
  } = useGetUserWalletCache({
    enabled: false,
    onSuccess: () => {
      setIsFakeReload(true);
    },
  });
  const onHandleDepositOpen = () => {
    if (isMobile) {
      navigate("/exchange", {
        state: {
          tabIndex: "deposit",
          backURL: "/lotlaunch",
        },
      });
    } else {
      navigate("/account?tab=deposit");
    }
    handleDepositCallback();
  };

  const handleReloadCacheBalance = () => {
    if (!localWalletLoading) {
      if (isFakeReload === true) {
        setLocalWalletLoading(true);
        setTimeout(() => {
          setLocalWalletLoading(false);
        }, [750]);
        return;
      }
      refetchCache();
    }
  };
  useEffect(() => {
    if (isFakeReload === true) {
      const timeoutDelay = getRandomInt(5, 10);
      timeoutRef.current = setTimeout(() => {
        setIsFakeReload(false);
      }, [timeoutDelay * 1000]);
      return () => {
        clearTimeout(timeoutRef.current);
      };
    }
  }, [isFakeReload]);
  useEffect(() => {
    if (cacheWalletData) {
      setWalletAmount(cacheWalletData.money);
    }
  }, [cacheWalletData]);
  useEffect(() => {
    const _cacheWallet = localStorage.getItem(localStorageKey.WALLET);
    if (_cacheWallet) {
      setWalletAmount(_cacheWallet);
    } else {
      refetchCache();
    }
  }, []);
  useEffect(() => {
    setLocalWalletLoading(isFetchingCache || fakeLoading);
  }, [isFetchingCache, fakeLoading]);

  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <div className={styles.depositButton} onClick={onHandleDepositOpen}>
          <PlusOutlined style={{ color: "#fff" }} />
        </div>
        <div className={styles.money}>{`${t(
          `currency.symbol.${CURRENCY.toUpperCase()}`,
        )} ${moneyFormat(walletAmount)}`}</div>
        <div className={styles.reload} onClick={handleReloadCacheBalance} spin={localWalletLoading}>
          <SyncOutlined className={styles.icon} spin={localWalletLoading} />
        </div>
      </div>
      <div className={styles.ruleButton} onClick={onRulesOpen}>
        {t("win.normal.rule")}
      </div>
    </div>
  );
};

export default BalanceViewer;
