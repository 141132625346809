import { Image } from "antd-mobile";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DarkTab,
  LaunchBetModalContent,
  WinBetPanel,
  WinCustomModal,
  WinHistoryPanel,
  WinRulePopup,
} from "../../../../../components";
import { useLoading } from "../../../../../context/LoadingContext";
import { useGameWebsocket } from "../../../../../context/WebsocketGameContext";
import { usePostGameBet } from "../../../../../hooks/winBet/winbet.hooks";
import { useGetGameMethod, useGetLatestNumber } from "../../../../../hooks/winBet/wingame.hooks";
import betSelectionEnum from "../../enumeration/betSelectionEnum";
import channelSettingsEnum from "../../enumeration/channelSettingsEnum";
import gameStateEnum from "../../enumeration/gameStateEnum";
import { useGetGameCategory } from "../../hooks/win.hooks";
import { ValidateMaxMinBet, ValidateMaxPayout, ValidateUserMoney } from "../../validator";
import AnimatedPanel from "../AnimatedPanel";
import RuleContent from "../RuleContent";
import styles from "./style.module.scss";

const smallPopupStyle = {
  titleContainerStyle: {
    background: "linear-gradient(180deg, #891CBD 0%, #7000FF 100%)",
  },
  buttonStyle: {
    background: "linear-gradient(180deg, #891CBD 0%, #7000FF 100%)",
    border: "none",
  },
  buttonInnerStyle: {
    // border: "1px solid rgba(0, 0, 0, 0.3)",
    // borderRadius: "8px",
  },
  popupStyle: {
    "--border-radius": "8px",
    "--popup-border": "0 solid transparent",
    "--popup-background": "#FFF",
  },
};

const GameContainer = ({
  handleClosePage = () => {},
  // handleCloseWebsocket = () => {},
  gameCode,
  gameId,
}) => {
  const { t } = useTranslation();

  const { setIsLoading } = useLoading();

  const isSwitchChannel = useRef(true);

  const {
    // openWebsocket,
    // isWebsocketConnected,
    // currentPeriod,
    // switchChannel,
    counter,
    // currentChannel,
    // websocketTimeout,
  } = useGameWebsocket();

  const { data, isLoading, isError } = useGetGameCategory({
    params: {
      game_category_detail_type: gameCode.toUpperCase(),
    },
  });

  const [selectedGameList, setSelectedGameList] = useState();
  const [selectedGameWayType, setSelectedGameWayType] = useState(
    data && data.length > 0 ? data[0].game_way_type : null,
  );

  const {
    data: gameMethod,
    isLoading: gameMethodLoading,
    isError: gameMethodError,
  } = useGetGameMethod({
    params: {
      game_way_type: selectedGameWayType ? selectedGameWayType : "",
    },
    options: {
      enabled: !!selectedGameWayType,
    },
  });

  const {
    data: latestNumber,
    refetch: refetchLatestNumber,
    isLoading: latestNumberLoading,
    isRefetching: latestNumberRefetching,
  } = useGetLatestNumber({
    params: {
      game_way: selectedGameList,
    },
    options: {
      enabled: false,
    },
  });

  useEffect(() => {
    // let timeout;
    if (selectedGameList) {
      //   timeout = setTimeout(() => {
      //     if (!currentPeriod.period) {
      //       console.debug("fetching from API ---- ", latestNumber, currentPeriod.period);
      //       refetchLatestNumber();
      //     }
      //   }, [2000]);
      refetchLatestNumber();
    }

    // return () => {
    //   clearTimeout(timeout);
    // };
  }, [selectedGameList]);

  // const { data: gameFeeAndOdds } = useGetGameFeeAndOdds({});

  const timeoutPopup = {
    title: "",
    buttonText: t("win.popup.wsTimeout.button"),
    onButtonPress: () => {
      handleClosePage();
    },
    destroyOnClose: true,
    popupContent: t("win.popup.wsTimeout"),
    showCustomCloseButton: false,
  };

  // useEffect(() => {
  //   console.debug("websocketTimeout", websocketTimeout);
  //   if (websocketTimeout === true) {
  //     setPopupSetting(timeoutPopup);
  //     setPopupVisible(true);
  //   }
  // }, [websocketTimeout]);

  const [walletAmount, setWalletAmount] = useState(0);
  const [totalMoney, setTotalMoney] = useState(null);

  const [ruleVisible, setRuleVisible] = useState(false);
  const [isBetVisible, setIsBetVisible] = useState(false);
  const [selectedBet, setSelectedBet] = useState();
  const [nowOddsSetting, setNowOddsSetting] = useState({});

  const [popupVisible, setPopupVisible] = useState(false);
  const [showPeriodEndModal, setShowPeriodEndModal] = useState(false);
  const [popupSetting, setPopupSetting] = useState({
    title: "",
    buttonText: "",
    onButtonPress: () => {},
    popupContent: "",
  });

  const [currentGameState, setCurrentGameState] = useState(gameStateEnum.BET_PHASE);
  const [resultDefaultTimer, setResultDefaultTimer] = useState(4);
  const [animationStartPosition, setAnimationStartPosition] = useState(0);

  const VALIDATOR_FUNCTION_LIST = [ValidateMaxMinBet, ValidateUserMoney, ValidateMaxPayout];

  const { mutate: postGameBet } = usePostGameBet();

  const onTabChange = (e) => {
    const foundData = data.find((game) => game.key === e);
    isSwitchChannel.current = true;
    setIsLoading(true);
    // switchChannel({ oldChannel: selectedGameList, newChannel: e });
    setSelectedGameWayType(foundData.game_way_type);
    setSelectedGameList(e);
  };

  // const handleOpenWebsocket = (category) => {
  //   const foundData = data.find((game) => game.key === category);
  //   if (isWebsocketConnected === false) {
  //     openWebsocket({ channel: category });
  //     setSelectedGameWayType(foundData.game_way_type);
  //   }
  // };

  const onBetClose = () => {
    setTotalMoney(null);
    setIsBetVisible(false);
  };

  const handleBetItemClick = (option) => {
    setSelectedBet(option);
    setIsBetVisible(true);
  };

  const renderBetModalTitle = () => {
    if (!selectedBet) return null;
    let title = "";
    if (selectedBet.translate === true) {
      title = t(selectedBet.title);
    } else {
      title = selectedBet.title;
    }
    return (
      <>
        <span>{`${t("win.betpopup.title")}: `}</span>
        <span>{title}</span>
      </>
    );
  };

  const colorStyle = () => {
    if (!selectedBet) return {};
    if (selectedBet.color.length <= 1) {
      return { backgroundColor: `${selectedBet.color[0]}` };
    }
    return {
      backgroundImage: `linear-gradient(160deg,${selectedBet.color[0]} 50%, ${selectedBet.color[1]} 0)`,
    };
  };

  const validateInput = ({
    walletAmount,
    betAmount,
    odds,
    highest_single_bet,
    lowest_single_bet,
    max_payout,
  }) => {
    let isValid = true;
    const max_total_bet_limit = latestNumber[0]?.max_total_bet_limit;
    VALIDATOR_FUNCTION_LIST.every((_validator, index) => {
      const return_value = _validator({
        walletAmount,
        betAmount: parseFloat(betAmount),
        odds,
        highest_single_bet:
          Number(max_total_bet_limit) <= 0 ? highest_single_bet : max_total_bet_limit,
        lowest_single_bet,
        max_payout,
      });
      if (return_value.valid === false) {
        setPopupSetting({
          title: t("win.popup.periodEnd.title"),
          buttonText: t("win.popup.periodEnd.button"),
          onButtonPress: () => {},
          destroyOnClose: true,
          popupContent: t(return_value.content, return_value.options),
        });
        setPopupVisible(true);
        isValid = false;
        return false;
      }
      return true;
    });
    return isValid;
  };

  const refreshData = () => {
    if (selectedGameList) refetchLatestNumber();
  };

  const handleSubmitBet = async () => {
    if (!selectedGameList) return;
    const selectedGameWay = data.find((game) => game.key === selectedGameList);
    const isValid = validateInput({
      walletAmount: walletAmount,
      betAmount: totalMoney,
      ...selectedBet,
    });
    if (isValid === false) return;
    const params = {
      game_category_type: selectedGameWay.game_category_type,
      game_category_detail_type: selectedGameWay.game_category_detail_type,
      game_type: selectedGameWay.game_type,
      game_way_type: selectedGameWay.game_way_type,
      game_way_detail_type: null,
      period: latestNumber[0]?.period,
      choice_number: [
        {
          bet_content: selectedBet.key,
          bet_point: totalMoney,
          game_method_code: selectedBet.game_method_code,
        },
      ],
    };
    await postGameBet(
      {
        params: params,
      },
      {
        onSuccess: () => {
          setPopupSetting({
            title: t("win.popup.periodEnd.title"),
            buttonText: t("win.popup.betSuccess.btn.close"),
            onButtonPress: () => {},
            destroyOnClose: true,
            popupContent: t("win.popup.betSuccess.content"),
          });
          setPopupVisible(true);
        },
        onError: (error) => {
          if (error?.result?.code === "6669999") {
            setPopupSetting({
              title: t("win.popup.periodEnd.title"),
              buttonText: t("win.popup.periodEnd.button"),
              onButtonPress: () => {
                if (selectedGameList) {
                  refetchLatestNumber();
                }
              },
              onClose: () => {
                if (selectedGameList) {
                  refetchLatestNumber();
                }
              },
              destroyOnClose: true,
              popupContent: t("validate.notWithinAllowedTime"),
            });

            setPopupVisible(true);
            return;
          }
          setPopupSetting({
            title: t("win.popup.periodEnd.title"),
            buttonText: t("win.popup.periodEnd.button"),
            onButtonPress: () => {},
            destroyOnClose: true,
            popupContent: error,
          });
          setPopupVisible(true);
        },
      },
    );
  };

  // useEffect(() => {
  //   return () => {
  //     handleCloseWebsocket();
  //   };
  // }, [isWebsocketConnected]);

  useEffect(() => {
    if (!data || data.length <= 0) return;
    const foundData = data.find((game) => game.key === gameId);
    if (foundData) {
      // handleOpenWebsocket(foundData.key);
      setSelectedGameList(foundData.key);
      setSelectedGameWayType(foundData.game_way_type);
    } else {
      // handleOpenWebsocket(data[0].key);
      setSelectedGameList(data[0].key);
      setSelectedGameWayType(data[0].game_way_type);
    }
  }, [data, gameId]);

  useEffect(() => {
    if (counter <= 1) {
      if (isBetVisible === true) {
        setPopupSetting({
          title: t("win.popup.periodEnd.title"),
          buttonText: t("win.popup.periodEnd.button"),
          onButtonPress: () => {},
          destroyOnClose: true,
          popupContent: t("validate.notWithinAllowedTime"),
        });
        setPopupVisible(true);
      } else {
        setShowPeriodEndModal(true);
      }
    }
  }, [counter, isBetVisible]);

  useEffect(() => {
    if (showPeriodEndModal === true) {
      setTimeout(() => {
        setShowPeriodEndModal(false);
      }, [5000]);
    }
  }, [showPeriodEndModal]);

  // useEffect(() => {
  //   if (!!gameFeeAndOdds && selectedGameList) {
  //     const nowOdds = gameFeeAndOdds[selectedGameList] ?? {};
  //     // console.log("nowOdds", nowOdds);
  //     setNowOddsSetting(nowOdds);
  //   }
  // }, [gameFeeAndOdds, selectedGameList]);

  //Phase:
  // 00:00:00 - 00:00:05 -> prepare launch
  // 00:00:05 - 00:00:15 -> launching (0-10second)
  // 00:00:15 - 00:00:25 -> result phase
  // 00:00:25 - 00:01:00 -> bet phase
  // repeat

  // const countdown = useCallback(() => {
  //   if (currentPeriod.end && currentPeriod.current_date_time) {
  //     const timeStringProcess = (timeString) => {
  //       const regex = /\-/g;
  //       return timeString.replace(regex, "/");
  //     };
  //     const difference = dayjs(`${timeStringProcess(currentPeriod.end)} +00:00`).diff(
  //       dayjs(timeStringProcess(currentPeriod.current_date_time)),
  //       "s",
  //     );
  //     return difference;
  //   }
  //   return 0;
  // }, [currentPeriod.end, currentPeriod.current_date_time]);

  const countdown = useCallback(() => {
    if (latestNumber) {
      const activePeriod = latestNumber[0];
      const difference = dayjs(`${activePeriod.end} +00:00`.replace(/-/g, "/")).diff(
        dayjs().utc(),
        "seconds",
      );
      return difference;
    }
    return 0;
  }, [latestNumber]);

  // useEffect(() => {
  //   if (currentChannel && currentPeriod.channel === currentChannel) {
  //     setTimeout(() => {
  //       setIsLoading(false);
  //     }, 1000);
  //     const counter = countdown();
  //     if (isSwitchChannel.current === true && counter >= 0) {
  //       const ellapsedTime = channelSettingsEnum[currentChannel].maxTimer - counter;
  //       isSwitchChannel.current = false;
  //       switch (true) {
  //         case ellapsedTime < 15: //result phase
  //           setResultDefaultTimer(Math.min(15 - ellapsedTime, 4));
  //           setCurrentGameState(gameStateEnum.RESULT_PHASE);
  //           break;
  //         default:
  //           setCurrentGameState(gameStateEnum.BET_PHASE);
  //           break;
  //       }
  //     }
  //   }
  // }, [currentChannel, currentPeriod.channel]);

  useEffect(() => {
    if (selectedGameList && latestNumber) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
      const counter = countdown();
      if (isSwitchChannel.current === true && counter >= 0) {
        const ellapsedTime = channelSettingsEnum[selectedGameList].maxTimer - counter;
        isSwitchChannel.current = false;
        switch (true) {
          case ellapsedTime < 15: //result phase
            if (latestNumber[1].lottery_number) {
              setResultDefaultTimer(Math.min(15 - ellapsedTime, 4));
              setCurrentGameState(gameStateEnum.RESULT_PHASE);
            } else {
              setCurrentGameState(gameStateEnum.PENDING_LAUNCH);
            }
            break;
          default:
            setCurrentGameState(gameStateEnum.BET_PHASE);
            break;
        }
      }
    }
  }, [selectedGameList, countdown, latestNumber]);

  return (
    <>
      <DarkTab
        activeKey={selectedGameList}
        onTabChange={onTabChange}
        tabItems={data || []}
        darkTabContainerStyle={"federal-dark-tab"}
      />
      <AnimatedPanel
        walletAmount={walletAmount}
        setWalletAmount={setWalletAmount}
        currentGameState={currentGameState}
        setCurrentGameState={setCurrentGameState}
        resultDefaultTimer={resultDefaultTimer}
        animationStartPosition={animationStartPosition}
        isSwitchChannel={isSwitchChannel.current}
        setRuleVisible={setRuleVisible}
        handleDepositCallback={handleClosePage}
        latestNumber={latestNumber}
        latestNumberRefetching={latestNumberRefetching}
        selectedGameList={selectedGameList}
        refreshData={refreshData}
      />
      <div className={styles.betPanelContainer}>
        <WinBetPanel
          onBetItemClick={handleBetItemClick}
          gameMethod={gameMethod}
          betSelectionEnum={betSelectionEnum}
          latestNumber={latestNumber ? latestNumber[0]?.period : undefined}
        />
      </div>
      <WinHistoryPanel
        selectedGameList={selectedGameList}
        refetchWaitTime={5}
        gameMethod={gameMethod}
        leading={false}
        trailing={true}
        gameCategoryDetailType={gameCode.toUpperCase()}
        style={{
          "--border-radius": "0",
          "--container-background": "var(--color-launchGame-background)",
          "--container-margin": "0 12px 12px 12px",
          "--column-title-color": "rgba(255, 255, 255, 0.6)",
          "--column-title-bottom-border-color": "rgba(1, 253, 153, 0.2)",
          "--body-period-color": "#FFFFFF",
          "--numberResult-textAlign": "center",
          "--numberResult-fontWeight": 700,
          "--result-flexAlign": "center",
          "--footer-arrow-color": "#01FD99",
          "--total-page-color": "#FFFFFF",
          "--current-page-color": "#01FD99",
        }}
        titleRender={() => {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Image
                src={"/images/game/launchGame/trophy.png"}
                width={24}
                height={24}
                fit="cover"
                alt="trophy"
              />
              <span
                style={{
                  marginLeft: "8px",
                  color: "#ffffff",
                  fontSize: "var(--fontSetting-large)",
                }}
              >
                {t("win.launchgame.historyPanel")}
              </span>
            </div>
          );
        }}
      />
      <WinRulePopup
        onClose={() => {
          setRuleVisible(false);
        }}
        visible={ruleVisible}
        destroyOnClose={true}
        content={() => (
          <RuleContent selectedGameList={selectedGameList} gameCode={gameCode.toLowerCase()} />
        )}
      />
      <WinCustomModal
        title={renderBetModalTitle()}
        visible={isBetVisible}
        buttonText={t("normal.confirm")}
        onClose={onBetClose}
        closeOnMaskClick={false}
        destroyOnClose={true}
        onButtonPress={handleSubmitBet}
        titleContainerStyle={{
          // borderRadius: "8px 8px 0 0",
          ...colorStyle(),
        }}
        buttonStyle={{ ...colorStyle(), border: "none" }}
        buttonInnerStyle={{
          border: "1px solid rgba(255, 255, 255, 0.4)",
          borderRadius: "8px",
        }}
        popupStyle={{
          "--border-radius": "8px",
          "--popup-border": "1px solid var(--color-launchGame-popup-border)",
          "--popup-background": "#0A131B",
        }}
        disableButton={!!currentGameState.disableBet || !totalMoney}
      >
        <LaunchBetModalContent
          colorStyle={colorStyle()}
          setTotalMoney={setTotalMoney}
          totalMoney={totalMoney}
        />
      </WinCustomModal>
      <WinCustomModal
        title={popupSetting.title}
        visible={popupVisible}
        buttonText={popupSetting.buttonText}
        onClose={() => setPopupVisible(false)}
        closeOnMaskClick={false}
        destroyOnClose={true}
        onButtonPress={popupSetting.onButtonPress}
        showCustomCloseButton={popupSetting.showCustomCloseButton}
        {...smallPopupStyle}
      >
        <div style={{ textAlign: "center", color: "#000" }}>{popupSetting.popupContent}</div>
      </WinCustomModal>
    </>
  );
};

export default GameContainer;
