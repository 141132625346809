import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useLogin } from "../../../context/LoginContext";
import localStorageKey from "../../../enumerations/localStorageKey";
import { useGetCustomerService } from "../../../hooks/customerService/customerService.hook";
import { useFacebookPixel } from "../../../hooks/fbPixel/fbPixel.hooks";
import { useGetUserInfo, useLogout } from "../../../hooks/personal/personal.hook";
import AccountArea from "../../../pages/PC/Account/components/AccountArea";
import MenuItem from "../../../pages/mobile/Personal/components/MenuItem";
import { checkUrlReg } from "../../../plugins/utils";
import { PersonalMenu } from "../../AccountInfoPersonal/components";
import CSIcon from "./images/cs.png";
import FinancialRecordIcon from "./images/financial_record.png";
import WithdrawalIcon from "./images/withdraw.png";
import styles from "./style.module.scss";
import "./style.scss";

const ProfileMenu = ({ closeDropdown = () => {} }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { isLoading, isError, error, data: userInfo } = useGetUserInfo({});
  const { mutate: logout } = useLogout();
  const { checkIfLogin } = useLogin();
  const getCustomerService = useGetCustomerService({});
  const { isHavePixelIntegration, pixelCreateCustomTracker } = useFacebookPixel();
  const getLanguage = () => i18n.language;
  const nowLang = getLanguage();
  const currency = localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);

  const customMenu = [
    {
      title: t("normal.withdraw"),
      onClick: () => {
        closeDropdown();
        if (!checkIfLogin()) return;
        navigate("/account?tab=withdrawal");
        closeDropdown();
      },
      icon: <img className={styles.icon} src={WithdrawalIcon} alt={t("normal.withdraw")} />,
    },
    {
      title: t("transaction.record.title"),
      onClick: () => {
        closeDropdown();
        if (!checkIfLogin()) return;
        navigate("/account?tab=transaction");
        closeDropdown();
      },
      icon: (
        <img
          className={styles.icon}
          src={FinancialRecordIcon}
          alt={t("transaction.record.title")}
        />
      ),
    },
    {
      title: t("personal.cs"),
      onClick: () => {
        closeDropdown();
        if (!checkIfLogin()) return;
        if (getCustomerService?.data)
          checkUrlReg(getCustomerService.data, window.open(getCustomerService?.data));
      },
      icon: <img className={styles.icon} src={CSIcon} alt={t("personal.cs")} />,
    },
  ];

  const itemList = [
    {
      key: "group_personal",
      cardBackground: true,
      menu: [
        {
          title: t("page.personal.info"),
          onClick: () => {
            closeDropdown();
            if (!checkIfLogin()) return;
            navigate("/account");
            closeDropdown();
          },
          hideBottomBorder: true,
          hideArrow: true,
          icon: (
            <img
              className={styles.icon}
              src={"/images/personal/personal_info_icon.png"}
              alt={t("page.personal.info")}
            />
          ),
        },
        {
          title: t("withdraw.manage.title"),
          onClick: () => {
            closeDropdown();
            if (!checkIfLogin()) return;
            navigate("/account");
            closeDropdown();
          },
          hideBottomBorder: true,
          hideArrow: true,
          icon: (
            <img
              className={styles.icon}
              src={"/images/personal/bank_card_icon.png"}
              alt={t("withdraw.manage.title")}
            />
          ),
        },
        {
          title: t("personal.affiliateCenter"),
          onClick: (e) => {
            closeDropdown();
            if (!checkIfLogin()) return;
            // const token = window.localStorage.getItem("token");
            // window.open(`${AGENT_MOBILE_URL}?token=${token}&lang=${nowLang}&currency=${currency}`);
            navigate("/agent");
            closeDropdown();
          },
          hideBottomBorder: true,
          hideArrow: true,
          icon: (
            <img
              className={styles.icon}
              src={"/images/personal/affiliate_icon.png"}
              alt={t("page.personal.affiliateCenter")}
            />
          ),
        },
        {
          title: t("personal.betRecord"),
          onClick: () => {
            closeDropdown();
            if (!checkIfLogin()) return;
            navigate("/account?tab=betRecord");
            closeDropdown();
          },
          hideBottomBorder: true,
          hideArrow: true,
          icon: (
            <img
              className={styles.icon}
              src={"/images/personal/bet_record_icon.png"}
              alt={t("personal.betRecord")}
            />
          ),
        },
      ],
    },
    {
      key: "group_logout",
      cardBackground: false,
      menu: [
        {
          title: t("personal.signOut"),
          onClick: () => {
            if (isHavePixelIntegration) {
              pixelCreateCustomTracker({ eventId: "logout" });
            }
            logout();
            closeDropdown();
          },
          hideBottomBorder: true,
          hideArrow: true,
          icon: (
            <img
              className={styles.icon}
              src={"/images/personal/logout_icon.png"}
              alt={t("personal.signOut")}
            />
          ),
        },
      ],
    },
  ];

  return (
    <div className={styles.container}>
      <AccountArea
        userInfo={userInfo}
        isLoading={isLoading}
        customContainerStyle={styles.accountArea}
        customAvatarStyle={styles.avatar}
        customAccountTextStyle={styles.accountText}
      />
      <PersonalMenu customContainerStyle={styles.personalMenu} customMenu={customMenu} />
      {itemList.map((group) => {
        return (
          <div
            key={group.key}
            className={`${styles.groupContainer} ${
              group.cardBackground === false ? styles.noBackground : ""
            }`}
          >
            {group.menu.map((menu, index) => {
              return (
                <MenuItem
                  titleColor={"var(--profile-text2)"}
                  customContainer={"vip-dropdown"}
                  key={"personal" + index}
                  {...menu}
                  style={{ background: "transparent" }}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default ProfileMenu;
