import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import React from "react";
import WarningIcon from "./icons/WarningIcon";
import WarningIconPc from "./icons/WarningIconPc";
import styles from "./style.module.scss";
import "./style.scss";

function CustomModal({
  isHaveCancel = true,
  isModalVisible,
  setIsModalVisible,
  maskClosable = false,
  title = "",
  content = "",
  needIcon = false,
  contentContainerStyle = "",
  closable,
  iconRender,
  customBodyStyle = {},
  okBtnText = " ",
  cancelBtnText = " ",
  header = false,
  onOk = () => {
    setIsModalVisible(false);
  },
  onCancel = () => {
    setIsModalVisible(false);
  },
  onCloseModal = () => {},
  okBtnStyle = {},
  type = "mobile",
}) {
  const handleCloseModal = () => {
    setIsModalVisible(false);
    onCloseModal();
  };

  return (
    <>
      {type === "mobile" ? (
        <Modal
          visible={isModalVisible}
          onCancel={handleCloseModal}
          footer={null}
          width={265}
          centered
          closable={closable ? closable : false}
          maskClosable={maskClosable}
          destroyOnClose
          className={"custom-modal"}
          bodyStyle={{
            borderRadius: "8px",
            padding: "0",
            maxHeight: "60vh",
          }}
        >
          <div className={`${styles.customModal}`}>
            <div className={`${styles.contentBox} ${contentContainerStyle}`}>
              {needIcon ? (
                <div className={`${styles.iconBox}`}>
                  {iconRender ? iconRender() : <WarningIcon />}
                </div>
              ) : (
                ""
              )}
              <div className={`${styles.title}`}>{title}</div>
              <div className={`${styles.content}`}>
                {typeof content === "function" ? content() : content}
              </div>
            </div>
            <div className={`${styles.buttonBox} ${!isHaveCancel ? styles.fullWidth : ""}`}>
              {isHaveCancel ? (
                <Button type="text" className={`${styles.cancelBtn}`} onClick={onCancel}>
                  {cancelBtnText}
                </Button>
              ) : null}

              <Button
                type="primary"
                className={`${styles.okBtn} ${!isHaveCancel ? styles.fullWidth : ""}`}
                onClick={onOk}
                style={{
                  ...okBtnStyle,
                }}
              >
                {okBtnText}
              </Button>
            </div>
          </div>
        </Modal>
      ) : (
        <Modal
          visible={isModalVisible}
          onCancel={handleCloseModal}
          footer={null}
          width={360}
          centered
          closable={closable ? closable : false}
          maskClosable={maskClosable}
          title={header === true ? title : undefined}
          closeIcon={<CloseCircleOutlined className={styles.icon} />}
          destroyOnClose
          className={"custom-modal-type-pc"}
          bodyStyle={{
            borderRadius: "16px",
            padding: "0",
            maxHeight: "60vh",
            background: "var(--popup-background)",
            ...customBodyStyle,
          }}
        >
          <div className={`${styles.customPcModal}`}>
            {/* <div className={styles.header}>
              <div className={`${styles.title}`}>{title}</div>
            </div> */}
            <div className={`${styles.contentBox} ${contentContainerStyle}`}>
              {needIcon ? (
                <div className={`${styles.iconBox}`}>
                  {iconRender ? iconRender() : <WarningIconPc />}
                </div>
              ) : (
                ""
              )}
              {header === false ? <div className={`${styles.title}`}>{title}</div> : ""}
              <div className={`${styles.content}`}>
                {typeof content === "function" ? content() : content}
              </div>
            </div>
            <div className={`${styles.buttonBox} ${!isHaveCancel ? styles.fullWidth : ""}`}>
              {isHaveCancel ? (
                <Button type="text" className={`${styles.cancelBtn}`} onClick={onCancel}>
                  {cancelBtnText}
                </Button>
              ) : null}

              <Button
                type="primary"
                className={`${styles.okBtn} ${!isHaveCancel ? styles.fullWidth : ""}`}
                onClick={onOk}
                style={{
                  ...okBtnStyle,
                }}
              >
                {okBtnText}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default CustomModal;
