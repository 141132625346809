import { useMutation } from "@tanstack/react-query";
import { apiUpdateRecentPlayGameStatus } from "../service";

export const usePutRecentPlayStatus = (options) => {
  return useMutation(
    (params) =>
      apiUpdateRecentPlayGameStatus(params).then((res) => {
        return res.data.data;
      }),
    {
      onError: (error) => {
        console.debug(JSON.stringify("ERR", error));
      },
      onSuccess: options.onSuccess,
    },
  );
};
