const Bonus = (props) => {
  const { color, size } = props;
  return (
    <svg
      width={size ?? 54}
      height={size ?? 54}
      viewBox="0 0 54 54"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.862 24.917V52H10.28a4.167 4.167 0 0 1-4.167-4.167V24.917h18.75zm25 0v22.916A4.167 4.167 0 0 1 45.695 52H31.112V24.917h18.75zM15.863 2c.06.02.118.057.178.06 1.645.102 3 .802 4.18 1.892.792.731 1.61 1.435 2.418 2.15.57.505 1.143 1.008 1.73 1.525a5.22 5.22 0 0 1 1.946-1.186 4.839 4.839 0 0 1 1.7-.291c.659.001 1.269.125 1.832.358.662.254 1.26.638 1.76 1.122l-.047.042c1.473-1.305 2.914-2.572 4.344-3.85.842-.752 1.785-1.324 2.901-1.587.433-.103.877-.158 1.316-.235h.385c.172.029.344.07.517.085 3.424.294 6.089 3.435 5.67 6.768-.105.844-.436 1.66-.675 2.521l4.886.001c1.15 0 2.083.933 2.083 2.083v6.25c0 1.15-.933 2.084-2.083 2.084H5.07a2.083 2.083 0 0 1-2.083-2.084v-6.25c0-1.15.933-2.083 2.083-2.083l5.03-.001-.04-.084c-.042-.09-.07-.152-.1-.211-1.652-3.14-.384-6.901 2.896-8.382.81-.366 1.745-.472 2.622-.697h.385zm21.42 5.165a524.05 524.05 0 0 0-4.104 3.816c.011.13.016.262.016.394h8.554c.378-.409.649-.913.758-1.46.251-1.251-.26-2.522-1.293-3.215-1.26-.845-2.686-.683-3.93.465zm-22.062-.689c-1.15.526-1.766 1.457-1.815 2.739-.032.84.261 1.595.776 2.16h8.597c0-.162.007-.321.021-.48l.712.648c-1.696-1.527-3.361-3.09-5.067-4.605-.954-.847-2.07-.99-3.224-.462z"
        fill={color ?? "var(--color-text-stable)"}
        fillRule="evenodd"
      />
    </svg>
  );
};
export default Bonus;
