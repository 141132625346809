import { Swiper, SwiperSlide } from "swiper/react";
import localStorageKey from "../../../enumerations/localStorageKey";
import { useGetAllBanner } from "../../../hooks/Home/home.hook";
import { checkUrlReg } from "../../../plugins/utils";
import styles from "./style.module.scss";

// Import Swiper styles
import { useNavigate } from "react-router-dom";
import { Autoplay, EffectCreative, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/effect-creative";
import "swiper/css/navigation";
import { useLogin } from "../../../context/LoginContext";
import { useWheel } from "../../../context/WheelContext";
import { useFacebookPixel } from "../../../hooks/fbPixel/fbPixel.hooks";

const Carousel3D = ({
  bannerStyle = "",
  prevTranslateEffect = ["-22%", 0, -500],
  nextTranslateEffect = ["22%", 0, -500],
  showNavigation = false,
}) => {
  const { checkIfLogin } = useLogin();
  const { handleShowWheelPopup } = useWheel();
  const navigate = useNavigate();
  const CURRENCY = localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);

  const { isHavePixelIntegration, pixelCreateCustomTracker } = useFacebookPixel();

  const { data: allBanner, isLoading: isLoadingAllBanner } = useGetAllBanner({
    enabled: !!CURRENCY,
  });

  const itemOnClick = (item) => {
    if (!checkIfLogin()) return;
    if (isHavePixelIntegration) {
      pixelCreateCustomTracker({ eventId: "bannerClick", props: item });
    }
    if (item.type === "promo") {
      if (item.promotion_type === "PRREEC") {
        handleShowWheelPopup({ wheelId: item.promotion_id });
      } else {
        navigate(`/bonus?type=${item.promotion_type}&id=${item.promotion_id}`);
      }
    } else {
      if (!item.url) return;
      checkUrlReg(item.url);
    }
  };

  return (
    <div className={styles.bannerContainer}>
      {allBanner?.length > 0 && isLoadingAllBanner === false ? (
        <Swiper
          className="custom-swiper"
          spaceBetween={0}
          slidesPerView={"auto"}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          grabCursor={true}
          loop={true}
          navigation={showNavigation}
          initialSlide={1}
          effect={"creative"}
          creativeEffect={
            allBanner?.length > 1
              ? {
                  prev: {
                    translate: prevTranslateEffect,
                  },
                  next: {
                    translate: nextTranslateEffect,
                  },
                  perspective: true,
                }
              : {}
          }
          modules={[EffectCreative, Navigation, Autoplay]}
          loopedSlides={allBanner?.length || 3}
          direction={"horizontal"}
        >
          {[...allBanner.slice(-1), ...allBanner, ...allBanner.slice(0, -1)]?.map((item, index) => {
            return (
              <SwiperSlide
                key={`${item.id || item.promotion_id}_${index}`}
                onClick={() => {
                  itemOnClick(item);
                }}
              >
                <div className={`${styles.itemContainer} ${bannerStyle} banner3d-container`}>
                  <div
                    // src={item.img}
                    style={{ backgroundImage: `url(${item.img}` }}
                    className={`${styles.image} banner-image`}
                  />
                  {/* TEST
                </img> */}
                </div>
              </SwiperSlide>
            );
          })}
          {/* <swiper-slide>Slide 1</swiper-slide>
        <swiper-slide>Slide 2</swiper-slide>
        <swiper-slide>Slide 3</swiper-slide> */}
        </Swiper>
      ) : (
        <div className={styles.noData} />
      )}

      {/* <Carousel
          slides={items}
          goToSlide={currentSlide}
          offsetRadius={2}
          animationConfig={config.gentle}
        /> */}
    </div>
  );
};

export default Carousel3D;
