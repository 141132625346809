import { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import { FormModal, PCBackButton, TabButton } from "..";
import { useGetCustomerService } from "../../hooks/customerService/customerService.hook";
import ForgetPasswordForm from "../../layouts/components/PC/HeaderComponent/components/ForgetPasswordForm";
import { CSCurrencyPicker } from "../../layouts/components/PC/HeaderComponent/components/ForgetPasswordForm/components";
import LoginForm from "../../layouts/components/PC/HeaderComponent/components/LoginForm";
import RegisterForm from "../../layouts/components/PC/HeaderComponent/components/RegisterForm";
import { checkUrlReg } from "../../plugins/utils";
import styles from "./style.module.scss";

const PCAuthenticationPopup = ({ visible = true, setVisible = () => {}, activeMenu = "login" }) => {
  const { t } = useTranslation();
  const [currentActiveMenu, setCurrentActiveMenu] = useState(activeMenu);
  const [hideFooter, setHideFooter] = useState(false);
  const [prevPage, setPrevPage] = useState("");
  const { data: csLink } = useGetCustomerService({});
  const authMenu = [
    {
      key: "login",
      text: t("win.normal.login"),
      hasIcon: false,
      icon: ``,
      type: "tab",
    },
    {
      key: "register",
      text: t("normal.signup"),
      hasIcon: false,
      icon: ``,
      type: "tab",
    },
    {
      key: "forget_password",
      text: t("forgot.forgotPassword"),
      hasIcon: false,
      icon: ``,
      type: "stack",
      backTo: "login",
    },
    {
      key: "cs",
      text: "",
      hasIcon: false,
      icon: ``,
      type: "stack",
      backTo: "",
    },
  ];

  const onTabPress = (selectedMenu) => {
    setCurrentActiveMenu(selectedMenu.key);
  };

  const renderModalSider = () => {
    return (
      <div className={styles.siderContainer}>
        <div
          className={styles.siderTitle}
          dangerouslySetInnerHTML={{ __html: t("popup.register.title") }}
        />
      </div>
    );
  };

  const renderHeader = () => {
    const activeMenuObj = authMenu.find((menu) => menu.key === currentActiveMenu);
    switch (activeMenuObj.key) {
      case "login":
      case "register":
        return (
          <div className={styles.tabContainer}>
            {authMenu
              .filter((menu) => menu.key !== "forget_password")
              .map((menu) => {
                return (
                  <TabButton
                    key={menu.key}
                    onClick={() => onTabPress(menu)}
                    hasIcon={menu.hasIcon}
                    selectedKey={currentActiveMenu}
                    currentKey={menu.key}
                    text={menu.text}
                    buttonContainerStyle={styles.tabButtonStyle}
                  />
                );
              })}
          </div>
        );
      case "forget_password":
        return null;
      default:
        break;
    }
  };

  const onOpenCS = () => {
    // setPrevPage(currentActiveMenu);
    // setCurrentActiveMenu("cs");
    checkUrlReg(csLink, window.open(csLink));
  };

  const onPressBack = () => {
    setCurrentActiveMenu(prevPage);
    setPrevPage("");
  };

  const renderBody = () => {
    const activeMenuObj = authMenu.find((menu) => menu.key === currentActiveMenu);
    switch (activeMenuObj.key) {
      case "login":
        return (
          <LoginForm setCurrentActiveMenu={setCurrentActiveMenu} setHideFooter={setHideFooter} />
        );
      case "register":
        return (
          <RegisterForm setCurrentActiveMenu={setCurrentActiveMenu} setHideFooter={setHideFooter} />
        );

      case "forget_password":
        return (
          <ForgetPasswordForm
            setCurrentActiveMenu={setCurrentActiveMenu}
            backTo={activeMenuObj.backTo}
            setHideFooter={setHideFooter}
          />
        );
      case "cs":
        return (
          <div className={styles.csContainer}>
            <PCBackButton onPressBack={onPressBack} />
            <CSCurrencyPicker setHideFooter={setHideFooter} />
          </div>
        );
      default:
        break;
    }
  };

  const renderFooter = () => {
    if (hideFooter) return null;
    return (
      <div className={styles.csLinkFooter} onClick={onOpenCS}>
        {t("normal.customerService")}
      </div>
    );
  };

  useEffect(() => {
    setCurrentActiveMenu(activeMenu);
  }, [activeMenu]);

  return (
    <FormModal visible={visible} siderContent={renderModalSider()} setVisible={setVisible}>
      <div className={styles.container}>
        <div className={styles.headerContainer}>{renderHeader()}</div>
        <Scrollbars autoHide className={styles.bodyContainer}>
          {renderBody()}
        </Scrollbars>
        <div className={styles.footerContainer}>{renderFooter()}</div>
      </div>
    </FormModal>
  );
};

export default PCAuthenticationPopup;
