import { LotteryPopHeader } from "../../../../../components";
import LotteryPopBody from "../../../../../components/Home/LotteryPopBody";
import styles from "./style.module.scss";
import "./style.scss";

const LotteryPop = ({
  setShowLotteryDrawer = () => {},
  setShowNotification = () => {},
  showNotification = true,
}) => {
  const GAME_CATEGORY_TYPE = "LOT";
  const GAME_CATEGORY_DETAIL_TYPE = "LOTEC";
  const GAME_TYPE = "LOTEC5";
  const GAME_WAY_TYPE = "LOTEC5DXDS";
  const BET_POSITION = "W";
  const GAME_METHOD_CODE = "LOTECDXDS";

  return (
    <div className={styles.lotteryShadow} onClick={() => setShowLotteryDrawer(false)}>
      <div className={styles.lotteryContainer} onClick={(e) => e.stopPropagation()}>
        <LotteryPopHeader
          showSwitchSetting={true}
          GAME_TYPE={GAME_TYPE}
          GAME_WAY_TYPE={GAME_WAY_TYPE}
          showNotification={showNotification}
          setShowNotification={setShowNotification}
        />
        <LotteryPopBody
          GAME_TYPE={GAME_TYPE}
          GAME_WAY_TYPE={GAME_WAY_TYPE}
          BET_POSITION={BET_POSITION}
          GAME_METHOD_CODE={GAME_METHOD_CODE}
          GAME_CATEGORY_TYPE={GAME_CATEGORY_TYPE}
          GAME_CATEGORY_DETAIL_TYPE={GAME_CATEGORY_DETAIL_TYPE}
        />
      </div>
    </div>
  );
};

export default LotteryPop;
