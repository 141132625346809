import QueueAnim from "rc-queue-anim";
import { useEffect, useState } from "react";
import { useGameWebsocket } from "../../../../../context/WebsocketGameContext";
import animationPhaseEnum from "../BallBackgroundAnimation/enumeration/animationPhaseEnum";
import ballImageEnum from "./enumerations/ballImageEnum";
import positionEnum from "./enumerations/positionEnum";
import styles from "./style.module.scss";

const BallCollection = ({ currentAnimationState, latestNumber }) => {
  const { prevPeriod } = useGameWebsocket();
  const [ballNumbers, setBallNumbers] = useState([0, 0, 0, 0, 0]);
  const [show, setShow] = useState(false);

  const assignNumberToBall = () => {
    if (prevPeriod && prevPeriod.lottery_number) {
      const numbers = prevPeriod?.data?.other_number.split("");
      numbers.push(prevPeriod.lottery_number);
      return numbers;
    }
    if (latestNumber) {
      const numbers = latestNumber[1].data?.other_number.split("");
      numbers.push(latestNumber[1].lottery_number);
      return numbers;
    }
  };
  useEffect(() => {
    switch (currentAnimationState) {
      case animationPhaseEnum.ZOOMING: {
        const balls = assignNumberToBall();
        setBallNumbers(balls);
        break;
      }
      case animationPhaseEnum.DRAW_BALL: {
        setShow(true);
        break;
      }
      case animationPhaseEnum.RESET: {
        setShow(false);
        break;
      }
      default:
        break;
    }
  }, [currentAnimationState]);

  return (
    <div className={styles.container}>
      <QueueAnim
        animConfig={[{ opacity: 1, rotate: 0, translateY: -60 }]}
        leaveReverse={true}
        ease={["easeInSine", "easeOutSine"]}
        duration={[900, 0]}
        interval={[200, 0]}
        appear={false}
      >
        {show === true
          ? positionEnum.map((pos, index) => {
              const image =
                index < 4
                  ? ballImageEnum.rand[ballNumbers[index]]
                  : ballImageEnum.result[ballNumbers[index]];
              // return <Ball key={index} image={image} bottom={pos.bottom} left={pos.left} />;
              return (
                <img
                  key={pos.key}
                  src={image}
                  alt={""}
                  className={styles.image}
                  style={{
                    bottom: pos.bottom,
                    left: pos.left,
                    opacity: 0,
                    transform: `rotate(${180}deg)`,
                  }}
                />
              );
            })
          : null}
      </QueueAnim>
    </div>
  );
};

export default BallCollection;
