const User = (props) => {
  const { color, size } = props;
  return (
    <svg
      width={size ?? 54}
      height={size ?? 54}
      viewBox="0 0 54 54"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27 2c9.22 0 16.696 7.462 16.696 16.667a16.61 16.61 0 0 1-5.407 12.279c6.024 1.755 9.988 4.792 11.891 9.11.192.4.353.818.48 1.25l.035.102c.197.718.305 1.476.305 2.259A8.333 8.333 0 0 1 42.667 52H11.333a8.333 8.333 0 0 1-7.425-12.12l.011-.026c1.937-4.21 5.868-7.179 11.795-8.907a16.606 16.606 0 0 1-5.41-12.28C10.304 9.462 17.78 2 27 2zm7.188 18.563a2.09 2.09 0 0 0-2.952.032c-1.31 1.338-2.744 1.968-4.406 1.968-1.643 0-2.995-.612-4.185-1.915a2.09 2.09 0 0 0-2.948-.135 2.08 2.08 0 0 0-.136 2.943c1.971 2.157 4.437 3.274 7.269 3.274 2.812 0 5.31-1.1 7.39-3.221a2.08 2.08 0 0 0-.032-2.946z"
        fill={color ?? "var(--color-text-stable)"}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default User;
