import { Button } from "antd";
import styles from "./style.module.scss";
import "./style.scss";

const PCButton = ({
  block = false,
  disabled = false,
  size = "",
  type = "",
  htmlType = "",
  shape = "default",
  onClick = () => {},
  icon = undefined,
  iconStyle = "",
  customButtonStyle = "",
  children,
}) => {
  const renderIcon = () => {
    if (icon) {
      return (
        <img className={`${styles.buttonIcon} ${iconStyle} pc-icon`} src={icon} alt={"icon"} />
      );
    }
  };

  return (
    <Button
      icon={renderIcon()}
      disabled={disabled}
      block={block}
      size={size}
      shape={shape}
      className={`custom-pc-button ${customButtonStyle}`}
      type={type}
      htmlType={htmlType}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default PCButton;
