import { FloatingBubble } from "antd-mobile";
import vars from "../../assets/styles/_variables.scss";

function CustomFloatingBubble(props) {
  const isPcScreen = Boolean(window.innerWidth > vars.maxWidthInPc.replace("px", ""));
  const { positionRight = "24px", edgeDistance = "24px" } = props;
  return (
    <FloatingBubble
      axis={"xy"}
      magnetic="x"
      {...props}
      style={{
        "--initial-position-right": isPcScreen
          ? `calc((${window.innerWidth}px - ${vars.maxWidthInPc}) / 2 + ${positionRight})`
          : positionRight,
        "--initial-position-bottom": "24px",
        "--edge-distance": isPcScreen
          ? `24px calc((${window.innerWidth}px - ${vars.maxWidthInPc}) / 2 + ${edgeDistance})`
          : edgeDistance,
        "--background": "#fff",
        "--z-index": "100000",
        ...props.style,
      }}
    >
      {props.children}
    </FloatingBubble>
  );
}

export default CustomFloatingBubble;
