import React from "react";
import { useTranslation } from "react-i18next";

import Scrollbars from "react-custom-scrollbars";
import GradientTitle from "../../GradientTitle";
import styles from "./style.module.scss";

function Description({
  churnStandard = "",
  description = "",
  startAt = "",
  endAt = "",
  pc = false,
}) {
  const { t } = useTranslation();

  const renderDescription = () => {
    if (pc === true) {
      return (
        <Scrollbars autoHide className={styles.desc}>
          <div dangerouslySetInnerHTML={{ __html: description }} />
          {/* <ul>
        <li>{t("bonusWheel.rule1", { betAmount: moneyFormat(churnStandard) })}</li>
        <li>{t("bonusWheel.rule2")}</li>
        <li>{t("bonusWheel.rule3")}</li>
        <li>{t("bonusWheel.rule4")}</li>
      </ul> */}
        </Scrollbars>
      );
    }
    return <div className={styles.desc} dangerouslySetInnerHTML={{ __html: description }} />;
  };

  return (
    <div className={`${styles.descriptionContainer} ${pc ? styles.pc : ""}`}>
      <GradientTitle
        renderTitle={() => <span className={styles.title}>{t("bonusWheel.rule.title")}</span>}
        colorType={"gray"}
      />
      <div className={styles.activityTime}>
        <div className={styles.text}>{t("bonusWheel.activityTime")}</div>
        <div className={styles.time}>
          {startAt} - {endAt}
        </div>
      </div>
      {renderDescription()}
    </div>
  );
}

export default Description;
