export const checkStatusEnum = {
  NOT_CHECK: {
    key: "notCheck",
    src: "/images/dailyCheck/treasure_not_check.gif",
    name: "treasure_not_check",
    showType: true,
    buttonRender: (props) => {
      return (
        <img
          src={"/images/dailyCheck/check_btn.png"}
          alt="check_btn"
          className={props.styles.checkBtnImg}
          onClick={props.onClick}
        />
      );
    },
  },
  CHECKING: {
    key: "checking",
    src: "/images/dailyCheck/treasure_checking.gif",
    name: "treasure_checking",
    showType: true,
    buttonRender: (props) => {
      return (
        <img
          src={"/images/dailyCheck/check_btn.png"}
          alt="check_btn"
          className={props.styles.checkBtnImg}
          onClick={props.onClick}
        />
      );
    },
  },
  CHECKED: {
    key: "checked",
    src: "/images/dailyCheck/treasure_checked.png",
    name: "treasure_checked",
    showType: false,
    buttonRender: (props) => {
      return <div className={props.styles.checkText}>{props.t("dailyCheck.button.checked")}</div>;
    },
  },
};
