import { Avatar, Layout, Menu } from "antd";
import styles from "./style.module.scss";
import "./style.scss";
const { Sider, Content } = Layout;

const contentStyle = {
  textAlign: "center",
  borderTopRightRadius: "16px",
  borderBottomRightRadius: "16px",
  color: "var(--text-h1)",
  background: "var(--content-background)",
};

const siderStyle = {
  textAlign: "center",
  borderTopLeftRadius: "16px",
  borderBottomLeftRadius: "16px",
  color: "var(--contentmenu-item-text-active)",
  background: "var(--contentmenu-background",
};

function PCCustomMenuContent({ menu = [], needIcon = false, content = "", selectedKeys = [] }) {
  const renderIcon = (item) => {
    if (typeof item.icon === "string") {
      return <Avatar src={item.icon} size={36} shape={"circle"} style={item.iconStyle} />;
    } else if (typeof item.icon === "object") {
      return <div className={styles.iconContainer}>{item.icon}</div>;
    }
  };
  return (
    <div className="menu-layout">
      <Layout style={{ background: "none", minHeight: "600px", marginBottom: "16px" }}>
        <Sider style={siderStyle}>
          <div className="demo-logo-vertical" />
          <Menu
            theme="dark"
            selectedKeys={selectedKeys}
            items={menu.map((item, index) => ({
              key: item.key,
              label: item.title,
              onClick: item.onClick,
              icon: needIcon ? renderIcon(item) : "",
            }))}
          />
        </Sider>
        <Layout style={{ background: "none" }}>
          <Content style={contentStyle}>
            {typeof content === "function" ? content() : content}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}

export default PCCustomMenuContent;
