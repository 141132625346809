import { CloseCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { Rule, Treasure } from "./components";
import styles from "./style.module.scss";

const DailyCheck = ({ visible = false, setVisible = () => {} }) => {
  const closeModal = () => {
    setVisible(false);
  };

  return (
    <Modal
      width={839}
      height={652}
      className={"wheel-modal"}
      visible={visible}
      centered
      footer={null}
      closable={false}
      destroyOnClose={true}
    >
      <div className={styles.container}>
        <div className={styles.closeButton} onClick={closeModal}>
          <CloseCircleOutlined className={styles.icon} />
        </div>
        <div className={styles.treasureImgContainer}>
          <img
            className={styles.treasureImg}
            src="/images/dailyCheck/treasure_logo.png"
            alt="treasure_logo"
          />
        </div>
        <div
          className={styles.bodyContainer}
          style={{
            background: `url('/images/dailyCheck/background.png') no-repeat top / contain `,
          }}
        >
          <div className={styles.treasureContainer}>
            <Treasure />
          </div>
          <div className={styles.ruleContainer}>
            <Rule />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DailyCheck;
