import { SyncOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PRODUCTION_NAME_DEV } from "../../../config";
import { useLogin } from "../../../context/LoginContext";
import { useGetUserWallet } from "../../../hooks/wallet/wallet.hooks";
import { moneyFormat } from "../../../plugins/numberFormat";
import PCButton from "../../PCButton";
import styles from "./style.module.scss";
const BalanceViewer = ({
  showReload = false,
  showDeposit = false,
  depositUrl = "",
  containerStyle = "",
}) => {
  const { t } = useTranslation();
  const { checkIfLogin } = useLogin();
  const { data: walletData, refetch, isFetching } = useGetUserWallet({});
  const { userInfo } = useLogin();
  const navigate = useNavigate();

  const handleReloadBalance = () => {
    refetch();
  };

  const renderReload = () => {
    if (showReload) {
      return (
        <div onClick={handleReloadBalance} className={styles.reloadContainer}>
          <SyncOutlined className={styles.icon} spin={isFetching} />
        </div>
      );
    }
  };

  const handleDepositOpen = () => {
    if (!checkIfLogin()) return;
    navigate("/account?tab=deposit");
  };

  const renderDeposit = () => {
    if (showDeposit) {
      return (
        <PCButton
          customButtonStyle={"secondary-button"}
          icon={`/images/logo/${PRODUCTION_NAME_DEV}/pc_deposit.svg`}
          type={"primary"}
          onClick={handleDepositOpen}
          htmlType={"button"}
          size={"small"}
          shape={"round"}
        >
          {t("normal.deposit")}
        </PCButton>
      );
    }
  };

  return (
    <div className={`${styles.balance} ${containerStyle}`}>
      <div className={styles.currency}>{userInfo?.currency}</div>
      <div className={styles.moneyGroup}>
        <div className={`${styles.money}`}>
          {walletData?.money ? moneyFormat(walletData.money, 2, 2).split(".")[0] : "0"}
        </div>
        <div className={`${styles.moneyFraction}`}>
          .{walletData?.money ? moneyFormat(walletData.money, 2, 2).split(".")[1] : "00"}
        </div>
      </div>
      {renderReload()}
      {renderDeposit()}
    </div>
  );
};
export default BalanceViewer;
