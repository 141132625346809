import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import localStorageKey from "../../../../../../../enumerations/localStorageKey";
import { moneyFormat } from "../../../../../../../plugins/numberFormat";
import styles from "./style.module.scss";

const CURRENCY = localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);

const WithdrawItem = ({ account = "", amount = 0, time = "" }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.itemContainer}>
      <div className={styles.account}>{account}</div>
      <div className={styles.amount}>{`${t(`currency.symbol.${CURRENCY}`)}${moneyFormat(
        amount,
      )}`}</div>
      <div className={styles.time}>{dayjs(time).format("hh:mm")}</div>
    </div>
  );
};

export default WithdrawItem;
