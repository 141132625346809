import { useRef } from "react";
import Countdown from "react-countdown";
import { useTranslation } from "react-i18next";
import { useGameWebsocket } from "../../../../../context/WebsocketGameContext";
import gameStateEnum from "../../enumeration/gameStateEnum";
import PanelImage from "./images/background.png";
import styles from "./style.module.scss";

const ResultViewer = ({
  setCurrentGameState = () => {},
  resultTimer = 4,
  flyRocket = () => {},
  latestNumber,
}) => {
  const { t } = useTranslation();
  const { prevPeriod } = useGameWebsocket();
  const currentDate = useRef(Date.now());
  const TIMER = resultTimer * 1000; //10seconds

  const renderLotteryNumber = (_prevPeriod, _latestNumber) => {
    if (_prevPeriod && _prevPeriod.lottery_number) {
      return `${prevPeriod.lottery_number} `;
    }
    if (_latestNumber) {
      return `${latestNumber[1]?.lottery_number} `;
    }
    return "";
  };

  return (
    <div className={`${styles.container}`}>
      <div className={styles.subContainer}>
        <img className={styles.image} src={PanelImage} />
        <div className={styles.textContainer}>
          <div className={styles.title}>{t("win.launchgame.result.title")}</div>
          <div className={styles.result}>
            {renderLotteryNumber(prevPeriod, latestNumber)}
            <span className={styles.km}>{t("win.launchgame.km")}</span>
          </div>
          <Countdown
            date={currentDate.current + TIMER}
            intervalDelay={0}
            precision={2}
            onComplete={() => {
              setCurrentGameState(gameStateEnum.BET_PHASE);
              flyRocket();
            }}
            renderer={(props) => {
              return (
                <>
                  <div className={styles.timer}>{`${(props.seconds + 100)
                    .toString()
                    .substring(1, 3)}:${(props.milliseconds + 10000)
                    .toString()
                    .substring(1, 3)}s`}</div>
                </>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ResultViewer;
