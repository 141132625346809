import { Form } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PCMobileForm, PCModal } from "../index.js";

import { usePostMobileVerify, usePutForgetPassword } from "../../hooks/verification/mobile.hook.js";

const PCBindMobileModal = ({
  visible,
  setVisible,
  onBeforeFinish,
  isForgetPassword = false,
  account,
  title = null,
}) => {
  const [areaCodeSelected, setAreaCodeSelected] = useState();
  const { mutate: mobileVerify } = usePostMobileVerify({
    onSuccess: onBeforeFinish,
  });

  const { mutate: forgetPassword } = usePutForgetPassword({
    onSuccess: onBeforeFinish,
  });
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const onFinish = (form) => {
    if (isForgetPassword) {
      forgetPassword({ ...form, type: "mobile" });
    } else {
      mobileVerify({ ...form, area_code: areaCodeSelected });
    }
  };

  return (
    <>
      <PCModal
        isForm={true}
        formRef={form}
        isModalVisible={visible}
        setIsModalVisible={setVisible}
        title={title}
        onOk={onFinish}
      >
        <PCMobileForm
          isForgetPassword={isForgetPassword}
          account={account}
          formRef={form}
          modalVisible={visible}
          setAreaCodeSelected={setAreaCodeSelected}
          areaCodeSelected={areaCodeSelected}
        />
      </PCModal>
    </>
  );
};

export default PCBindMobileModal;
