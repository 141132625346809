import { requestGlobalUserData, request } from "../../apis";
import localStorageKey from "../../enumerations/localStorageKey";

const currency = window.localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);

export const apiGetUserAreaCode = (params) =>
  request("get", `/${currency}/user/area/code`, params, true, false);

export const apiPostUserMobileSend = (params) =>
  requestGlobalUserData("get", `/user/mobile/send`, params, true, false);

export const apiGetUserForgetSendVerify = (params) =>
  requestGlobalUserData("get", `/user/forget/send/verify`, params, true, false);

export const apiPostUserMobileVerify = (params) =>
  requestGlobalUserData("put", `/user/mobile/verify`, params, true, false);

export const apiPutUserForgetPassword = (params) =>
  requestGlobalUserData("put", `/user/forget/password`, params, true, false);
