export const ValidateBettingOptions = ({ betOptionSelectCount }) => {
  if (betOptionSelectCount <= 0) {
    return { valid: false, content: "lotterypop.validate.noBetOption" };
  }
  return { valid: true };
};

export const ValidateUserMoney = ({ walletAmount, betAmount, betOptionSelectCount }) => {
  if (walletAmount >= betAmount * betOptionSelectCount) return { valid: true };
  return { valid: false, content: "lotterypop.validate.notEnoughMoney", options: {} };
};

export const ValidateMaxMinBet = ({ betAmount, highest_single_bet, lowest_single_bet }) => {
  if (
    parseInt(highest_single_bet) < parseInt(betAmount) ||
    parseInt(lowest_single_bet) > parseInt(betAmount)
  ) {
    return {
      valid: false,
      content: "lotterypop.validate.maxMinBet",
      options: { max: highest_single_bet, min: lowest_single_bet },
    };
  }
  return { valid: true };
};

export const ValidateMaxPayout = ({ betAmount, odds, max_payout }) => {
  const minimumOdds = Math.min(...odds.split(","));
  if (max_payout <= minimumOdds * betAmount) {
    return { valid: false, content: "lotterypop.validate.maxPayout", options: { max: max_payout } };
  }
  return { valid: true };
};
