import { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { tip } from "../apis/utils";
import {
  CustomModal,
  CustomerServiceDrawer,
  PCAuthenticationPopup,
  PCVerificationPopup,
} from "../components";
import BindEmailPopup from "../components/BindEmailPopup";
import BindMobilePopup from "../components/BindMobilePopup";
import ConfirmValidatePopup from "../components/ConfirmValidatePopup";
import { DEFAULT_CURRENCY, TIMEZONE_CURRENCY } from "../config";
import localStorageKey from "../enumerations/localStorageKey";
import { useGetCustomerService } from "../hooks/customerService/customerService.hook";
import { useGetUserInfo, useLogout } from "../hooks/personal/personal.hook";
import { useDevice } from "./DeviceContext";

const LoginContext = createContext({
  isLogin: false,
  setIsLogin: () => {},
  checkIfLogin: () => {},
  userInfo: {},
  setIsHaveInvitation: () => {},
  isHaveInvitation: false,
  agentCode: undefined,
  setAgentCode: () => {},
  setShowCustomerDrawer: () => {},
  csLink: undefined,
  checkLoginProcess: () => {},
  setCheckLoginProcess: () => {},
  setUserInfo: () => {},
  setToken: () => {},
  openPCLoginPopup: () => {},
  openPCRegisterPopup: () => {},
  checkIfVerified: () => {},
});

export const LoginContextProvider = ({ children }) => {
  const { t } = useTranslation();
  // const [params] = useSearchParams();
  const navigate = useNavigate();

  const [showNeedLoginModal, setShowNeedLoginModal] = useState(false);
  const [showConfirmValidateModal, setShowConfirmValidateModal] = useState(false);
  const [showConfirmValidatePopup, setShowConfirmValidatePopup] = useState(false);
  const [showBindMobile, setShowBindMobile] = useState(false);
  const [showBindEmail, setShowBindEmail] = useState(false);
  const [isLogin, setIsLogin] = useState();
  const [isHaveInvitation, setIsHaveInvitation] = useState(false);
  const [agentCode, setAgentCode] = useState(undefined);
  const [registerLoginVisible, setRegisterLoginVisible] = useState(false);
  const [activeMenu, setActiveMenu] = useState();
  const [showPCVerificationModal, setShowPCVerificationModal] = useState(false);

  const { mutate: logout } = useLogout();
  const { isMobile } = useDevice();
  const { data: userInfo } = useGetUserInfo({
    enabled: !!isLogin,
  });

  const { data: csLink } = useGetCustomerService({});
  const [showCustomerDrawer, setShowCustomerDrawer] = useState(false);

  const [checkLoginProcess, setLocalCheckLoginProcess] = useState({
    process: () => true,
  });
  const setCheckLoginProcess = (newFunc) => {
    // checkLoginProcess = newFunc;
    setLocalCheckLoginProcess({ process: newFunc });
  };

  const checkIfVerified = () => {
    if (userInfo.mobile_status === 0 && userInfo.email_status === 0) {
      if (isMobile) {
        setShowConfirmValidateModal(true);
      } else {
        setShowPCVerificationModal(true);
      }
      return false;
    }
    return true;
  };

  const checkIfLogin = () => {
    if (!isLogin) {
      if (isMobile) {
        setShowNeedLoginModal(true);
      } else {
        if (isHaveInvitation) {
          openPCRegisterPopup();
        } else {
          openPCLoginPopup();
        }
      }
      return false;
    } else {
      return checkIfVerified();
    }
  };

  const getTimeZoneAndCurrency = () => {
    const currencySetting = window.localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);
    if (currencySetting == "rmb") {
      window.localStorage.setItem(localStorageKey.DEFAULT_CURRENCY, DEFAULT_CURRENCY);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else if (isLogin == false && !currencySetting) {
      //isLogin有可能是undefined
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const currency = TIMEZONE_CURRENCY[timeZone] ?? DEFAULT_CURRENCY;
      window.localStorage.setItem(localStorageKey.DEFAULT_CURRENCY, currency);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const setUserInfo = (userInfo) => {
    localStorage.setItem(
      localStorageKey.USER_INFO,
      JSON.stringify({
        userInfo: { user: userInfo },
      }),
    );
  };

  const openPCLoginPopup = () => {
    setActiveMenu("login");
    setRegisterLoginVisible(true);
  };

  const openPCRegisterPopup = () => {
    setActiveMenu("register");
    setRegisterLoginVisible(true);
  };

  const setToken = (token) => {
    localStorage.setItem(localStorageKey.TOKEN, JSON.stringify(token));
  };

  useEffect(() => {
    const userIsLogin = Boolean(
      window.localStorage.getItem(localStorageKey.USER_INFO) &&
        window.localStorage.getItem(localStorageKey.TOKEN),
    );
    setIsLogin(userIsLogin);
  }, []);

  useEffect(() => {
    setCheckLoginProcess(checkIfLogin);
  }, [userInfo, isLogin]);

  useEffect(() => {
    getTimeZoneAndCurrency();
  }, [isLogin]);

  return (
    <LoginContext.Provider
      value={{
        isLogin,
        setIsLogin,
        checkIfLogin,
        userInfo,
        setIsHaveInvitation,
        isHaveInvitation,
        agentCode,
        setAgentCode,
        setShowCustomerDrawer,
        csLink,
        checkLoginProcess,
        setCheckLoginProcess,
        setUserInfo,
        setToken,
        openPCLoginPopup,
        openPCRegisterPopup,
        checkIfVerified,
      }}
    >
      {children}

      {/* PC LOGIN MODAL */}
      <PCAuthenticationPopup
        visible={registerLoginVisible}
        setVisible={setRegisterLoginVisible}
        activeMenu={activeMenu}
      />
      {/* PC VERIVICATION MODAL*/}
      <PCVerificationPopup
        visible={showPCVerificationModal}
        account={userInfo?.account}
        setVisible={setShowPCVerificationModal}
      />
      {/* NEED LOGIN MODAL */}
      <CustomModal
        type={isMobile ? "mobile" : "pc"}
        isModalVisible={showNeedLoginModal}
        setIsModalVisible={setShowNeedLoginModal}
        title={t("normal.notLoggedIn")}
        content={t("normal.notLoggedIn.hint")}
        needIcon={true}
        okBtnText={t("normal.forwardTo")}
        cancelBtnText={t("normal.cancel")}
        onOk={() => {
          setShowNeedLoginModal(false);
          if (isHaveInvitation === true) {
            navigate(`/signup?invite=${agentCode}`);
          } else {
            navigate("/signin");
          }
        }}
      />
      {/* VALIDATION VERIFICATION */}
      <CustomModal
        type={isMobile ? "mobile" : "pc"}
        isModalVisible={showConfirmValidateModal}
        setIsModalVisible={setShowConfirmValidateModal}
        title={t("verifymodal.title")}
        content={t("verifymodal.content")}
        needIcon={true}
        okBtnText={t("verifymodal.goValidate")}
        cancelBtnText={t("normal.cancel")}
        onOk={() => {
          setShowConfirmValidateModal(false);
          setShowConfirmValidatePopup(true);
        }}
        onCancel={logout}
      />
      <ConfirmValidatePopup
        visible={showConfirmValidatePopup}
        setVisible={setShowConfirmValidatePopup}
        onMobileClick={() => {
          setShowBindMobile(true);
        }}
        onEmailClick={() => {
          setShowBindEmail(true);
        }}
      />
      {/* BIND EMAIL AND PHONE POPUPS */}
      <BindEmailPopup
        visible={showBindEmail}
        setVisible={setShowBindEmail}
        onBeforeFinish={() => {
          setShowBindEmail(false);
          setShowConfirmValidatePopup(false);
          setTimeout(() => {
            tip({ content: t("normal.success"), type: "success" });
            window.location.reload();
          }, 1000);
        }}
      />
      <BindMobilePopup
        visible={showBindMobile}
        setVisible={setShowBindMobile}
        onBeforeFinish={() => {
          setShowBindMobile(false);
          setShowConfirmValidatePopup(false);
          setTimeout(() => {
            tip({ content: t("normal.success"), type: "success" });
            window.location.reload();
          }, 1000);
        }}
      />
      <CustomerServiceDrawer
        csLink={csLink}
        visible={showCustomerDrawer}
        handleVisible={setShowCustomerDrawer}
      />
    </LoginContext.Provider>
  );
};
export const useLogin = () => {
  const LoginValue = useContext(LoginContext);
  return LoginValue;
};
