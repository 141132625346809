import { Form } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { tip } from "../../../../../../apis/utils";
import { CustomCheckBox, PCButton } from "../../../../../../components";
import CustomInput from "../../../../../../components/CustomInput";
import { useGetLangListByCurrency } from "../../../../../../components/LangSelector/hooks/langSelector.hook";
import { useLogin as useLoginData } from "../../../../../../context/LoginContext";
import localStorageKey from "../../../../../../enumerations/localStorageKey";
import { useLogin } from "../../../../../../hooks/Signin/login.hooks";
import { useFacebookPixel } from "../../../../../../hooks/fbPixel/fbPixel.hooks";
import { delCookie, getCookie, setCookie } from "../../../../../../plugins/cookie";
import styles from "./style.module.scss";
import "./style.scss";

const LoginForm = ({ setCurrentActiveMenu = () => {}, setHideFooter = () => {} }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isChecked, setIsChecked] = useState(localStorage.getItem("checkbox"));
  const [userCurrency, setUserCurrency] = useState();
  const { setUserInfo, setToken } = useLoginData();
  const { isHavePixelIntegration, pixelCreateCustomTracker } = useFacebookPixel();
  const {
    mutate: login,
    isError: isLoginError,
    isLoading: isLoginLoading,
    isSuccess: isLoginSuccess,
  } = useLogin();

  const { data: langList } = useGetLangListByCurrency(
    {
      enabled: !!userCurrency,
    },
    {
      currency: userCurrency,
    },
  );

  const onFinish = async (values) => {
    if (isChecked) {
      localStorage.setItem("checkbox", true);
    } else {
      localStorage.setItem("checkbox", false);
    }
    await login(
      {
        ...values,
        currency: localStorage.getItem(localStorageKey.DEFAULT_CURRENCY),
        lang: localStorage.getItem(localStorageKey.LANG_SETTING),
      },
      {
        onSuccess: (data) => {
          if (isHavePixelIntegration) {
            pixelCreateCustomTracker({ eventId: "login" });
          }
          if (isChecked) {
            let accountInfo = values.account;
            setCookie("accountInfo", accountInfo);
          } else {
            delCookie("accountInfo");
          }
          setUserInfo(data.info);
          setToken(data.token);
          setUserCurrency(data.info.currency);
          window.localStorage.setItem(localStorageKey.DEFAULT_CURRENCY, data.info.currency);
          tip({
            content: t("login.success"),
            type: "success",
          });
          // window.location.href = "/";
        },
        onError: (err) => {
          // tip({ content: err, type: "error" });
          console.debug("err", err);
        },
      },
    );
  };

  const loadAccountInfo = () => {
    let accountInfo = getCookie("accountInfo");
    if (Boolean(accountInfo) == false) {
      setIsChecked(false);
      return false;
    } else {
      setIsChecked(true);
      if (isChecked) {
        form.setFieldsValue({
          account: accountInfo,
        });
      }
    }
  };

  useEffect(() => {
    setHideFooter(false);
    loadAccountInfo();
  }, []);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onRememberCheck = (e) => {
    setIsChecked(e.target.checked);
  };

  useEffect(() => {
    setIsChecked(localStorage.checkbox);
  }, [localStorage.checkbox]);
  useEffect(() => {
    if (userCurrency && langList) {
      window.location.href = "/";
    }
  }, [userCurrency, langList]);

  return (
    <div className={styles.loginFormContainer}>
      <Form
        form={form}
        name="login"
        initialValues={{
          remember: isChecked,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        requiredMark={false}
        layout="vertical"
      >
        <CustomInput
          type="pc"
          name="account"
          label={""}
          placeholder={t("form.pleaseEnter", { name: t("normal.account") })}
          autoComplete={"false"}
          rules={[
            {
              required: true,
              message: t("form.required", { name: t("normal.account") }),
            },
          ]}
        />

        <CustomInput
          type="pc"
          name="password"
          label={""}
          placeholder={t("form.pleaseEnter", { name: t("signup.password") })}
          password={true}
          autoComplete={"false"}
          rules={[
            {
              required: true,
              message: t("form.required", { name: t("signup.password") }),
            },
            {
              min: 6,
              max: 16,
              message: t("normal.validation.length.error", {
                name: t("signup.password"),
                min: 6,
                max: 16,
              }),
            },
          ]}
        />
        <span
          className={styles.forgotPassword}
          onClick={() => {
            setCurrentActiveMenu("forget_password");
          }}
        >
          {t("login.forgotPassword")}
        </span>
        <PCButton block size="large" type="primary" htmlType="submit">
          {t("normal.login")}
        </PCButton>
        <div className={styles.checkboxContainer}>
          <CustomCheckBox
            name={"remember"}
            valuePropName={"checked"}
            onChange={onRememberCheck}
            checked={isChecked}
            title={t("signin.rememberMe")}
            type={"pc"}
          />
        </div>
      </Form>
    </div>
  );
};

export default LoginForm;
