import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PRODUCTION_NAME_DEV } from "../../../../config";
import { useLogin } from "../../../../context/LoginContext";
import styles from "./style.jpy188.module.scss";
const Footer = () => {
  const [currentMod, setCurrentMod] = useState("home");
  const { checkLoginProcess } = useLogin();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { checkIfLogin } = useLogin();

  useEffect(() => {
    updateCurrentMod();
  }, [window.location.pathname]);

  const updateCurrentMod = () => {
    const target = items.find((m) => m.path === window.location.pathname);
    setCurrentMod(target?.id);
  };

  const items = [
    {
      id: "lobby",
      name: t("1bbet.footer.lobby"),
      icon: `./images/${PRODUCTION_NAME_DEV}/home.png`,
      icon_active: `./images/${PRODUCTION_NAME_DEV}/home_active.png`,
      // icon_active: HomeIconActive,
      path: "/",
      style: "",
      textStyle: "",
    },
    {
      id: "promo",
      name: t("1bbet.footer.promo"),
      icon: `./images/${PRODUCTION_NAME_DEV}/promo.png`,
      icon_active: `./images/${PRODUCTION_NAME_DEV}/promo_active.png`,
      path: "/bonus",
      style: "",
      textStyle: "",
    },
    {
      id: "deposit",
      name: t("1bbet.footer.deposit"),
      icon: `./images/${PRODUCTION_NAME_DEV}/deposit.png`,
      icon_active: `./images/${PRODUCTION_NAME_DEV}/deposit_active.png`,
      path: "/exchange",
      style: "",
      textStyle: "",
    },
    {
      id: "profile",
      name: t("1bbet.footer.profile"),
      icon: `./images/${PRODUCTION_NAME_DEV}/profile.png`,
      icon_active: `./images/${PRODUCTION_NAME_DEV}/profile_active.png`,
      path: "/personal",
      style: "",
      textStyle: "",
    },
  ];

  const onHandleFooterClick = (item) => {
    if (!checkIfLogin()) return;

    if (!checkLoginProcess?.process()) {
      return;
    }

    navigate(item.path);
  };

  return (
    <div className={styles.footerContainer}>
      <div className={styles.footer}>
        {items.map((item) => {
          return (
            <div
              className={styles.footerItem}
              onClick={() => onHandleFooterClick(item)}
              key={item.id}
            >
              <div className={`${styles.iconContainer} ${item.style}`}>
                <img
                  src={`${
                    item.id === currentMod
                      ? require(`${item.icon_active}`)
                      : require(`${item.icon}`)
                  }`}
                  className={styles.icon}
                  alt={item.id}
                />
              </div>
              <div
                className={`${styles.text} ${item.id === currentMod ? styles.textActive : ""} ${
                  item.textStyle
                }`}
              >
                {item.name}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Footer;
