import { requestGlobalSettingData, requestMain } from "../../apis";
import { DEFAULT_CURRENCY } from "../../config";
import localStorageKey from "../../enumerations/localStorageKey";

const currency = window.localStorage.getItem(localStorageKey.DEFAULT_CURRENCY) ?? DEFAULT_CURRENCY;

export const apiGetCustomerService = (params) =>
  requestMain("get", `/${currency}/customerservice`, params, false, false);

export const apiGetCustomerServiceNoCurrency = (params) =>
  requestGlobalSettingData("get", `/customerservice`, params, true, true);
