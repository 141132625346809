import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDailyCheck } from "../../../../context/DailyCheckContext";
import { useCheck } from "../../../../hooks/dailyCheck/dailyCheck.hooks";
import CustomModal from "../../../CustomModal";
import { checkStatusEnum } from "../../enumeration/checkStatusEnum";
import ResultModal from "../ResultModal";
import Steps from "../Steps";
import styles from "./style.module.scss";
import "./style.scss";

const Treasure = () => {
  const { t } = useTranslation();
  const [showResultModal, setShowResultModal] = useState(false);
  const [checkStatus, setCheckStatus] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const { data, setting, activeDay, limitType, setActiveDay, refetchDailyCheckData } =
    useDailyCheck();

  const { mutate: check } = useCheck({
    onSuccess: () => {
      setCheckStatus(checkStatusEnum.CHECKING);
      setTimeout(() => {
        setCheckStatus(checkStatusEnum.CHECKED);
        setShowResultModal(true);
        setActiveDay((prev) => prev + 1);
        refetchDailyCheckData();
      }, 3000);
    },
  });

  useEffect(() => {
    if (data && !data.current_signin_day) {
      setCheckStatus(checkStatusEnum.CHECKED);
      return;
    }
    setCheckStatus(checkStatusEnum.NOT_CHECK);
  }, [data]);
  return (
    <>
      {data && limitType && (
        <div className={styles.mainContainer} style={{ paddingBottom: limitType.src && "40px" }}>
          <div
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: t("dailyCheck.row", { day: activeDay - 1 }) }}
          ></div>
          <Steps />
          <div
            className={styles.treasureContainer}
            style={{
              background: `url('/images/dailyCheck/treasure_background.png') no-repeat center / contain `,
            }}
          >
            {checkStatus.src && (
              <>
                <img src={checkStatus.src} alt={checkStatus.name} className={styles.treasure} />
                <div className={styles.bottomContainer}>
                  {checkStatus.buttonRender({
                    styles,
                    t,
                    onClick: () => {
                      if (setting[activeDay - 1].type !== 0) {
                        if (data[limitType.total_value] < setting[activeDay - 1].threshold) {
                          setModalContent(t("dailyCheck.error.notComplete"));
                          setIsModalVisible(true);
                          return;
                        }
                      }
                      check();
                    },
                  })}
                  {limitType.src && checkStatus.showType && (
                    <div className={styles.text} style={{ display: !limitType && "none" }}>
                      {t(`dailyCheck.limit.${limitType.key}`)}
                      <span
                        className={`${styles.amount} ${
                          data[limitType.total_value] >= setting[activeDay - 1].threshold
                            ? styles.pass
                            : ""
                        }`}
                      >
                        {data[limitType.total_value]}
                      </span>
                      / {setting[activeDay - 1].threshold}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          {limitType.src && checkStatus.showType ? (
            <img
              src={limitType.src}
              alt={limitType.name}
              className={styles.navigate}
              onClick={limitType.onClick}
            />
          ) : (
            ""
          )}
        </div>
      )}
      <ResultModal
        amount={setting[activeDay - 2]?.bonus_amount}
        isModalVisible={showResultModal}
        setIsModalVisible={setShowResultModal}
      />
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        content={modalContent}
        needIcon={true}
        okBtnText={t("normal.confirm")}
        isHaveCancel={false}
      />
    </>
  );
};

export default Treasure;
