const channelSettingsEnum = {
  LOTFEDERAL5: {
    maxTimer: 60 * 5,
  },
  LOTFEDERAL60: {
    maxTimer: 60 * 60,
  },
};

export default channelSettingsEnum;
