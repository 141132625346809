import { useMutation, useQuery } from "@tanstack/react-query";
import queryKey from "../../../enumerations/queryKey";
import {
  apiPostUserMobileSend,
  apiPostUserMobileVerify,
  apiGetUserAreaCode,
  apiPutUserForgetPassword,
  apiGetUserForgetSendVerify,
} from "../service";

export const usePostMobileSend = (options) => {
  return useMutation(
    (params) =>
      apiPostUserMobileSend(params).then((res) => {
        return res.data.data;
      }),
    {
      onError: (error) => {
        console.debug(JSON.stringify("ERR", error));
      },
    }
  );
};
export const useGetUserForgetSendVerify = (options) => {
  return useMutation(
    (params) =>
      apiGetUserForgetSendVerify(params).then((res) => {
        return res.data.data;
      }),
    {
      onError: (error) => {
        console.debug(JSON.stringify("ERR", error));
      },
    }
  );
};
export const usePostMobileVerify = (options = { onSuccess: () => {} }) => {
  return useMutation(
    (params) =>
      apiPostUserMobileVerify(params).then((res) => {
        return res.data.data;
      }),
    {
      onError: (error) => {
        console.debug(JSON.stringify("ERR", error));
      },
      onSuccess: options.onSuccess,
    }
  );
};

export const useGetUserAreaCode = (options) => {
  return useQuery(
    [queryKey.USER_AREA_CODE],
    () => apiGetUserAreaCode().then((res) => res.data.data),
    {
      ...options,
    }
  );
};
export const usePutForgetPassword = (options = { onSuccess: () => {} }) => {
  return useMutation(
    (params) =>
      apiPutUserForgetPassword(params).then((res) => {
        return res.data.data;
      }),
    {
      onError: (error) => {
        console.debug(JSON.stringify("ERR", error));
      },
      onSuccess: options.onSuccess,
    }
  );
};
