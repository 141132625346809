import { Input } from "antd-mobile";
import "./style.scss";

const WinCustomNumberInput = ({
  value = null,
  onChange = () => {},
  placeholder = "",
  inputStyle = {},
}) => {
  return (
    <div className="win-custom-number-input">
      <Input
        type={"number"}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        style={inputStyle}
      />
    </div>
  );
};

export default WinCustomNumberInput;
