import { Input } from "antd-mobile";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCategory } from "../../../../../context/CategoryContext";
import SearchIcon from "./images/SearchIcon";
import styles from "./style.module.scss";

const CustomSearchBar = ({
  setSearchValue = () => {},
  iconPosition = "right",
  customSearchStyle = "",
  customContainerStyle = "",
  currentCategoryCode = "",
  customIconStyle = "",
  customInputStyle = "",
  onFocus = () => {},
}) => {
  const { t } = useTranslation();
  const { selectedCategory } = useCategory();
  const [localSearchValue, setLocalSearchValue] = useState("");

  const handleSearch = useCallback(
    _.debounce(
      (searchValue) => {
        setSearchValue(searchValue);
      },
      [1000],
      { leading: false },
    ),
    [],
  );

  useEffect(() => {
    setLocalSearchValue("");
    setSearchValue("");
  }, [selectedCategory]);

  const onSearchValueChange = (val) => {
    setLocalSearchValue(val);
    handleSearch(val);
  };

  return (
    <div className={`${styles.container} ${customContainerStyle}`}>
      <div className={`${styles.searchContainer} ${customSearchStyle}`}>
        {iconPosition === "left" ? <SearchIcon /> : null}
        <Input
          className={`styles.searchInput ${customInputStyle}`}
          placeholder={t("home.search.placeholder")}
          type={"search"}
          value={localSearchValue}
          onChange={onSearchValueChange}
          onFocus={onFocus}
        />
        {iconPosition === "right" ? <SearchIcon /> : null}
      </div>
    </div>
  );
};

export default CustomSearchBar;
