const WarningIconPc = (props) => {
  const { size } = { props };
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Iconsax/Bold/infocircle" clip-path="url(#clip0_3520_8237)">
        <path
          id="Vector"
          d="M20 -0.00195312C8.97997 -0.00195312 -3.05176e-05 8.97822 -3.05176e-05 19.9984C-3.05176e-05 31.0187 8.97997 39.9988 20 39.9988C31.02 39.9988 40 31.0187 40 19.9984C40 8.97822 31.02 -0.00195312 20 -0.00195312ZM18.5 11.9983C18.5 11.1783 19.18 10.4983 20 10.4983C20.82 10.4983 21.5 11.1783 21.5 11.9983V21.9985C21.5 22.8185 20.82 23.4985 20 23.4985C19.18 23.4985 18.5 22.8185 18.5 21.9985V11.9983ZM21.84 28.7586C21.74 29.0186 21.6 29.2186 21.42 29.4186C21.22 29.5986 21 29.7386 20.76 29.8386C20.52 29.9386 20.26 29.9986 20 29.9986C19.74 29.9986 19.48 29.9386 19.24 29.8386C19 29.7386 18.78 29.5986 18.58 29.4186C18.4 29.2186 18.26 29.0186 18.16 28.7586C18.0572 28.5182 18.0029 28.26 18 27.9986C18 27.7386 18.06 27.4786 18.16 27.2386C18.26 26.9986 18.4 26.7786 18.58 26.5786C18.78 26.3986 19 26.2586 19.24 26.1586C19.7269 25.9585 20.273 25.9585 20.76 26.1586C21 26.2586 21.22 26.3986 21.42 26.5786C21.6 26.7786 21.74 26.9986 21.84 27.2386C21.94 27.4786 22 27.7386 22 27.9986C22 28.2586 21.94 28.5186 21.84 28.7586Z"
          fill="#F29A17"
        />
      </g>
      <defs>
        <clipPath id="clip0_3520_8237">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default WarningIconPc;
