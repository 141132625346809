import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomInput from "../CustomInput/index.jsx";

import "./style.scss";

import {
  useGetUserAreaCode,
  useGetUserForgetSendVerify,
  usePostMobileSend,
} from "./hooks/bind.hook";
const { Option } = Select;
const COOLDOWN_SEC = 60;
let cooldownNumber;
let cooldownInterval;
function PCMobileForm({
  isForgetPassword,
  account,
  formRef,
  isHideLabel = false,
  modalVisible,
  setAreaCodeSelected = () => {},
  areaCodeSelected,
}) {
  const [sendCount, setSendCount] = useState(0);
  const { data: areaCode } = useGetUserAreaCode({
    enabled: modalVisible === true,
  });
  const { mutate: mobileSend } = usePostMobileSend();
  const { mutate: forgetMobileSend } = useGetUserForgetSendVerify();
  // const [showSelectAreaCode, setShowAreaCode] = useState(false);
  // const [selectedCode, setSelectedCode] = useState();
  const currentAreaCode = areaCode?.find((a) => a.area_code === areaCodeSelected);
  const [cooldown, setCooldown] = useState();
  const { t } = useTranslation();
  const initCoolDown = () => {
    setCooldown(COOLDOWN_SEC);
    cooldownNumber = COOLDOWN_SEC;
    cooldownInterval = setInterval(() => {
      setCooldown(cooldownNumber - 1);
      cooldownNumber = cooldownNumber - 1;
      if (cooldownNumber <= 0) {
        setCooldown(undefined);
        cooldownNumber = undefined;
        clearInterval(cooldownInterval);
      }
    }, 1000);
  };

  const getCode = async () => {
    try {
      if (isForgetPassword) {
        forgetMobileSend({
          // ...params,
          // area_code: areaCodeSelected,
          type: "mobile",
          account,
        });
      } else {
        const mobileProviderArr = currentAreaCode?.mobile_provider?.split(",");
        await formRef.validateFields(["mobile"]);
        const params = {
          mobile: formRef.getFieldValue("mobile"),
          area_code: areaCodeSelected,
          type: mobileProviderArr[sendCount % mobileProviderArr?.length],
        };
        mobileSend(params);
      }

      initCoolDown();
      setSendCount(sendCount + 1);
    } catch (err) {
      console.error("err", err);
    }
  };
  const renderMobile = () => {
    if (isForgetPassword === true) return null;
    return (
      <CustomInput
        type="pc"
        name="mobile"
        label={isHideLabel ? "" : t("normal.mobile")}
        customClassNames="custom-mobile-input"
        addonBefore={
          // <div
          //   style={{
          //     display: "flex",
          //     justifyContent: "center",
          //     alignItems: "center",
          //     cursor: "pointer",
          //   }}
          //   onClick={() => {
          //     setShowAreaCode(true);
          //   }}
          // >
          //   <div>{currentAreaCode ? `+${currentAreaCode?.area_code}` : ""}</div>
          //   <Divider type="vertical" />
          // </div>
          <Select
            value={areaCodeSelected}
            onChange={(e) => {
              setAreaCodeSelected(e);
            }}
            className="area-code-select"
          >
            {areaCode?.map((a) => {
              return <Option value={a.area_code}>{"+" + a.area_code}</Option>;
            })}
          </Select>
        }
        placeholder={
          isHideLabel
            ? t("form.pleaseEnter", { name: t("normal.mobile") })
            : t("form.holder.pleaseEnter")
        }
        rules={[
          {
            required: true,
            message: t("form.required", { name: t("normal.mobile") }),
          },
        ]}
      />
    );
  };

  const renderForgetPasswordForm = () => {
    if (isForgetPassword === false) return null;
    return (
      <>
        <CustomInput
          type="pc"
          name="new_password"
          label={isHideLabel ? "" : t("signup.newPassword")}
          placeholder={
            isHideLabel
              ? t("form.pleaseEnter", { name: t("signup.newPassword") })
              : t("form.holder.pleaseEnter")
          }
          password={true}
          rules={[
            {
              required: true,
              message: t("form.required", {
                name: t("signup.newPassword"),
              }),
            },
            {
              min: 6,
              max: 16,
              message: t("normal.validation.length.error", {
                name: t("signup.password"),
                min: 6,
                max: 16,
              }),
            },
          ]}
        />
        <CustomInput
          type="pc"
          name="confirm_new_password"
          label={isHideLabel ? "" : t("signup.confirmNewPassword")}
          dependencies={["password"]}
          placeholder={
            isHideLabel
              ? t("form.pleaseEnter", { name: t("signup.confirmNewPassword") })
              : t("form.holder.pleaseEnter")
          }
          password={true}
          rules={[
            {
              required: true,
              message: t("form.required", {
                name: t("signup.confirmNewPassword"),
              }),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("new_password") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error(t("signup.confirmPasswordError")));
              },
            }),
          ]}
        />
      </>
    );
  };

  useEffect(() => {
    if (areaCode?.length > 0) {
      setAreaCodeSelected(areaCode[0]?.area_code);
    }
  }, [areaCode]);

  // useEffect(() => {
  //   console.log("areaCodeSelecte", areaCodeSelected);
  // }, [areaCodeSelected]);

  return (
    <>
      {renderMobile()}
      <CustomInput
        type="pc"
        name="code"
        label={isHideLabel ? "" : t("forgot.verifyMobileCode")}
        placeholder={
          isHideLabel
            ? t("form.pleaseEnter", { name: t("forgot.verifyMobileCode") })
            : t("form.holder.pleaseEnter")
        }
        addonAfter={
          <div
            style={{
              color: cooldown === undefined ? " var(--button-link-base)" : "var(--text-p1)",
              fontWeight: 500,
              cursor: cooldown === undefined ? "pointer" : "inherit",
            }}
            onClick={() => {
              if (cooldown === undefined) {
                getCode();
              }
            }}
          >
            {cooldown === undefined
              ? t("forgot.sendCode")
              : t("forgotPassword.cooldown", { sec: cooldown })}
          </div>
        }
        rules={[
          {
            required: true,
            message: t("form.required", {
              name: t("forgot.verifyMobileCode"),
            }),
          },
        ]}
      />

      {renderForgetPasswordForm()}
    </>
  );
}

export default PCMobileForm;
