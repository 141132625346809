import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DailyCheck } from "../components";
import { useGetDailyCheck } from "../hooks/dailyCheck/dailyCheck.hooks";

const DailyCheckContext = createContext({
  handleShowDailyCheckPopup: () => {},
  data: {},
  setting: [],
  activeDay: 1,
  limitType: {},
  setActiveDay: () => {},
  refetchDailyCheckData: () => {},
});

export const DailyCheckContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [setting, setSetting] = useState([]);
  const [activeDay, setActiveDay] = useState(1);
  const [limitType, setLimitType] = useState({});
  const { data: dailyCheckData, refetch: refetchDailyCheckData } = useGetDailyCheck();
  const [showDailyCheckPopup, setShowDailyCheckPopup] = useState(false);
  const limitTypeEnum = {
    0: {
      key: "none",
      src: "",
      name: "",
      total_value: "",
      onClick: () => {},
    },
    1: {
      key: "recharge",
      src: "/images/dailyCheck/go_recharge.png",
      name: "go_recharge",
      total_value: "total_deposit_amount",
      onClick: () => {
        setShowDailyCheckPopup(false);
        navigate("/account?tab=deposit");
      },
    },
    2: {
      key: "bet",
      src: "/images/dailyCheck/go_bet.png",
      name: "go_bet",
      total_value: "total_bet_amount",
      onClick: () => {
        setShowDailyCheckPopup(false);
        navigate("/");
      },
    },
  };

  const handleShowDailyCheckPopup = () => {
    setShowDailyCheckPopup(true);
  };

  useEffect(() => {
    if (!dailyCheckData) return;
    setData(dailyCheckData);
    if (dailyCheckData.length !== 0) {
      setSetting(dailyCheckData.data.setting);
      setActiveDay(dailyCheckData.last_signin_day + 1);
      if (dailyCheckData.last_signin_day !== dailyCheckData.data.setting.length) {
        setLimitType(
          limitTypeEnum[dailyCheckData.data.setting[dailyCheckData.last_signin_day]?.type],
        );
      } else {
        setLimitType(limitTypeEnum[0]);
      }
      if (dailyCheckData.current_signin_day) setShowDailyCheckPopup(true);
    }
  }, [dailyCheckData]);

  return (
    <DailyCheckContext.Provider
      value={{
        handleShowDailyCheckPopup,
        data,
        setting,
        activeDay,
        limitType,
        setActiveDay,
        refetchDailyCheckData,
      }}
    >
      {children}
      <DailyCheck visible={showDailyCheckPopup} setVisible={setShowDailyCheckPopup} />
    </DailyCheckContext.Provider>
  );
};
export const useDailyCheck = () => {
  const DailyCheckValue = useContext(DailyCheckContext);
  return DailyCheckValue;
};
