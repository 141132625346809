import React from "react";
import Background from "./images/background.png";
import styles from "./style.module.scss";

const RocketBackground = ({ currentGameState = {} }) => {
  return (
    <div className={styles.container}>
      <img src={Background} className={styles.img} alt="" />
    </div>
  );
};

export default RocketBackground;
