import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import vars from "../assets/styles/_variables.scss";
import Background from "../layouts/components/Background";
import Overlay from "../layouts/components/Overlay";

const BackgroundContext = createContext({
  maxBodyWidth: `${vars.maxPCMainContentWidth}`,
});

export const BackgroundContextProvider = ({ children }) => {
  const location = useLocation();
  const [overlayColor, setOverlayColor] = useState("transparent");
  const [maxBodyWidth, setMaxBodyWidth] = useState(`${vars.maxPCMainContentWidth}`);
  useEffect(() => {
    //PATH SELECTOR TO CHANGE BACKGROUND OVERLAY
    switch (location.pathname) {
      case "/vip":
        setOverlayColor(
          "linear-gradient(90deg, rgba(51, 69, 98, 0.50) 0%, rgba(9, 12, 17, 0.50) 100%)",
        );
        break;
      case "/download":
        setOverlayColor(
          "linear-gradient(90deg, rgba(105, 23, 255, 0.50) 0%, rgba(0, 117, 250, 0.50) 100%)",
        );
        break;
      default:
        setOverlayColor("transparent");
        break;
    }

    //PATH SELECTOR TO CHANGE BACKGROUND WIDTH
    switch (location.pathname) {
      case "/sport":
        setMaxBodyWidth("100%");
        break;
      default:
        setMaxBodyWidth(`${vars.maxPCMainContentWidth}`);
        break;
    }
  }, [location]);
  return (
    <BackgroundContext.Provider value={{ maxBodyWidth }}>
      <Background location={location.pathname}>
        <Overlay color={overlayColor}>{children}</Overlay>
      </Background>
    </BackgroundContext.Provider>
  );
};
export const useBackground = () => {
  const BackgroundValue = useContext(BackgroundContext);
  return BackgroundValue;
};
