import { Form, Select } from "antd";
import "./style.scss";

const SelectInput = ({ rules, label, name, placeholder, options }) => {
  return (
    <Form.Item label={label} name={name} rules={rules} className={"custom-select"}>
      <Select placeholder={placeholder} options={options} />
    </Form.Item>
  );
};

export default SelectInput;
