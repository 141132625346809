import { Image } from "antd";
import React from "react";
import noData from "./images/noData.png";
import styles from "./style.module.scss";

const NoData = ({
  title = "",
  height = "auto",
  padding = undefined,
  showImage = true,
  titleStyle = "",
}) => {
  return (
    <div className={styles.container} style={{ height: height, padding: padding }}>
      {showImage === true ? <Image width={200} height={200} src={noData} preview={false} /> : null}
      <div className={`${styles.title} ${titleStyle}`}>{title}</div>
    </div>
  );
};

export default NoData;
