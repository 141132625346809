import { CheckOutlined, LeftOutlined } from "@ant-design/icons";
import Box from "@mui/material/Box";
import { Drawer, Menu } from "antd";
import "antd/dist/antd.css";
import React from "react";
import styles from "./style.module.scss";
import "./style.scss";
const AntDrawer = ({
  className = "",
  placement,
  title,
  description,
  items,
  itemRender,
  changeEvent,
  nowItem,
  showDrawer,
  setShowDrawer,
  renderContent,
  compareKeyName,
  checkDisable,
  height,
  width,
  changeBackgroundColor,
  closeIcon,
  isClosable = true,
  onClose = () => {},
  destroyOnClose = false,
  maskClosable = true,
}) => {
  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setShowDrawer(open);
    onClose();
  };
  const compareVal = (i) => {
    if (compareKeyName) return i[compareKeyName];
    return i.displayName;
  };

  const checkRender = (item) => {
    if (checkDisable) {
      return <></>;
    }
    return nowItem == compareVal(item) ? (
      <CheckOutlined
        sx={{
          color: "#e9a817",
          fontSize: "var(--fontSetting-normal)",
        }}
      />
    ) : (
      ""
    );
  };
  const closeDrawer = () => {
    setShowDrawer(false);
  };
  return (
    <>
      <Drawer
        className={`Meta-Drawer ${className}`}
        placement={placement}
        visible={showDrawer}
        closable={isClosable}
        onClose={toggleDrawer(false)}
        destroyOnClose={destroyOnClose}
        maskClosable={maskClosable}
        drawerStyle={{
          backgroundColor: changeBackgroundColor
            ? changeBackgroundColor
            : "#var(--indexBackground)",
        }}
        width={width ? width : "100%"}
        style={{ maxWidth: "var(--maxWidth-in-pc)", margin: "0 auto" }}
        title={title}
        closeIcon={
          closeIcon ? closeIcon() : <LeftOutlined style={{ color: "var(--color-topbar-icon)" }} />
        }
      >
        <div
          className={`${styles.settingDrawer} settingDrawer `}
          style={height ? { height: `calc(var(--vh, 1vh) * ${height} - 50px)` } : undefined}
        >
          {description ? (
            <Box>
              <div
                style={{
                  padding: "0 16px",
                  fontSize: "var(--fontSetting-small)",
                  color: "var(--drawerDescription)",
                }}
              >
                {description}
              </div>
            </Box>
          ) : (
            ""
          )}
          {renderContent ? (
            renderContent()
          ) : (
            <>
              <Menu>
                {items &&
                  items.map((item, index) => {
                    return (
                      <Menu.Item
                        key={item.title + index}
                        onClick={() => {
                          if (changeEvent) {
                            changeEvent(item);
                          }
                        }}
                      >
                        <div className={`${styles.settingSelection}`}>
                          {itemRender ? itemRender(item) : <span>{item.displayName}</span>}
                          {/* compareVal 自定義比較的keyname */}
                          {checkRender(item)}
                        </div>
                      </Menu.Item>
                    );
                  })}
              </Menu>
            </>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default AntDrawer;
