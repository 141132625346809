import { useQueryClient } from "@tanstack/react-query";
import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import WebsocketHeartbeatJs from "websocket-heartbeat-js";
import queryKey from "../enumerations/queryKey";
import { chart_record } from "../pages/mobile/BitGame/enumeration/chartRecordEnum";
const WebsocketStockContext = createContext({
  closeWebsocket: () => {},
  openWebsocket: () => {},
  subscribe: () => {},
  ping: () => {},
  setWebsocketChannel: () => {},
  isWebsocketConnected: false,
  switchChannel: () => {},
  counter: null,
  setCounter: () => {},
});

export const WebsocketStockContextProvider = ({ children }) => {
  const webSocket = useRef(null);
  const queryClient = useQueryClient();
  const [isWebsocketConnected, setIsWebsocketConnected] = useState(false);
  const [websocketChannel, setWebsocketChannel] = useState("");
  const [chartData, setChartData] = useState([]);
  const [counter, setCounter] = useState();

  const BINANCE_WS = "wss://stream.binance.com:9443/ws";
  const CHANNEL_LIST = {
    LOTBTC3: "btcusdt@kline_3m",
    LOTETH3: "ethusdt@kline_3m",
  };

  const ping = () => {
    webSocket.current.send("ping");
  };

  const subscribe = ({ channel = null }) => {
    webSocket.current.send(
      JSON.stringify({
        method: "SUBSCRIBE",
        params: [CHANNEL_LIST[channel ? channel : websocketChannel]],
        id: 1,
      }),
    );
  };

  const unsubscribe = () => {
    webSocket.current.send(
      JSON.stringify({
        method: "UNSUBSCRIBE",
        params: [CHANNEL_LIST[websocketChannel]],
        id: 1,
      }),
    );
    setWebsocketChannel("");
  };

  const switchChannel = ({ oldChannel, newChannel }) => {
    if (websocketChannel === oldChannel) {
      unsubscribe();
      subscribe({ channel: newChannel });
      setWebsocketChannel(newChannel);
    }
  };

  const openWebsocket = ({ channel }) => {
    if (!webSocket.current) {
      const opt = {
        url: BINANCE_WS,
        pingMsg: "ping",
      };
      try {
        setWebsocketChannel(channel);
        webSocket.current = new WebsocketHeartbeatJs(opt);
      } catch (error) {
        console.debug("error creating webscoket", error);
      }
      setIsWebsocketConnected(true);
    }
  };

  const closeWebsocket = () => {
    if (webSocket.current) {
      unsubscribe();
      webSocket.current.close();
      webSocket.current = null;
      setIsWebsocketConnected(false);
    }
  };

  const MAPPING = {
    K_CHART: "k",
    OPEN_TIME: "t",
    OPEN_PRICE: "o",
    HIGH_PRICE: "h",
    LOW_PRICE: "l",
    CLOSE_PRICE: "c",
    CLOSE__TIME: "T",
  };
  const processBinanceWSData = (rawData) => {
    const chart_data = rawData[MAPPING.K_CHART];
    if (!chart_data) return;
    return {
      [chart_data[MAPPING.OPEN_TIME]]: {
        [chart_record.OPEN_PRICE]: chart_data[MAPPING.OPEN_PRICE],
        [chart_record.CLOSE_PRICE]: chart_data[MAPPING.CLOSE_PRICE],
        [chart_record.LOW_PRICE]: chart_data[MAPPING.LOW_PRICE],
        [chart_record.HIGH_PRICE]: chart_data[MAPPING.HIGH_PRICE],
      },
    };
  };

  useEffect(() => {
    if (webSocket.current && isWebsocketConnected) {
      webSocket.current.onopen = () => {
        subscribe({ channel: null });
      };
      webSocket.current.onerror = (error) => {
        console.log("WebSocket error", JSON.stringify(error));
      };
      webSocket.current.onclose = () => {
        // unsubscribe();
        console.log("WebSocket Connection Closed");
      };
      webSocket.current.onreconnect = (error) => {
        console.log("WebSocket Reconnection", JSON.stringify(error));
      };

      // "e": "kline",     // Event type
      // "E": 123456789,   // Event time
      // "s": "BNBBTC",    // Symbol
      // "k": {
      //   "t": 123400000, // Kline start time
      //   "T": 123460000, // Kline close time
      //   "s": "BNBBTC",  // Symbol
      //   "i": "1m",      // Interval
      //   "f": 100,       // First trade ID
      //   "L": 200,       // Last trade ID
      //   "o": "0.0010",  // Open price
      //   "c": "0.0020",  // Close price
      //   "h": "0.0025",  // High price
      //   "l": "0.0015",  // Low price
      //   "v": "1000",    // Base asset volume
      //   "n": 100,       // Number of trades
      //   "x": false,     // Is this kline closed?
      //   "q": "1.0000",  // Quote asset volume
      //   "V": "500",     // Taker buy base asset volume
      //   "Q": "0.500",   // Taker buy quote asset volume
      //   "B": "123456"   // Ignore

      webSocket.current.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          const processedData = processBinanceWSData(data);
          if (processedData) {
            queryClient.setQueriesData([queryKey.WIN_BINANCE_KLINE, data.s], (oldData) => {
              return { ...oldData, ...processedData };
            });
          }
        } catch (error) {
          console.debug(error);
        }
      };
    }
  }, [webSocket.current, isWebsocketConnected]);

  return (
    <WebsocketStockContext.Provider
      value={{
        openWebsocket,
        closeWebsocket,
        subscribe,
        ping,
        switchChannel,
        setCounter,
        isWebsocketConnected,
        counter,
      }}
    >
      {children}
    </WebsocketStockContext.Provider>
  );
};
export const useStockWebsocket = () => {
  const WebsocketValue = useContext(WebsocketStockContext);
  return WebsocketValue;
};
