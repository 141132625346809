import { useQueryClient } from "@tanstack/react-query";
import { Button, Grid } from "antd-mobile";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { GCODE } from "../../../config";
import queryKey from "../../../enumerations/queryKey";
import { useGetUserWallet } from "../../../hooks/wallet/wallet.hooks";
import {
  useGetCurrentLottery,
  useGetGameRecord,
  useGetGameWay,
  usePostGameBet,
} from "../../../pages/mobile/Home/components/LotteryPop/hooks/lottery.hooks";
import {
  ValidateBettingOptions,
  ValidateMaxMinBet,
  ValidateMaxPayout,
  ValidateUserMoney,
} from "../../../pages/mobile/Home/components/LotteryPop/validator";
import styles from "./style.module.scss";

const LotteryPopBody = ({
  GAME_TYPE = "",
  GAME_WAY_TYPE = "",
  BET_POSITION = "",
  GAME_METHOD_CODE = "",
  GAME_CATEGORY_TYPE = "",
  GAME_CATEGORY_DETAIL_TYPE = "",
  customContainerStyle = "",
}) => {
  const cache = useQueryClient();
  const { t } = useTranslation();

  const VALIDATOR_FUNCTION_LIST = [
    ValidateMaxMinBet,
    ValidateUserMoney,
    ValidateMaxPayout,
    ValidateBettingOptions,
  ];

  const [selectedBet, setSelectedBet] = useState([]);
  const [selectedAmount, setSelectedAmount] = useState(1);
  const [messageContent, setMessageContent] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [messageType, setMessageType] = useState(undefined);

  const BET_CONTENT = [
    {
      value: `${BET_POSITION}|big`,
      text: t("lottery.item.big"),
    },
    {
      value: `${BET_POSITION}|small`,
      text: t("lottery.item.small"),
    },
    {
      value: `${BET_POSITION}|odd`,
      text: t("lottery.item.odd"),
    },
    {
      value: `${BET_POSITION}|even`,
      text: t("lottery.item.even"),
    },
  ];
  const AMOUNT_CONTENT = [
    {
      value: 1,
      text: "1",
    },
    {
      value: 5,
      text: "5",
    },
    {
      value: 10,
      text: "10",
    },
    {
      value: 50,
      text: "50",
    },
  ];

  const { mutate: betLottery, isLoading: isLoadingBet } = usePostGameBet();
  const { data: gameRecord } = useGetGameRecord(
    {
      game_type: GAME_TYPE,
      page_size: 1,
    },
    {},
  );

  const { data: currentLottery } = useGetCurrentLottery({ game_type: GAME_TYPE, page_size: 0 }, {});

  const { data: userWallet } = useGetUserWallet({});

  const { data: game_way } = useGetGameWay(
    {
      game_way_type: GAME_WAY_TYPE,
      bet_position: BET_POSITION,
    },
    {},
  );

  const handleSelectBet = (_selectedBet) => {
    const foundIndex = selectedBet.findIndex((bet) => bet === _selectedBet);
    if (foundIndex >= 0) {
      setSelectedBet([...selectedBet.slice(0, foundIndex), ...selectedBet.slice(foundIndex + 1)]);
    } else {
      setSelectedBet([...selectedBet, _selectedBet]);
    }
  };

  const handleSelectAmount = (_selectedAmount) => {
    setSelectedAmount(_selectedAmount);
  };

  const validateInput = ({
    walletAmount,
    betAmount,
    odds,
    highest_single_bet,
    lowest_single_bet,
    max_payout,
    betOptionSelectCount,
  }) => {
    let isValid = true;
    VALIDATOR_FUNCTION_LIST.every((_validator, index) => {
      const return_value = _validator({
        walletAmount,
        betAmount,
        odds,
        highest_single_bet,
        lowest_single_bet,
        max_payout,
        betOptionSelectCount,
      });
      if (return_value.valid === false) {
        setMessageContent(t(return_value.content, return_value.options));
        setMessageType("error");
        setShowMessage(true);
        isValid = false;
        return false;
      }
      return true;
    });
    return isValid;
  };

  const handleBet = async () => {
    setShowMessage(false);

    const isValid = validateInput({
      walletAmount: userWallet?.money,
      betAmount: selectedAmount,
      odds: game_way?.odds,
      highest_single_bet: game_way?.highest_single_bet,
      lowest_single_bet: game_way?.lowest_single_bet,
      max_payout: game_way?.max_payout,
      betOptionSelectCount: selectedBet.length,
    });
    if (isValid === false) return;
    if (GCODE !== "" && GCODE !== null && GCODE !== undefined) {
      window.dataLayer.push({
        event: "button-click",
        eventProps: {
          value: "Lottery_Pop_Bet_Button",
        },
      });
    }
    const betContent = {
      game_category_type: GAME_CATEGORY_TYPE,
      game_category_detail_type: GAME_CATEGORY_DETAIL_TYPE,
      game_type: GAME_TYPE,
      game_way_type: GAME_WAY_TYPE,
      period: currentLottery?.period || "",
      choice_number: selectedBet.map((bet) => {
        return {
          game_method_code: GAME_METHOD_CODE,
          bet_point: selectedAmount,
          bet_content: bet,
        };
      }),
      place: "popup",
    };

    await betLottery(
      {
        params: betContent,
      },
      {
        onSuccess: () => {
          cache.invalidateQueries([queryKey.USER_WALLET]);
          cache.invalidateQueries([queryKey.LOTTERY_GAME_RECORD]);
          cache.invalidateQueries([queryKey.CURRENT_LOTTERY]);
          setMessageContent(t("lotterypop.betSuccess.content"));
          setMessageType("success");
          setShowMessage(true);
        },
        onError: (error) => {
          setMessageContent(error);
          setMessageType("error");
          setShowMessage(true);
          cache.invalidateQueries([queryKey.LOTTERY_GAME_RECORD]);
          cache.invalidateQueries([queryKey.CURRENT_LOTTERY]);
        },
      },
    );
  };

  const renderMessage = () => {
    if (showMessage === false) return null;
    return (
      <div
        className={`${styles.message} ${messageType === "error" ? styles.error : styles.success}`}
      >
        {messageContent}
      </div>
    );
  };

  const renderPrevNumber = () => {
    if (!gameRecord) return null;
    const numbers = gameRecord[0]?.lottery_number?.split(",") || [];
    return (
      <div className={styles.numberContainer}>
        {numbers.map((_num) => {
          return <div className={styles.number}>{_num}</div>;
        })}
      </div>
    );
  };

  const renderPrevResultData = () => {
    if (!gameRecord) return null;
    return (
      <div className={styles.resultInfoContainer}>
        <div className={styles.dragontiger}>
          {gameRecord[0]?.data?.dragon_tiger
            ? t(`lottery.item.${gameRecord[0]?.data?.dragon_tiger}`)
            : ""}
        </div>
        <div className={styles.size}>
          {gameRecord[0]?.data?.big_small
            ? t(`lottery.item.${gameRecord[0]?.data?.big_small}`)
            : ""}
        </div>
        <div className={styles.total}>
          {gameRecord[0]?.data?.odd_even ? t(`lottery.item.${gameRecord[0]?.data?.odd_even}`) : ""}
        </div>
      </div>
    );
  };

  return (
    <div className={`${styles.container} ${customContainerStyle}`}>
      <div className={`${styles.lotteryInfoContainer}`}>
        <div className={styles.left}>
          <div className={styles.infoContainer}>
            <div className={styles.infoTitle}>{`${t("lotterypop.normal.now")}:`}</div>
            <div className={styles.infoDesc}>{currentLottery?.period || "-"}</div>
          </div>
          <div className={styles.infoContainer}>
            <div className={styles.infoTitle}>{`${t("lotterypop.normal.odds")}:`}</div>
            <div className={styles.infoDesc}>{`${game_way?.odds}x` || ""}</div>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.periodContainer}>
            <div className={styles.title}>{`${t("lotterypop.normal.period")}:`}</div>
            <div className={styles.period}>{(gameRecord && gameRecord[0]?.period) || "-"}</div>
          </div>
          {renderPrevNumber()}
          {renderPrevResultData()}
        </div>
      </div>
      <div className={styles.betContainer}>
        <div className={styles.betSubTitle}>
          {t(`gameWay.items.${GAME_WAY_TYPE}.${BET_POSITION}`)}
        </div>
        <Grid columns={4} gap={8} style={{ width: "100%" }}>
          {BET_CONTENT.map((content) => {
            return (
              <Grid.Item key={content.value} onClick={() => handleSelectBet(content.value)}>
                <div
                  className={`${styles.betChoice} ${
                    selectedBet.includes(content.value) ? styles.active : ""
                  }`}
                >
                  {content.text}
                </div>
              </Grid.Item>
            );
          })}
        </Grid>
        <div className={styles.betSubTitle}>{t("lotterypop.amount.title")}</div>
        <Grid columns={4} gap={8} style={{ width: "100%" }}>
          {AMOUNT_CONTENT.map((content) => {
            return (
              <Grid.Item key={content.value} onClick={() => handleSelectAmount(content.value)}>
                <div
                  className={`${styles.amountChoice} ${
                    content.value === selectedAmount ? styles.active : ""
                  }`}
                >
                  {content.text}
                </div>
              </Grid.Item>
            );
          })}
        </Grid>
        <Button
          loading={isLoadingBet}
          onClick={handleBet}
          className={`${styles.betButton} lottery-pop-bet-button`}
          block={true}
        >
          {t("lotterypop.button.bet")}
        </Button>
        {renderMessage()}
      </div>
    </div>
  );
};

export default LotteryPopBody;
