import { CaretDownOutlined } from "@ant-design/icons";
import { Popover } from "antd-mobile";
import { useRef, useState } from "react";
import ProfileMenu from "../ProfileMenu";
import VIPAvatar from "../VIPAvatar";
import styles from "./style.module.scss";
import "./style.scss";

const VIPDropdown = () => {
  const popoverRef = useRef();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleCloseDropdown = () => {
    popoverRef.current.hide();
  };

  const renderPopOverContent = () => {
    return <ProfileMenu closeDropdown={handleCloseDropdown} />;
  };

  return (
    <Popover
      ref={popoverRef}
      // visible={isDropdownOpen}
      content={renderPopOverContent()}
      placement={"bottom-end"}
      className={`custom-popover`}
      trigger="click"
      onVisibleChange={(visible) => {
        setIsDropdownOpen(visible);
      }}
    >
      <div
        className={`${styles.dropdownContainer} ${isDropdownOpen === true ? styles.open : ""}`}
        // onClick={handleOpenCloseDropdown}
      >
        <VIPAvatar customContainerStyle={styles.vipContainer} />
        <CaretDownOutlined rotate={isDropdownOpen ? 180 : 0} />
      </div>
    </Popover>
  );
};

export default VIPDropdown;
