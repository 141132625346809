import { LeftOutlined } from "@ant-design/icons";
import { Button, Col } from "antd";
const LeftGroup = ({ goBack, backURL }) => {
  return (
    <Col span={4} style={{ display: "flex", alignItems: "center" }}>
      {backURL ? (
        <Button
          onClick={() => {
            goBack(backURL);
          }}
          type="text"
          icon={<LeftOutlined style={{ color: "var(--color-topbar-icon)" }} />}
        />
      ) : (
        ""
      )}
    </Col>
  );
};

export default LeftGroup;
