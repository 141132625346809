import { useEffect, useState } from "react";
import "./style.scss";
const Background = ({ location, children }) => {
  const DEFAULT_COLOR =
    "radial-gradient(ellipse at bottom, var(--background-gradient1) 0%, var(--background-gradient2) 100%)";
  const [color, setColor] = useState(DEFAULT_COLOR);

  const PATH_WITH_STAR_BACKGROUND = [
    "/vip",
    "/download",
    "/inMaintenance",
    "/connectionFailed",
    "/accessDenied",
  ];

  useEffect(() => {
    if (PATH_WITH_STAR_BACKGROUND.includes(location)) {
      setColor("radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%)");
    } else {
      setColor(DEFAULT_COLOR);
    }
  }, [location]);

  return (
    <div
      style={{
        background: color,
        width: "100vw",
        height: "calc(var(--vh, 1vh) * 100)",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <div id="stars" />
      <div id="stars2" />
      <div id="stars3" />
      {children}
    </div>
  );
};
export default Background;
