import { Popover } from "antd-mobile";
import React from "react";

import styles from "./style.module.scss";
import "./style.scss";

function PopoverMenu(props) {
  const { setShowPopover = () => {} } = props;
  return (
    <Popover.Menu
      mode="dark"
      placement="bottom-start"
      trigger="click"
      onVisibleChange={(visible) => {
        setShowPopover(visible);
      }}
      {...props}
      className={`${props.className} custom-popover-menu ${styles.customPopover}`}
    >
      {props.children}
    </Popover.Menu>
  );
}

export default PopoverMenu;
