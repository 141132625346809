import { SyncOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { WIN_RECORD_DELAY_TIMER } from "../../config";
import { useDevice } from "../../context/DeviceContext";
import { useGameWebsocket } from "../../context/WebsocketGameContext";
import localStorageKey from "../../enumerations/localStorageKey";
import { useGetUserWalletCache } from "../../hooks/wallet/wallet.hooks";
import { moneyFormat } from "../../plugins/numberFormat";
import { getRandomInt } from "../../plugins/utils";
import Countdown from "../Countdown";
import styles from "./style.module.scss";

const CURRENCY = localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);

const GameHeader = ({
  onHandleDepositOpenCallback = () => {},
  setRuleVisible = () => {},
  walletAmount,
  setWalletAmount = () => {},
  setCurrentGameState = () => {},
  currentGameState,
  selectedGameList,
  latestNumber = undefined,
  refreshData = () => {},
  latestNumberRefetching = false,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useDevice();
  const { currentPeriod } = useGameWebsocket();
  const [localPeriod, setLocalPeriod] = useState("-");
  const [localWalletLoading, setLocalWalletLoading] = useState(false);
  const [fakeLoading, setFakeLoading] = useState(false);
  const [isFakeReload, setIsFakeReload] = useState(false);
  const timeoutRef = useRef();
  const navigate = useNavigate();
  const {
    data: cacheWalletData,
    refetch: refetchCache,
    isFetching: isFetchingCache,
  } = useGetUserWalletCache({
    enabled: false,
    onSuccess: () => {
      setIsFakeReload(true);
    },
  });

  const onHandleDepositOpen = () => {
    if (isMobile) {
      navigate("/exchange", {
        state: {
          tabIndex: "deposit",
          backURL: "/lotwin",
        },
      });
    } else {
      navigate("/account?tab=deposit");
    }

    onHandleDepositOpenCallback();
  };

  const onHandleRuleOpen = () => {
    setRuleVisible(true);
  };

  const handleReloadCacheBalance = () => {
    if (!localWalletLoading) {
      if (isFakeReload === true) {
        setLocalWalletLoading(true);
        setTimeout(() => {
          setLocalWalletLoading(false);
        }, [750]);
        return;
      }
      refetchCache();
    }
  };
  useEffect(() => {
    if (isFakeReload === true) {
      const timeoutDelay = getRandomInt(5, 10);
      timeoutRef.current = setTimeout(() => {
        setIsFakeReload(false);
      }, [timeoutDelay * 1000]);
      return () => {
        clearTimeout(timeoutRef.current);
      };
    }
  }, [isFakeReload]);

  useEffect(() => {
    if (cacheWalletData) {
      setWalletAmount(cacheWalletData.money);
    }
  }, [cacheWalletData]);

  useEffect(() => {
    setLocalPeriod(currentPeriod?.period || (latestNumber && latestNumber[0]?.period) || "-");
  }, [currentPeriod.period, latestNumber]);
  useEffect(() => {
    const _cacheWallet = localStorage.getItem(localStorageKey.WALLET);
    if (_cacheWallet) {
      setWalletAmount(_cacheWallet);
    } else {
      refetchCache();
    }
  }, []);
  useEffect(() => {
    setLocalWalletLoading(isFetchingCache || fakeLoading);
  }, [isFetchingCache, fakeLoading]);

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <div className={styles.balanceContainer}>
          <div className={styles.balanceTitle}>{t("win.normal.mybalance")}</div>
          <div className={styles.balanceInnerContainer}>
            <div className={styles.balance}>{`${t(
              `currency.symbol.${CURRENCY.toUpperCase()}`,
            )} ${moneyFormat(walletAmount)}`}</div>
            <div
              className={styles.reload}
              onClick={handleReloadCacheBalance}
              spin={localWalletLoading}
            >
              <SyncOutlined className={styles.icon} spin={localWalletLoading} />
            </div>
          </div>
        </div>
        <div className={styles.periodTitle}>{t("win.normal.period")}</div>
        <div className={styles.period}>{localPeriod}</div>
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.buttonContainer}>
          <div className={styles.buttonDeposit} onClick={onHandleDepositOpen}>
            {t("normal.deposit")}
          </div>
          <div className={styles.buttonRule} onClick={onHandleRuleOpen}>
            {t("win.normal.rule")}
          </div>
        </div>
        <Countdown
          setCurrentGameState={setCurrentGameState}
          currentGameState={currentGameState}
          selectedGameList={selectedGameList}
          refreshData={refreshData}
          latestNumber={latestNumber}
          latestNumberRefetching={latestNumberRefetching}
          recordTimer={WIN_RECORD_DELAY_TIMER}
        />
      </div>
    </div>
  );
};

export default GameHeader;
