import { useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGameWebsocket } from "../../../../../context/WebsocketGameContext";
import queryKey from "../../../../../enumerations/queryKey";
import webSocketActionEnum from "../../../../../enumerations/webSocketActionEnum";
import channelSettingsEnum from "../../enumeration/channelSettingsEnum";
import gameStateEnum from "../../enumeration/gameStateEnum";
import styles from "./style.module.scss";

const HOUR_SECOND = 60 * 60;
const MINUTE_SECOND = 60;

const PreparingCountDown = ({
  visible = false,
  disableTimeZoneTrans,
  setCurrentGameState = () => {},
  currentGameState,
  isSwitchChannel,
  latestNumber,
  refreshData = () => {},
  latestNumberRefetching = false,
  selectedGameList,
}) => {
  const { t } = useTranslation();
  const { currentPeriod, setCounter, prevPeriod, counter } = useGameWebsocket();
  const [visiblePeriod, setVisiblePeriod] = useState(currentPeriod.period);
  const queryClient = useQueryClient();
  const apiTimerRef = useRef();
  const recordTimerRef = useRef();
  const [localCurrentTime, setLocalCurrentTime] = useState();
  const [localCounter, setLocalCounter] = useState({
    minutes: "00",
    seconds: "00",
  });

  const handleChangeGameState = (diff, latestNumber) => {
    if (diff <= latestNumber[0].close_time) {
      if (currentGameState !== gameStateEnum.DISABLE_BET) {
        setCurrentGameState(gameStateEnum.DISABLE_BET);
      }
    } else {
      if (
        currentGameState === gameStateEnum.DISABLE_BET &&
        latestNumber[1].lottery_number === null
      ) {
        setCurrentGameState(gameStateEnum.PENDING_PHASE);
      } else if (currentGameState === gameStateEnum.DISABLE_BET && latestNumber[1].lottery_number) {
        setCurrentGameState(gameStateEnum.START_PHASE);
      }
    }
  };

  const countdown = useCallback(() => {
    if (currentPeriod.end && currentPeriod.current_date_time) {
      const timeStringProcess = (timeString) => {
        const regex = /\-/g;
        return timeString.replace(regex, "/");
      };
      const difference = dayjs(
        `${timeStringProcess(currentPeriod.end)}${disableTimeZoneTrans ? "" : " +00:00"}`,
      ).diff(dayjs(timeStringProcess(currentPeriod.current_date_time)), "s");
      setCounter(difference);
      if (difference <= 5) {
        setCurrentGameState(gameStateEnum.DISABLE_BET);
      } else if (difference >= channelSettingsEnum[currentPeriod.channel].maxTimer) {
        if (prevPeriod.action === webSocketActionEnum.PENDING) {
          if (currentGameState !== gameStateEnum.PENDING_PHASE) {
            setCurrentGameState(gameStateEnum.PENDING_PHASE);
          }
        }
      }
      return difference;
    }
    return 0;
  }, [
    currentPeriod.end,
    currentPeriod.current_date_time,
    currentPeriod.channel,
    currentPeriod.period,
    prevPeriod.action,
    currentGameState,
  ]);

  const apiCountDown = useCallback(() => {
    if (latestNumber) {
      const activePeriod = latestNumber[0];
      const difference = dayjs(`${activePeriod.end} +00:00`.replace(/-/g, "/")).diff(
        dayjs(`${localCurrentTime} +00:00`.replace(/-/g, "/")),
        // dayjs().utc(),
        "seconds",
      );
      setCounter(difference);
      handleChangeGameState(difference, latestNumber);
      return difference;
    }
    return 0;
  }, [latestNumber, localCurrentTime]);

  useEffect(() => {
    if (currentPeriod.period) {
      if (prevPeriod.action === webSocketActionEnum.END) {
        if (currentPeriod.period !== visiblePeriod) {
          setVisiblePeriod(currentPeriod.period);
        }
      }
    } else {
      if (latestNumber) {
        const currentPeriod = latestNumber[0];
        const prevPeriod = latestNumber[1];
        if (prevPeriod.lottery_number) {
          if (currentPeriod.period !== visiblePeriod) {
            setTimeout(() => {
              setVisiblePeriod(currentPeriod.period);
            }, [1000]);
          }
        } else {
          setVisiblePeriod(prevPeriod.period);
        }
      }
    }
  }, [currentPeriod.period, prevPeriod.action, visiblePeriod, latestNumber]);

  const refreshRecord = (latestNumber) => {
    if (latestNumber) {
      let id = 0;
      const currentData = queryClient.getQueryData([
        queryKey.WIN_GAME_HISTORY,
        selectedGameList,
        10,
        1,
      ]);
      if (currentData?.data && currentData?.data.length > 0 && latestNumber?.period) {
        if (currentData.data[0].period !== latestNumber.period) {
          id = currentData.data[0].id;
          queryClient.setQueryData(
            [queryKey.WIN_GAME_HISTORY, selectedGameList, 10, 1],
            (oldData) => {
              return {
                ...oldData,
                data: [
                  {
                    id: id,
                    game_type: selectedGameList,
                    period: latestNumber.period,
                    lottery_number: "",
                    draw_time: latestNumber.end,
                    created_at: latestNumber.start,
                    action: webSocketActionEnum.PENDING,
                  },
                  ...oldData.data,
                ].slice(0, 20),
              };
            },
          );
        }
      }
      clearTimeout(recordTimerRef.current);
      recordTimerRef.current = setTimeout(() => {
        queryClient.invalidateQueries([queryKey.WIN_GAME_HISTORY, selectedGameList, 10, 1]);
      }, [10000]);
    }
  };

  const handleSetLocalTimer = (diff) => {
    // const hours = String(parseInt(diff / HOUR_SECOND + 100)).slice(-2);
    const minutes = String(parseInt(((diff / MINUTE_SECOND) % MINUTE_SECOND) + 100)).slice(-2);
    const seconds = String(parseInt((diff % MINUTE_SECOND) + 100)).slice(-2);
    setLocalCounter({ minutes, seconds });
  };

  const timer = useCallback(() => {
    const _timer = countdown();
    // const hours = String(parseInt(_timer / HOUR_SECOND + 100)).slice(-2);
    const minutes = String(parseInt(((_timer / MINUTE_SECOND) % MINUTE_SECOND) + 100)).slice(-2);
    const seconds = String(parseInt((_timer % MINUTE_SECOND) + 100)).slice(-2);
    return { minutes, seconds };
  }, [countdown]);

  useEffect(() => {
    if (latestNumber) {
      setLocalCurrentTime(latestNumber[0].current_time);
    }
  }, [latestNumber]);

  useEffect(() => {
    let _timer;
    if (currentPeriod.end) {
      clearInterval(apiTimerRef.current);
      _timer = countdown();
      handleSetLocalTimer(_timer);
    } else {
      let _diff = apiCountDown();
      let waitTime = 1;
      apiTimerRef.current = setInterval(() => {
        _diff = Math.max(_diff - 1, 0);
        handleSetLocalTimer(_diff);
        handleChangeGameState(_diff, latestNumber);
        setCounter(_diff);
        if (latestNumberRefetching === false) {
          if (_diff <= 0) {
            // if (currentGameState.key !== gameStateEnum.PENDING_PHASE.key) {
            //   setCurrentGameState(gameStateEnum.PENDING_PHASE);
            // }
            if (waitTime <= 0) {
              refreshData();
              refreshRecord(latestNumber ? latestNumber[0] : undefined);
              waitTime = 2;
            }
            waitTime--;
          } else {
            if (latestNumber[1].lottery_number === null) {
              if (waitTime <= 0) {
                refreshData();
                waitTime = 2;
              }
              waitTime--;
            }
            // else {
            //   if (currentGameState.key === gameStateEnum.PENDING_PHASE.key) {
            //     setCurrentGameState(gameStateEnum.START_PHASE);
            //   }
            // }
          }
        }
      }, [1000]);
    }
    return () => {
      clearInterval(apiTimerRef.current);
    };
  }, [currentPeriod, latestNumber, latestNumberRefetching, localCurrentTime]);

  useEffect(() => {
    if (latestNumber) {
      if (counter > 0 && latestNumber[1].lottery_number !== null) {
        if (currentGameState === gameStateEnum.PENDING_PHASE) {
          setCurrentGameState(gameStateEnum.START_PHASE);
        }
      }
    }
  }, [counter, latestNumber, currentGameState]);

  useEffect(() => {
    return () => {
      clearTimeout(recordTimerRef.current);
    };
  }, []);

  const renderContent = () => {
    switch (currentGameState.key) {
      case gameStateEnum.PENDING_PHASE.key:
        return (
          <div className={styles.contentContainer}>
            <div className={styles.title}>{visiblePeriod}</div>
            <div className={styles.subtitle}>{`${t("win.gophersGame.prepare.title")} 00:00`}</div>
          </div>
        );
      case gameStateEnum.START_PHASE.key:
      case gameStateEnum.PAUSE_RESULT_PHASE.key:
        return null;
      case gameStateEnum.BET_PHASE.key:
      case gameStateEnum.DISABLE_BET.key:
        return (
          <div className={styles.contentContainer}>
            <div className={styles.title}>{visiblePeriod}</div>
            <div className={styles.subtitle}>{`${t("win.gophersGame.prepare.title")} ${
              localCounter.minutes
            }:${localCounter.seconds}`}</div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className={`${styles.container} ${visible === false ? styles.hide : ""}`}>
      {renderContent()}
    </div>
  );
};

export default PreparingCountDown;
