import Big from "big.js";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import ReactECharts from "echarts-for-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetSettings } from "../../../../../hooks/timezone/settings.hooks";
import { chart_record } from "../../enumeration/chartRecordEnum";
import { useGetKLineHistory } from "./hooks/kline.hooks";

dayjs.extend(utc);
dayjs.extend(timezone);

const upColor = "#ec0000";
const upBorderColor = "#8A0000";
const downColor = "#00da3c";
const downBorderColor = "#008F28";

const KLineChart = ({ ticker = "BTCUSDT" }) => {
  const { t } = useTranslation();
  const { data: setting, error: settingError } = useGetSettings({});
  const { data, isLoading, isError } = useGetKLineHistory({
    params: {
      symbol: ticker,
      limit: 30,
      interval: "3m",
    },
    options: {},
  });
  const [klineData, setKlineData] = useState({});

  const calculateMA = (dayCount) => {
    let result = [];
    if (klineData.values) {
      for (var i = 0, len = klineData?.values.length; i < len; i++) {
        if (i < dayCount - 1) {
          result.push("-");
          continue;
        }
        var sum = 0;
        for (var j = 0; j < dayCount; j++) {
          sum += +klineData.values[i - j][1];
        }
        const bigSum = new Big(sum);
        const bigDayCount = new Big(dayCount);
        const formattedResult = bigSum.div(bigDayCount).toFixed(2);
        // console.log("formattedResult", formattedResult);
        result.push(formattedResult);
      }
    }

    return result;
  };

  const splitData = (rawData) => {
    let values = [];
    const categoryData = Object.keys(rawData)
      .sort((a, b) => a - b)
      .map((key) => {
        let date = dayjs(parseInt(key)).format("HH:mm");
        if (setting) {
          date = dayjs(parseInt(key)).tz(setting?.timezone).format("HH:mm");
        }
        values.push([
          rawData[key][chart_record.OPEN_PRICE],
          rawData[key][chart_record.CLOSE_PRICE],
          rawData[key][chart_record.LOW_PRICE],
          rawData[key][chart_record.HIGH_PRICE],
        ]);
        return date;
      });
    return {
      categoryData: categoryData.slice(-30), //only show last 30 data
      values: values.slice(-30), //only show last 30 data
    };
  };

  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    grid: {
      left: "14%",
      top: "5%",
      right: "2%",
      bottom: "15%",
    },
    animation: true,
    xAxis: {
      type: "category",
      data: klineData?.categoryData,
      boundaryGap: false,
      axisLine: { onZero: false },
      splitLine: { show: false },
      min: "dataMin",
      max: "dataMax",
    },
    yAxis: {
      scale: true,
    },
    dataZoom: [
      {
        type: "inside",
        start: 10,
        end: 100,
      },
    ],
    series: [
      {
        name: t("win.chart.tooltip.title"),
        type: "candlestick",
        data: klineData?.values,
        itemStyle: {
          color: upColor,
          color0: downColor,
          borderColor: upBorderColor,
          borderColor0: downBorderColor,
        },
      },
      {
        name: "MA5",
        type: "line",
        data: calculateMA(5),
        smooth: true,
        lineStyle: {
          opacity: 0.5,
        },
      },
      {
        name: "MA10",
        type: "line",
        data: calculateMA(10),
        smooth: true,
        lineStyle: {
          opacity: 0.5,
        },
      },
      {
        name: "MA20",
        type: "line",
        data: calculateMA(20),
        smooth: true,
        lineStyle: {
          opacity: 0.5,
        },
      },
      {
        name: "MA30",
        type: "line",
        data: calculateMA(30),
        smooth: true,
        lineStyle: {
          opacity: 0.5,
        },
      },
    ],
  };

  useEffect(() => {
    if (!data) return;
    // console.log("data", data);
    setKlineData(splitData(data));
  }, [data]);

  return (
    <div>
      <ReactECharts option={option} />
    </div>
  );
};

export default KLineChart;
