import { requestGlobalSettingData } from "../apis";
import localStorageKey from "../enumerations/localStorageKey";

const currency = window.localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);

export const apiGameSearch = (params) =>
  requestGlobalSettingData("get", `/${currency}/game/v1/list`, params, true, true);

export const apiUpdateRecentPlayGameStatus = (params) =>
  requestGlobalSettingData("put", `/${currency}/game/status/check`, params, true, true);
