import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CustomTab,
  GameHeader,
  WinBetModalContent,
  WinBetPanel,
  WinCustomModal,
  WinHistoryPanel,
  WinRulePopup,
} from "../../../../../components";
import { useLoading } from "../../../../../context/LoadingContext";
import { useGameWebsocket } from "../../../../../context/WebsocketGameContext";
import { useStockWebsocket } from "../../../../../context/WebsocketStockContext";
import winStateEnum from "../../../../../enumerations/winStateEnum";
import { usePostGameBet } from "../../../../../hooks/winBet/winbet.hooks";
import { useGetGameMethod, useGetLatestNumber } from "../../../../../hooks/winBet/wingame.hooks";
import betSelectionEnum from "../../enumeration/betSelectionEnum";
import { useGetGameCategory } from "../../hooks/bit.hooks";
import { ValidateMaxMinBet, ValidateMaxPayout, ValidateUserMoney } from "../../validator";
import KLineChart from "../KLineChart";
import LastPriceViewer from "../LastPriceViewer";
import RuleContent from "../RuleContent";
import styles from "./style.module.scss";

const GameContainer = ({ handleClosePage = () => {}, gameCode = "", gameId = "" }) => {
  const { t } = useTranslation();
  const { setIsLoading } = useLoading();

  const timeoutPopup = {
    title: "",
    buttonText: t("win.popup.wsTimeout.button"),
    onButtonPress: () => {
      handleClosePage();
    },
    destroyOnClose: true,
    popupContent: t("win.popup.wsTimeout"),
    showCustomCloseButton: false,
  };

  const {
    openWebsocket: openStockWS,
    isWebsocketConnected: isStockWSConnected,
    switchChannel: switchStockWS,
  } = useStockWebsocket();

  const {
    // openWebsocket: openGameWS,
    // isWebsocketConnected: isGameWSConnected,
    // currentPeriod,
    // switchChannel: switchGameWS,
    counter,
    // websocketTimeout,
  } = useGameWebsocket();

  // useEffect(() => {
  //   console.debug("websocketTimeout", websocketTimeout);
  //   if (websocketTimeout === true) {
  //     setPopupSetting(timeoutPopup);
  //     setPopupVisible(true);
  //   }
  // }, [websocketTimeout]);

  const { data, isLoading, isError } = useGetGameCategory({
    params: {
      game_category_detail_type: gameCode.toUpperCase(),
    },
  });
  const [selectedGameList, setSelectedGameList] = useState();
  const [selectedGameWayType, setSelectedGameWayType] = useState(
    data && data.length > 0 ? data[0].game_way_type : null,
  );

  const {
    data: gameMethod,
    isLoading: gameMethodLoading,
    isError: gameMethodError,
  } = useGetGameMethod({
    params: {
      game_way_type: selectedGameWayType ? selectedGameWayType : "",
    },
    options: {
      enabled: !!selectedGameWayType,
    },
  });

  const {
    data: latestNumber,
    refetch: refetchLatestNumber,
    isLoading: latestNumberLoading,
    isRefetching: latestNumberRefetching,
  } = useGetLatestNumber({
    params: {
      game_way: selectedGameList,
    },
    options: {
      enabled: !!selectedGameList,
    },
  });

  // const { data: gameFeeAndOdds } = useGetGameFeeAndOdds({});
  const [walletAmount, setWalletAmount] = useState(0);
  const [totalMoney, setTotalMoney] = useState(null);

  const [ruleVisible, setRuleVisible] = useState(false);
  const [isBetVisible, setIsBetVisible] = useState(false);

  const [selectedBet, setSelectedBet] = useState();
  // const [nowOddsSetting, setNowOddsSetting] = useState({});

  const [popupVisible, setPopupVisible] = useState(false);
  const [showPeriodEndModal, setShowPeriodEndModal] = useState(false);
  const [popupSetting, setPopupSetting] = useState({
    title: "",
    buttonText: "",
    onButtonPress: () => {},
    popupContent: "",
  });

  const [currentGameState, setCurrentGameState] = useState(winStateEnum.BET_PHASE);

  const VALIDATOR_FUNCTION_LIST = [ValidateMaxMinBet, ValidateUserMoney, ValidateMaxPayout];

  const { mutate: postGameBet } = usePostGameBet();

  const onTabChange = (e) => {
    switchStockWS({ oldChannel: selectedGameList, newChannel: e });
    // switchGameWS({ oldChannel: selectedGameList, newChannel: e });
    const foundData = data.find((game) => game.key === e);
    setSelectedGameList(e);
    setSelectedGameWayType(foundData.game_way_type);
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 750);
  };

  const handleOpenWebsocket = (category) => {
    const foundData = data.find((game) => game.key === category);
    if (isStockWSConnected === false) {
      openStockWS({ channel: category });
    }
    // if (isGameWSConnected === false) {
    //   openGameWS({ channel: category });
    //   setSelectedGameList(category);
    //   setSelectedGameWayType(foundData.game_way_type);
    // }
  };

  const onBetClose = () => {
    setIsBetVisible(false);
    setTotalMoney(null);
  };

  const handleBetItemClick = (option) => {
    setSelectedBet(option);
    setIsBetVisible(true);
  };

  const renderBetModalTitle = () => {
    if (!selectedBet) return null;
    let title = "";
    if (selectedBet.translate === true) {
      title = t(selectedBet.title);
    } else {
      title = selectedBet.title;
    }
    return (
      <>
        <span>{`${t("win.betpopup.title")}: `}</span>
        <span className={styles.titleBold}>{title}</span>
      </>
    );
  };

  const colorStyle = () => {
    if (!selectedBet) return {};
    if (selectedBet.color.length <= 1) {
      return { backgroundColor: `${selectedBet.color[0]}` };
    }
    return {
      backgroundImage: `linear-gradient(160deg,${selectedBet.color[0]} 50%, ${selectedBet.color[1]} 0)`,
    };
  };

  const validateInput = ({
    walletAmount,
    betAmount,
    odds,
    highest_single_bet,
    lowest_single_bet,
    max_payout,
  }) => {
    let isValid = true;
    const max_total_bet_limit = latestNumber[0]?.max_total_bet_limit;
    VALIDATOR_FUNCTION_LIST.every((_validator, index) => {
      const return_value = _validator({
        walletAmount,
        betAmount: parseFloat(betAmount),
        odds,
        highest_single_bet:
          Number(max_total_bet_limit) <= 0 ? highest_single_bet : max_total_bet_limit,
        lowest_single_bet,
        max_payout,
      });
      if (return_value.valid === false) {
        setPopupSetting({
          title: t("win.popup.periodEnd.title"),
          buttonText: t("win.popup.periodEnd.button"),
          onButtonPress: () => {},
          destroyOnClose: true,
          popupContent: t(return_value.content, return_value.options),
        });
        setPopupVisible(true);
        isValid = false;
        return false;
      }
      return true;
    });
    return isValid;
  };

  const refreshData = () => {
    if (selectedGameList) refetchLatestNumber();
  };

  const handleSubmitBet = async () => {
    if (!selectedGameList) return;
    const selectedGameWay = data.find((game) => game.key === selectedGameList);
    const isValid = validateInput({
      walletAmount: walletAmount,
      betAmount: totalMoney,
      ...selectedBet,
    });
    if (isValid === false) return;
    const params = {
      game_category_type: selectedGameWay.game_category_type,
      game_category_detail_type: selectedGameWay.game_category_detail_type,
      game_type: selectedGameWay.game_type,
      game_way_type: selectedGameWay.game_way_type,
      game_way_detail_type: null,
      period: latestNumber[0]?.period,
      choice_number: [
        {
          bet_content: selectedBet.key,
          bet_point: totalMoney,
          game_method_code: selectedBet.game_method_code,
        },
      ],
    };
    await postGameBet(
      {
        params: params,
      },
      {
        onSuccess: () => {
          setPopupSetting({
            title: t("win.popup.periodEnd.title"),
            buttonText: t("win.popup.betSuccess.btn.close"),
            onButtonPress: () => {},
            destroyOnClose: true,
            popupContent: t("win.popup.betSuccess.content"),
          });
          setPopupVisible(true);
        },
        onError: (error) => {
          if (error?.result?.code === "6669999") {
            setPopupSetting({
              title: t("win.popup.periodEnd.title"),
              buttonText: t("win.popup.periodEnd.button"),
              onButtonPress: () => {
                if (selectedGameList) {
                  refetchLatestNumber();
                }
              },
              onClose: () => {
                if (selectedGameList) {
                  refetchLatestNumber();
                }
              },
              destroyOnClose: true,
              popupContent: t("validate.notWithinAllowedTime"),
            });

            setPopupVisible(true);
            return;
          }
          setPopupSetting({
            title: t("win.popup.periodEnd.title"),
            buttonText: t("win.popup.periodEnd.button"),
            onButtonPress: () => {},
            destroyOnClose: true,
            popupContent: error,
          });
          setPopupVisible(true);
        },
      },
    );
  };

  // useEffect(() => {
  //   return () => {
  //     handleCloseWebsocket();
  //   };
  // }, [isGameWSConnected]);

  useEffect(() => {
    if (!data || data.length <= 0 || !gameId) return;
    const foundData = data.find((game) => game.key === gameId);
    if (foundData) {
      // handleOpenWebsocket(foundData.key);
      setSelectedGameList(foundData.key);
      setSelectedGameWayType(foundData.game_way_type);
    } else {
      // handleOpenWebsocket(data[0].key);
      setSelectedGameList(data[0].key);
      setSelectedGameWayType(data[0].game_way_type);
    }
  }, [data, gameId]);

  useEffect(() => {
    if (counter <= 1) {
      if (isBetVisible === true) {
        setPopupSetting({
          title: t("win.popup.periodEnd.title"),
          buttonText: t("win.popup.periodEnd.button"),
          onButtonPress: () => {},
          destroyOnClose: true,
          popupContent: t("validate.notWithinAllowedTime"),
        });
        setPopupVisible(true);
      } else {
        setShowPeriodEndModal(true);
      }
    }
  }, [counter, isBetVisible]);

  useEffect(() => {
    if (showPeriodEndModal === true) {
      setTimeout(() => {
        setShowPeriodEndModal(false);
      }, [5000]);
    }
  }, [showPeriodEndModal]);

  // useEffect(() => {
  //   if (!!gameFeeAndOdds && selectedGameList) {
  //     const nowOdds = gameFeeAndOdds[selectedGameList] ?? {};
  //     console.log("nowOdds", nowOdds);
  //     setNowOddsSetting(nowOdds);
  //   }
  // }, [gameFeeAndOdds, selectedGameList]);

  const getActiveTicker = () => {
    if (!data) return "BTCUSDT";
    const _data = data.find((category) => category.key === selectedGameList);
    return _data?.ticker || "BTCUSDT";
  };

  return (
    <>
      <GameHeader
        refreshData={refreshData}
        setRuleVisible={setRuleVisible}
        walletAmount={walletAmount}
        setWalletAmount={setWalletAmount}
        setCurrentGameState={setCurrentGameState}
        currentGameState={currentGameState}
        onHandleDepositOpenCallback={handleClosePage}
        selectedGameList={selectedGameList}
        latestNumber={latestNumber}
        latestNumberRefetching={latestNumberRefetching}
      />
      <LastPriceViewer ticker={getActiveTicker()} />
      <CustomTab
        activeKey={selectedGameList}
        onTabChange={onTabChange}
        tabItems={data}
        tabBarStyle={{ backgroundColor: "#fff" }}
      />
      <KLineChart ticker={getActiveTicker()} />
      <div className={styles.betPanelContainer}>
        <WinBetPanel
          onBetItemClick={handleBetItemClick}
          gameMethod={gameMethod}
          betSelectionEnum={betSelectionEnum}
          latestNumber={latestNumber ? latestNumber[0]?.period : undefined}
        />
      </div>
      <WinHistoryPanel
        selectedGameList={selectedGameList}
        gameCategoryDetailType={gameCode.toUpperCase()}
        latestNumber={latestNumber}
        gameMethod={gameMethod}
      />
      <WinRulePopup
        onClose={() => {
          setRuleVisible(false);
        }}
        visible={ruleVisible}
        destroyOnClose={true}
        content={() => (
          <RuleContent selectedGameList={selectedGameList} gameCode={gameCode.toLowerCase()} />
        )}
      />
      <WinCustomModal
        title={renderBetModalTitle()}
        visible={isBetVisible}
        buttonText={t("normal.confirm")}
        onClose={onBetClose}
        closeOnMaskClick={false}
        destroyOnClose={true}
        onButtonPress={handleSubmitBet}
        titleContainerStyle={colorStyle()}
        buttonStyle={colorStyle()}
        disableButton={!!currentGameState.disableBet || !totalMoney}
      >
        <WinBetModalContent
          colorStyle={colorStyle()}
          setTotalMoney={setTotalMoney}
          totalMoney={totalMoney}
        />
      </WinCustomModal>
      <WinCustomModal
        title={popupSetting.title}
        visible={popupVisible}
        buttonText={popupSetting.buttonText}
        onClose={() => {
          setPopupVisible(false);
        }}
        closeOnMaskClick={false}
        destroyOnClose={true}
        showCustomCloseButton={popupSetting.showCustomCloseButton}
        onButtonPress={popupSetting.onButtonPress}
      >
        <div style={{ textAlign: "center" }}>{popupSetting.popupContent}</div>
      </WinCustomModal>
      <WinCustomModal
        title={t("win.popup.periodEnd.title")}
        buttonText={t("win.popup.periodEnd.button")}
        visible={showPeriodEndModal}
        onClose={() => {
          setShowPeriodEndModal(false);
        }}
      >
        <div>{t("win.popup.periodEnd.content")}</div>
      </WinCustomModal>
    </>
  );
};

export default GameContainer;
