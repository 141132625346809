import { useEffect, useRef } from "react";
import { useGameWebsocket } from "../../../../../context/WebsocketGameContext";
import Gophers from "../Gophers";
import animationPhaseEnum from "./enumerations/animationPhaseEnum";
import positionEnum from "./enumerations/positionEnum";
import Gophers1 from "./images/animation1.png";
import Gophers2 from "./images/animation2.png";
import Gophers3 from "./images/animation3.png";
import Gophers4 from "./images/animation4.png";
import Gophers5 from "./images/animation5.png";
import Gophers6 from "./images/animation6.png";
import Gophers7 from "./images/animation7.png";
import Gophers8 from "./images/animation8.png";
import Gophers9 from "./images/animation9.png";
import styles from "./style.module.scss";

const GOPHERS_IMAGES = [
  {
    image: Gophers1,
  },
  {
    image: Gophers2,
  },
  {
    image: Gophers3,
  },
  {
    image: Gophers4,
  },
  {
    image: Gophers5,
  },
  {
    image: Gophers6,
  },
  {
    image: Gophers7,
  },
  {
    image: Gophers8,
  },
  {
    image: Gophers9,
  },
];

const GophersCollection = ({ gophersState = animationPhaseEnum.RESET, latestNumber }) => {
  const gophersRef = useRef([]);
  const { currentPeriod, prevPeriod } = useGameWebsocket();

  const randomNumber = (max) => {
    return Math.floor(Math.random() * max);
  };

  const randomPosition = (quantity, max) => {
    const set = new Set();
    while (set.size < quantity) {
      set.add(randomNumber(max));
    }
    return set;
  };

  useEffect(() => {
    let timer = null;
    switch (gophersState) {
      case animationPhaseEnum.RANDOM_PENDING:
      case animationPhaseEnum.RANDOM: {
        timer = setInterval(() => {
          const randomCount = randomNumber(9) + 1;
          const positions = randomPosition(randomCount, 9);
          gophersRef.current.forEach((gopher, index) => {
            if (positions.has(index)) {
              if (gopher.getCurrentFrame() === 6) gopher.show();
            } else {
              if (gopher.getCurrentFrame() === 0) gopher.hide();
            }
          });
        }, [1000]);
        break;
      }
      case animationPhaseEnum.RESULT: {
        let number = 0;
        if (prevPeriod.lottery_number) {
          number = randomPosition(parseInt(prevPeriod.lottery_number || 0), 9);
        } else {
          number = latestNumber ? parseInt(latestNumber[1]?.lottery_number || 0) : 0;
        }
        const positions = randomPosition(number, 9);
        gophersRef.current.forEach((gopher, index) => {
          if (positions.has(index)) {
            gopher.show();
          } else {
            gopher.hide();
          }
          if (positions.size === 0) {
            gopher.hide();
          }
        });
        break;
      }
      case animationPhaseEnum.RESET:
        gophersRef.current.forEach((gopher) => {
          gopher.hide();
        });
        break;
      default:
        break;
    }
    return () => {
      clearInterval(timer);
    };
  }, [gophersState, latestNumber]);

  return (
    <div className={styles.container}>
      {GOPHERS_IMAGES.map((gopher, index) => {
        return (
          <Gophers
            ref={(el) => (gophersRef.current[index] = el)}
            image={gopher.image}
            top={positionEnum[index].top}
            left={positionEnum[index].left}
          />
        );
      })}
    </div>
  );
};

export default GophersCollection;
