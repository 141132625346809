import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiApplyCoupon } from "../service";

export const useApplyCoupon = () => {
  const cache = useQueryClient();
  return useMutation(
    ({ params, query }) => apiApplyCoupon(params, query).then((res) => res.data.data),
    {
      onSuccess: () => {
        // cache.invalidateQueries([queryKey.SPIN_RECORD]);
      },
    },
  );
};
