import { Spin } from "antd";
import React from "react";

import styles from "./style.module.scss";

const LoadingView = () => {
  return (
    <div className={styles.container}>
      <Spin />
    </div>
  );
};

export default LoadingView;
