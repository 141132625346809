import "animate.css";
import "antd/dist/antd.min.css";
import { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import "./App.css";
import { httpClient } from "./apis/https";
import FontSetting from "./components/FontSetting";
import { GCODE, HEAD_51LA_ID, HEAD_FBPIXEL_ID, PRODUCTION_NAME_DEV } from "./config";
import {
  LoadingContextProvider,
  WebsocketGameContextProvider,
  WebsocketStockContextProvider,
} from "./context";
import { useDevice } from "./context/DeviceContext";
import { platformAdScript } from "./externalScript";
import "./plugins/i18n";
import RouterRenderPC from "./router/PC";

const currentTheme = require(`./theme/${PRODUCTION_NAME_DEV}/theme.json`);
const defaultTheme = require(`./theme/defaultTheme.json`);
const finalTheme = { ...defaultTheme, ...currentTheme };

function getFaviconEl() {
  return document.getElementById("favicon");
}
function getTitleEl() {
  return document.getElementById("head-title");
}
function App() {
  const { t } = useTranslation();
  const [vh, setVh] = useState(1);
  const { isMobile } = useDevice();
  const setTheme = () => {
    const r = document.querySelector(":root");
    const final = Object.entries(finalTheme);
    final.map((color) => {
      r.style.setProperty(color[0], color[1]);
    });
  };
  useEffect(() => {
    setTheme();
  }, [finalTheme]);

  useEffect(() => {
    initStyle();
    initGTAG();
  }, []);

  const renderScript = () => {
    if (HEAD_FBPIXEL_ID) {
      return (
        <Helmet>
          <script type="text/javascript">
            {platformAdScript["FBpixel"].script({ id: HEAD_FBPIXEL_ID })}
          </script>
        </Helmet>
      );
    }
    if (HEAD_51LA_ID) {
      return (
        <Helmet>
          <script type="text/javascript">
            {platformAdScript["51la"].script({ id: HEAD_51LA_ID })}
          </script>
        </Helmet>
      );
    }
  };
  const renderNoScript = () => {
    if (HEAD_FBPIXEL_ID) {
      return (
        <Helmet>
          <noscript>{platformAdScript["FBpixel"].noscript({ id: HEAD_FBPIXEL_ID })}</noscript>
        </Helmet>
      );
    }
  };

  useEffect(() => {
    httpClient.translateFn = t;
  }, [t]);

  const initGTAG = () => {
    if (GCODE === "" || GCODE === null || GCODE === undefined) return;
    TagManager.initialize({
      gtmId: GCODE,
    });
  };

  const initStyle = () => {
    let _vh = window.innerHeight * 0.01;
    setVh(_vh);
    window.document.documentElement.style.setProperty("--vh", `${_vh}px`);
    window.document.documentElement.style.setProperty("background", `#f5f5f5`);
  };

  /**
   * SCROLL TESTING PURPOSE
   * DO NOT DELETE, OPEN WHEN DEBUG SCROLL ISSUE ONLY
   */
  // window.addEventListener(
  //   "scroll",
  //   function (e) {
  //     var el = e.target;
  //     while (el && el !== document && !isScrollable(el)) {
  //       el = el.parent;
  //     }
  //     console.debug("Scrolled element: " + (el.className || "document"));
  //   },
  //   true,
  // );

  // function isScrollable(el) {
  //   return el.scrollWidth > el.clientWidth || el.scrollHeight > el.clientHeight;
  // }

  return (
    <div id="app" className="app">
      {renderScript()}
      {renderNoScript()}
      <LoadingContextProvider>
        <WebsocketGameContextProvider>
          <WebsocketStockContextProvider>
            <FontSetting />
            {/* {isMobile ? <RouterRenderMobile /> : <RouterRenderPC />} */}
            <RouterRenderPC />
          </WebsocketStockContextProvider>
        </WebsocketGameContextProvider>
      </LoadingContextProvider>
    </div>
  );
}

export default App;
