import { CloseOutlined } from "@ant-design/icons";
import { Button, CenterPopup } from "antd-mobile";
import "./style.scss";

const WinCustomModal = ({
  title = "",
  buttonText = "",
  visible = false,
  children,
  closeOnMaskClick = false,
  showCloseButton = false,
  titleContainerStyle = {},
  buttonStyle = {},
  onClose = () => {},
  onButtonPress = () => {},
  destroyOnClose = false,
  popupStyle = {},
  buttonInnerStyle = {},
  disableButton = false,
  showCustomCloseButton = true,
}) => {
  const onHandleButtonPress = () => {
    onButtonPress();
    onClose();
  };

  return (
    <CenterPopup
      closeOnMaskClick={closeOnMaskClick}
      className="custom-popup"
      showCloseButton={showCloseButton}
      visible={visible}
      destroyOnClose={destroyOnClose}
      onClose={onClose}
      style={popupStyle}
    >
      <div className="title" style={{ ...titleContainerStyle }}>
        <div>{title}</div>

        {showCustomCloseButton === true ? (
          <div onClick={onClose} className="close-button">
            <CloseOutlined />
          </div>
        ) : null}
      </div>
      <div className="content">{children}</div>
      <div className="button-container">
        <Button
          onClick={onHandleButtonPress}
          className="button"
          style={{ ...buttonStyle }}
          disabled={disableButton}
        >
          <div className="button-inner-container" style={{ ...buttonInnerStyle }}>
            {buttonText}
          </div>
        </Button>
      </div>
    </CenterPopup>
  );
};

export default WinCustomModal;
