import { requestGlobalUserData } from "../../../apis";

export const apiForgotPassword = (params) =>
  requestGlobalUserData("put", `/user/forget/password?`, params, true);

export const apiGetCode = (params) =>
  requestGlobalUserData("get", `/user/forget/send/email`, params, true);

export const apiGetForgetCheckAccount = (params) =>
  requestGlobalUserData("get", `/user/forget/check/account`, params);
