import { RightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import styles from "./style.module.scss";

function FullWidthItem({
  title = "",
  content = "",
  showArrow = false,
  onClick = () => {},
  clickable = false,
  arrowOnClick = () => {},
  contentColor,
  type = "mobile",
}) {
  return (
    <div
      className={`${styles.fullWidthItem} ${type === "pc" ? styles.pc : ""} ${
        clickable ? styles.clickable : ""
      }`}
      onClick={onClick}
    >
      <div className={`${styles.title}`}>{title}</div>
      <div
        className={`${styles.content}`}
        style={{
          width: showArrow ? "calc(50% - 24px)" : "",
          color: contentColor,
        }}
      >
        {content}
      </div>
      {showArrow ? (
        <Button
          onClick={arrowOnClick}
          shape="circle"
          type="text"
          size="small"
          icon={<RightOutlined style={{ color: "var(--text-p1)" }} />}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default FullWidthItem;
