import { Carousel } from "antd";
import React, { useState } from "react";
import { tip } from "../../../../../apis/utils";
import { useLogin } from "../../../../../context/LoginContext";
import localStorageKey from "../../../../../enumerations/localStorageKey";
import { useGetBanner, useGetPromotionBanner } from "../../../../../hooks/Home/home.hook";
import { checkUrlReg } from "../../../../../plugins/utils";
import "./style.scss";

function FullWidthCarousel({}) {
  const [items, setItems] = useState([]);
  const { checkIfLogin } = useLogin();
  const getNormalBanner = useGetBanner({
    enabled: !!window.localStorage.getItem(localStorageKey.DEFAULT_CURRENCY),
    staleTime: 0,
    onSuccess: (data) => {
      setItems(data);
      getPromotionBanner.refetch();
    },
    onError: (error) => {
      tip({ content: error, type: "error" });
      getPromotionBanner.refetch();
    },
  });
  const getPromotionBanner = useGetPromotionBanner({
    //promotion banner should render behind main banner
    //home banner api may be in error
    enabled: false,
    onSuccess: (promotionBanner) => {
      // let newItems = JSON.parse(JSON.stringify(items));
      let newItems = [];
      promotionBanner?.forEach((item) => {
        newItems.push({
          url: "",
          img: item.img_url,
          name: item.title,
        });
      });
      setItems((prevState) => [...prevState, ...newItems]);
    },
    onError: (error) => {
      tip({ content: error, type: "error" });
    },
  });
  const itemOnClick = (url) => {
    if (!checkIfLogin()) return;
    if (!url) return;
    checkUrlReg(url);
  };
  const contentStyle = {
    // borderRadius: "4px",
    overflow: "hidden",
  };
  const imgStyle = {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  };
  return (
    <div className="home-full-width-carousel">
      <Carousel
        // style={{ margin: "0px 16px" }}
        className="home-full-width-carousel"
        dots={{ className: "home-carousel-dot" }}
        autoplay
        autoplaySpeed={5000}
        // draggable={true}
      >
        {items?.map((item, index) => {
          return (
            <div
              style={contentStyle}
              key={"carousel" + index}
              onClick={() => {
                itemOnClick(item.url);
              }}
            >
              <img src={item.img} alt={item.name} style={imgStyle} />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
}

export default FullWidthCarousel;
