import Tick from "@pqina/flip";
import "@pqina/flip/dist/flip.min.css";
import { useEffect, useRef } from "react";
import styles from "./style.module.scss";
import "./style.scss";

const Flip = ({ value }) => {
  const divRef = useRef();
  const tickRef = useRef();

  useEffect(() => {
    const didInit = (tick) => {
      tickRef.current = tick;
    };
    const currDiv = divRef.current;
    const tickValue = tickRef.current;
    Tick.DOM.create(currDiv, {
      value,
      didInit,
    });
    return () => Tick.DOM.destroy(tickValue);
  });

  useEffect(() => {
    if (tickRef.current) {
      tickRef.current.value = value;
    }
  }, [value]);

  return (
    <div className={styles.container}>
      <div ref={divRef} className="tick">
        <div
          data-repeat="true"
          aria-hidden="true"
          data-layout="horizontal left fit"
          style={{ fontSize: "120px", fontWeight: "700" }}
        >
          <span data-view="flip">Tick</span>
        </div>
      </div>
    </div>
  );
};
export default Flip;
