import { LangSelectorPopover } from "../../../../../components";
import { PRODUCTION_NAME_DEV, PRODUCTION_NAME_DISPLAY } from "../../../../../config";
import styles from "./style.module.scss";
const LangHeader = ({ style = {}, showLanguageSelector = true }) => {
  return (
    <div className={styles.container} style={{ ...style }}>
      <img
        className={styles.logo}
        src={`/images/logo/${PRODUCTION_NAME_DEV}/logo_header.png`}
        alt={PRODUCTION_NAME_DISPLAY}
      />
      {showLanguageSelector ? <LangSelectorPopover /> : null}
    </div>
  );
};

export default LangHeader;
