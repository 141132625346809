import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { PCButton, PCMobileForm } from "../../../../../../../../components";

const PhoneVerificationForm = ({
  account = "",
  onFinish = () => {},
  setAreaCodeSelected = () => {},
  areaCodeSelected,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  return (
    <Form
      form={form}
      onFinish={(values) => {
        onFinish(values);
      }}
      autoComplete="off"
      requiredMark={false}
      layout="vertical"
      style={{ paddingTop: "24px", paddingBottom: "24px" }}
    >
      <PCMobileForm
        isForgetPassword={false}
        account={account}
        formRef={form}
        isHideLabel={false}
        modalVisible={true}
        areaCodeSelected={areaCodeSelected}
        setAreaCodeSelected={setAreaCodeSelected}
      />
      <PCButton block size="large" type="primary" htmlType="submit">
        {t("normal.confirm")}
      </PCButton>
    </Form>
  );
};

export default PhoneVerificationForm;
