import { useMutation, useQueryClient } from "@tanstack/react-query";
import localStorageKey from "../../enumerations/localStorageKey";
import queryKey from "../../enumerations/queryKey";
import { apiPostGameBet } from "./service";

export const usePostGameBet = () => {
  const cache = useQueryClient();
  return useMutation(({ params }) => apiPostGameBet(params), {
    onSuccess: (data, variables, context) => {
      // cache.invalidateQueries([queryKey.USER_WALLET]);
      // cache.invalidateQueries([queryKey.BET_RECORD]);
      const betPoint = variables?.params?.choice_number[0].bet_point;
      const currentWallet = parseFloat(localStorage.getItem(localStorageKey.WALLET));
      const newAmount = currentWallet - betPoint;
      cache.setQueryData([queryKey.USER_WALLET_CACHE], (oldData) => {
        return {
          ...oldData,
          money: newAmount,
        };
      });
      cache.setQueryData([queryKey.USER_WALLET], (oldData) => {
        return {
          ...oldData,
          money: newAmount,
        };
      });
      localStorage.setItem(localStorageKey.WALLET, newAmount);
    },
    onError: (error) => {
      console.debug(JSON.stringify("ERR", error));
    },
  });
};
