const Home = (props) => {
  const { color, size } = props;
  return (
    <svg
      width={size ?? 54}
      height={size ?? 54}
      viewBox="0 0 54 54"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.494 2c3.796 0 11.163 4.238 22.1 12.715a7.5 7.5 0 0 1 2.906 5.928V44.5A7.5 7.5 0 0 1 45 52H10a7.5 7.5 0 0 1-7.5-7.5V20.642a7.5 7.5 0 0 1 2.904-5.927C16.334 6.238 23.698 2 27.494 2zm8.733 30.81a1.875 1.875 0 0 0-2.502.878c-1.905 3.964-4.347 5.79-7.475 5.79-3.061 0-5.179-1.741-6.57-5.612a1.875 1.875 0 0 0-3.53 1.268c1.894 5.267 5.332 8.094 10.1 8.094 4.7 0 8.369-2.743 10.855-7.916a1.875 1.875 0 0 0-.878-2.502z"
        fill={color ?? "var(--color-text-stable)"}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Home;
