import { Image } from "antd-mobile";
import { useEffect, useState } from "react";
import { useGetGameV2 } from "../../../../hooks/Home/home1bbetv2.hooks";
import styles from "./style.module.scss";

import AgeRestrictionImage from "./images/18plus.png";
import PlayProtectImage from "./images/protect.png";

import { APPLICATION_VERSION, PRODUCTION_NAME_DISPLAY } from "../../../../config";
import GamblingComissionImage from "./images/gc.png";
import GamingLabsImage from "./images/gli.png";
import MaltaImage from "./images/mga.png";
import PagcorImage from "./images/pagcor.png";

const GROUPED_PLATFORM = [
  ["pp", "pplive"],
  ["welive", "welottery", "weblockchain", "wesport"],
  ["jili", "jilifish"],
];

const FooterComponent = ({ hidden }) => {
  const [game3thList, setGame3thList] = useState([]);
  const [footerGameList, setFooterGameList] = useState([]);
  const { data } = useGetGameV2({});

  useEffect(() => {
    if (data && data.category_list) {
      const _game3thList = data.category_list.slice(1).reduce((acc, val) => {
        return [...acc, ...val.game_3th];
      }, []);
      setGame3thList(_game3thList);
    }
  }, [data]);

  useEffect(() => {
    if (!game3thList) return;
    let newList = game3thList;
    GROUPED_PLATFORM.forEach((group) => {
      let count = 0;
      let tempList = [];
      newList.forEach((game3th) => {
        if (group.includes(game3th.game_3th_code)) {
          count = count + 1;
          if (count <= 1) tempList.push(game3th);
        } else {
          tempList.push(game3th);
        }
      });
      newList = [...tempList];
    });

    setFooterGameList(newList);
  }, [game3thList]);

  return (
    <div className={`${styles.footerContainer} ${hidden ? styles.hide : ""}`}>
      <div className={styles.gameListContainer}>
        <div className={styles.gameListSubContainer}>
          {footerGameList.map((_game, index) => {
            return (
              <Image
                key={`${index}_${_game.game_3th_code}`}
                src={`/images/platform/logo/dark/${_game.game_3th_code}.png`}
                className={styles.image}
              />
            );
          })}
        </div>
      </div>
      <div className={styles.licenseContainer}>
        <div className={styles.playProtect}>
          <Image className={styles.ageRestrict} src={AgeRestrictionImage} />
          <Image className={styles.protectImage} src={PlayProtectImage} />
          <div>
            Attention! It is gambling advertising. Gambling is not suitable for solving financial
            problems. Please read terms and conditions and play responsibly.
          </div>
        </div>
        <div className={styles.license}>
          <Image className={styles.licenseImage} src={MaltaImage} />
          <Image className={styles.licenseImage} src={PagcorImage} />
          <Image className={styles.licenseImage} src={GamingLabsImage} />
          <Image className={styles.licenseImage} src={GamblingComissionImage} />
        </div>
        {/* <div className={styles.licenseText}>
          AA188.com is owned and operated by Santeda International B.V. (registration number:
          089453; registered in Curacao) operating through its EU-EEA representative SANTEDA
          INTERNATIONAL LIMITED(registration number: HE406761; registered in Cyprus with actual
          address: Patrikiou Loumoumpa, 7, Block A, Flat A13, 7560 Pervolia, Larnaca, Cyprus).
          Santeda International B.V. is a company registered and established under the laws of
          Curacao and licensed and regulated by the Government of Curaçao under the gaming license
          No. 1668/JAZ issued to Curaçao eGaming, authorized and regulated by the Government of
          Curacao with registered/actual address: Pareraweg 45, Curacao.
        </div> */}
        <div className={styles.copyright}>
          <div>{`Copyright © 2023 ${PRODUCTION_NAME_DISPLAY}. All rights reserved.`}</div>
          <div className={styles.version}>
            <div>Current version： </div>
            <span>{`Ver ${APPLICATION_VERSION}`}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterComponent;
