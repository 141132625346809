import React from "react";
import { Row } from "antd";
import styles from "./style.module.scss";
import LeftGroup from "./components/LeftGroup";
import CenterGroup from "./components/CenterGroup";
import RightGroup from "./components/RightGroup";
const Navigation = (params) => {
  return (
    <>
      <div className={`${styles.navigation}`}>
        <Row style={{ height: "50px" }}>
          <LeftGroup {...(params?.left ?? {})} goBack={params.goBack} />
          <CenterGroup {...(params?.center ?? {})} />
          <RightGroup {...(params?.right ?? {})} />
        </Row>
      </div>
    </>
  );
};

export default Navigation;
