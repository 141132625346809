import { useEffect, useState } from "react";
import { useGameWebsocket } from "../../../../../context/WebsocketGameContext";
import gameStateEnum from "../../enumeration/gameStateEnum";
import styles from "./style.module.scss";

const ResultAnimateText = ({
  startAt = 0,
  animate = false,
  explodeRocket = () => {},
  setCurrentGameState = () => {},
  setResultTimer = () => {},
  latestNumber,
}) => {
  const { prevPeriod } = useGameWebsocket();
  const MAX = 9;
  const MIN = 0;
  const [heightValue, setHeightValue] = useState(startAt);
  const [isExploded, setIsExploded] = useState(false);

  useEffect(() => {
    let timer = undefined;
    setTimeout(() => {
      timer = setInterval(() => {
        setHeightValue((prevState) => {
          return prevState + 1;
        });
      }, [1000]);
    }, [1000]);
    return () => {
      clearInterval(timer);
    };
  }, [isExploded]);

  useEffect(() => {
    if (prevPeriod && prevPeriod.lottery_number) {
      if (heightValue === parseInt(prevPeriod.lottery_number)) {
        if (isExploded === false) {
          explodeRocket();
        }
        setIsExploded(true);
        setCurrentGameState(gameStateEnum.EXPLODED);
        setTimeout(() => {
          setCurrentGameState(gameStateEnum.RESULT_PHASE);
          setResultTimer(4);
        }, [1000]);
      }
      return;
    }
    if (latestNumber) {
      if (heightValue === parseInt(latestNumber[1].lottery_number)) {
        if (isExploded === false) {
          explodeRocket();
        }
        setIsExploded(true);
        setCurrentGameState(gameStateEnum.EXPLODED);
        setTimeout(() => {
          setCurrentGameState(gameStateEnum.RESULT_PHASE);
          setResultTimer(4);
        }, [1000]);
      }
      return;
    }
  }, [heightValue, isExploded, prevPeriod, latestNumber]);

  return (
    <div className={`${styles.container}`}>
      <div className={styles.number}>{heightValue}</div>
      <div className={styles.km}>km</div>
    </div>
  );
};

export default ResultAnimateText;
