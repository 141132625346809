import React from "react";
import { useTranslation } from "react-i18next";
import { Drawer } from "..";

function CustomerServiceDrawer({ csLink = "", visible = false, handleVisible = () => {} }) {
  const { t } = useTranslation();
  const renderDrawerContent = () => {
    return (
      <div style={{ padding: 0, height: "100%" }}>
        <iframe
          style={{ width: "100%", height: "100%" }}
          seamless="seamless"
          frameBorder={0}
          src={csLink}
          title="Customer Service"
        />
      </div>
    );
  };
  const onClose = () => {
    handleVisible(false);
  };
  return (
    <Drawer
      placement={"right"}
      showDrawer={visible}
      setShowDrawer={handleVisible}
      title={t("normal.customerService")}
      height={100}
      onClose={onClose}
      renderContent={renderDrawerContent}
      closeIcon={false}
      destroyOnClose={true}
    />
  );
}

export default CustomerServiceDrawer;
