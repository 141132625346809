import { Grid } from "antd-mobile";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useGetCustomerService } from "../../../../hooks/customerService/customerService.hook";
import {
  CSIcon,
  FinancialRecordIcon,
  WithdrawalIcon,
} from "../../../../pages/mobile/Personal/images";
import { checkUrlReg } from "../../../../plugins/utils";
import styles from "./style.module.scss";

import CustomerServiceDrawer from "../../../CustomerServiceDrawer";

const PersonalMenu = ({ customContainerStyle = "", customMenu = undefined }) => {
  const { t } = useTranslation();
  const [showCustomerDrawer, setShowCustomerDrawer] = useState(false);
  const defaultMenuItems = [
    {
      title: t("normal.withdraw"),
      onClick: () => {
        navigate("/exchange", {
          state: {
            tabIndex: "withdraw",
            backURL: "/personal",
          },
        });
      },
      icon: <img className={styles.icon} src={WithdrawalIcon} alt={t("normal.withdraw")} />,
    },
    {
      title: t("transaction.record.title"),
      onClick: () => {
        navigate("/exchange/history");
      },
      icon: (
        <img
          className={styles.icon}
          src={FinancialRecordIcon}
          alt={t("transaction.record.title")}
        />
      ),
    },
    {
      title: t("personal.cs"),
      onClick: () => {
        if (getCustomerService?.data) checkUrlReg(getCustomerService.data, openCSDrawer);
      },
      icon: <img className={styles.icon} src={CSIcon} alt={t("personal.cs")} />,
    },
  ];
  const [menu, setMenu] = useState(customMenu ? customMenu : defaultMenuItems);
  const navigate = useNavigate();
  const getCustomerService = useGetCustomerService({});
  const openCSDrawer = () => {
    setShowCustomerDrawer(true);
  };

  return (
    <div className={`${styles.menuContainer} ${customContainerStyle}`}>
      <Grid columns={3}>
        {menu.map((item) => {
          return (
            <Grid.Item key={item.title}>
              <div className={styles.itemContainer} onClick={item.onClick}>
                {item.icon}
                <div className={styles.menuTitle}>
                  <div className={styles.title}>{item.title}</div>
                </div>
              </div>
            </Grid.Item>
          );
        })}
      </Grid>
      <CustomerServiceDrawer
        csLink={getCustomerService?.data}
        visible={showCustomerDrawer}
        handleVisible={setShowCustomerDrawer}
      />
    </div>
  );
};

export default PersonalMenu;
