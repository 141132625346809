import React from "react";
import styles from "./style.module.scss";

import { useTranslation } from "react-i18next";
import { PCButton } from "../../../../../../../../components";
import Success from "./image/success.png";

const SuccessMessage = ({ buttonOnClick = () => {} }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.successContainer}>
      <img className={styles.logo} src={Success} alt="success" />
      <div className={styles.title}>{t("verificationForm.success")}</div>
      {/* <div className={styles.subtitle}>{t("pc.forgetPassword.success.subtitle")}</div> */}
      <PCButton block size="large" type="primary" onClick={buttonOnClick}>
        {t("verificationForm.success.button.text")}
      </PCButton>
    </div>
  );
};

export default SuccessMessage;
