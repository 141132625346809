import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useGetGameV2 } from "../hooks/Home/home1bbetv2.hooks";

const CategoryContext = createContext({
  selectedCategory: undefined,
  setSelectedCategory: () => {},
  gameCategories: undefined,
});

export const CategoryContextProvider = ({ children }) => {
  const location = useLocation();
  const [selectedCategory, setSelectedCategory] = useState();
  const [currentPath, setCurrentPath] = useState();
  const { data } = useGetGameV2({});

  useEffect(() => {
    if (data && data.category_list) {
      if (location.pathname !== "/") {
        setCurrentPath(location.pathname);
        setSelectedCategory(undefined);
      } else {
        if (currentPath !== "/") {
          setCurrentPath(location.currentPath);
          if (!selectedCategory) setSelectedCategory(data.category_list[0]);
        }
      }
    }
  }, [location, data, selectedCategory]);

  return (
    <CategoryContext.Provider
      value={{
        selectedCategory,
        setSelectedCategory,
        gameCategories: data?.category_list,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
};
export const useCategory = () => {
  const CategoryValue = useContext(CategoryContext);
  return CategoryValue;
};
