import { useTranslation } from "react-i18next";

import { PRODUCTION_NAME_DEV } from "../../../../../../config";
import styles from "./style.module.scss";

const CategoryItem = ({ category, active = false, onClick = () => {}, selectedCategory }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`${styles.categoryContainer} ${active ? styles.active : ""}`}
      onClick={() => {
        onClick(category);
      }}
    >
      <img
        className={styles.image}
        src={
          active
            ? `/images/logo/${PRODUCTION_NAME_DEV}/${category?.game_category_code}_active.png`
            : `/images/logo/${PRODUCTION_NAME_DEV}/${category?.game_category_code}.png`
        }
        alt={category?.game_category_code}
      />
      <div className={`${styles.title} ${active ? styles.active : ""}`}>
        {category?.game_category_code === "hot"
          ? t(`home.games.lobby`)
          : t([
              `home.games.${PRODUCTION_NAME_DEV}.${category?.game_category_code}`,
              `home.games.${category?.game_category_code}`,
            ])}
      </div>
    </div>
  );
};

export default CategoryItem;
