import { requestGlobalUserData } from "../../apis";
import localStorageKey from "../../enumerations/localStorageKey";

const currency = window.localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);

export const apiPostUserMailSend = (params) =>
  requestGlobalUserData("get", `/user/mail/send`, params, true, false);

export const apiPostUserMailVerify = (params) =>
  requestGlobalUserData("put", `/user/mail/verify`, params, true, false);

export const apiPutUserForgetPassword = (params) =>
  requestGlobalUserData("put", `/user/forget/password`, params, true, false);

export const apiGetUserForgetSendVerify = (params) =>
  requestGlobalUserData("get", `/user/forget/send/verify`, params, true, false);
