import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { PCButton, PCEmailForm } from "../../../../../../../../components";

const EmailVerificationForm = ({ account = "", onFinish = () => {} }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  return (
    <Form
      form={form}
      onFinish={(values) => {
        onFinish(values);
      }}
      autoComplete="off"
      requiredMark={false}
      layout="vertical"
      style={{ paddingTop: "24px", paddingBottom: "24px" }}
    >
      <PCEmailForm
        isForgetPassword={true}
        account={account}
        formRef={form}
        isHideLabel={true}
        modalVisible={true}
      />
      <PCButton block size="large" type="primary" htmlType="submit">
        {t("normal.confirm")}
      </PCButton>
    </Form>
  );
};

export default EmailVerificationForm;
