import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { HeaderButton } from "../../../../components";
import { PRODUCTION_NAME_DEV, PRODUCTION_NAME_DISPLAY } from "../../../../config";
import { useCategory } from "../../../../context/CategoryContext";
import { useLogin } from "../../../../context/LoginContext";
import { AccountContainer, LoginLink } from "./components";
import styles from "./style.module.scss";
const HeaderComponent = ({ siderCollapsed = false, setSiderCollapsed = () => {} }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { isLogin, checkIfLogin } = useLogin();
  const { gameCategories } = useCategory();

  const handleOpenGame = () => {
    navigate("/", {
      replace: true,
    });
  };

  const handleOpenSport = () => {
    if (!checkIfLogin()) return;
    navigate("/sport", {
      replace: true,
    });
  };

  const renderSportButton = () => {
    if (gameCategories) {
      const hasSport = gameCategories.find((category) => category.game_category_code === "sport");
      if (!hasSport) return null;
      return (
        <HeaderButton
          onClick={handleOpenSport}
          text={t("home.games.sport")}
          icon={`/images/logo/${PRODUCTION_NAME_DEV}/pc_sport${
            location.pathname !== "/sport" ? "_inactive" : ""
          }.svg`}
          containerStyle={`${styles.headerButton} ${
            location.pathname !== "/sport" ? styles.sportsInactive : ""
          }`}
          textStyle={`${styles.textButton} ${
            location.pathname !== "/sport" ? styles.textInactive : ""
          }`}
        />
      );
    }
  };

  return (
    <div className={`${styles.container}`}>
      <div className={styles.headerLeft}>
        <HeaderButton
          onClick={handleOpenGame}
          icon={`/images/logo/${PRODUCTION_NAME_DEV}/pc_games${
            location.pathname === "/sport" ? "_inactive" : ""
          }.svg`}
          text={t("home.games.game")}
          containerStyle={`${styles.headerButton} ${
            location.pathname === "/sport" ? styles.gameInactive : ""
          }`}
          textStyle={`${styles.textButton} ${
            location.pathname === "/sport" ? styles.textInactive : ""
          }`}
        />
        {renderSportButton()}
      </div>
      <div className={styles.headerRight}>
        <div className={styles.rightContainer}>
          {isLogin ? <AccountContainer /> : <LoginLink />}
        </div>
      </div>
      <Button
        type="text"
        icon={
          siderCollapsed ? (
            <MenuUnfoldOutlined style={{ color: "white", fontSize: "24px", paddingTop: "8px" }} />
          ) : (
            <MenuFoldOutlined style={{ color: "white", fontSize: "24px", paddingTop: "8px" }} />
          )
        }
        onClick={() => setSiderCollapsed(!siderCollapsed)}
        className={styles.siderCollapseButton}
      />
      <img
        onClick={handleOpenGame}
        className={styles.headerIcon}
        src={`/images/logo/${PRODUCTION_NAME_DEV}/pc_header_logo.png`}
        alt={`${PRODUCTION_NAME_DISPLAY}`}
      />
    </div>
  );
};

export default HeaderComponent;
