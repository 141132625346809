import { CloseCircleOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { Layout, Modal } from "antd";
import { useEffect, useRef, useState } from "react";
import { useGetPrizeList, useGetSpinRecord } from "../../../hooks/bonusWheel/bonusWheel.hook";
import { useGetCustomerService } from "../../../hooks/customerService/customerService.hook";
import Wheel from "../Wheel";

import BigLogo from "../images/bigLogo.png";
import Coin1 from "../images/coin1.png";
import Coin2 from "../images/coin2.png";
import Phone from "../images/phone.png";

import Big from "big.js";
import { useTranslation } from "react-i18next";
import queryKey from "../../../enumerations/queryKey";
import PCButton from "../../PCButton";
import Description from "../Description";
import RemainingSpins from "../RemainingSpins";
import SpinRecordPopup from "../SpinRecordPopup";
import styles from "./style.module.scss";
import "./style.scss";
const { Header, Sider, Content, Footer } = Layout;

const PCWheelPopup = ({ visible = false, promotionId = 85, setVisible = () => {} }) => {
  const closeModal = () => {
    setVisible(false);
  };
  const { t } = useTranslation();
  const cache = useQueryClient();
  const { data: prizeListData } = useGetPrizeList({
    id: promotionId,
    options: { enabled: !!promotionId && visible === true },
  });
  const { data: csLink } = useGetCustomerService({});

  const { data: spinRecordData } = useGetSpinRecord({
    params: { promotion_id: prizeListData?.id },
    options: { enabled: !!prizeListData && visible === true },
  });

  const animateTimeOut = useRef(undefined);
  const [winner, setWinner] = useState();
  const [lastWinner, setLastWinner] = useState(0);
  const [spinng, setSpinng] = useState(false);
  const [prizeDetail, setPrizeDetail] = useState();
  const [spinning, setSpinning] = useState(false);
  const [animateStyle, setAnimateStyle] = useState({});
  const [remaingTimes, setRemaingTimes] = useState(0);
  const [neededChurnForNext, setNeededChurnForNext] = useState(0);

  const [showSpinRecord, setShowSpinRecord] = useState(false);

  const handleShowRecord = () => {
    setShowSpinRecord(true);
  };

  const spinAfterGetResult = (prizeIndex) => {
    if (prizeListData?.data?.length > 0) {
      const listLength = prizeListData?.data?.length;
      const styleSheet = document?.styleSheets[0];
      let animationName = `animation${Math.round(Math.random() * 100)}`;
      let keyframes = `@keyframes ${animationName} {
        from {
          transform: rotate(${0 - (lastWinner ?? 0) * (360 / listLength)}deg);
        }
        to {
          transform: rotate(${1080 - prizeIndex * (360 / listLength)}deg);
        }
      }`;

      styleSheet.insertRule(keyframes, styleSheet.cssRules.length);

      const style = {
        animationDuration: "5s",
        animationTimingFunction: "cubic-bezier(0.44, -0.205, 0, 1.13)",
        animationName: animationName,
      };
      setAnimateStyle(style);
    }
  };

  const getRemainingTimes = () => {
    const totalChurn = new Big(prizeListData?.total_churn);
    const churnStandard = new Big(
      prizeListData?.churn_standard ? prizeListData?.churn_standard : 1,
    );
    const usedChurn = new Big(spinRecordData?.total_used_churn ?? 0);
    const times = totalChurn.minus(usedChurn).div(churnStandard).round(0, Big.roundDown).toNumber();
    const nextNeed = churnStandard.minus(totalChurn.minus(usedChurn).mod(churnStandard)).toNumber();
    setRemaingTimes(times);
    setNeededChurnForNext(nextNeed);
  };

  useEffect(() => {
    if ((!!winner || winner === 0) && !!spinng) {
      spinAfterGetResult(winner);
    }
  }, [winner, spinng]);

  useEffect(() => {
    if (Object.keys(animateStyle)?.length > 0) {
      animateTimeOut.current = setTimeout(() => {
        setSpinng(false);
        setPrizeDetail(prizeListData?.data[winner]);
        setLastWinner(winner);
        cache.invalidateQueries([queryKey.SPIN_RECORD]);
      }, 5000);
    }
  }, [animateStyle]);

  useEffect(() => {
    return () => {
      clearTimeout(animateTimeOut.current);
    };
  }, []);

  useEffect(() => {
    if (prizeListData && spinRecordData) {
      getRemainingTimes();
    } else {
      setRemaingTimes(0);
      setNeededChurnForNext(0);
    }
  }, [prizeListData, spinRecordData]);

  useEffect(() => {
    if (!visible) {
      setWinner(undefined);
      setAnimateStyle({});
      setLastWinner(0);
      setPrizeDetail(undefined);
    }
  }, [visible]);

  return (
    <Modal
      width={839}
      height={652}
      className={"wheel-modal"}
      visible={visible}
      centered
      footer={null}
      closable={false}
      destroyOnClose={true}
    >
      <div className={styles.container}>
        <div className={styles.closeButton} onClick={closeModal}>
          <CloseCircleOutlined className={styles.icon} />
        </div>
        <div className={styles.wheelContainer}>
          {!!prizeListData && prizeListData?.data?.length > 0 && visible === true ? (
            <Wheel
              customWheelContainerStyle={"custom-wheel"}
              promotionId={prizeListData?.id}
              prizeList={prizeListData?.data}
              animateStyle={animateStyle}
              setAnimateStyle={setAnimateStyle}
              spinng={spinng}
              setSpinng={setSpinng}
              setWinner={setWinner}
              prizeDetail={prizeDetail}
              setPrizeDetail={setPrizeDetail}
              csLink={csLink}
              remaingTimes={remaingTimes}
            />
          ) : (
            ""
          )}
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.imgContainer}>
            <img src={BigLogo} alt="big logo" className={styles.bigLogo} />
            <img src={Coin1} alt="coin" className={styles.coin1} />
            <img src={Coin2} alt="coin" className={styles.coin2} />
            <img src={Phone} alt="phone" className={styles.phone} />
          </div>
          <div className={styles.titleContainer}>
            <div className={styles.subTitle}>{t("bonusWheel.spinTheWheel")}</div>
            <div className={styles.mainTitle}>{t("bonusWheel.winJackpot")}</div>
          </div>
          <RemainingSpins
            pc={true}
            remaingTimes={remaingTimes}
            neededChurnForNext={neededChurnForNext}
            churnStandard={prizeListData?.churn_standard}
          />
        </div>
        <div className={styles.descContainer}>
          <Description
            churnStandard={prizeListData?.churn_standard}
            description={prizeListData?.description}
            startAt={prizeListData?.start_time}
            endAt={prizeListData?.end_time}
            pc={true}
          />
        </div>
      </div>
      <div className={styles.recordButton}>
        <PCButton type={"primary"} size={"small"} onClick={handleShowRecord}>
          {t("bonusWheel.spinRecord")}
        </PCButton>
      </div>
      <SpinRecordPopup
        visible={showSpinRecord}
        onClose={() => {
          setShowSpinRecord(false);
        }}
        data={spinRecordData?.record}
      />
    </Modal>
  );
};

export default PCWheelPopup;
