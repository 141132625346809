import { useTranslation } from "react-i18next";
import { PCButton } from "../../../../../../components";
import { useLogin } from "../../../../../../context/LoginContext";
import styles from "./style.module.scss";

const LoginLink = () => {
  const { t } = useTranslation();
  const { openPCLoginPopup, openPCRegisterPopup } = useLogin();

  const onLoginOpen = () => {
    openPCLoginPopup();
  };

  const onRegisterOpen = () => {
    openPCRegisterPopup();
  };

  return (
    <div className={styles.container}>
      <PCButton
        containerStyle={styles.loginButton}
        icon={false}
        type={"text"}
        onClick={onLoginOpen}
        htmlType={"button"}
      >
        {t("win.normal.login")}
      </PCButton>
      <PCButton
        containerStyle={styles.registerButton}
        icon={false}
        type={"primary"}
        onClick={onRegisterOpen}
        htmlType={"button"}
        size={"small"}
        shape={"round"}
      >
        {t("normal.signup")}
      </PCButton>
    </div>
  );
};

export default LoginLink;
