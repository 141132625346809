import { useQuery } from "@tanstack/react-query";
import queryKey from "../../enumerations/queryKey";
import { apiGetTGLink } from "./service";

export const useGetTGLink = ({ options }) => {
  return useQuery(
    [queryKey.TG_LINK],
    () =>
      apiGetTGLink({}).then((res) => {
        return res.data;
      }),
    {
      ...options,
    },
  );
};
