import { CloseCircleOutlined, RightOutlined } from "@ant-design/icons";
import { Popover } from "antd-mobile";
import { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import { PRODUCTION_NAME_DEV, PRODUCTION_NAME_DISPLAY } from "../../config";
import { useLogin } from "../../context/LoginContext";
import {
  useGetCustomerService,
  useGetCustomerServiceNoCurrency,
} from "../../hooks/customerService/customerService.hook";
import { checkUrlReg } from "../../plugins/utils";
import CustomerServiceIcon from "./images/CustomerServiceIcon";
import styles from "./style.module.scss";
import "./style.scss";
const PCCSButton = () => {
  const { t } = useTranslation();
  const [languagePopoverVisible, setLanguagePopoverVisible] = useState(false);
  const [currentCSLink, setCurrentCSLink] = useState();
  const { isLogin, checkIfVerified } = useLogin();
  const { data: csLink, isError } = useGetCustomerService({
    enabled: isLogin === true,
    onError: (error) => {
      setCurrentCSLink(error.customer_service_link);
    },
  });
  const { data: csCurrencyList } = useGetCustomerServiceNoCurrency({
    enabled: isLogin === false,
  });

  const handleCSClick = () => {
    if (isLogin) {
      if (!checkIfVerified()) return;
      checkUrlReg(currentCSLink, window.open(currentCSLink));
    } else {
      // setLanguagePopoverVisible((prevState) => !prevState);
      checkUrlReg(currentCSLink, window.open(currentCSLink));
    }
  };

  const handleClosePopup = () => {
    setLanguagePopoverVisible(false);
  };

  const handleOpenCS = (csLink) => {
    checkUrlReg(csLink, window.open(csLink));
  };

  const renderLanguagePopoverContent = () => {
    return (
      <div className={styles.popoverContent}>
        <div className={styles.header}>
          <img
            src={`/images/logo/${PRODUCTION_NAME_DEV}/pc_cs_logo.png`}
            alt="cs"
            className={styles.logo}
          />
          <CloseCircleOutlined className={styles.close} onClick={handleClosePopup} />
        </div>
        <div
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: t("pc.cs.title", { name: PRODUCTION_NAME_DISPLAY }) }}
        />
        <div className={styles.languageCard}>
          <Scrollbars autoHide>
            <div>{t("pc.cs.chooseLanguage")}</div>
            {csCurrencyList?.data.map((cs, index) => {
              return (
                <div
                  className={styles.cardItem}
                  key={`${cs?.id}_${cs?.name}_${index}`}
                  onClick={() => {
                    handleOpenCS(cs.customer_service_link);
                  }}
                >
                  <div className={styles.cardTitle}>{cs?.name}</div>
                  <RightOutlined className={styles.arrow} />
                </div>
              );
            })}
          </Scrollbars>
        </div>
        {/* <div className={styles.help}>{t("personal.help")}</div> */}
      </div>
    );
  };

  useEffect(() => {
    if (!isError) {
      setCurrentCSLink(csLink);
    }
  }, [csLink, isError]);

  return (
    <Popover
      visible={languagePopoverVisible}
      content={renderLanguagePopoverContent()}
      placement={"top-end"}
      className={"custom-popover cs-popover"}
    >
      <div onClick={handleCSClick} className={styles.csButton}>
        <CustomerServiceIcon />
      </div>
    </Popover>
  );
};

export default PCCSButton;
