import { lazy } from "react";
const ConnectionFailed = lazy(() => import("../../pages/PC/ConnectionFailed"));
const InMaintenance = lazy(() => import("../../pages/PC/InMaintenance"));
const AccessDenied = lazy(() => import("../../pages/PC/AccessDenied"));
const Sport = lazy(() => import("../../pages/PC/Sport"));
const Download = lazy(() => import("../../pages/PC/Download"));
const Bonus = lazy(() => import("../../pages/PC/Bonus"));
const Vip = lazy(() => import("../../pages/PC/Vip"));
const Home = lazy(() => import("../../pages/PC/Home"));
const Account = lazy(() => import("../../pages/PC/Account"));
const Messages = lazy(() => import("../../pages/PC/Messages"));
const Support = lazy(() => import("../../pages/PC/Support"));
const Agent = lazy(() => import("../../pages/PC/Agent"));

const RouterList = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/account",
    name: "account",
    component: Account,
  },
  {
    path: "/vip",
    name: "vip",
    component: Vip,
  },
  {
    path: "/bonus",
    name: "bonus",
    component: Bonus,
  },
  {
    path: "/support",
    name: "support",
    component: Support,
  },
  {
    path: "/messages",
    name: "messages",
    component: Messages,
  },
  {
    path: "/download",
    name: "download",
    component: Download,
  },
  {
    path: "/sport",
    name: "sport",
    component: Sport,
  },
  {
    path: "/accessDenied",
    name: "accessDenied",
    component: AccessDenied,
  },
  {
    path: "/inMaintenance",
    name: "inMaintenance",
    component: InMaintenance,
  },
  {
    path: "/connectionFailed",
    name: "connectionFailed",
    component: ConnectionFailed,
  },
  {
    path: "/agent",
    name: "agent",
    component: Agent,
  },
];
export default RouterList;
