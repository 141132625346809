import { useQueryClient } from "@tanstack/react-query";
import { Form } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PCStepButton } from "../../../../../../components";
import styles from "./style.module.scss";

import queryKey from "../../../../../../enumerations/queryKey";
import { usePostEmailVerify } from "../../../../../../hooks/verification/email.hook";
import { usePostMobileVerify } from "../../../../../../hooks/verification/mobile.hook";
import {
  CSCurrencyPicker,
  EmailVerificationForm,
  PCVerificationMethodChoice,
  PhoneVerificationForm,
  SuccessMessage,
} from "./components";

const csSteps = [
  { key: "select_method", title: "Step 01" },
  { key: "verification", title: "Step 02" },
];
const steps = [...csSteps, { key: "verification_finish", title: "Step 03" }];

const VerificationForm = ({
  setCurrentActiveMenu = () => {},
  account = undefined,
  setVisible = () => {},
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const cache = useQueryClient();

  const [currentStep, setCurrentStep] = useState(0);
  const [forgetPasswordMethod, setForgetPasswordMethod] = useState();
  const [stepList, setStepList] = useState(steps);

  //mobile
  const [areaCodeSelected, setAreaCodeSelected] = useState();
  const { mutate: mobileVerify } = usePostMobileVerify({
    onSuccess: () => {
      setCurrentStep(2);
      cache.invalidateQueries([queryKey.USER_INFO]);
    },
  });
  //email
  const { mutate: emailVerify } = usePostEmailVerify({
    onSuccess: () => {
      setCurrentStep(2);
      cache.invalidateQueries([queryKey.USER_INFO]);
    },
  });

  const onEmailVerificationFinish = async (value) => {
    emailVerify(value);
  };
  const onPhoneVerificationFinish = async (value) => {
    mobileVerify({ ...value, area_code: areaCodeSelected });
  };

  // useEffect(() => {
  //   console.log("rrrrr", areaCodeSelected);
  // }, [areaCodeSelected]);

  const renderVerificationForm = () => {
    switch (forgetPasswordMethod) {
      case "email":
        return <EmailVerificationForm account={account} onFinish={onEmailVerificationFinish} />;
      case "mobile":
        return (
          <PhoneVerificationForm
            account={account}
            onFinish={onPhoneVerificationFinish}
            areaCodeSelected={areaCodeSelected}
            setAreaCodeSelected={setAreaCodeSelected}
          />
        );
      case "cs":
        return <CSCurrencyPicker />;
      default:
        break;
    }
  };

  const renderForgetPasswordForm = () => {
    switch (currentStep) {
      case 0: // choose verification method
        return (
          <div className={styles.formContainer}>
            <PCStepButton
              steps={stepList}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
            />
            <PCVerificationMethodChoice
              onMobileClick={() => {
                setCurrentStep(1);
                setForgetPasswordMethod("mobile");
              }}
              onEmailClick={() => {
                setCurrentStep(1);
                setForgetPasswordMethod("email");
              }}
              onCSClick={() => {
                setStepList(csSteps);
                setCurrentStep(1);
                setForgetPasswordMethod("cs");
              }}
            />
          </div>
        );
      case 1: // Verification Form
        return (
          <div className={styles.formContainer}>
            <PCStepButton
              steps={stepList}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
            />
            {renderVerificationForm()}
          </div>
        );
      case 2: //Success / Failed
        return (
          <div className={styles.formContainer}>
            <PCStepButton
              steps={stepList}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              allowBackward={false}
            />
            <SuccessMessage
              buttonOnClick={() => {
                setVisible(false);
              }}
            />
          </div>
        );
      default:
        break;
    }
  };

  useEffect(() => {
    if (currentStep === 0) {
      setStepList(steps);
    }
  }, [currentStep]);

  useEffect(() => {
    return () => {
      setCurrentStep(-1);
    };
  }, []);

  return <div className={styles.forgetPasswordContainer}>{renderForgetPasswordForm()}</div>;
};

export default VerificationForm;
