import { CloseCircleOutlined } from "@ant-design/icons";
import { Layout, Modal } from "antd";
import styles from "./style.module.scss";
import "./style.scss";
const { Header, Sider, Content, Footer } = Layout;

const FormModal = ({
  visible = false,
  children,
  siderContent = undefined,
  setVisible = () => {},
  closeModalCallback = () => {},
}) => {
  const closeModal = () => {
    closeModalCallback();
    setVisible(false);
  };

  return (
    <Modal
      width={780}
      height={550}
      className={"form-modal"}
      visible={visible}
      centered
      footer={null}
      closable={false}
    >
      <div className={styles.outerLayout}>
        <div className={styles.side}>{siderContent}</div>
        <div className={styles.outerMain}>
          <div className={styles.closeButton} onClick={closeModal}>
            <CloseCircleOutlined className={styles.icon} />
          </div>
          {children}
        </div>
      </div>
    </Modal>
  );
};

export default FormModal;
