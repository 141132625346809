const channelSettingsEnum = {
  LOTLAUNCH1: {
    maxTimer: 60,
  },
  LOTLAUNCH2: {
    maxTimer: 60 * 2,
  },
  LOTLAUNCH3: {
    maxTimer: 60 * 3,
  },
  LOTLAUNCH5: {
    maxTimer: 60 * 5,
  },
};

export default channelSettingsEnum;
