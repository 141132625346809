import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CustomModal } from "../../../../components";
import { CDN_URL, PRODUCTION_NAME_DEV } from "../../../../config";
import { useLogin } from "../../../../context/LoginContext";
import Bonus from "./components/Bonus";
import Home from "./components/Home";
import User from "./components/User";
import Wallet from "./components/Wallet";
import styles from "./style.module.scss";
const Footer = ({ boxBg = "", isLogin }) => {
  const [currentMod, setCurrentMod] = useState("home");
  const { checkLoginProcess } = useLogin();
  const [showNeedLoginModal, setShowNeedLoginModal] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    updateCurrentMod();
  }, [window.location.pathname]);

  const updateCurrentMod = () => {
    const target = methods.find((m) => m.path === window.location.pathname);
    setCurrentMod(target?.id);
  };

  const methods = [
    {
      id: "home",
      name: t("footer.home"),
      code: "home",
      icon: Home,
      path: "/",
    },
    {
      id: "bonus",
      name: t("footer.bonus"),
      code: "gift",
      icon: Bonus,
      path: "/bonus",
    },
    {
      id: "wallet",
      name: t("footer.wallet"),
      icon: Wallet,
      code: "wallet",
      path: "/wallet",
      active: false,
    },
    {
      id: "personal",
      name: t("footer.personal"),
      icon: User,
      code: "me",
      path: "/personal",
      active: false,
    },
  ];

  return (
    <div style={{ background: boxBg }}>
      <div className={styles.footer}>
        <ul className={styles.footerItems}>
          {methods.map((m, index) => (
            <li
              className={`${styles.item} ${currentMod == m.id ? styles.active : ""}`}
              key={"footer" + index}
              onClick={() => {
                if (!isLogin) {
                  setShowNeedLoginModal(true);
                  return;
                }

                if (!checkLoginProcess?.process()) {
                  return;
                }

                navigate(m.path);
              }}
            >
              <div className={styles.content}>
                <span className={styles.icon}>
                  <img
                    src={`${CDN_URL}/imgs/${PRODUCTION_NAME_DEV}/tabbar/${
                      currentMod == m.id ? "active" : "inactive"
                    }/${currentMod == m.id ? "front-" : ""}${m.code}.png`}
                    alt={m.code}
                    style={{ width: 48, height: 48 }}
                  />
                  {/* <m.icon
                    size="24"
                    color={
                      currentMod == m.id
                        ? "var(--footer-icon-active)"
                        : "var(--footer-icon)"
                    }
                  ></m.icon> */}
                </span>
                <span className={styles.text}>{m.name.toUpperCase()}</span>
              </div>
            </li>
          ))}
          <div
            className={styles.indicator}
            style={{
              background: `url("${CDN_URL}/imgs/${PRODUCTION_NAME_DEV}/tabbar/active/back-gift.png")`,
              backgroundSize: "cover",
            }}
          />
        </ul>
      </div>
      <CustomModal
        isModalVisible={showNeedLoginModal}
        setIsModalVisible={setShowNeedLoginModal}
        title={t("normal.notLoggedIn")}
        content={t("normal.notLoggedIn.hint")}
        needIcon={true}
        okBtnText={t("normal.forwardTo")}
        cancelBtnText={t("normal.cancel")}
        onOk={() => {
          navigate("/signin");
        }}
      />
    </div>
  );
};

export default Footer;
