import React from "react";

function Lobby({ color = "var(--color-floating-bubble-icon)" }) {
  return (
    <svg width="28" height="30" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.4857 18.5161H22.402V7.36738C22.402 6.75405 22.0986 6.18029 21.5676 5.80438L20.2347 4.8547L20.2131 2.43106C20.2131 1.88698 19.7254 1.45171 19.1294 1.45171H15.4342L13.9821 0.422901C13.2019 -0.140967 12.0966 -0.140967 11.3163 0.422901L3.73079 5.80438C3.19981 6.18029 2.89638 6.75405 2.89638 7.35749L2.8422 18.5161H1.81274C1.36844 18.5161 1 18.8525 1 19.2581C1 19.6637 1.36844 20 1.81274 20H23.4857C23.93 20 24.2984 19.6637 24.2984 19.2581C24.2984 18.8525 23.93 18.5161 23.4857 18.5161ZM6.68915 10.1076V8.62372C6.68915 8.07963 7.17679 7.63447 7.7728 7.63447H9.94009C10.5361 7.63447 11.0237 8.07963 11.0237 8.62372V10.1076C11.0237 10.6517 10.5361 11.0968 9.94009 11.0968H7.7728C7.17679 11.0968 6.68915 10.6517 6.68915 10.1076ZM15.3583 18.5161H9.94009V15.7957C9.94009 14.9747 10.6661 14.3119 11.5656 14.3119H13.7329C14.6323 14.3119 15.3583 14.9747 15.3583 15.7957V18.5161ZM18.6093 10.1076C18.6093 10.6517 18.1216 11.0968 17.5256 11.0968H15.3583C14.7623 11.0968 14.2747 10.6517 14.2747 10.1076V8.62372C14.2747 8.07963 14.7623 7.63447 15.3583 7.63447H17.5256C18.1216 7.63447 18.6093 8.07963 18.6093 8.62372V10.1076Z"
        fill={color}
      />
      <path d="M0.0947266 29H4.37207V28.209H0.973633V21.9541H0.0947266V29Z" fill={color} />
      <path
        d="M7.44434 29.166C9.45605 29.166 10.6768 27.7256 10.6768 25.4795V25.4697C10.6768 23.2139 9.44629 21.7881 7.44434 21.7881C5.45215 21.7881 4.21191 23.209 4.21191 25.4697V25.4795C4.21191 27.7305 5.42285 29.166 7.44434 29.166ZM7.44434 28.3555C5.97949 28.3555 5.11035 27.2227 5.11035 25.4795V25.4697C5.11035 23.7119 6.00879 22.5986 7.44434 22.5986C8.88477 22.5986 9.77832 23.7119 9.77832 25.4697V25.4795C9.77832 27.2227 8.88965 28.3555 7.44434 28.3555Z"
        fill={color}
      />
      <path
        d="M11.2002 29H13.9492C15.4482 29 16.3369 28.2627 16.3369 27.0273V27.0176C16.3369 26.0898 15.7266 25.4307 14.7646 25.3086V25.2305C15.4434 25.1084 15.9805 24.4443 15.9805 23.7168V23.707C15.9805 22.6328 15.1895 21.9541 13.8955 21.9541H11.2002V29ZM13.71 22.7305C14.584 22.7305 15.0918 23.1455 15.0918 23.8584V23.8682C15.0918 24.6104 14.5498 24.9912 13.4854 24.9912H12.0791V22.7305H13.71ZM13.7344 25.748C14.8574 25.748 15.4336 26.1631 15.4336 26.9736V26.9834C15.4336 27.7939 14.877 28.2236 13.8174 28.2236H12.0791V25.748H13.7344Z"
        fill={color}
      />
      <path
        d="M16.7725 29H19.5215C21.0205 29 21.9092 28.2627 21.9092 27.0273V27.0176C21.9092 26.0898 21.2988 25.4307 20.3369 25.3086V25.2305C21.0156 25.1084 21.5527 24.4443 21.5527 23.7168V23.707C21.5527 22.6328 20.7617 21.9541 19.4678 21.9541H16.7725V29ZM19.2822 22.7305C20.1562 22.7305 20.6641 23.1455 20.6641 23.8584V23.8682C20.6641 24.6104 20.1221 24.9912 19.0576 24.9912H17.6514V22.7305H19.2822ZM19.3066 25.748C20.4297 25.748 21.0059 26.1631 21.0059 26.9736V26.9834C21.0059 27.7939 20.4492 28.2236 19.3896 28.2236H17.6514V25.748H19.3066Z"
        fill={color}
      />
      <path
        d="M24.0879 29H24.9668V25.9678L27.4668 21.9541H26.4658L24.5664 25.084H24.4883L22.5889 21.9541H21.5879L24.0879 25.9678V29Z"
        fill={color}
      />
    </svg>
  );
}

export default Lobby;
