import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Element } from "react-scroll";
import localStorageKey from "../../enumerations/localStorageKey";
import { useGetUserInfo } from "../../hooks/personal/personal.hook";
import Footer from "../components/mobile/Footer";
import Navigation from "../components/mobile/Navigation";
import styles from "./style.module.scss";

import Background from "../components/Background";

const MobileLayout = ({ navType, children }) => {
  const [params] = useSearchParams();

  const dontGetUserPages = [
    "/signin",
    "/signup",
    "/",
    "/emailVerify",
    "/forgotPassword",
    "/accessDenied",
    "/inMaintenance",
    "/appDownload",
  ];
  const dontReloadPages = [
    "/signin",
    "/signup",
    "/",
    "/emailVerify",
    "/forgotPassword",
    "/accessDenied",
    "/inMaintenance",
    "/appDownload",
  ];
  const { data } = useGetUserInfo({
    enabled: !dontGetUserPages.includes(window.location.pathname),
  });
  let navigate = useNavigate();
  useEffect(() => {
    if (data) {
      if (
        data.email_status == 0 &&
        !dontReloadPages.includes(window.location.pathname) &&
        data.mobile_status == 0
      ) {
        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
      }
    }
  }, [data, window.location.pathname]);

  useEffect(() => {
    if (params.get("app")) {
      window.localStorage.setItem(localStorageKey.IS_APP, true);
    }
  }, [params]);

  return (
    <Background>
      <div
        className={styles.main}
        style={{
          overflow: "hidden",
          maxWidth: "1920px",
          height: navType.doNotInitWindowHeight ? "" : "calc(var(--vh, 1vh) * 100)", //vh * 100,
          margin: "0 auto",
        }}
      >
        {navType.hideHeader ? (
          <></>
        ) : (
          <Navigation
            {...navType}
            goBack={(backURL) => {
              navigate(backURL, { replace: true });
            }}
          />
        )}
        <Element
          className={styles.container}
          id="containerElement"
          style={{
            flex: 1,
            overflow: "scroll",
            background: navType.background
              ? typeof navType.background === "object"
                ? `linear-gradient(180deg, ${navType.background.start} 0%, ${navType.background.end} 100%)`
                : navType.background
              : "#ffffff",
          }}
        >
          {children}
        </Element>
        {navType.hideFooter ? <></> : <Footer boxBg={navType.footerBoxBg} />}
      </div>
    </Background>
  );
};
export default MobileLayout;
