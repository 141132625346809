const gameStateEnum = {
  BET_PHASE: {
    key: "BET_PHASE",
    animated: false,
    showHeight: false,
    isReset: true,
    showResult: false,
    showCountDown: true,
    rocketVisible: true,
    platformVisible: true,
    disableBet: false,
  },
  DISABLE_BET: {
    key: "DISABLE_BET",
    animated: false,
    showHeight: false,
    isReset: true,
    showResult: false,
    showCountDown: true,
    rocketVisible: true,
    platformVisible: true,
    disableBet: true,
  },
  PENDING_LAUNCH: {
    key: "PENDING_LAUNCH",
    animated: false,
    showHeight: false,
    isReset: true,
    showResult: false,
    showCountDown: true,
    rocketVisible: true,
    platformVisible: true,
    disableBet: true,
  },
  LAUNCHING: {
    key: "LAUNCHING",
    animated: true,
    showHeight: true,
    isReset: false,
    showResult: false,
    showCountDown: false,
    rocketVisible: true,
    platformVisible: true,
    disableBet: true,
  },
  EXPLODED: {
    key: "EXPLODED",
    animated: false,
    showHeight: true,
    isReset: false,
    showResult: false,
    showCountDown: false,
    rocketVisible: true,
    platformVisible: false,
    disableBet: true,
  },
  RESULT_PHASE: {
    key: "RESULT_PHASE",
    animated: false,
    showHeight: false,
    isReset: false,
    showResult: true,
    showCountDown: false,
    rocketVisible: false,
    platformVisible: false,
    disableBet: true,
  },
};

export default gameStateEnum;
