import { useMutation } from "@tanstack/react-query";
import { apiUploadImage } from "./service";


export const useImageUpload = () => {
  return (
    useMutation(({params})=> apiUploadImage({params}).then((res)=> {
      return res.data.data;
    }),{
      onError: (error) => {
        console.debug(JSON.stringify("ERR",error))
      }
    })
  )
}