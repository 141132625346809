import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetGameFeeAndOdds } from "../../../../../hooks/winBet/wingame.hooks";
import { moneyFormat } from "../../../../../plugins/numberFormat";

const RuleContent = ({ gameCode = "", selectedGameList = undefined }) => {
  const { t } = useTranslation();
  const { data: gameFeeAndOdds } = useGetGameFeeAndOdds({});
  const [nowOddsSetting, setNowOddsSetting] = useState({});
  const totalBet = 100;
  const feeRate = gameFeeAndOdds?.fee ? gameFeeAndOdds?.fee * 100 : 0;
  const fee = totalBet * (feeRate / 100);
  const betAmount = totalBet - fee;
  const [greenOdds, setGreenOdds] = useState();
  const [redOdds, setRedOdds] = useState();
  const [violetOdds, setVioletOdds] = useState();
  const [bigOdds, setBigOdds] = useState();
  const [smallOdds, setSmallOdds] = useState();

  useEffect(() => {
    if (!!gameFeeAndOdds && selectedGameList) {
      const nowOdds = gameFeeAndOdds[selectedGameList] ?? {};
      setGreenOdds(nowOdds?.green ? nowOdds?.green.split(",") : undefined);
      setRedOdds(nowOdds?.red ? nowOdds?.red.split(",") : undefined);
      setVioletOdds(nowOdds?.violet);
      setBigOdds(nowOdds?.big);
      setSmallOdds(nowOdds?.small);
      setNowOddsSetting(nowOdds);
    }
  }, [selectedGameList, gameFeeAndOdds]);

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: t(`win.rule.${gameCode}.desc`),
        }}
      />
      <div
        style={{ color: "red" }}
        dangerouslySetInnerHTML={{
          __html: t("win.rule.feeExample", {
            totalBet: moneyFormat(totalBet),
            feeRate: feeRate,
            fee: moneyFormat(fee),
            betAmount: moneyFormat(betAmount),
          }),
        }}
      />
      {greenOdds ? (
        <div
          dangerouslySetInnerHTML={{
            __html: t(`win.rule.${gameCode}.desc.green`, {
              betAmount: moneyFormat(betAmount),
              odd1: greenOdds[0],
              odd2: greenOdds[1],
              total1: moneyFormat(betAmount * greenOdds[0]),
              total2: moneyFormat(betAmount * greenOdds[1]),
            }),
          }}
        />
      ) : null}
      {redOdds ? (
        <div
          dangerouslySetInnerHTML={{
            __html: t(`win.rule.${gameCode}.desc.red`, {
              betAmount: moneyFormat(betAmount),
              odd1: redOdds[0],
              odd2: redOdds[1],
              total1: moneyFormat(betAmount * redOdds[0]),
              total2: moneyFormat(betAmount * redOdds[1]),
            }),
          }}
        />
      ) : null}
      {violetOdds ? (
        <div
          dangerouslySetInnerHTML={{
            __html: t(`win.rule.${gameCode}.desc.violet`, {
              betAmount: moneyFormat(betAmount),
              odd: violetOdds,
              total: moneyFormat(betAmount * violetOdds),
            }),
          }}
        />
      ) : null}
      {bigOdds ? (
        <div
          dangerouslySetInnerHTML={{
            __html: t(`win.rule.${gameCode}.desc.big`, {
              betAmount: moneyFormat(betAmount),
              odd: bigOdds,
              total: moneyFormat(betAmount * bigOdds),
            }),
          }}
        />
      ) : null}
      {smallOdds ? (
        <div
          dangerouslySetInnerHTML={{
            __html: t(`win.rule.${gameCode}.desc.small`, {
              betAmount: moneyFormat(betAmount),
              odd: smallOdds,
              total: moneyFormat(betAmount * smallOdds),
            }),
          }}
        />
      ) : null}
      <div
        dangerouslySetInnerHTML={{
          __html: t(`win.rule.${gameCode}.desc.number.title`),
        }}
      />
      {[...Array(10)].map((i, index) => {
        if (nowOddsSetting[index])
          return (
            <span
              key={index}
              dangerouslySetInnerHTML={{
                __html: t(`win.rule.${gameCode}.desc.number.content`, {
                  num: index,
                  betAmount: moneyFormat(betAmount),
                  odd: nowOddsSetting[index] ?? 0,
                  total: moneyFormat(betAmount * (nowOddsSetting[index] ?? 0)),
                }),
              }}
            />
          );
        else return null;
      })}
    </>
  );
};

export default RuleContent;
