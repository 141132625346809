import { Modal } from "antd";
import { Button } from "antd-mobile";
import { t } from "i18next";

import { moneyFormat } from "../../../../plugins/numberFormat";

import styles from "./style.module.scss";
import "./style.scss";

function ResultModal({
  amount,
  isModalVisible,
  setIsModalVisible,
  maskClosable = false,
  onCloseModal = () => {},
}) {
  const handleCloseModal = () => {
    setIsModalVisible(false);
    onCloseModal();
  };

  const handleConfirmCograt = () => {
    handleCloseModal();
  };

  const congratModal = () => {
    return (
      <div className={styles.congratModal}>
        <img
          src="/images/dailyCheck/congratuation.png"
          alt="congratuation ribbon"
          className={styles.ribbon}
        />
        <span className={styles.congratText}>{t("bonusWheel.congratuations")}</span>
        <div
          className={styles.content}
          style={{
            backgroundImage: `url('/images/dailyCheck/result_bonus.png')`,
            backgroundSize: "100% auto",
            backgroundPositionY: "-5px",
            backgroundRepeat: "no-repeat",
          }}
        >
          <img
            src="/images/dailyCheck/result_shining.gif"
            alt="shining"
            className={styles.resultShining}
          />
          <div className={styles.text}>{t("bonusWheel.getRewarded")}</div>
          <span className={styles.reward}>+ {moneyFormat(parseFloat(amount))}</span>
          <Button block className={styles.confirmBtn} onClick={handleConfirmCograt}>
            {t("normal.confirm")}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      visible={isModalVisible}
      onCancel={handleCloseModal}
      footer={null}
      width={258}
      centered
      closable={false}
      maskClosable={maskClosable}
      destroyOnClose
      className={`daily-check-result-modal congrat`}
      bodyStyle={{ padding: "0" }}
      // style={{borderRadius: '22px'}}
    >
      {congratModal()}
    </Modal>
  );
}

export default ResultModal;
