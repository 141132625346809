import React, { createContext, useContext, useMemo, useState } from "react";
import { LoadingView } from "../components";

const LoadingContext = createContext({
  isLoading: false,
  setIsLoading: () => {},
});

export const LoadingContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const loading = useMemo(() => {
    return (
      <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
        {children}
        {isLoading && <LoadingView />}
      </LoadingContext.Provider>
    );
  }, [isLoading]);

  return loading;
};

export const useLoading = () => {
  const LoadingValue = useContext(LoadingContext);
  return LoadingValue;
};
