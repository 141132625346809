import React from "react";

function Marquee() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          x1="8.738%"
          y1="24.596%"
          x2="93.778%"
          y2="74.597%"
          id="jr202qdaka"
        >
          <stop stopColor="var(--color-label-alt-1)" offset="0%" />
          <stop stopColor="var(--color-label-alt-2)" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="m8.133 11.143 6.384-5.124a1 1 0 0 1 1.626.78v19.076a1 1 0 0 1-1.624.781l-6.56-5.236H3a1 1 0 0 1-1-1v-8.277a1 1 0 0 1 1-1h5.133zm12.829 11.361a1 1 0 0 1-1.401-1.427 6.69 6.69 0 0 0 2.01-4.791 6.685 6.685 0 0 0-1.743-4.514 1 1 0 1 1 1.48-1.345 8.685 8.685 0 0 1 2.263 5.859 8.69 8.69 0 0 1-2.609 6.218zm4.754 4.336a1 1 0 1 1-1.434-1.394A13.094 13.094 0 0 0 28 16.286c0-3.397-1.293-6.589-3.576-9.012a1 1 0 0 1 1.456-1.371c2.63 2.79 4.12 6.47 4.12 10.383 0 3.995-1.555 7.747-4.284 10.554z"
        fill="url(#jr202qdaka)"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default Marquee;
