import { useEffect, useRef, useState } from "react";
import { useGameWebsocket } from "../../../../../context/WebsocketGameContext";
import webSocketActionEnum from "../../../../../enumerations/webSocketActionEnum";
import gameStateEnum from "../../enumeration/gameStateEnum";
import rocketStateEnum from "../../enumeration/rocketStateEnum";
import BalanceViewer from "../BalanceViewer";
import PreparingCountDown from "../PreparingCountDown";
import ResultAnimateText from "../ResultAnimateText";
import ResultViewer from "../ResultViewer";
import Rocket from "../Rocket";
import RocketBackground from "../RocketBackground";
import styles from "./style.module.scss";

const AnimatedPanel = ({
  walletAmount,
  setWalletAmount,
  currentGameState,
  setCurrentGameState,
  resultDefaultTimer,
  animationStartPosition = 0,
  isSwitchChannel,
  setRuleVisible,
  handleDepositCallback = () => {},
  selectedGameList,
  latestNumber = undefined,
  refreshData = () => {},
  latestNumberRefetching = false,
}) => {
  const [resultTimer, setResultTimer] = useState(resultDefaultTimer);
  const { currentPeriod, prevPeriod } = useGameWebsocket();

  useEffect(() => {
    setResultTimer(resultDefaultTimer);
  }, [resultDefaultTimer]);

  const rocketRef = useRef(null);
  const [currentRocketState, setCurrentRocketState] = useState(rocketStateEnum.FLYING);

  const explodeRocket = () => {
    setCurrentRocketState(rocketStateEnum.EXPLODE);
    rocketRef.current.setStartAt(rocketStateEnum.EXPLODE.startFrame);
    rocketRef.current.setEndAt(rocketStateEnum.EXPLODE.endFrame);
    rocketRef.current.goToAndPlay(rocketStateEnum.EXPLODE.startFrame);
  };

  const flyRocket = () => {
    if (rocketRef && rocketRef.current) {
      setCurrentRocketState(rocketStateEnum.FLYING);
      rocketRef.current.pause();
      rocketRef.current.setStartAt(rocketStateEnum.FLYING.startFrame);
      rocketRef.current.setEndAt(rocketStateEnum.FLYING.endFrame);
      rocketRef.current.goToAndPlay(1);
    }
  };

  const calculateBackgroundEnd = () => {
    if (prevPeriod && prevPeriod.lottery_number) {
      const end = prevPeriod?.lottery_number
        ? 100 - (parseInt(prevPeriod?.lottery_number) + 1) * 10 // 5   5+1 *10 100-60 -> 40
        : 0;
      return end;
    } else {
      if (latestNumber) {
        const end = latestNumber[1].lottery_number
          ? 100 - (parseInt(latestNumber[1].lottery_number) + 1) * 10
          : 0;
        return end;
      }
    }
  };

  useEffect(() => {
    switch (currentGameState.key) {
      case "BET_PHASE":
        setTimeout(() => {
          flyRocket();
        }, 500);
        break;
      default:
        break;
    }
  }, [currentGameState]);

  useEffect(() => {
    if (prevPeriod && prevPeriod.action) {
      switch (prevPeriod.action) {
        case webSocketActionEnum.PENDING:
          if (currentGameState.key === gameStateEnum.DISABLE_BET.key)
            setCurrentGameState(gameStateEnum.PENDING_LAUNCH);
          break;
        case webSocketActionEnum.END:
          if (currentGameState.key === gameStateEnum.PENDING_LAUNCH.key)
            setCurrentGameState(gameStateEnum.LAUNCHING);
          break;
        default:
          break;
      }
    }
  }, [prevPeriod, currentGameState]);

  return (
    <div className={styles.container}>
      <RocketBackground
        isAnimated={currentGameState.animated}
        isReset={currentGameState.isReset}
        startPosition={100 - animationStartPosition * 10}
        endPosition={calculateBackgroundEnd()}
        groundStartPosition={animationStartPosition * 10}
        groundEndPosition={100}
        currentGameState={currentGameState}
      />
      <PreparingCountDown
        visible={currentGameState?.showCountDown}
        setCurrentGameState={setCurrentGameState}
        currentGameState={currentGameState}
        isSwitchChannel={isSwitchChannel}
        latestNumber={latestNumber}
        refreshData={refreshData}
        latestNumberRefetching={latestNumberRefetching}
        selectedGameList={selectedGameList}
      />
      <BalanceViewer
        walletAmount={walletAmount}
        setWalletAmount={setWalletAmount}
        refetchWaitTime={10}
        onRulesOpen={() => {
          setRuleVisible(true);
        }}
        handleDepositCallback={handleDepositCallback}
      />
      {currentGameState?.showHeight ? (
        <ResultAnimateText
          explodeRocket={explodeRocket}
          setCurrentGameState={setCurrentGameState}
          startAt={animationStartPosition}
          setResultTimer={setResultTimer}
          latestNumber={latestNumber}
        />
      ) : null}
      {currentGameState?.showResult === true ? (
        <ResultViewer
          setCurrentGameState={setCurrentGameState}
          resultTimer={resultTimer}
          flyRocket={flyRocket}
          latestNumber={latestNumber}
        />
      ) : null}
      <Rocket ref={rocketRef} {...currentRocketState} visible={currentGameState.rocketVisible} />
    </div>
  );
};

export default AnimatedPanel;
