import {
  requestAesexy,
  requestCQ9,
  requestEvo,
  requestEvoplay,
  requestFC,
  requestGlobalSettingData,
  requestJili,
  requestLuckySport,
  requestMain,
  requestMg,
  requestPPLive,
  requestPT,
  requestPg,
  requestPlayStar,
  requestPp,
  requestSpribe,
  requestSpribeone,
  requestT1,
  requestTcg,
  requestWeblockchain,
  requestWelive,
  requestWelottery,
  requestWesport,
  requestWin,
  requestYbslot,
  requestYgg,
  requestYoubet,
} from "../../apis";
import localStorageKey from "../../enumerations/localStorageKey";

const currency = window.localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);

export const apiGetBanner = (params) =>
  requestGlobalSettingData("get", `/${currency}/banner`, params, false, false);

export const apiGetPromotionBanner = (params) =>
  requestMain("get", `/${currency}/promotion/show/banner`, params, false, false);

export const apiGetAllBanner = (params) =>
  requestMain("get", `/${currency}/promotion/global/banner`, params, false, false);

export const apiGetNotice = (params) =>
  requestGlobalSettingData("get", `/${currency}/notice`, params, false, false);

export const apiGetGameTypes = (params) =>
  requestGlobalSettingData("get", `/${currency}/game/category`, params, false, false);

export const apiGetGameListByType = (params, gameTypeID) =>
  requestGlobalSettingData("get", `/${currency}/game/${gameTypeID}`, params, false, false);

export const apiRedirectToGame = (params) =>
  requestMain("post", `/${currency}/game/login`, params, true, false);

export const apiGetMessage = (params) =>
  requestMain("get", `/${currency}/message`, params, true, false);

export const getWinGameList = (params) =>
  requestGlobalSettingData("get", `/${currency}/game/win/list`, params, true);

export const getWinCategory = (params) =>
  requestWin("get", `/${currency}/game/category/LOT`, params, true);

//
export const apiGetMgGameLoginUrl = (params) =>
  requestMg("get", `/${currency}/game/login`, params, true);

export const apiGetT1LoginUrl = (params) =>
  requestT1("post", `/${currency}/user/login`, params, true);

export const getT1GameList = (params) => requestT1("get", `/${currency}/game`, params, true);

export const getEvoGameUrl = (params) =>
  requestEvo("post", `/${currency}/user/login`, params, true);

export const apiGetPpGameLoginUrl = (params) =>
  requestPp("post", `/${currency}/user/login`, params, true);

export const getPpGameList = (params) => requestPp("get", `/${currency}/game`, params, true);

//pg
export const apiGetPgGameLoginUrl = (params) =>
  requestPg("post", `/${currency}/user/login`, params, true);

export const getPgGameList = (params) => requestPg("get", `/${currency}/game`, params, true);

//ygg
export const apiGetYggGameLoginUrl = (params) =>
  requestYgg("post", `/${currency}/user/login`, params, true);

export const getYggGameList = (params) => requestYgg("get", `/${currency}/game`, params, true);

//evoplay
export const apiGetEvoplayGameLoginUrl = (params) =>
  requestEvoplay("post", `/${currency}/user/login`, params, true);

export const getEvoplayGameList = (params) =>
  requestEvoplay("get", `/${currency}/game`, params, true);

//jili
export const apiGetJiliGameLoginUrl = (params) =>
  requestJili("post", `/${currency}/user/login`, params, true);

export const getJiliGameList = (params) => requestJili("get", `/${currency}/game`, params, true);

//spribe
export const apiGetSpribeGameLoginUrl = (params) =>
  requestSpribe("post", `/${currency}/user/login`, params, true);

export const getSpribeGameList = (params) =>
  requestSpribe("get", `/${currency}/game`, params, true);

//PT
export const apiGetPTGameLoginUrl = (params) =>
  requestPT("post", `/${currency}/user/login`, params, true);

export const getPTGameList = (params) => requestPT("get", `/${currency}/game`, params, true);

//PPLIVE
export const apiGetPPLiveGameLoginUrl = (params) =>
  requestPPLive("post", `/${currency}/user/login`, params, true);

export const getPPLIveGameList = (params) =>
  requestPPLive("get", `/${currency}/game`, params, true);
//FC
export const apiGetFCGameLoginUrl = (params) =>
  requestFC("post", `/${currency}/user/login`, params, true);

export const getFCGameList = (params) => requestFC("get", `/${currency}/game`, params, true);
//CQ9
export const apiGetCQ9GameLoginUrl = (params) =>
  requestCQ9("post", `/${currency}/user/login`, params, true);

export const getCQ9GameList = (params) => requestCQ9("get", `/${currency}/game`, params, true);
//PLAYSTAR
export const apiGetPlayStarGameLoginUrl = (params) =>
  requestPlayStar("post", `/${currency}/user/login`, params, true);

export const getPlayStarGameList = (params) =>
  requestPlayStar("get", `/${currency}/game`, params, true);

//TCG
export const apiGetTcgGameLoginUrl = (params) =>
  requestTcg("post", `/${currency}/user/login`, params, true);
export const getTcgGameList = (params) => requestTcg("get", `/${currency}/game`, params, true);

export const apiGetWeblockchainGameLoginUrl = (params) =>
  requestWeblockchain("post", `/${currency}/user/login`, params, true);
export const getWeblockchainGameList = (params) =>
  requestWeblockchain("get", `/${currency}/game`, params, true);
export const apiGetWelotteryGameLoginUrl = (params) =>
  requestWelottery("post", `/${currency}/user/login`, params, true);
export const getWelotteryGameList = (params) =>
  requestWelottery("get", `/${currency}/game`, params, true);
export const apiGetWeliveGameLoginUrl = (params) =>
  requestWelive("post", `/${currency}/user/login`, params, true);
export const getWeliveGameList = (params) =>
  requestWelive("get", `/${currency}/game`, params, true);
export const apiGetYoubetGameLoginUrl = (params) =>
  requestYoubet("post", `/${currency}/user/login`, params, true);
export const getYoubetGameList = (params) =>
  requestYoubet("get", `/${currency}/game`, params, true);
export const apiGetAesexyGameLoginUrl = (params) =>
  requestAesexy("post", `/${currency}/user/login`, params, true);
export const getAesexyGameList = (params) =>
  requestAesexy("get", `/${currency}/game`, params, true);
export const apiGetSpribeoneGameLoginUrl = (params) =>
  requestSpribeone("post", `/${currency}/user/login`, params, true);
export const getSpribeoneGameList = (params) =>
  requestSpribeone("get", `/${currency}/game`, params, true);
export const apiGetWesportGameLoginUrl = (params) =>
  requestWesport("post", `/${currency}/user/login`, params, true);
export const getWesportGameList = (params) =>
  requestWesport("get", `/${currency}/game`, params, true);
export const apiGetLuckySportGameLoginUrl = (params) =>
  requestLuckySport("post", `/${currency}/user/login`, params, true);
export const getLuckySportGameList = (params) =>
  requestLuckySport("get", `/${currency}/game`, params, true);
export const apiGetYbslotGameLoginUrl = (params) =>
  requestYbslot("post", `/${currency}/user/login`, params, true);
export const getYbslotGameList = (params) =>
  requestYbslot("get", `/${currency}/game`, params, true);
//1bbetv2
export const apiGetGameCategoryV2 = (params) =>
  requestGlobalSettingData("get", `/${currency}/game/v1/category/list`, params, false, false);

export const apiGetHotGameV2 = (params) =>
  requestGlobalSettingData("get", `/${currency}/game/v1/hot/list`, params, false, false);

export const apiGetGameV2 = (params) =>
  requestGlobalSettingData(
    "get",
    `/${currency}/game/v1/combine/category/hot/list`,
    params,
    false,
    false,
  );
