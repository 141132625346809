const rocketStateEnum = {
  FLYING: {
    state: "flying",
    loop: true,
    startFrame: 1,
    endFrame: 14,
  },
  EXPLODE: {
    state: "explode",
    loop: false,
    startFrame: 15,
    endFrame: 23,
  },
};

export default rocketStateEnum;
