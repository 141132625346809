import { CloseCircleOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { Layout, Modal, Spin } from "antd";

import { Input } from "antd-mobile";
import { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import CustomModal from "../CustomModal";
import { useApplyCoupon } from "./hooks/secretnumber.hooks";
import button from "./images/button.png";
import styles from "./style.module.scss";
import "./style.scss";
const { Header, Sider, Content, Footer } = Layout;

const PCSerialNumberPopup = ({ visible = false, setVisible = () => {} }) => {
  const closeModal = () => {
    setVisible(false);
  };
  const { t } = useTranslation();
  const cache = useQueryClient();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [secretNumber, setSecretNumber] = useState("");
  const [modalContent, setModalContent] = useState("");
  const { mutate: applyCoupon, isLoading: isApplyLoading } = useApplyCoupon();

  const onSubmitNumber = async () => {
    if (!secretNumber) {
      setModalContent(t("activity.secretNumber.empty"));
      setIsModalVisible(true);
      return;
    }
    if (isApplyLoading) return;
    await applyCoupon(
      { params: { coupon_code: secretNumber } },
      {
        onSuccess: async (success) => {
          setModalContent(t("activity.secretNumber.success"));
          setIsModalVisible(true);
        },
        onError: (error) => {
          switch (error?.data?.result.code) {
            case 3000010:
              setModalContent(t("activity.secretNumber.used"));
              setIsModalVisible(true);
              break;
            default: //3000008 => 'Coupon promotion not found',3000009 => 'Invalid coupon code.',3000011 => 'Coupon apply failed.',
              setModalContent(t("activity.secretNumber.invalid"));
              setIsModalVisible(true);
              break;
          }
          setSecretNumber("");
          console.debug("error", error);
        },
      },
    );
  };

  const onNumberChange = (val) => {
    setSecretNumber(val);
  };

  return (
    <Modal
      width={600}
      height={540}
      className={"serial-number-modal"}
      visible={visible}
      centered
      footer={null}
      closable={false}
      destroyOnClose={true}
    >
      <div className={styles.container}>
        <div className={styles.closeButton} onClick={closeModal}>
          <CloseCircleOutlined className={styles.icon} />
        </div>
        <div className={styles.detailContainer}>
          <div className={styles.title}>{t("activity.secretNumber.subtitle")}</div>
          <div className={styles.formContainer}>
            <Input
              className={styles.customInput}
              placeholder={t("normal.input.placeholder")}
              value={secretNumber}
              onChange={onNumberChange}
            />
            <div className={styles.button} onClick={onSubmitNumber}>
              <div className={styles.buttonTitle}>
                {isApplyLoading ? <Spin /> : t("activity.secretNumber.button")}
              </div>
              <img src={button} alt="submit" />
            </div>
          </div>
          {/* <div className={styles.desc}> */}
          <Scrollbars autoHide className={styles.desc} style={{ flex: "1", height: "auto" }}>
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{ __html: t("activity.secretNumber.desc") }}
            />
          </Scrollbars>
          {/* </div> */}
        </div>
      </div>
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        title={""}
        content={modalContent}
        needIcon={false}
        okBtnText={t("normal.close")}
        showCloseButton={false}
        isHaveCancel={false}
        onOk={() => {
          setIsModalVisible(false);
        }}
      />
    </Modal>
  );
};

export default PCSerialNumberPopup;
