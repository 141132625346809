import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Form, Input } from "antd";
import React from "react";

import "./style.scss";

const CustomInput = ({
  placeholder = "",
  addonBefore = null,
  addonAfter = null,
  bordered = true,
  label = "",
  name = "",
  rules = [],
  disabled = false,
  readOnly = false,
  password = false,
  shouldUpdate,
  dependencies = "",
  initialValue,
  tooltip,
  onChange,
  hidden = false,
  type = "mobile",
  customClassNames = "",
  width = "",
}) => {
  const renderInput = (password) => {
    switch (password) {
      case true:
        return (
          <Input.Password
            readOnly={readOnly}
            disabled={disabled}
            placeholder={placeholder}
            addonBefore={addonBefore}
            addonAfter={addonAfter}
            bordered={bordered}
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            dependencies={dependencies}
            onChange={onChange}
            autoComplete={"new-password"}
          />
        );
      default:
        return (
          <Input
            readOnly={readOnly}
            disabled={disabled}
            placeholder={placeholder}
            addonBefore={addonBefore}
            addonAfter={addonAfter}
            bordered={bordered}
            onChange={onChange}
            autoComplete={"new-password"}
          />
        );
    }
  };
  return (
    <Form.Item
      style={{ display: hidden ? "none" : "block", width: width ? width : "" }}
      className={`${password ? "Meta-Custom-Input-Pass" : "Meta-Custom-Input"} ${
        type === "pc" ? "pc-style" : "mobile-style"
      } ${customClassNames}`}
      label={label}
      name={name}
      rules={rules}
      shouldUpdate={shouldUpdate}
      initialValue={initialValue}
      tooltip={tooltip}
    >
      {renderInput(password)}
    </Form.Item>
  );
};

export default CustomInput;
