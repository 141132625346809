import { Divider, Grid, Image } from "antd-mobile";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { LangSelectorPopover } from "../../../../components";
import { PRODUCTION_NAME_DEV } from "../../../../config";
import { useCategory } from "../../../../context/CategoryContext";
import { useDailyCheck } from "../../../../context/DailyCheckContext";
import { useLogin } from "../../../../context/LoginContext";
import { useSerialNumber } from "../../../../context/SerialNumberContext";
import { useWheel } from "../../../../context/WheelContext";
import activityTypeIdEnum from "../../../../enumerations/activityTypeIdEnum";
import localStorageKey from "../../../../enumerations/localStorageKey";
import { useGetPromotionList } from "../../../../hooks/Promotion/promotionList.hooks";
import { useCheckShowBubble } from "../../../../hooks/bonusWheel/bonusWheel.hook";
import { useGetTGLink } from "../../../../hooks/tgLink/tglink.hooks";
import CategoryItem from "./components/CategoryItem";
import SiderButton from "./components/SiderButton";
import styles from "./style.module.scss";

const SiderComponent = () => {
  const { t, i18n } = useTranslation();
  const { data: tgLink } = useGetTGLink({});
  const { handleShowWheelPopup } = useWheel();
  const { handleShowDailyCheckPopup, data: dailyCheckData } = useDailyCheck();
  const { handleShowSerialNumberPopup } = useSerialNumber();
  const { data: apiShowBubble } = useCheckShowBubble({});
  const {
    data: promotionList,
    isLoading: promotionListIsLoading,
    error: errorList,
  } = useGetPromotionList({
    value: activityTypeIdEnum.ALL,
    page_size: 10,
  });
  const [showCode, setShowCode] = useState(false);
  const [showDaily, setShowDaily] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { gameCategories, selectedCategory, setSelectedCategory } = useCategory();
  const { checkIfLogin } = useLogin();
  const getLanguage = () => i18n.language;

  const token = window.localStorage.getItem("token");
  const nowLang = getLanguage();
  const currency = localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);

  useEffect(() => {
    if (promotionList && promotionList.pages.length > 0) {
      const _showCode =
        promotionList.pages[0].data.find((_data) => _data.promotion_type === "PRCP")?.status ===
          1 || false;
      //ADD DAILY PROMO
      setShowCode(_showCode);
    }
  }, [promotionList]);

  useEffect(() => {
    if (!dailyCheckData) return;
    if (dailyCheckData.length !== 0) setShowDaily(true);
  }, [dailyCheckData]);

  const onChangeActiveType = (category) => {
    if (location.pathname !== "/")
      navigate("/", {
        replace: true,
      });
    setSelectedCategory(category);
  };

  const renderAbsoluteImage = (url, customStyle) => {
    return (
      <div className={styles.absoluteImage}>
        <Image
          className={`${customStyle || ""}`}
          src={`/images/logo/${PRODUCTION_NAME_DEV}/${url}`}
          fallback={<Image className={`${customStyle || ""}`} src={`/images/logo/${url}`} />}
        />
      </div>
    );
  };

  const MENU = [
    {
      key: "bet_record",
      type: "button",
      action: "page",
      url: "/account?tab=betRecord",
      containerStyle: styles.betRecordContainer,
      textStyle: styles.betRecordText,
      icon: `/images/logo/${PRODUCTION_NAME_DEV}/pc_bet_record.png`,
      span: 2,
      text: t("personal.betRecord"),
      divider: false,
      needLogin: true,
      visible: true,
    },
    {
      key: "vip",
      type: "button",
      action: "page",
      url: "/vip", // NEED TO CHANGE TO REAL ROUTE
      icon: false,
      span: 1,
      text: t("normal.vip"),
      containerStyle: styles.vipButtonContainer,
      textStyle: styles.vipButtonText,
      children: renderAbsoluteImage(`pc_vip.png`),
      divider: false,
      needLogin: true,
      visible: true,
    },
    {
      key: "promotion",
      type: "button",
      action: "page",
      url: "/bonus",
      icon: false,
      span: 1,
      text: t("1bbet.footer.promo"),
      children: renderAbsoluteImage(`pc_promo.png`),
      containerStyle: styles.promotionButtonContainer,
      textStyle: styles.promoButtonText,
      divider: false,
      needLogin: true,
      visible: true,
    },
    {
      key: "dailyCheck",
      type: "button",
      action: "popup",
      url: "undefined",
      icon: false,
      span: showCode ? 1 : 2,
      text: t([`normal.${PRODUCTION_NAME_DEV}.dailyCheck`, "normal.dailyCheck"]),
      children: renderAbsoluteImage(`pc_checkin.png`, styles.image),
      containerStyle: `${!showCode ? styles.long : ""} ${styles.dailyButtonContainer}`,
      textStyle: styles.dailyButtonText,
      divider: false,
      needLogin: true,
      visible: showDaily,
    },
    {
      key: "secret_code",
      type: "button",
      action: "popup",
      url: "undefined",
      icon: false,
      span: showDaily ? 1 : 2,
      text: t([
        `activity.secretNumber.${PRODUCTION_NAME_DEV}.title`,
        "activity.secretNumber.title",
      ]),
      children: renderAbsoluteImage(`pc_coupon.png`, styles.image),
      containerStyle: `${!showDaily ? styles.long : ""} ${styles.codeButtonContainer}`,
      textStyle: styles.codeButtonText,
      divider: false,
      needLogin: true,
      visible: showCode,
    },
    {
      key: "wheel",
      type: "button",
      action: "popup",
      url: undefined, // NEED TO CHANGE TO REAL ROUTE
      icon: false,
      span: 2,
      text: t("home.bonusWheelBubble.text"),
      containerStyle: styles.wheelButtonContainer,
      textStyle: styles.wheelButtonText,
      children: renderAbsoluteImage(`pc_wheels.gif`),
      divider: true,
      needLogin: true,
      visible: apiShowBubble?.status,
    },
    {
      key: "agent",
      type: "button",
      action: "page",
      // url: `${AGENT_MOBILE_URL}?token=${token}&lang=${nowLang}&currency=${currency}`,
      url: `/agent`,
      icon: `/images/logo/${PRODUCTION_NAME_DEV}/pc_agent.svg`,
      span: 2,
      text: t("personal.affiliateCenter"),
      containerStyle: styles.textButtonContainer,
      textStyle: styles.affiliateButtonText,
      divider: true,
      needLogin: true,
      visible: true,
    },
    {
      key: "help",
      type: "button",
      action: "page",
      url: "/support?tab=faq", // NEED TO CHANGE TO REAL ROUTE
      icon: false,
      span: 2,
      text: t("personal.FAQs"),
      containerStyle: styles.textButtonContainer,
      textStyle: styles.personalMenuText,
      divider: false,
      needLogin: true,
      visible: true,
    },
    {
      key: "about",
      type: "button",
      action: "page",
      url: "/support?tab=about", // NEED TO CHANGE TO REAL ROUTE
      icon: false,
      span: 2,
      text: t("personal.about"),
      containerStyle: styles.textButtonContainer,
      textStyle: styles.personalMenuText,
      divider: false,
      needLogin: true,
      visible: true,
    },
    {
      key: "download",
      type: "button",
      action: "page",
      url: "/download", // NEED TO CHANGE TO REAL ROUTE
      icon: false,
      span: 2,
      text: t("personal.appDownload"),
      containerStyle: styles.textButtonContainer,
      textStyle: styles.personalMenuText,
      divider: true,
      needLogin: false,
      visible: true,
    },
    {
      key: "language",
      type: "custom",
      action: null,
      url: "",
      icon: false,
      span: 2,
      text: "",
      containerStyle: "",
      divider: false,
      needLogin: false,
      visible: true,
    },
    {
      key: "telegram",
      type: "button",
      action: "external",
      url: tgLink ? tgLink?.data : "", // NEED TO CHANGE TO REAL ROUTE
      icon: `/images/logo/${PRODUCTION_NAME_DEV}/pc_telegram.svg`,
      span: 2,
      text: t("normal.tgChannel"),
      containerStyle: styles.tgButtonContainer,
      textStyle: styles.tgTextStyle,
      divider: false,
      needLogin: true,
      visible: tgLink && tgLink?.data !== "",
    },
  ];

  const renderCategory = () => {
    if (!gameCategories) return null;
    return (
      gameCategories
        // .filter((category) => !PC_EXCLUDED_CATEGORIES.includes(category.game_category_code))
        .map((category) => {
          return (
            <Grid.Item key={category.game_category_code}>
              <CategoryItem
                onClick={onChangeActiveType}
                selectedCategory={selectedCategory}
                key={category.game_category_code}
                category={category}
                active={category.game_category_code === selectedCategory?.game_category_code}
              />
            </Grid.Item>
          );
        })
    );
  };

  const onMenuClick = (menu) => {
    if (menu.needLogin === true) {
      if (!checkIfLogin()) return;
    }
    switch (
      menu.action //page; external; popup
    ) {
      case "page":
        navigate(`${menu.url}`);
        break;
      case "popup":
        switch (menu.key) {
          case "wheel":
            if (apiShowBubble?.status) {
              handleShowWheelPopup({ wheelId: apiShowBubble?.id });
            }
            break;
          case "dailyCheck":
            handleShowDailyCheckPopup();
            break;
          case "secret_code":
            handleShowSerialNumberPopup();
            break;
          //in case there are more popup in the future
          default:
            break;
        }
        break;
      case "external":
        window.open(menu.url);
        break;
      default:
        break;
    }
  };

  const renderCustomMenu = (key) => {
    switch (key) {
      case "language":
        return (
          <div className={styles.languageButtonContainer}>
            <LangSelectorPopover popoverPlacement="top-end" type="full" />
          </div>
        );

      default:
        break;
    }
  };

  const renderMenuItem = (menu) => {
    switch (menu.type) {
      case "button":
        return (
          <>
            <SiderButton menu={menu} onClick={onMenuClick} children={menu.children} />
            {menu.divider === true ? <Divider className={styles.divider} /> : null}
          </>
        );
      case "custom":
        return renderCustomMenu(menu.key);
      default:
        break;
    }
  };

  return (
    <div className={styles.siderContainer}>
      <Grid columns={2} gap={10}>
        {renderCategory()}
      </Grid>
      <Grid columns={2} gap={8} className={styles.menuGrid}>
        {MENU.filter((menu) => menu.visible === true).map((menu) => {
          return (
            <Grid.Item span={menu.span} key={menu.key}>
              {renderMenuItem(menu)}
            </Grid.Item>
          );
        })}
      </Grid>
    </div>
  );
};

export default SiderComponent;
