import { Col } from "antd";

const RightGroup = () => {
  return (
    <Col
      span={4}
      style={{
        textAlign: "right",
      }}
    ></Col>
  );
};

export default RightGroup;
