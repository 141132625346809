import { Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useGetUserInfo } from "../../../hooks/personal/personal.hook";
import styles from "./style.module.scss";

const VIPAvatar = ({ customContainerStyle = "", hideLevel = false }) => {
  const { data: userInfo, error: userInfoError, isLoading } = useGetUserInfo({});
  const [userIconId, setUserIconId] = useState();

  const findAvatar = (userId) => {
    const iconId = parseInt(userId) % 20;
    setUserIconId(iconId + 1);
  };

  useEffect(() => {
    if (userInfo?.account_id) {
      findAvatar(userInfo.account_id);
    }
  }, [userInfo]);

  return (
    <div className={`${styles.iconContainer} ${customContainerStyle}`}>
      {isLoading || userInfoError ? (
        <Skeleton.Avatar active size={64} />
      ) : (
        <>
          <div className={styles.iconBox}>
            <img
              src={`/images/avatar/avatar${userIconId}.png`}
              alt="avatar"
              className={`${styles.userIcon}`}
            />
          </div>
          <img
            src={`/images/vip/secondVersion/frame/avatar_frame.gif`}
            alt="avatar frame"
            className={`${styles.avatarFrame}`}
          />
          {hideLevel ? null : (
            <img
              src={`/images/vip/secondVersion/smallIcon/${userInfo?.level_name}_S.png`}
              alt="level icon"
              className={`${styles.levelIcon}`}
            />
          )}
        </>
      )}
    </div>
  );
};

export default VIPAvatar;
