import { Spin } from "antd";
import { Grid } from "antd-mobile";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGameWebsocket } from "../../context/WebsocketGameContext";
import winStateEnum from "../../enumerations/winStateEnum";
import { Flip } from "../../pages/mobile/WinGame/components";
import styles from "./style.module.scss";

const WinBetPanel = ({
  gameMethod = null,
  onBetItemClick = () => {},
  betSelectionEnum = {},
  showOdds = false,
  winColorContainer = "",
  winColorBetContainer = "",
  showImages = false,
  latestNumber = undefined,
  currentGameState = {},
  showCountDown = false,
  onBetClose = () => {},
  setPopupVisible = () => {},
}) => {
  const { t } = useTranslation();
  const { currentPeriod, counter } = useGameWebsocket();
  const [countDownStr, setCountDownStr] = useState(String(counter).padStart(2, "0"));

  const renderOdds = (option) => {
    if (showOdds === false) return;
    return <div className={styles.odds}>{option.odds}</div>;
  };

  const renderBetPanel = () => {
    if (!gameMethod) return null;
    if (!currentPeriod.period && !latestNumber) {
      return (
        <div className={styles.loadingContainer}>
          <div className={styles.title}>{t("wingame.loading")}</div>
          <Spin />
        </div>
      );
    }
    return gameMethod.game_method.map((item) => {
      const setting = betSelectionEnum[item.code];
      if (!setting) return null;
      return (
        <Grid className={styles.grid} columns={setting.column} key={item.code} gap={12}>
          {item.option.map((option) => {
            return (
              <Grid.Item key={option.key}>
                {item.code === "NUM" && showImages ? (
                  <img
                    src={`/images/game/gamewin/${setting[option.key].src}`}
                    alt={setting[option.key].src}
                    className={styles.num}
                    onClick={() =>
                      onBetItemClick({
                        ...option,
                        ...setting[option.key],
                        game_method_code: item.code,
                      })
                    }
                  />
                ) : (
                  <div
                    onClick={() =>
                      onBetItemClick({
                        ...option,
                        ...setting[option.key],
                        game_method_code: item.code,
                      })
                    }
                    className={styles.betItem}
                    style={
                      setting[option.key].color.length <= 1
                        ? { backgroundColor: `${setting[option.key].color[0]}` }
                        : {
                            backgroundImage: `linear-gradient(160deg,${
                              setting[option.key].color[0]
                            } 50%, ${setting[option.key].color[1]} 0)`,
                          }
                    }
                  >
                    {t(
                      `${
                        setting[option.key].translate
                          ? t(setting[option.key].title)
                          : setting[option.key].title
                      }`,
                    )}
                    {renderOdds(option)}
                  </div>
                )}
              </Grid.Item>
            );
          })}
        </Grid>
      );
    });
  };

  useEffect(() => {
    setCountDownStr(String(counter).padStart(2, "0"));
  }, [counter]);

  useEffect(() => {
    if (showCountDown && currentGameState.key === winStateEnum.DISABLE_BET_PHASE.key) {
      setPopupVisible(false);
      onBetClose();
    }
  }, [currentGameState, setPopupVisible, onBetClose, showCountDown]);
  return (
    <div className={`${styles.container} ${winColorContainer}`}>
      <div className={`${styles.betContainer} ${winColorBetContainer}`}>
        {showCountDown && currentGameState.key === winStateEnum.DISABLE_BET_PHASE.key && (
          <Flip value={countDownStr} />
        )}
        {renderBetPanel()}
      </div>
    </div>
  );
};

export default WinBetPanel;
