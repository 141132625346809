const betSelectionEnum = {
  DXDS: {
    column: 4,
    big: {
      key: "big",
      title: "win.bitgame.bettype.big",
      translate: true,
      color: ["#20AC4A"],
    },
    small: {
      key: "small",
      title: "win.bitgame.bettype.small",
      translate: true,
      color: ["#B2533F"],
    },
    odd: {
      key: "odd",
      title: "win.bitgame.bettype.odd",
      translate: true,
      color: ["#5C32A8"],
    },
    even: {
      key: "even",
      title: "win.bitgame.bettype.even",
      translate: true,
      color: ["#A7714D"],
    },
    small_even: {
      key: "small_even",
      title: "win.bitgame.bettype.small_even",
      translate: true,
      color: ["#C98C6F"],
    },
    small_odd: {
      key: "small_odd",
      title: "win.bitgame.bettype.small_odd",
      translate: true,
      color: ["#367C7C"],
    },
    big_even: {
      key: "big_even",
      title: "win.bitgame.bettype.big_even",
      translate: true,
      color: ["#A0864A"],
    },
    big_odd: {
      key: "big_odd",
      title: "win.bitgame.bettype.big_odd",
      translate: true,
      color: ["#C89E90"],
    },
  },
};

export default betSelectionEnum;
