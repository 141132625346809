import Icon, { CaretDownOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LangSelector from "../LangSelector";
import langDict from "../LangSelector/langDict.json";
import Earth from "./icons/Earth";
import styles from "./style.module.scss";

function LangSelectorBtn({ changeStyle }) {
  const [showDrawer, setShowDrawer] = useState(false);
  const [nowLangShortName, setNowLangShortName] = useState("");
  const { t, i18n } = useTranslation();
  const getLanguage = () => i18n.language;
  useEffect(() => {
    const nowLang = getLanguage();
    // console.log("nowLang", nowLang);
    const found = langDict.find((i) => i.value == nowLang);
    setNowLangShortName(found.shortName);
  }, [getLanguage]);

  const EarthIcon = (props) => <Icon component={Earth} {...props} />;

  return (
    <div className={`${styles.langSelectorBtn}`}>
      <div
        onClick={() => {
          setShowDrawer(true);
        }}
        className={`${changeStyle ? styles.changeStyleGroup : styles.group}`}
      >
        <EarthIcon
          style={{
            color: changeStyle ? "var(--color-lang-icon)" : "var(--color-home-icon-lang)",
            fontSize: "16px",
          }}
        />
        <div className={`${styles.shortName}`}>
          <span>{nowLangShortName}</span>
        </div>
        <CaretDownOutlined
          style={{
            fontSize: "8px",
            color: changeStyle ? "var(--color-lang-icon)" : "var(--color-home-icon-lang)",
          }}
          rotate={showDrawer ? 180 : 0}
        />
      </div>
      <LangSelector visible={showDrawer} setVisible={setShowDrawer} />
    </div>
  );
}

export default LangSelectorBtn;
