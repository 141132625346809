import { Switch } from "antd-mobile";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./style.module.scss";

const LotteryPopHeader = ({
  showSwitchSetting = true,
  GAME_TYPE = "",
  GAME_WAY_TYPE = "",
  showNotification = true,
  setShowNotification = () => {},
  customHeaderStyle = "",
}) => {
  const { t } = useTranslation();
  return (
    <div className={`${styles.lotteryHeader} ${customHeaderStyle}`}>
      <div className={styles.subHeader}>
        <img
          className={styles.headerIcon}
          src={require(`./images/${GAME_TYPE}.png`)}
          alt={"loteria_federal"}
        />
        <div className={styles.titleContainer}>
          <div className={styles.title}>{t(`lottery.gameType.${GAME_TYPE}`)}</div>
          <div className={styles.subtitle}>{t(`gameWay.${GAME_WAY_TYPE}`)}</div>
        </div>
      </div>
      {showSwitchSetting ? (
        <div className={styles.switchContainer}>
          <div className={styles.switchTitle}>{`${t("normal.notice")}:`}</div>
          <Switch
            className={"custom-switch"}
            checked={showNotification}
            onChange={(e) => setShowNotification(e)}
          />
        </div>
      ) : null}
    </div>
  );
};

export default LotteryPopHeader;
