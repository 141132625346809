const winMoneySelectionEnum = [
  {
    key: "10",
    value: 10,
  },
  {
    key: "50",
    value: 50,
  },
  {
    key: "100",
    value: 100,
  },
  {
    key: "500",
    value: 500,
  },
  {
    key: "1000",
    value: 1000,
  },
  {
    key: "10000",
    value: 10000,
  },
];

export default winMoneySelectionEnum;
