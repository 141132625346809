import { useQueryClient } from "@tanstack/react-query";
import { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { tip } from "../apis/utils";
import { CustomModal, PCWinGamePopup } from "../components";
import localStorageKey from "../enumerations/localStorageKey";
import queryKey from "../enumerations/queryKey";
import { useRedirectToGame } from "../hooks/Home/home.hook";
import {
  useGetAesexyGameLoginLink,
  useGetCQ9GameLoginLink,
  useGetEvoGameLoginLink,
  useGetEvoplayGameLoginLink,
  useGetFCGameLoginLink,
  useGetJiliGameLoginLink,
  useGetLuckySportGameLoginLink,
  useGetMgGameLoginLink,
  useGetPPLiveGameLoginLink,
  useGetPTGameLoginLink,
  useGetPgGameLoginLink,
  useGetPlayStarGameLoginLink,
  useGetPpGameLoginLink,
  useGetSpribeGameLoginLink,
  useGetSpribeoneGameLoginLink,
  useGetT1GameLoginLink,
  useGetTcgGameLoginLink,
  useGetWeblockchainGameLoginLink,
  useGetWeliveGameLoginLink,
  useGetWelotteryGameLoginLink,
  useGetWesportGameLoginLink,
  useGetYbslotGameLoginLink,
  useGetYggGameLoginLink,
  useGetYoubetGameLoginLink,
} from "../hooks/Home/home1bbetv2.hooks";
import { useFacebookPixel } from "../hooks/fbPixel/fbPixel.hooks";
import { GamePopup } from "../pages/mobile/Home/components";
import gameListsEnum from "../pages/mobile/Home/enumeration/gameListsEnum";
import { useLoading } from "./LoadingContext";
import { useLogin } from "./LoginContext";
import { useRecentPlayGames } from "./RecentPlayGamesContext";

const GameLoginContext = createContext({
  showGamePopup: false,
  setShowGamePopup: () => {},
  handleLoginGame: ({ game3th = {}, gameItem = "", isNewWindow = false }) => {},
});

export const GameLoginContextProvider = ({ children }) => {
  const { t, i18n } = useTranslation();
  const cache = useQueryClient();
  const navigate = useNavigate();
  const getLanguage = () => i18n.language;
  const { setIsLoading } = useLoading();
  const { setRecentPlayGameList } = useRecentPlayGames();
  const { userInfo } = useLogin();
  const nowLang = getLanguage();
  const currency = localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);
  const token = window.localStorage.getItem("token");

  const [mgGameCode, setMGGameCode] = useState(null);
  const [newWindow, setNewWindow] = useState(false);

  const [showWinGame, setShowWinGame] = useState(false);
  const [winGameCode, setWinGameCode] = useState();
  const [winGameId, setWinGameId] = useState();

  //saba
  const { mutate: redirectToGame } = useRedirectToGame();
  //T1
  const { mutate: redirectToT1 } = useGetT1GameLoginLink();
  //Evo Live
  const { mutate: redirectToEvo } = useGetEvoGameLoginLink();
  //PP slot
  const { mutate: redirectToPp } = useGetPpGameLoginLink();
  //PG
  const { mutate: redirectToPg } = useGetPgGameLoginLink();
  //YGG
  const { mutate: redirectToYgg } = useGetYggGameLoginLink();
  //EVOPLAY
  const { mutate: redirectToEvoplay } = useGetEvoplayGameLoginLink();
  //JILI
  const { mutate: redirectToJili } = useGetJiliGameLoginLink();
  //SPRIBE
  const { mutate: redirectToSpribe } = useGetSpribeGameLoginLink();
  //PT
  const { mutate: redirectToPT } = useGetPTGameLoginLink();
  //PPLIVE
  const { mutate: redirectToPPLive } = useGetPPLiveGameLoginLink();
  //CQ9
  const { mutate: redirectToCQ9 } = useGetCQ9GameLoginLink();
  //FC
  const { mutate: redirectToFC } = useGetFCGameLoginLink();
  //PlayStar
  const { mutate: redirectToPlayStar } = useGetPlayStarGameLoginLink();
  //WE LIVE
  const { mutate: redirectToWeLive } = useGetWeliveGameLoginLink();
  //WE LOTTERY
  const { mutate: redirectToWeLottery } = useGetWelotteryGameLoginLink();
  //WE BLOCKCHAIN
  const { mutate: redirectToWeBlockchain } = useGetWeblockchainGameLoginLink();
  //TCG
  const { mutate: redirectToTCG } = useGetTcgGameLoginLink();
  //YOUBET
  const { mutate: redirecToYoubet } = useGetYoubetGameLoginLink();
  //AESEXY
  const { mutate: redirectToAeSexy } = useGetAesexyGameLoginLink();
  //SPRIBEONE
  const { mutate: redirectoSpribeone } = useGetSpribeoneGameLoginLink();
  //WE SPORT
  const { mutate: redirectoWeSport } = useGetWesportGameLoginLink();
  //LUCKY SPORT
  const { mutate: redirectoLuckySport } = useGetLuckySportGameLoginLink();
  //YB SLOT
  const { mutate: redirectoYBSlot } = useGetYbslotGameLoginLink();
  //MG -> special using GET
  const { refetch: getMGLoginLink, isFetching: isFetchingMgLink } = useGetMgGameLoginLink(
    { content_code: mgGameCode },
    {
      enabled: false,
      onSuccess: (data) => {
        setIsLoading(false);
        if (newWindow) {
          window.open(`${data?.url}`, t(`games.fullName.mg`));
          return;
        }
        setGameURL(data?.url);
        setShowHeader(false);
        setShowBubble(true);
        setShowGamePopup(true);
      },
      onError: (e) => {
        setGameURL(undefined);
        setIsLoading(false);
      },
    },
  );

  const { isHavePixelIntegration, pixelCreateCustomTracker } = useFacebookPixel();

  const [showGamePopup, setShowGamePopup] = useState(false);
  const [showTestAccountWarning, setShowTestAccountWarning] = useState(false);
  const [gameURL, setGameURL] = useState("");
  const [showHeader, setShowHeader] = useState(true);
  const [showBubble, setShowBubble] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);
  const [showLotteryButton, setShowLotteryButton] = useState(false);
  const [title, setTitle] = useState("");
  const [game3thName, setGame3thName] = useState("");

  const handleLoginGame = async ({ game3th, gameItem, isNewWindow = false }) => {
    setNewWindow(isNewWindow);
    if (userInfo.billing_type === 0) {
      if (game3th.allow_test_account === 0 || gameItem.allow_test_account === 0) {
        setShowTestAccountWarning(true);
        return;
      }
    }

    if (gameItem) {
      setRecentPlayGameList({ game: { ...gameItem, is_recent: 1 } });
    }
    if (
      !getTutorialSetting().includes(game3th.game_3th_code) &&
      gameListsEnum[game3th.game_3th_code] &&
      gameListsEnum[game3th.game_3th_code].haveTutorial === true
    ) {
      setGame3thName(game3th.game_3th_code);
      setShowTutorial(true);
    }

    if (gameListsEnum[game3th.game_3th_code].showLotteryButton === true) {
      setShowLotteryButton(true);
    }
    if (isHavePixelIntegration) {
      pixelCreateCustomTracker({
        eventId: "enterGame",
        props: {
          platform: game3th.game_3th_code,
          game_code: gameItem?.game_code ? gameItem?.game_code : "",
        },
      });
    }

    switch (game3th.game_3th_code) {
      case "t1": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game: game3th.game_3th_code,
          game_code: gameItem.game_code,
        };
        setIsLoading(true);
        await redirectToT1(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setIsLoading(false);
              if (isNewWindow) {
                window.open(`${success}`, t(`games.fullName.${game3th.game_3th_code}`));
                return;
              }
              setGameURL(`${success}`);
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              tip({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "evo": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game: game3th.game_3th_code,
          game_code: gameItem?.game_code ? gameItem.game_code : "",
          game_id: gameItem?.game_id,
        };
        setIsLoading(true);
        await redirectToEvo(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setIsLoading(false);
              if (isNewWindow) {
                window.open(
                  `${success}` +
                    (success.includes("lang")
                      ? ""
                      : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
                  t(`games.fullName.${game3th.game_3th_code}`),
                );
                return;
              }
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );

              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              tip({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "pp": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
        };
        setIsLoading(true);

        await redirectToPp(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setIsLoading(false);

              if (isNewWindow) {
                window.open(
                  `${success}` +
                    (success.includes("lang")
                      ? ""
                      : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
                  t(`games.fullName.${game3th.game_3th_code}`),
                );
                return;
              }

              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              tip({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "pg": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
          game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirectToPg(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setIsLoading(false);
              if (isNewWindow) {
                const newWindow = window.open("", "_blank");
                newWindow.document.write(success);
                return;
              }

              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              tip({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "ygg": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
          game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirectToYgg(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setIsLoading(false);
              if (isNewWindow) {
                window.open(
                  `${success}` +
                    (success.includes("lang")
                      ? ""
                      : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
                  t(`games.fullName.${game3th.game_3th_code}`),
                );
                return;
              }

              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              tip({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "evoplay": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
          game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirectToEvoplay(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setIsLoading(false);
              if (isNewWindow) {
                window.open(
                  `${success}` +
                    (success.includes("lang")
                      ? ""
                      : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
                  t(`games.fullName.${game3th.game_3th_code}`),
                );
                return;
              }
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              tip({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "jili": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
          game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirectToJili(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setIsLoading(false);
              if (isNewWindow) {
                window.open(
                  `${success}` +
                    (success.includes("lang")
                      ? ""
                      : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
                  t(`games.fullName.${game3th.game_3th_code}`),
                );
                return;
              }
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              tip({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "jdb": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
          game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirectToSpribe(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setIsLoading(false);
              if (isNewWindow) {
                window.open(
                  `${success}` +
                    (success.includes("lang")
                      ? ""
                      : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
                  t(`games.fullName.${game3th.game_3th_code}`),
                );
                return;
              }

              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              tip({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "pt": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
          game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirectToPT(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setIsLoading(false);
              if (isNewWindow) {
                window.open(
                  `${success}` +
                    (success.includes("lang")
                      ? ""
                      : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
                  t(`games.fullName.${game3th.game_3th_code}`),
                );
                return;
              }

              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              tip({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "pplive": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
          game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirectToPPLive(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setIsLoading(false);
              if (isNewWindow) {
                window.open(
                  `${success}` +
                    (success.includes("lang")
                      ? ""
                      : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
                  t(`games.fullName.${game3th.game_3th_code}`),
                );
                return;
              }
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              tip({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "fc": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: "FC",
          game_id: gameItem.game_id,
          platform: isNewWindow ? "web" : "H5",
        };
        setIsLoading(true);
        await redirectToFC(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setIsLoading(false);
              if (isNewWindow) {
                window.open(
                  `${success.gameUrl}` +
                    (success.gameUrl.includes("lang")
                      ? ""
                      : `${success.gameUrl.includes("?") ? "&" : "?"}lang=${nowLang}`),
                );
                return;
              }
              setGameURL(
                `${success.gameUrl}` +
                  (success.gameUrl.includes("lang")
                    ? ""
                    : `${success.gameUrl.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              tip({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "cq9": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: "CQ9",
          game_id: gameItem.game_id,
          platform: isNewWindow ? "web" : "H5",
        };
        setIsLoading(true);
        await redirectToCQ9(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setIsLoading(false);
              if (isNewWindow) {
                window.open(
                  `${success.gameUrl}` +
                    (success.gameUrl.includes("lang")
                      ? ""
                      : `${success.gameUrl.includes("?") ? "&" : "?"}lang=${nowLang}`),
                );
                return;
              }
              setGameURL(
                `${success.gameUrl}` +
                  (success.gameUrl.includes("lang")
                    ? ""
                    : `${success.gameUrl.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setIsLoading(false);
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              tip({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "playstar": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
          game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirectToPlayStar(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setIsLoading(false);
              if (isNewWindow) {
                window.open(
                  `${success}` +
                    (success.includes("lang")
                      ? ""
                      : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
                );
                return;
              }
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              tip({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "welive": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
          game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirectToWeLive(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setIsLoading(false);
              if (isNewWindow) {
                window.open(
                  `${success}` +
                    (success.includes("lang")
                      ? ""
                      : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
                );
                return;
              }
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              tip({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "weblockchain": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
          game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirectToWeBlockchain(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setIsLoading(false);
              if (isNewWindow) {
                window.open(
                  `${success}` +
                    (success.includes("lang")
                      ? ""
                      : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
                );
                return;
              }
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              tip({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "welottery": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
          game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirectToWeLottery(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setIsLoading(false);
              if (isNewWindow) {
                window.open(
                  `${success}` +
                    (success.includes("lang")
                      ? ""
                      : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
                );
                return;
              }
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              tip({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "jilifish": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
          game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirectToJili(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setIsLoading(false);
              if (isNewWindow) {
                window.open(
                  `${success}` +
                    (success.includes("lang")
                      ? ""
                      : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
                  t(`games.fullName.${game3th.game_3th_code}`),
                );
                return;
              }
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              tip({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "tcg": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
          game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirectToTCG(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setIsLoading(false);
              if (isNewWindow) {
                window.open(
                  `${success}` +
                    (success.includes("lang")
                      ? ""
                      : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
                  t(`games.fullName.${game3th.game_3th_code}`),
                );
                return;
              }
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              tip({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "youbet": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
          game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirecToYoubet(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setIsLoading(false);
              if (isNewWindow) {
                window.open(
                  `${success}` +
                    (success.includes("lang")
                      ? ""
                      : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
                  t(`games.fullName.${game3th.game_3th_code}`),
                );
                return;
              }
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              tip({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "aesexy": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
          game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirectToAeSexy(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setIsLoading(false);
              if (isNewWindow) {
                window.open(
                  `${success}` +
                    (success.includes("lang")
                      ? ""
                      : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
                  t(`games.fullName.${game3th.game_3th_code}`),
                );
                return;
              }
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              tip({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "spribeone": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: "spribeone",
          game_id: gameItem.game_id,
          platform: isNewWindow ? "web" : "H5",
        };
        setIsLoading(true);
        await redirectoSpribeone(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setIsLoading(false);
              if (isNewWindow) {
                window.open(
                  `${success.gameUrl}` +
                    (success.gameUrl.includes("lang")
                      ? ""
                      : `${success.gameUrl.includes("?") ? "&" : "?"}lang=${nowLang}`),
                );
                return;
              }
              setGameURL(
                `${success.gameUrl}` +
                  (success.gameUrl.includes("lang")
                    ? ""
                    : `${success.gameUrl.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setIsLoading(false);
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              tip({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "wesport": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: "SPORT-WEB_SOCCER",
        };
        setIsLoading(true);
        await redirectoWeSport(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setIsLoading(false);
              if (isNewWindow) {
                window.open(
                  `${success}` +
                    (success.includes("lang")
                      ? ""
                      : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
                );
                return;
              }
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              tip({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "luckysport": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: "SPORT-WEB_SOCCER",
        };
        setIsLoading(true);
        await redirectoLuckySport(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setIsLoading(false);
              if (isNewWindow) {
                window.open(success.url, "_blank");
                return;
              }
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              tip({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "ybslot": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: "YBNGO_1_1038",
          game_id: gameItem.game_id,
          platform: isNewWindow ? "web" : "H5",
        };
        setIsLoading(true);
        await redirectoYBSlot(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setIsLoading(false);
              if (isNewWindow) {
                window.open(
                  `${success.gameUrl}` +
                    (success.gameUrl.includes("lang")
                      ? ""
                      : `${success.gameUrl.includes("?") ? "&" : "?"}lang=${nowLang}`),
                );
                return;
              }
              setGameURL(
                `${success.gameUrl}` +
                  (success.gameUrl.includes("lang")
                    ? ""
                    : `${success.gameUrl.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setIsLoading(false);
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              tip({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "saba": {
        const submitReq = {
          //1 pc 2 mobile
          platform: 1,
          game: game3th.game_3th_code,
        };
        setIsLoading(true);
        await redirectToGame(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setIsLoading(false);
              if (isNewWindow) {
                window.open(
                  `${success}` +
                    (success.includes("lang")
                      ? ""
                      : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
                  t(`games.fullName.${game3th.game_3th_code}`),
                );
                return;
              }

              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              tip({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "77lottery": {
        setIsLoading(false);
        if (isNewWindow) {
          window.open(
            `${gameListsEnum[game3th.game_3th_code].gameRoutePrefix}/${
              gameItem.game_code
            }?token=${token?.replace(/\"/gm, "")}&lang=${nowLang}&currency=${currency}`,
          );
          return;
        }

        setGameURL(
          `${gameListsEnum[game3th.game_3th_code].gameRoutePrefix}/${
            gameItem.game_code
          }?token=${token?.replace(/\"/gm, "")}&lang=${nowLang}&currency=${currency}`,
        );
        setTitle(t(`games.fullName.${game3th.game_3th_code}`));
        setGame3thName(game3th.game_3th_code);
        setShowGamePopup(true);
        break;
      }
      case "winlottery": {
        if (isNewWindow) {
          setWinGameCode(gameItem?.game_code);
          setWinGameId(gameItem.game_id);
          setShowWinGame(true);
          return;
        }
        setWinGameCode(game3th.game_3th_code?.toLowerCase());
        navigate(
          `${
            gameListsEnum[game3th.game_3th_code].gameRoutePrefix ?? "/"
          }${gameItem.game_code?.toLowerCase()}?tab=${gameItem.game_id}`,
        );
        break;
      }
      case "mg": {
        setGame3thName(game3th.game_3th_code);
        setMGGameCode(gameItem.game_code);
        setIsLoading(true);
        break;
      }
      default:
        break;
    }
  };

  const safeTutorialSetting = (game3thName) => {
    const _localSettings = JSON.parse(localStorage.getItem(localStorageKey.LOCAL_SETTINGS)) || {};
    let _userLocalSettings = {};
    if (_localSettings) {
      _userLocalSettings = _localSettings[userInfo.account_id];
    }

    let newClosedTutorial = game3thName;
    if (!_userLocalSettings) {
      const userSetting = {
        ..._localSettings,
        [userInfo.account_id]: {
          ..._userLocalSettings,
          skip_tutorial: newClosedTutorial,
        },
      };
      localStorage.setItem(localStorageKey.LOCAL_SETTINGS, JSON.stringify(userSetting));
    } else {
      const currentClosedTutorial = _userLocalSettings?.skip_tutorial || "";
      newClosedTutorial = `${newClosedTutorial},${currentClosedTutorial}`;
      const newUserSetting = {
        ...[userInfo.account_id],
        skip_tutorial: newClosedTutorial,
      };
      localStorage.setItem(localStorageKey.LOCAL_SETTINGS, JSON.stringify(newUserSetting));
    }
  };

  const getTutorialSetting = () => {
    const _localSettings = JSON.parse(localStorage.getItem(localStorageKey.LOCAL_SETTINGS));
    if (!_localSettings) return [];
    const _userLocalSettings = _localSettings[userInfo.account_id];
    if (!_userLocalSettings || !_userLocalSettings.skip_tutorial) return [];
    return _userLocalSettings.skip_tutorial.split(",");
  };

  useEffect(() => {
    if (mgGameCode) {
      getMGLoginLink();
    }
  }, [mgGameCode]);

  const resetState = () => {
    cache.invalidateQueries([queryKey.USER_WALLET]);
    setShowGamePopup(false);
    setGameURL("");
    setTitle("");
    setShowHeader(true);
    setShowBubble(false);
    setMGGameCode(null);
    setGame3thName("");
    setShowTutorial(false);
    setShowLotteryButton(false);
    setWinGameCode();
    setWinGameId();
  };

  return (
    <GameLoginContext.Provider
      value={{
        handleLoginGame,
        showGamePopup,
        setShowGamePopup,
      }}
    >
      {children}
      <GamePopup
        title={title}
        visible={showGamePopup}
        onClose={resetState}
        showHeader={showHeader}
        showBubble={showBubble}
        showTutorial={showTutorial}
        setShowTutorial={setShowTutorial}
        gameURL={gameURL}
        game3thName={game3thName}
        closeTutorial={(isChecked, game3thName) => {
          setShowTutorial(false);
          if (isChecked === true) {
            safeTutorialSetting(game3thName);
          }
        }}
        showLotteryButton={showLotteryButton}
      />
      <CustomModal
        isModalVisible={showTestAccountWarning}
        setIsModalVisible={setShowTestAccountWarning}
        title={t("1bbet.popup.notAllowTest.desc")}
        content={""}
        needIcon={true}
        okBtnText={t("normal.confirm")}
        isHaveCancel={false}
      />

      <PCWinGamePopup
        visible={showWinGame}
        setVisible={setShowWinGame}
        gameCode={winGameCode}
        gameId={winGameId}
      />
    </GameLoginContext.Provider>
  );
};
export const useGameLogin = () => {
  const GameLoginValue = useContext(GameLoginContext);
  return GameLoginValue;
};
