export const ValidateUserMoney = ({ walletAmount, betAmount }) => {
  if (walletAmount >= betAmount) return { valid: true };
  return { valid: false, content: "validate.notEnoughMoney", options: {} };
};

export const ValidateMaxMinBet = ({ betAmount, highest_single_bet, lowest_single_bet }) => {
  if (highest_single_bet < parseInt(betAmount) || lowest_single_bet > parseInt(betAmount)) {
    return {
      valid: false,
      content: "validate.maxMinBet",
      options: { max: highest_single_bet, min: lowest_single_bet },
    };
  }
  return { valid: true };
};

export const ValidateMaxPayout = ({ betAmount, odds, max_payout }) => {
  const minimumOdds = Math.min(...odds.split(","));
  if (max_payout <= minimumOdds * betAmount)
    return { valid: false, content: "validate.maxPayout", options: { max: max_payout } };
  return { valid: true };
};
