import { Tabs } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import "./style.scss";

const DarkTab = ({
  onTabChange = () => {},
  tabItems = [],
  activeKey,
  tabBarStyle = {},
  darkTabContainerStyle = "",
}) => {
  const { t } = useTranslation();
  const { TabPane } = Tabs;
  return (
    <div className={`dark-tab-container ${darkTabContainerStyle}`}>
      <Tabs
        tabBarStyle={{ display: "flex", flexGrow: "1", ...tabBarStyle }}
        tabBarGutter={0}
        centered
        onChange={onTabChange}
        activeKey={activeKey}
      >
        {tabItems?.map((tabItem, index) => {
          return (
            <TabPane
              key={`${tabItem.key}`}
              tab={tabItem.translate ? t(`${tabItem.name}`) : tabItem.name}
            >
              {tabItem.component}
            </TabPane>
          );
        })}
      </Tabs>
      {/* <Tabs
        tabBarStyle={{ display: "flex", flexGrow: "1", ...tabBarStyle }}
        tabBarGutter={0}
        centered
        onChange={() => {}}
        // activeKey={}
      >
        <TabPane key={`star`} tab={"Star"} />
        <TabPane key={`moon`} tab={"Moon"} />
        <TabPane key={`sun`} tab={"Sun"} />
        <TabPane key={`spoace`} tab={"Space"} />
      </Tabs> */}
    </div>
  );
};

export default DarkTab;
