const SearchIcon = ({ width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.6529 20.2028L16.4074 16.191M16.4074 16.191C17.1298 15.5204 17.7048 14.721 18.099 13.8389C18.4933 12.9568 18.699 12.0095 18.7044 11.0517C18.7097 10.0939 18.5146 9.14463 18.1303 8.25863C17.7459 7.37262 17.1799 6.56748 16.4651 5.8897C15.7502 5.21192 14.9005 4.67493 13.9652 4.30973C13.0298 3.94454 12.0274 3.75838 11.0156 3.76201C10.0038 3.76565 9.00286 3.95899 8.07048 4.33088C7.13809 4.70278 6.29277 5.24585 5.58333 5.92874C4.16731 7.29178 3.37717 9.12904 3.38482 11.0408C3.39247 12.9525 4.1973 14.7841 5.6242 16.1369C7.0511 17.4897 8.9846 18.2544 11.004 18.2645C13.0235 18.2747 14.9654 17.5294 16.4074 16.191Z"
        stroke="var(--brand-primary-50)"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default SearchIcon;
