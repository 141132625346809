import { RightOutlined } from "@ant-design/icons";
import { Card, Divider, Drawer } from "antd";
import { t } from "i18next";
import React, { useState } from "react";
import { ACCOUNT_VERIFY_METHOD } from "../../config.js";
import { useGetCustomerServiceNoCurrency } from "../../hooks/customerService/customerService.hook.js";
import { checkUrlReg } from "../../plugins/utils.js";
import { CustomerServiceDrawer } from "../index";
import { AppBar, Drawer as CustomDrawer } from "../index.js";
function ConfirmValidatePopup({
  visible,
  setVisible,
  onMobileClick,
  onEmailClick,
  hideEmail,
  hideMobile,
}) {
  const { data: customerService } = useGetCustomerServiceNoCurrency({
    enabled: visible === true,
  });
  const [showCSDrawer, setShowCSDrawer] = useState(false);
  const [showCustomerDrawer, setShowCustomerDrawer] = useState(false);
  const openCSDrawer = () => {
    setShowCustomerDrawer(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const cardStyle = {
    marginTop: 8,
    borderRadius: 8,
    boxShadow: "1px 8px 12px 0 #e2eaf0",
    backgroundImage:
      "linear-gradient(to bottom, var(--color-table-normal-1), var(--color-login-input-text-title) 100%)",
  };

  return (
    <>
      <Drawer
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
        bodyStyle={{ padding: "0" }}
        width={"100%"}
        destroyOnClose={true}
      >
        <AppBar
          title={t("normal.selectValidate")}
          onClickEvent={() => {
            setVisible(false);
          }}
        />
        {showCSDrawer}
        <div style={{ padding: 16 }}>
          {hideMobile || ACCOUNT_VERIFY_METHOD.includes("phone") === false ? (
            <></>
          ) : (
            <Card
              style={cardStyle}
              bodyStyle={{ display: "flex" }}
              onClick={() => {
                if (onMobileClick) {
                  onMobileClick();
                }
              }}
            >
              <div>{t("normal.mobileValidate")}</div>
              <div style={{ flex: 1 }} />
              <div>
                <RightOutlined />
              </div>
            </Card>
          )}
          {hideEmail || ACCOUNT_VERIFY_METHOD.includes("email") === false ? (
            <></>
          ) : (
            <Card
              style={cardStyle}
              bodyStyle={{ display: "flex" }}
              onClick={() => {
                if (onEmailClick) {
                  onEmailClick();
                }
              }}
            >
              <div> {t("normal.emailValidate")} </div>
              <div style={{ flex: 1 }} />
              <div>
                <RightOutlined />
              </div>
            </Card>
          )}
          <Card
            style={cardStyle}
            bodyStyle={{ display: "flex" }}
            onClick={() => {
              setShowCSDrawer(true);
            }}
          >
            <div> {t("signup.contactCs")} </div>
            <div style={{ flex: 1 }} />
            <div>
              <RightOutlined />
            </div>
          </Card>
        </div>
      </Drawer>
      <CustomDrawer
        placement="right"
        showDrawer={showCSDrawer}
        setShowDrawer={setShowCSDrawer}
        title={t("signup.contactCs")}
        renderContent={() => {
          return (
            <div>
              {customerService?.data?.map((cs, index) => {
                return (
                  <div style={{ margin: "16px 16px 0px" }} key={`${cs.id}_${index}`}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      onClick={() => {
                        if (!!cs.customer_service_link)
                          checkUrlReg(cs.customer_service_link, openCSDrawer);
                        // window.location = cs.customer_service_link;
                      }}
                    >
                      <div style={{ color: "var(--color-text-main)" }}>{cs.name}</div>
                      <div style={{ color: "var(--color-radio-inactive)" }}>
                        <RightOutlined />
                      </div>
                    </div>
                    <Divider style={{ marginTop: 16 }} />
                    <CustomerServiceDrawer
                      csLink={cs.customer_service_link}
                      visible={showCustomerDrawer}
                      handleVisible={setShowCustomerDrawer}
                    />
                  </div>
                );
              })}
            </div>
          );
        }}
      />
    </>
  );
}

export default ConfirmValidatePopup;
