import { CloseCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import { useGameWebsocket } from "../../context/WebsocketGameContext";
import { useStockWebsocket } from "../../context/WebsocketStockContext";
import BitGameContainer from "../../pages/mobile/BitGame/components/GameContainer";
import GophersGameContainer from "../../pages/mobile/GophersGame/components/GameContainer";
import LaunchGameContainer from "../../pages/mobile/LaunchGame/components/GameContainer";
import FederalGameContainer from "../../pages/mobile/LoteriaFederal/components/GameContainer";
import WinGameContainer from "../../pages/mobile/WinGame/components/GameContainer";
import styles from "./style.module.scss";

const PCWinGamePopup = ({ visible = false, setVisible = () => {}, gameCode, gameId }) => {
  const { t } = useTranslation();
  const DARK_THEME = ["LOTLAUNCH", "LOTGOPHERS", "LOTFEDERAL"];
  const { closeWebsocket: closeStockWS, isWebsocketConnected: isStockWSConnected } =
    useStockWebsocket();

  const { closeWebsocket: closeGameWS, isWebsocketConnected: isGameWSConnected } =
    useGameWebsocket();

  const handleCloseWebsocket = () => {
    if (isStockWSConnected === true) {
      closeStockWS();
    }
    if (isGameWSConnected === true) {
      closeGameWS();
    }
  };

  const handleClosePage = () => {
    handleCloseWebsocket();
    setVisible(false);
  };

  const renderHeader = () => {
    return (
      <div className={styles.headerContainer}>
        <div className={styles.title}>{t(`win.game.title.${gameCode?.toUpperCase()}`)}</div>
        <div className={styles.closeButton} onClick={handleClosePage}>
          <CloseCircleOutlined className={styles.close} />
        </div>
      </div>
    );
  };
  const renderBody = () => {
    switch (gameCode?.toUpperCase()) {
      case "LOTBIT":
        return (
          <BitGameContainer
            handleClosePage={handleClosePage}
            handleCloseWebsocket={handleCloseWebsocket}
            gameCode={gameCode}
            gameId={gameId}
          />
        );
      case "LOTWIN":
        return (
          <WinGameContainer
            handleClosePage={handleClosePage}
            handleCloseWebsocket={handleCloseWebsocket}
            gameCode={gameCode}
            gameId={gameId}
          />
        );
      case "LOTLAUNCH":
        return (
          <LaunchGameContainer
            handleClosePage={handleClosePage}
            handleCloseWebsocket={handleCloseWebsocket}
            gameCode={gameCode}
            gameId={gameId}
          />
        );
      case "LOTGOPHERS":
        return (
          <GophersGameContainer
            handleClosePage={handleClosePage}
            handleCloseWebsocket={handleCloseWebsocket}
            gameCode={gameCode}
            gameId={gameId}
          />
        );
      case "LOTFEDERAL":
        return (
          <FederalGameContainer
            handleClosePage={handleClosePage}
            handleCloseWebsocket={handleCloseWebsocket}
            gameCode={gameCode}
            gameId={gameId}
          />
        );
      default:
        break;
    }
  };

  return (
    <Modal
      width={900}
      height={761}
      className={"form-modal"}
      visible={visible}
      centered
      footer={null}
      closable={false}
      destroyOnClose={true}
    >
      <div className={styles.container}>
        {renderHeader()}
        <Scrollbars
          autoHide
          className={`${styles.bodyContainer} ${
            DARK_THEME.includes(gameCode?.toUpperCase()) ? styles.dark : ""
          }`}
        >
          {renderBody()}
        </Scrollbars>
      </div>
    </Modal>
  );
};

export default PCWinGamePopup;
