import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { CaretDownOutlined } from "@ant-design/icons";
import { DEFAULT_LANG } from "../../config";
import localStorageKey from "../../enumerations/localStorageKey";
import LangSelector from "../LangSelector";
import langDict from "../LangSelector/langDict.json";
import styles from "./style.module.scss";

function LangSelectorPopover({ changeStyle, popoverPlacement = "bottom-end", type = "compact" }) {
  const [showPopover, setShowPopover] = useState(false);
  const { i18n } = useTranslation();
  const getLanguage = () => i18n.language;
  const nowLang = localStorage.getItem(localStorageKey.LANG_SETTING) || DEFAULT_LANG;

  const getLanguageName = () => {
    const lang = langDict.find((_lang) => _lang.value === nowLang);
    return lang?.label;
  };

  const renderDetail = () => {
    if (type === "full") {
      return <div className={styles.detailText}>{getLanguageName()}</div>;
    }
  };

  const renderDropdownIcon = () => {
    if (type === "full") {
      // return showPopover ? (
      //   <CaretUpOutlined onClick={(e) => e.stopPropagation()} />
      // ) : (
      //   <CaretDownOutlined />
      // );
      return (
        <CaretDownOutlined
          rotate={showPopover ? 180 : 0}
          style={{ color: "var(--button-text-base)" }}
        />
      );
    }
  };

  return (
    <LangSelector
      visible={showPopover}
      type="popover"
      setShowPopover={setShowPopover}
      popoverPlacement={popoverPlacement}
    >
      <div className={`${styles.container} ${type === "full" ? styles.full : ""}`}>
        <div
          className={`${styles.langSelectorBtn} ${showPopover ? styles.active : ""} ${
            type === "full" ? styles.full : ""
          }`}
        >
          <div
            className={`${changeStyle ? styles.changeStyleGroup : styles.group} ${
              type === "full" ? styles.full : ""
            }`}
          >
            <div className={`${styles.imgContainer}`}>
              <img src={`/images/langIcons/${nowLang}.png`} alt={nowLang} />
            </div>
          </div>
        </div>
        {renderDetail()}
        {renderDropdownIcon()}
      </div>
    </LangSelector>
  );
}

export default LangSelectorPopover;
