import { requestGlobalSettingData, requestGlobalUserData } from "../../apis";
import localStorageKey from "../../enumerations/localStorageKey";

const CURRENCY = window.localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);

export const apiRequestSignUp = (params) =>
  requestGlobalUserData("post", `/user/register`, params, true);

export const apiGetCurrency = (params) =>
  requestGlobalSettingData("get", `/currency`, params, false);

export const apiGetCountry = (params) =>
  requestGlobalUserData("get", `/user/register/country`, params, false);

export const apiGetLanguage = (params) =>
  requestGlobalSettingData("get", `/language`, params, false);
