import { useMutation } from "@tanstack/react-query";
import {
  apiGetUserForgetSendVerify, apiPostUserMailSend
} from "../service";

export const usePostEmailSend = (options) => {
  return useMutation(
    (params) =>
      apiPostUserMailSend(params).then((res) => {
        return res.data.data;
      }),
    {
      onError: (error) => {
        console.debug(JSON.stringify("ERR", error));
      },
    }
  );
};
export const useGetUserForgetSendVerify = () => {
  return useMutation(
    (params) =>
      apiGetUserForgetSendVerify(params).then((res) => {
        return res.data.data;
      }),
    {
      onError: (error) => {
        console.debug(JSON.stringify("ERR", error));
      },
    }
  );
};