import styles from "./style.module.scss";

const PCStepButton = ({
  steps = [],
  currentStep = "",
  setCurrentStep = () => {},
  allowForward = false,
  allowBackward = true,
}) => {
  const onHandleStepClick = (index) => {
    if (index - currentStep === 0 || index - currentStep > 1 || index - currentStep < -1) return;
    if (index - currentStep === 1 && allowForward === true) {
      setCurrentStep((prevState) => prevState + 1);
      return;
    }
    if (index - currentStep === -1 && allowBackward === true) {
      setCurrentStep((prevState) => prevState - 1);
      return;
    }
  };

  return (
    <div className={styles.stepButtonContainer}>
      {steps.map((step, index, _steps) => {
        return (
          <div
            key={step.key}
            onClick={() => {
              onHandleStepClick(index);
            }}
            className={`${styles.stepButton} ${
              index === 0
                ? styles.firstStep
                : index === _steps.length - 1
                ? styles.lastStep
                : styles.middleStep
            } ${index === currentStep ? styles.active : ""}`}
          >
            {step.title}
          </div>
        );
      })}
    </div>
  );
};

export default PCStepButton;
