import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { message } from "antd";
import React from "react";
import "./style.scss";

const toast = ({ content = "", type = "success", isMobile = true }) => {
  const renderIcon = (type) => {
    switch (type) {
      case "success":
        return (
          <CheckCircleOutlined
            style={{ color: "var(--ant-success-color)", marginRight: "0", fontSize: "28px" }}
          />
        );
      case "error":
        return (
          <CloseCircleOutlined
            style={{ color: "var(--ant-error-color)", marginRight: "0", fontSize: "28px" }}
          />
        );
      case "warning":
        return (
          <ExclamationCircleOutlined
            style={{ color: "var(--ant-warning-color)", marginRight: "0", fontSize: "28px" }}
          />
        );
      default:
        return (
          <CheckCircleOutlined
            style={{ color: "var(--ant-success-color)", marginRight: "0", fontSize: "28px" }}
          />
        );
    }
  };

  const customContent = (type, content) => {
    return (
      <div className="custom-content">
        <div className="icon">{renderIcon(type)}</div>
        <div className="content">{content}</div>
      </div>
    );
  };
  if (isMobile) {
    message.open({
      content: customContent(type, content),
      className: "custom-message",
    });
  } else {
    message.open({
      content: content,
      type: type,
    });
  }
};

export default toast;
