export const coinStatusEnum = {
  INACTIVE: {
    key: "inactive",
    src: "/images/dailyCheck/coin_inactive.png",
    name: "treasure_not_check",
  },
  ACTIVE: {
    key: "active",
    src: "/images/dailyCheck/coin_active.png",
    name: "coin_active",
  },
  SUCCESS: {
    key: "success",
    src: "/images/dailyCheck/coin_success.svg",
    name: "coin_success",
  },
};
