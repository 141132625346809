import React from "react";

import styles from "./style.module.scss";

function GradientTitle({ colorType = "yellow", title = "", renderTitle }) {
  return (
    <div className={styles.gradientTitle}>
      <img
        src={`/images/vip/secondVersion/titleBar/${colorType}.png`}
        alt={colorType}
        className={styles.leftBar}
      />
      {renderTitle ? (
        renderTitle()
      ) : (
        <div className={`${styles.title} ${styles[colorType]}`}>{title}</div>
      )}

      <img
        src={`/images/vip/secondVersion/titleBar/${colorType}.png`}
        alt={colorType}
        className={styles.rightBar}
      />
    </div>
  );
}

export default GradientTitle;
