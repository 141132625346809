import { useInfiniteQuery } from "@tanstack/react-query";
import activityTypeIdEnum from "../../enumerations/activityTypeIdEnum";
import queryKey from "../../enumerations/queryKey";
import { apiGetPromotionList } from "./service";

export const useGetPromotionList = ({ value, typeId, groupId, page_size, options }) => {
  let params = {};
  switch (value) {
    case activityTypeIdEnum.ALL:
      break;
    // case activityTypeIdEnum.STANDARD:
    //   params = {
    //     promotion_type_group: typeId
    //   }
    //   break;
    // default:
    //   params = {
    //     promotion_type_group: typeId,
    //     game_category_id: id,
    //   }
    //   break;
    default:
      params = {
        promotion_type_group: groupId,
        promotion_type_id: typeId,
      };
      break;
  }
  return useInfiniteQuery(
    [queryKey.PROMOTION_LIST, value],
    ({ pageParam = 1 }) =>
      apiGetPromotionList({ params, page_size, page: pageParam }).then((res) => {
        const promotions = {
          ...res.data.data,
          data: res.data.data.data
            .map((_promo) => {
              //NEED TO ADD DAILY PROMO promotion_type
              if (_promo.promotion_type === "PRCP") {
                return {
                  ..._promo,
                  start_time: undefined,
                  end_time: undefined,
                  img_url: `/images/activity/${_promo.promotion_type}.png`,
                };
              }
              return _promo;
            })
            .filter((_promo) => _promo.status === 1),
        };
        return promotions;
      }),
    {
      ...options,
    },
  );
};
