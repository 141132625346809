import "./style.scss";
const Overlay = ({ color = "transparent", children }) => {
  return (
    <div
      style={{
        background: color,
        width: "100vw",
        height: "calc(var(--vh, 1vh) * 100)",
        overflow: "hidden",
        position: "relative",
      }}
    >
      {children}
    </div>
  );
};
export default Overlay;
