import { RightOutlined } from "@ant-design/icons";
import { Image } from "antd-mobile";
import { useTranslation } from "react-i18next";
import { ACCOUNT_VERIFY_METHOD } from "../../../../../../../../config";
import Email from "./images/email.png";
import CSImage from "./images/online.png";
import Phone from "./images/phone.png";
import styles from "./style.module.scss";

const PCVerificationMethodChoice = ({
  onMobileClick = () => {},
  onEmailClick = () => {},
  onCSClick = () => {},
  hideMobile = false,
  hideEmail = false,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.verificationMethodContainer}>
      <div className={styles.title}>{t("normal.selectValidate")}</div>

      {hideMobile || ACCOUNT_VERIFY_METHOD.includes("phone") === false ? (
        <></>
      ) : (
        <div className={styles.card} onClick={onMobileClick}>
          <div className={styles.container}>
            <Image src={Phone} width={40} height={40} />
            <div className={styles.text}>{t("normal.mobileValidate")}</div>
          </div>

          <RightOutlined className={styles.icon} />
        </div>
      )}
      {hideEmail || ACCOUNT_VERIFY_METHOD.includes("email") === false ? (
        <></>
      ) : (
        <div className={styles.card} onClick={onEmailClick}>
          <div className={styles.container}>
            <Image src={Email} width={40} height={40} />
            <div className={styles.text}> {t("normal.emailValidate")} </div>
          </div>
          <RightOutlined className={styles.icon} />
        </div>
      )}
      <div className={styles.card} onClick={onCSClick}>
        <div className={styles.container}>
          <Image src={CSImage} width={40} height={40} />
          <div className={styles.text}> {t("signup.contactCs")} </div>
        </div>
        <RightOutlined className={styles.icon} />
      </div>
    </div>
  );
};

export default PCVerificationMethodChoice;
