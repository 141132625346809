const winStateEnum = {
  BET_PHASE: {
    key: "BET_PHASE",
    disableBet: false,
  },
  DISABLE_BET_PHASE: {
    key: "DISABLE_BET_PHASE",
    disableBet: true,
  },
  PENDING_PHASE: {
    key: "PENDING_PHASE",
    disableBet: true,
  },
};

export default winStateEnum;
