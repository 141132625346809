import { apiForgotPassword, apiGetForgetCheckAccount } from "../service";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryCache,
} from "@tanstack/react-query";

export const useForgotPassword = () => {
  return useMutation((params) =>
    apiForgotPassword(params).then((res) => res.data.data)
  );
};
export const useGetForgetCheckAccount = (options = { onSuccess: () => {} }) => {
  return useMutation(
    (params) => apiGetForgetCheckAccount(params).then((res) => res.data.data),
    { onSuccess: options.onSuccess }
  );
};
