import { CaretDownFilled } from "@ant-design/icons";
import { Button, Dropdown, Menu, Space } from "antd";
import { useState } from "react";
import "./style.scss";

function PCFilterSelect({ options = [], menuOnClick = () => {}, selectedKey, btnClass = "" }) {
  const [open, setOpen] = useState(false);
  return (
    <div className={"pc-filter-select"}>
      <Dropdown
        overlay={
          <Menu
            items={options}
            onClick={(e) => {
              menuOnClick(e);
              setOpen(false);
            }}
            selectedKeys={[selectedKey]}
            className="pc-filter-select-menu"
          />
        }
        trigger={["click"]}
        onVisibleChange={(e) => {
          setOpen(e);
        }}
      >
        <Button size="large" className={btnClass}>
          <Space>
            {options.find((item) => item.key === selectedKey)?.label}
            <CaretDownFilled rotate={open ? 180 : 0} style={{ color: "var(--button-text-base" }} />
          </Space>
        </Button>
      </Dropdown>
    </div>
  );
}

export default PCFilterSelect;
