const Wallet = (props) => {
  const { color, size } = props;
  return (
    <svg
      width={size ?? 54}
      height={size ?? 54}
      viewBox="0 0 54 54"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.391 16.122a4.348 4.348 0 0 1 4.348 4.348v4.621h-6.961c-4.943 0-8.95 4.016-8.95 8.97 0 4.954 4.007 8.97 8.95 8.97h6.961v4.621A4.348 4.348 0 0 1 44.391 52H6.348A4.348 4.348 0 0 1 2 47.652V20.47a4.348 4.348 0 0 1 4.348-4.348H44.39zM50 28.082a2 2 0 0 1 2 2v7.959a2 2 0 0 1-2 2h-8.325a5.98 5.98 0 0 1 0-11.96H50zm-7.171 3.986c-1.126 0-2.038.892-2.038 1.993s.912 1.993 2.038 1.993c1.125 0 2.038-.892 2.038-1.993 0-1.1-.913-1.993-2.038-1.993zM41.13 7.425c1.201 0 2.174.973 2.174 2.174v3.262H25.913V9.599c0-1.201.973-2.174 2.174-2.174H41.13zM28.517 3.228l.077.158.315.777h-.822A5.435 5.435 0 0 0 22.652 9.6v3.261H8.489c-.988-2.506-.67-4.101.953-4.787l.167-.065c6.016-2.203 12.372-4.74 15.217-5.7 1.82-.615 3.051-.309 3.691.92z"
        fill={color ?? "var(--color-text-stable)"}
        fillRule="evenodd"
      />
    </svg>
  );
};
export default Wallet;
