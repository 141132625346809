import { Modal } from "antd";
import { Button } from "antd-mobile";
import { t } from "i18next";
import { useState } from "react";

import { useEffect } from "react";
import { moneyFormat } from "../../../plugins/numberFormat";
import { checkUrlReg } from "../../../plugins/utils";
import CustomerServiceDrawer from "../../CustomerServiceDrawer";
import styles from "./style.module.scss";
import "./style.scss";

function ResultModal({
  prizeDetail,
  isModalVisible,
  setIsModalVisible,
  maskClosable = false,
  onCloseModal = () => {},
  csLink = "",
}) {
  const [showCustomerDrawer, setShowCustomerDrawer] = useState(false);

  const openCSDrawer = () => {
    setShowCustomerDrawer(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    onCloseModal();
  };

  const handleConfirmCograt = () => {
    if (prizeDetail.award_type === "item" && !!csLink) {
      checkUrlReg(csLink, openCSDrawer, handleCloseModal);
    } else {
      handleCloseModal();
    }
  };

  const winTypes = ["bonus", "item"];

  const rewardRender = (type, name) => {
    const typeDict = {
      bonus: "+" + moneyFormat(parseFloat(name)),
      item: name,
      text: t("bonusWheel.award.text"),
      default: name,
    };
    return typeDict[type] ?? typeDict.default;
  };

  const congratModal = () => {
    return (
      <div className={styles.congratModal}>
        <img
          src="/images/bonusWheel/congratuation.png"
          alt="congratuation ribbon"
          className={styles.ribbon}
        />
        <span className={styles.congratText}>{t("bonusWheel.congratuations")}</span>
        <div
          className={styles.content}
          style={{
            backgroundImage: `url('/images/bonusWheel/result_${prizeDetail?.award_type}.png')`,
            backgroundSize: "100% auto",
            backgroundPositionY: "-5px",
            backgroundRepeat: "no-repeat",
          }}
        >
          <img
            src="/images/bonusWheel/result_shining.gif"
            alt="shining"
            className={styles.resultShinig}
          />
          <div className={styles.text}>{t("bonusWheel.getRewarded")}</div>
          <span className={styles.reward}>
            {rewardRender(prizeDetail.award_type, prizeDetail.name)}
          </span>
          {prizeDetail.award_type === "item" ? (
            <div className={styles.contactCs}>{t("bonusWheel.contactCS")}</div>
          ) : (
            ""
          )}
          <Button block className={styles.confirmBtn} onClick={handleConfirmCograt}>
            {prizeDetail.award_type === "item" ? t("normal.customerService") : t("normal.confirm")}
          </Button>
        </div>
      </div>
    );
  };
  const loseModal = () => {
    return (
      <div className={styles.loseModal}>
        <div className={styles.content}>
          <div className={styles.text}>{t("bonusWheel.lose")}</div>
          <div className={styles.loseFace}>
            <img src="/images/bonusWheel/loseFace.png" alt="loseFace" />
          </div>
          <span className={styles.reward}>
            {rewardRender(prizeDetail.award_type, prizeDetail.name)}
          </span>
          <Button block className={styles.confirmBtn} onClick={handleCloseModal}>
            {t("normal.confirm")}
          </Button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (!showCustomerDrawer) {
      handleCloseModal();
    }
  }, [showCustomerDrawer]);

  return (
    <Modal
      visible={isModalVisible}
      onCancel={handleCloseModal}
      footer={null}
      width={258}
      centered
      closable={false}
      maskClosable={maskClosable}
      destroyOnClose
      className={`bonus-whewl-result-modal ${
        winTypes.includes(prizeDetail?.award_type) ? "congrat" : "lose"
      }`}
      bodyStyle={{ padding: "0" }}
      // style={{borderRadius: '22px'}}
    >
      {!!prizeDetail
        ? winTypes.includes(prizeDetail?.award_type)
          ? congratModal()
          : loseModal()
        : ""}
      <CustomerServiceDrawer
        csLink={csLink}
        visible={showCustomerDrawer}
        handleVisible={setShowCustomerDrawer}
      />
    </Modal>
  );
}

export default ResultModal;
