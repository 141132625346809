// console.log("innerWidth", window.innerWidth);
// const maxWidthSettingInPc = getComputedStyle(document.documentElement).getPropertyValue(
//   "--maxWidth-in-pc",
// );
const maxWidthSettingInPc = "450px";
// console.log("maxWidthSettingInPc2", maxWidthSettingInPc);
// console.log(maxWidthSettingInPc.replace("px", "") > window.innerWidth);
const fullWidth =
  maxWidthSettingInPc.replace("px", "") > window.innerWidth ? "100vw" : maxWidthSettingInPc;
// const positionEnum = [
//   {
//     key: "ball_0",
//     bottom: "calc(100vw / 720 * 179 - 60px)",
//     left: "calc(100vw / 720 * 81)",
//   },
//   {
//     key: "ball_1",
//     bottom: "calc(100vw / 720 * 179 - 60px)",
//     left: "calc(100vw / 720 * 81 + 1 * (100vw / 720 * 88 + 100vw / 720 * 29))",
//   },
//   {
//     key: "ball_2",
//     bottom: "calc(100vw / 720 * 179 - 60px)",
//     left: "calc(100% * 0.5  - (100vw / 720 * 88 / 2))",
//   },
//   {
//     key: "ball_3",
//     bottom: "calc(100vw / 720 * 179 - 60px)",
//     left: "calc(100vw / 720 * 81 + 3 * (100vw / 720 * 88 + 100vw / 720 * 29))",
//   },
//   {
//     key: "ball_4",
//     bottom: "calc(100vw / 720 * 179 - 60px)",
//     left: "calc(100vw / 720 * 81 + 4 * (100vw / 720 * 88 + 100vw / 720 * 29))",
//   },
// ];
const positionEnum = [
  {
    key: "ball_0",
    bottom: `calc(${fullWidth} / 720 * 179 - 60px)`,
    left: `calc(${fullWidth} / 720 * 81)`,
  },
  {
    key: "ball_1",
    bottom: `calc(${fullWidth} / 720 * 179 - 60px)`,
    left: `calc(${fullWidth} / 720 * 81 + 1 * (${fullWidth} / 720 * 88 + ${fullWidth} / 720 * 29))`,
  },
  {
    key: "ball_2",
    bottom: `calc(${fullWidth} / 720 * 179 - 60px)`,
    left: `calc(100% * 0.5  - (${fullWidth} / 720 * 88 / 2))`,
  },
  {
    key: "ball_3",
    bottom: `calc(${fullWidth} / 720 * 179 - 60px)`,
    left: `calc(${fullWidth} / 720 * 81 + 3 * (${fullWidth} / 720 * 88 + ${fullWidth} / 720 * 29))`,
  },
  {
    key: "ball_4",
    bottom: `calc(${fullWidth} / 720 * 179 - 60px)`,
    left: `calc(${fullWidth} / 720 * 81 + 4 * (${fullWidth} / 720 * 88 + ${fullWidth} / 720 * 29))`,
  },
];
export default positionEnum;
