import { Image } from "antd-mobile";
import styles from "./style.module.scss";

const SiderButton = ({ menu = {}, onClick = () => {}, children }) => {
  const { icon = false, text = "", containerStyle = "", textStyle = "", iconStyle = "" } = menu;
  return (
    <div onClick={() => onClick(menu)} className={`${styles.buttonContainer} ${containerStyle}`}>
      {icon !== false ? (
        <Image className={`${styles.buttonIcon} ${iconStyle}`} src={icon} alt={text} />
      ) : null}
      <div className={`${styles.buttonText} ${textStyle}`}>{text}</div>
      {children}
    </div>
  );
};

export default SiderButton;
