import { Form } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PCBackButton, PCButton, PCStepButton } from "../../../../../../components";
import { useGetForgetCheckAccount } from "../../../../../../pages/mobile/ForgotPassword/hooks/forgotPassword.hooks";
import styles from "./style.module.scss";

import { usePutForgetPassword } from "../../../../../../components/BindEmailPopup/hooks/bind.hook";
import CustomInput from "../../../../../../components/CustomInput";
import {
  CSCurrencyPicker,
  EmailVerificationForm,
  PCVerificationMethodChoice,
  PhoneVerificationForm,
  SuccessMessage,
} from "./components";

const csSteps = [
  { key: "select_method", title: "Step 01" },
  { key: "verification", title: "Step 02" },
];
const steps = [...csSteps, { key: "verification_finish", title: "Step 03" }];

const ForgetPasswordForm = ({
  backTo = "login",
  setCurrentActiveMenu = () => {},
  setHideFooter = () => {},
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [verifyItems, setVerifyItems] = useState();
  const [currentStep, setCurrentStep] = useState(-1);
  const [forgetPasswordMethod, setForgetPasswordMethod] = useState();
  const [account, setAccount] = useState();
  const [stepList, setStepList] = useState(steps);

  const { mutate: forgetCheckAccount } = useGetForgetCheckAccount({});
  const { mutate: forgetPassword } = usePutForgetPassword({});

  const onFinish = async (form) => {
    await forgetCheckAccount(
      { ...form },
      {
        onSuccess: (success) => {
          setVerifyItems(success);
          setAccount(form.account);
        },
        onError: (error) => {
          console.debug("error", error);
          // tip({ content: error, type: "error" });
        },
      },
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onPressBack = () => {
    setCurrentActiveMenu(backTo);
  };

  const onEmailVerificationFinish = async (value) => {
    await forgetPassword(
      { ...value, type: "email", account },
      {
        onSuccess: (data) => {
          setCurrentStep(2);
        },
        onError: (error) => {
          // tip({ content: error, type: "error" });
        },
      },
    );
  };
  const onPhoneVerificationFinish = async (value) => {
    await forgetPassword(
      { ...value, type: "mobile", account },
      {
        onSuccess: (data) => {
          setCurrentStep(2);
        },
        onError: (error) => {
          // tip({ content: error, type: "error" });
        },
      },
    );
  };

  const renderVerificationForm = () => {
    switch (forgetPasswordMethod) {
      case "email":
        return <EmailVerificationForm account={account} onFinish={onEmailVerificationFinish} />;
      case "mobile":
        return <PhoneVerificationForm account={account} onFinish={onPhoneVerificationFinish} />;
      case "cs":
        return <CSCurrencyPicker />;
      default:
        break;
    }
  };

  const renderForgetPasswordForm = () => {
    switch (currentStep) {
      case -1: //enter username
        return (
          <div className={styles.formContainer}>
            <Form
              form={form}
              name="resetPassword"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              requiredMark={false}
              layout="vertical"
            >
              <CustomInput
                type="pc"
                name="account"
                label={""}
                placeholder={t("form.pleaseEnter", { name: t("normal.account") })}
                autoComplete={"false"}
                rules={[
                  {
                    required: true,
                    message: t("form.required", { name: t("normal.account") }),
                  },
                ]}
              />
              <PCButton block size="large" type="primary" htmlType="submit">
                {t("normal.confirm")}
              </PCButton>
            </Form>
          </div>
        );
      case 0: // choose verification method
        return (
          <div className={styles.formContainer}>
            <PCStepButton
              steps={stepList}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
            />
            <PCVerificationMethodChoice
              onMobileClick={() => {
                setCurrentStep(1);
                setForgetPasswordMethod("mobile");
              }}
              onEmailClick={() => {
                setCurrentStep(1);
                setForgetPasswordMethod("email");
              }}
              onCSClick={() => {
                setStepList(csSteps);
                setCurrentStep(1);
                setForgetPasswordMethod("cs");
              }}
              hideMobile={!verifyItems.includes("mobile")}
              hideEmail={!verifyItems.includes("email")}
            />
          </div>
        );
      case 1: // Verification Form
        return (
          <div className={styles.formContainer}>
            <PCStepButton
              steps={stepList}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
            />
            {renderVerificationForm()}
          </div>
        );
      case 2: //Success / Failed
        return (
          <div className={styles.formContainer}>
            <PCStepButton
              steps={stepList}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
            />
            <SuccessMessage setCurrentActiveMenu={setCurrentActiveMenu} />
          </div>
        );
      default:
        break;
    }
  };

  useEffect(() => {
    if (currentStep === 0) {
      setStepList(steps);
    }
    if (currentStep > -1) {
      setHideFooter(true);
    } else {
      setHideFooter(false);
    }
  }, [currentStep]);

  useEffect(() => {
    if (verifyItems) {
      setCurrentStep(0);
    }
  }, [verifyItems]);

  useEffect(() => {
    return () => {
      setCurrentStep(-1);
      setHideFooter(false);
    };
  }, []);

  return (
    <div className={styles.forgetPasswordContainer}>
      <PCBackButton onPressBack={onPressBack} />
      {renderForgetPasswordForm()}
    </div>
  );
};

export default ForgetPasswordForm;
