import { Image, Tag } from "antd-mobile";
import styles from "./style.module.scss";

const TabButton = ({
  onClick = () => {},
  hasIcon = false,
  icon = "",
  selectedKey = "",
  currentKey = "",
  text = "",
  buttonContainerStyle = "",
}) => {
  return (
    <Tag
      onClick={onClick}
      className={`${styles.tag} ${
        selectedKey === currentKey ? styles.selected : ""
      } ${buttonContainerStyle}`}
      key={currentKey}
    >
      {hasIcon ? <Image className={styles.image} width={28} src={icon} /> : null}
      {text}
    </Tag>
  );
};

export default TabButton;
