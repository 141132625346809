import { useEffect, useState } from "react";
import { useGameWebsocket } from "../../../../../context/WebsocketGameContext";
import webSocketActionEnum from "../../../../../enumerations/webSocketActionEnum";
import gameStateEnum from "../../enumeration/gameStateEnum";
import BalanceViewer from "../BalanceViewer";
import GophersBackground from "../GophersBackground";
import GophersCollection from "../GophersCollection";
import animationPhaseEnum from "../GophersCollection/enumerations/animationPhaseEnum";
import PreparingCountDown from "../PreparingCountDown";
import ResultViewer from "../ResultViewer";
import styles from "./style.module.scss";

const AnimatedPanel = ({
  walletAmount,
  setWalletAmount,
  currentGameState,
  setCurrentGameState,
  resultDefaultTimer,
  animationStartPosition = 0,
  isSwitchChannel,
  setRuleVisible,
  handleDepositCallback = () => {},
  selectedGameList,
  latestNumber = undefined,
  refreshData = () => {},
  latestNumberRefetching = false,
}) => {
  const [resultTimer, setResultTimer] = useState(resultDefaultTimer);
  const { prevPeriod } = useGameWebsocket();
  const [gophersState, setGophersState] = useState(animationPhaseEnum.RESET);

  useEffect(() => {
    setResultTimer(resultDefaultTimer);
  }, [resultDefaultTimer]);

  useEffect(() => {
    let timer = null;
    switch (currentGameState.key) {
      case "BET_PHASE":
        setGophersState(animationPhaseEnum.RANDOM_PENDING);
        break;
      case "PENDING_LAUNCH":
        if (gophersState === animationPhaseEnum.RANDOM_PENDING) {
          setGophersState(animationPhaseEnum.RESET);
          setTimeout(() => {
            setGophersState(animationPhaseEnum.RANDOM);
          }, 2000);
        }
        break;

      case "LAUNCHING": {
        setTimeout(() => {
          setGophersState(animationPhaseEnum.RESULT);
          setCurrentGameState(gameStateEnum.EXPLODED);
        }, 3000);

        break;
      }
      case "EXPLODED": {
        setTimeout(() => {
          setCurrentGameState(gameStateEnum.RESULT_PHASE);
        }, 3000);
        break;
      }
      case "RESULT_PHASE": {
        if (timer) {
          clearInterval(timer);
        }
        break;
      }
      default:
        break;
    }
    return () => {
      clearInterval(timer);
    };
  }, [currentGameState, gophersState]);

  useEffect(() => {
    if (prevPeriod && prevPeriod.action) {
      switch (prevPeriod.action) {
        case webSocketActionEnum.PENDING:
          if (currentGameState.key === gameStateEnum.DISABLE_BET.key)
            setCurrentGameState(gameStateEnum.PENDING_LAUNCH);
          break;
        case webSocketActionEnum.END:
          if (currentGameState.key === gameStateEnum.PENDING_LAUNCH.key)
            setCurrentGameState(gameStateEnum.LAUNCHING);
          break;
        default:
          break;
      }
    }
  }, [currentGameState, prevPeriod]);

  return (
    <div className={styles.container}>
      <GophersBackground currentGameState={currentGameState} />
      <GophersCollection gophersState={gophersState} latestNumber={latestNumber} />
      <PreparingCountDown
        visible={currentGameState?.showCountDown}
        setCurrentGameState={setCurrentGameState}
        isSwitchChannel={isSwitchChannel}
        currentGameState={currentGameState}
        latestNumber={latestNumber}
        refreshData={refreshData}
        latestNumberRefetching={latestNumberRefetching}
        selectedGameList={selectedGameList}
      />
      <BalanceViewer
        walletAmount={walletAmount}
        setWalletAmount={setWalletAmount}
        refetchWaitTime={15}
        onRulesOpen={() => {
          setRuleVisible(true);
        }}
        handleDepositCallback={handleDepositCallback}
      />
      {currentGameState?.showResult === true ? (
        <ResultViewer
          setCurrentGameState={setCurrentGameState}
          resultTimer={resultTimer}
          latestNumber={latestNumber}
        />
      ) : null}
    </div>
  );
};

export default AnimatedPanel;
