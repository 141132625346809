import { Tabs } from "antd";
import React from "react";
import "./style.scss";

function PCCardTabs({ onTabChange = () => {}, tabItems = [], activeKey, extraClass = "" }) {
  const { TabPane } = Tabs;
  return (
    <Tabs
      onChange={onTabChange}
      activeKey={activeKey}
      type="card"
      className={`${extraClass} pc-card-tabs`}
    >
      {tabItems?.map((tabItem, index) => {
        return (
          <TabPane key={`${tabItem.key}`} tab={tabItem.label}>
            {tabItem.component}
          </TabPane>
        );
      })}
    </Tabs>
  );
}

export default PCCardTabs;
