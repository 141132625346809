const betSelectionEnum = {
  COLOR: {
    column: 3,
    green: {
      key: "green",
      title: "win.wingame.bettype.green",
      translate: true,
      color: ["#20AC4A"],
    },
    violet: {
      key: "violet",
      title: "win.wingame.bettype.violet",
      translate: true,
      color: ["#5C32A8"],
    },
    red: {
      key: "red",
      title: "win.wingame.bettype.red",
      translate: true,
      color: ["#FF464E"],
    },
  },
  NUM: {
    column: 5,
    0: {
      key: "0",
      title: "0",
      translate: false,
      color: ["#5C32A8", "#FF464E"],
    },
    1: {
      key: "1",
      title: "1",
      translate: false,
      color: ["#20AC4A"],
    },
    2: {
      key: "2",
      title: "2",
      translate: false,
      color: ["#FF464E"],
    },
    3: {
      key: "3",
      title: "3",
      translate: false,
      color: ["#20AC4A"],
    },
    4: {
      key: "4",
      title: "4",
      translate: false,
      color: ["#FF464E"],
    },
    5: {
      key: "5",
      title: "5",
      translate: false,
      color: ["#20AC4A", "#5C32A8"],
    },
    6: {
      key: "6",
      title: "6",
      translate: false,
      color: ["#FF464E"],
    },
    7: {
      key: "7",
      title: "7",
      translate: false,
      color: ["#20AC4A"],
    },
    8: {
      key: "8",
      title: "8",
      translate: false,
      color: ["#FF464E"],
    },
    9: {
      key: "9",
      title: "9",
      translate: false,
      color: ["#20AC4A"],
    },
  },
  DX: {
    column: 2,
    big: {
      key: "big",
      title: "win.wingame.bettype.big",
      translate: true,
      color: ["var(--win-option-big)"],
    },
    small: {
      key: "small",
      title: "win.wingame.bettype.small",
      translate: true,
      color: ["var(--win-option-small)"],
    },
  },
};

export default betSelectionEnum;
