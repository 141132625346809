import { useMutation, useQuery } from "@tanstack/react-query";
import localStorageKey from "../../enumerations/localStorageKey";
import queryKey from "../../enumerations/queryKey";
import singleWalletEnum from "../../enumerations/singleWalletEnum";
import {
  apiGetGameList,
  apiGetUserGameWallet,
  apiGetUserWallet,
  apiGetUserWalletCache,
  apiTransferMoneyToGame,
  apiTransferMoneyToMainWallet,
} from "./service";

export const useGetUserWallet = (options) => {
  return useQuery(
    [queryKey.USER_WALLET],
    () =>
      apiGetUserWallet().then((res) => {
        localStorage.setItem(localStorageKey.WALLET, res?.data?.data?.money || 0);
        return res.data.data;
      }),
    {
      ...options,
    },
  );
};

export const useGetUserWalletCache = (options) => {
  return useQuery(
    [queryKey.USER_WALLET_CACHE],
    () =>
      apiGetUserWalletCache().then((res) => {
        localStorage.setItem(localStorageKey.WALLET, res?.data?.data?.money || 0);
        return res.data.data;
      }),
    {
      ...options,
    },
  );
};

export const useGetUserGameWallet = (options, gameCode) => {
  return useQuery(
    [queryKey.USER_GAME_WALLET, gameCode],
    () => apiGetUserGameWallet({ game: gameCode }).then((res) => res.data.data),
    {
      ...options,
    },
  );
};

export const useGetGameList = (options) => {
  return useQuery(
    [queryKey.WALLET_GAME_LIST],
    () =>
      apiGetGameList().then((res) => {
        const SINGLE_WALLET_GAMES = singleWalletEnum;
        return res.data.data.filter((item) => !SINGLE_WALLET_GAMES.includes(item.code));
      }),
    {
      ...options,
    },
  );
};

export const useTransferMoneyToGame = () => {
  return useMutation(({ params, query }) =>
    apiTransferMoneyToGame(params, query).then((res) => res.data.data),
  );
};

export const useTransferMoneyToMainWallet = () => {
  return useMutation(({ params, query }) =>
    apiTransferMoneyToMainWallet(params, query).then((res) => res.data.data),
  );
};
