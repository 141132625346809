import { LeftOutlined } from "@ant-design/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./style.module.scss";

const PCBackButton = ({ onPressBack = () => {} }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.backButton} onClick={onPressBack}>
      <LeftOutlined />
      <div>{t("normal.back")}</div>
    </div>
  );
};

export default PCBackButton;
