import { Form } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PCButton, SelectInput } from "../../../../../../components";
import { useLoading } from "../../../../../../context/LoadingContext";
import localStorageKey from "../../../../../../enumerations/localStorageKey";
// import { apiGetCountry } from "./service";
import { tip } from "../../../../../../apis/utils";
import CustomInput from "../../../../../../components/CustomInput";
import { useSignUp } from "../../../../../../hooks/SignUp/signup.hooks";
import { useGetCountry } from "../../../../../../hooks/country/country.hook";
import { useFacebookPixel } from "../../../../../../hooks/fbPixel/fbPixel.hooks";
import styles from "./style.module.scss";

const RegisterForm = ({ setCurrentActiveMenu = () => {}, setHideFooter = () => {} }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [params] = useSearchParams();
  const { isHavePixelIntegration, pixelCreateCustomTracker } = useFacebookPixel();

  const { setIsLoading } = useLoading();
  const {
    mutate: signup,
    isError: isLoginError,
    isLoading: isLoginLoading,
    isSuccess: isLoginSuccess,
  } = useSignUp();

  const { data: countryList } = useGetCountry();

  const [haveInvite, setHaveInvite] = useState(false);
  const [currency, setCurrency] = useState("");
  const [nowCurrency, setNowCurrency] = useState("");
  const [isSingleCurrency, setIsSingleCurrency] = useState(true);

  const getCountry = () => {
    const data = Object.entries(countryList);
    let country = data.map((c) => {
      return {
        ...c[1],
        value: c[1].currency,
        label:
          t(`currency.symbol.${c[1].currency}`) +
          "-" +
          c[1].currency +
          "-" +
          t(`currency.name.${c[1].currency}`),
      };
    });
    // console.log("country length", country.length);
    // console.log("country", country);
    if (country.length > 1) {
      setIsSingleCurrency(false);
    } else {
      setNowCurrency(country[0]?.currency);
      window.localStorage.setItem(localStorageKey.DEFAULT_CURRENCY, country[0]?.currency);
    }
    setCurrency(country);
  };

  const getInviteCode = () => {
    //QUERY STRING
    let inviteCode = params.get("invite");

    //LOCAL STORAGE
    if (!inviteCode) {
      inviteCode = window.localStorage.getItem(localStorageKey.INVITE_CODE);
    }
    if (inviteCode) {
      form.setFieldsValue({
        inviteCode: inviteCode,
      });
      setHaveInvite(true);
      // }
    }
  };

  useEffect(() => {
    getInviteCode();
  }, [params]);

  useEffect(() => {
    if (isHavePixelIntegration) {
      pixelCreateCustomTracker({ eventId: "registerClick" });
    }
    if (countryList) {
      getCountry();
    }
  }, [countryList]);

  useEffect(() => {
    setHideFooter(false);
  }, []);

  const onFinish = async (values) => {
    setIsLoading(true);
    const field = {
      language_id: localStorage.getItem(localStorageKey.LANG_SETTING),
      currency: localStorage.getItem(localStorageKey.DEFAULT_CURRENCY),
      account: values.account,
      password: values.password,
      confirm_password: values.confirmPassword,
      invite_code: values.inviteCode ? values.inviteCode : "",
      code: "123456",
    };
    signup(
      {
        ...field,
      },
      {
        onSuccess: (data) => {
          if (isHavePixelIntegration) {
            pixelCreateCustomTracker({ eventId: "register" });
          }
          setIsLoading(false);
          tip({
            content: t("signup.success"),
            type: "success",
          });
          setCurrentActiveMenu("login");
        },
        onError: (err) => {
          setIsLoading(false);
          // tip({ content: err, type: "error" });
        },
      },
    );
  };

  const onFinishFailed = (errorInfo) => {
    setIsLoading(false);
  };

  return (
    <div className={styles.registerFormContainer}>
      <Form
        form={form}
        name="register"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        requiredMark={false}
        layout="vertical"
      >
        <CustomInput
          type="pc"
          name="account"
          label={""}
          placeholder={t("form.pleaseEnter", { name: t("normal.account") })}
          autoComplete={"false"}
          rules={[
            {
              required: true,
              message: t("form.required", { name: t("normal.account") }),
            },
            {
              min: 6,
              max: 30,
              message: t("signup.account.validation.hint"),
            },
            {
              pattern: /^[a-zA-Z0-9\@\.]+$/,
              message: t("errorMessage.usernameFormat"),
            },
          ]}
        />
        <CustomInput
          type="pc"
          name="password"
          label={""}
          placeholder={t("form.pleaseEnter", { name: t("signup.password") })}
          password={true}
          autoComplete={"false"}
          rules={[
            {
              required: true,
              message: t("form.required", { name: t("signup.password") }),
            },
            {
              min: 6,
              max: 16,
              message: t("normal.validation.length.error", {
                name: t("signup.password"),
                min: 6,
                max: 16,
              }),
            },
          ]}
        />
        <CustomInput
          type="pc"
          name="confirmPassword"
          dependencies={["password"]}
          label={""}
          placeholder={t("form.pleaseEnter", { name: t("signup.confirmPassword") })}
          password={true}
          autoComplete={"false"}
          rules={[
            {
              required: true,
              message: t("form.required", { name: t("signup.confirmPassword") }),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error(t("signup.confirmPasswordError")));
              },
            }),
          ]}
        />
        {isSingleCurrency ? (
          ""
        ) : (
          <SelectInput
            name={"currency"}
            placeholder={t("form.pleaseEnter", { name: t("signup.pickCurrency") })}
            options={currency}
            rules={[
              {
                required: true,
                message: t("form.required", {
                  name: t("signup.pickCurrency"),
                }),
              },
            ]}
          />
        )}
        <CustomInput
          type="pc"
          name="inviteCode"
          readOnly={haveInvite}
          label={""}
          placeholder={t("form.pleaseEnter", { name: t("signup.inviteCode") })}
          rules={[
            {
              min: 5,
              max: 8,
              message: t("normal.validation.length.error", {
                name: t("signup.inviteCode"),
                min: 5,
                max: 8,
              }),
            },
          ]}
        />
        <PCButton block size="large" type="primary" htmlType="submit">
          {t("normal.register")}
        </PCButton>
      </Form>
    </div>
  );
};

export default RegisterForm;
