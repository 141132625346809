import { useQuery } from "@tanstack/react-query";
import queryKey from "../../../../enumerations/queryKey";
import { getWinCategory } from "../service";

export const useGetGameCategory = ({ params, options }) => {
  Object.keys(params).forEach((key) => {
    if (params[key] === null || params[key] === undefined || params[key] === "") {
      delete params[key];
    }
  });
  const _arrParams = Object.keys(params).map((key) => params[key]);
  return useQuery(
    [queryKey.WIN_GAME_CATEGORY, ..._arrParams],
    () =>
      getWinCategory().then((res) => {
        const filter = params.game_category_detail_type;
        const _result = res.data.data;
        const categories = _result
          .filter((item) => item.game_category_detail_type === filter)
          .map((item) => {
            return [
              ...item.game_list.map((_game) => {
                return {
                  game_category_type: item.game_category_type,
                  ..._game,
                  key: _game.game_type,
                  name: `win.wingame.gameCategory.title.${_game.game_type}`,
                  translate: true,
                };
              }),
            ];
          });
        return categories[0] || [];
      }),
    {
      ...options,
    },
  );
};
