import { useTranslation } from "react-i18next";
import { Col } from "antd";
import styles from "../style.module.scss";
const CenterGroup = ({ title, titleEllipsis }) => {
  const { t } = useTranslation();
  return (
    <Col span={16}>
      <div className={`${styles.navBarTitleBox}`}>
        <span
          className={`${styles.navBarTitle} ${
            titleEllipsis ? styles.navBarTitleEllipsis : ""
          }`}
        >
          {title ? t(title) : ""}
        </span>
      </div>
    </Col>
  );
};

export default CenterGroup;
