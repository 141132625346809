import React from "react";

function Arrowright({ color = "#0076EB", size = 12 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.45503 9.95998L7.71503 6.69998C8.10003 6.31498 8.10003 5.68498 7.71503 5.29998L4.45503 2.03998"
        stroke={color}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default Arrowright;
