import React, { useEffect, useLayoutEffect, useState } from "react";

import ground from "./images/ground.png";

function RocketBackground({
  isAnimated = false,
  isReset = false,
  startPosition,
  endPosition,
  groundStartPosition,
  groundEndPosition,
  currentGameState = {},
}) {
  const [bgExtraStyle, setBgExtraStyle] = useState({});
  const [groundExtraStyle, setGroundExtraStyle] = useState({});

  useLayoutEffect(() => {
    // console.log("isAnimated, startPosition, endPosition", isAnimated, startPosition, endPosition);
    if (isAnimated === true && !!startPosition && endPosition !== undefined) {
      const seconds = (startPosition - endPosition) / 10;
      setTimeout(() => {
        setBgExtraStyle({
          backgroundPosition: `0% ${endPosition}%`,
          transition: `${seconds - 1}s linear`,
        });
        setGroundExtraStyle({
          transition: `1s`,
          transform: `translateY(${groundEndPosition}%)`,
        });
      }, 1000);
    }
  }, [isAnimated, startPosition, endPosition, groundEndPosition]);

  useEffect(() => {
    if (isReset) {
      setBgExtraStyle({
        backgroundPosition: `0% 100%`,
      });
      setGroundExtraStyle({
        transform: `translateY(0%)`,
      });
    }
  }, [isReset]);

  return (
    <div style={{ position: "relative", height: "100%", overflow: "hidden" }}>
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundImage: 'url("/images/game/launchGame/background.png")',
          backgroundSize: "100% auto",
          backgroundPosition: `0% ${startPosition}%`,
          backgroundRepeat: "no-repeat",
          ...bgExtraStyle,
        }}
      />
      <img
        src={ground}
        alt=""
        style={{
          display: `${currentGameState.platformVisible ? "block" : "none"}`,
          zIndex: "100",
          position: "absolute",
          bottom: 0,
          width: "100%",
          transform: `translateY(${groundStartPosition}%)`,
          ...groundExtraStyle,
        }}
      />
    </div>
  );
}

export default RocketBackground;
