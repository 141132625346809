import { LeftOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { Grid, Popup } from "antd-mobile";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { GameItem } from "..";
import { NoData } from "../..";
import { useLazyLoad } from "../../../hooks/lazyLoad/lazyLoad.hooks";
import LangHeader from "../../../pages/mobile/Home/components/LangHeader";
import { CustomSearchBar } from "./components";
import styles from "./style.module.scss";
const SearchPopup = ({
  visible = false,
  onClose = () => {},
  game3thCode = "search_all",
  checkIfLogin = () => {},
  setSearchValue = () => {},
  searchResult = undefined,
  searchError = false,
  hasNextPage = false,
  fetchNextPage = () => {},
  isFetchingNextPage = false,
}) => {
  const { t } = useTranslation();
  const observer = useRef(null);

  const lazyLoad = useLazyLoad({
    triggerRef: observer,
    hasNextPage: hasNextPage,
    fetchNextPage: fetchNextPage,
    isFetching: isFetchingNextPage,
  });

  const renderSearchItem = () => {
    if (!searchResult || searchError || searchResult?.pages[0]?.data.length <= 0)
      return <NoData title={t("normal.noData")} padding="0" height="calc(100vh - 128px)" />;
    return (
      <>
        <Grid columns={4} gap={8}>
          {searchResult?.pages?.map((perPageData, pageIndex) => {
            return perPageData.data.map((item, index) => {
              return (
                <Grid.Item key={`${item.game_code}-${item.game_id}`}>
                  <GameItem
                    game={item}
                    checkIfLogin={checkIfLogin}
                    gameNameStyle={styles.gameNameStyle}
                  />
                </Grid.Item>
              );
            });
          })}
        </Grid>
        <div
          ref={observer}
          className={styles.loadingContainer}
          style={{ display: hasNextPage ? "flex" : "none" }}
        >
          <Spin />
        </div>
      </>
    );
  };

  const onSearchClose = () => {
    setSearchValue("");
    onClose();
  };

  return (
    <Popup
      destroyOnClose={true}
      visible={visible}
      bodyStyle={{ width: "100vw", backgroundColor: "transparent" }}
      position={"right"}
      mask={false}
    >
      <div className={styles.searchContainer}>
        <LangHeader showLanguageSelector={false} />
        <div className={`${styles.titleBar}`}>
          <div className={styles.backButton} onClick={onSearchClose}>
            <LeftOutlined size={16} />
            <div className={`${styles.gameName}`}>
              {game3thCode === "search_all"
                ? t("home.search.placeholder")
                : t(`games.fullName.${game3thCode}`)}
            </div>
          </div>
        </div>
        <CustomSearchBar setSearchValue={setSearchValue} />
        <div className={styles.searchResultContainer}>{renderSearchItem()}</div>
      </div>
    </Popup>
  );
};

export default SearchPopup;
