import { Radio } from "antd";
import React from "react";
import "./style.scss";

const CheckBoxSelector = ({
  options = [],
  selectedOption,
  onChange = () => {},
  disableText = "",
  disableField = "",
  disableValue = 1,
}) => {
  const renderOption = (option) => {
    const isDisabled = disableField !== "" && option[disableField] === disableValue;
    return (
      <Radio
        className="radio-container"
        value={option.value}
        key={option.value}
        disabled={isDisabled}
      >
        <div className="radio-title">{`${option.label} ${isDisabled ? disableText : ""}`}</div>
        {option.subtitle ? (
          <div className="radio-subtitle">{option.subtitle}</div>
        ) : null}
      </Radio>
    );
  };

  return (
    <div className="checkbox_container">
      <Radio.Group
        // options={options}
        value={selectedOption}
        onChange={onChange}
      >
        {options.map((option) => {
          return renderOption(option);
        })}
      </Radio.Group>
    </div>
  );
};

export default CheckBoxSelector;
