import { useRef } from "react";
import Countdown from "react-countdown";
import { useTranslation } from "react-i18next";
import { useGameWebsocket } from "../../../../../context/WebsocketGameContext";
import gameStateEnum from "../../enumeration/gameStateEnum";
import ballImageEnum from "../BallCollection/enumerations/ballImageEnum";
import PanelImage from "./images/background.png";
import styles from "./style.module.scss";

const ResultViewer = ({ setCurrentGameState = () => {}, resultTimer = 4, latestNumber }) => {
  const { t } = useTranslation();
  const { prevPeriod } = useGameWebsocket();
  const currentDate = useRef(Date.now());
  const TIMER = resultTimer * 1000; //4seconds

  const getLotteryNumber = (_prevPeriod, _latestNumber) => {
    if (_prevPeriod && _prevPeriod.lottery_number) {
      return parseInt(_prevPeriod.lottery_number);
    }
    if (_latestNumber) {
      return parseInt(_latestNumber[1]?.lottery_number);
    }
    return 0;
  };

  return (
    <div className={`${styles.container}`}>
      <div className={styles.subContainer}>
        <img className={styles.image} src={PanelImage} alt="" />
        <div className={styles.textContainer}>
          <div className={styles.title}>{t("win.federalGame.result.title")}</div>
          <img
            src={ballImageEnum.result[getLotteryNumber(prevPeriod, latestNumber)]}
            alt={""}
            className={styles.ball}
          />
          <Countdown
            date={currentDate.current + TIMER}
            intervalDelay={0}
            precision={2}
            onComplete={() => {
              setCurrentGameState(gameStateEnum.BET_PHASE);
            }}
            renderer={(props) => {
              return (
                <>
                  <div className={styles.timer}>{`${(props.seconds + 100)
                    .toString()
                    .substring(1, 3)}:${(props.milliseconds + 10000)
                    .toString()
                    .substring(1, 3)}`}</div>
                </>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ResultViewer;
