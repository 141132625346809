import { SyncOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { WIN_RECORD_DELAY_TIMER } from "../../config";
import { useDevice } from "../../context/DeviceContext";
import { useGameWebsocket } from "../../context/WebsocketGameContext";
import localStorageKey from "../../enumerations/localStorageKey";
import { useGetUserWallet } from "../../hooks/wallet/wallet.hooks";
import { moneyFormat } from "../../plugins/numberFormat";
import Countdown from "../Countdown";
import styles from "./style.module.scss";

const CURRENCY = localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);

const WinGameHeader = ({
  onHandleDepositOpenCallback = () => {},
  setRuleVisible = () => {},
  setWalletAmount = () => {},
  setCurrentGameState = () => {},
  currentGameState,
  selectedGameList,
  latestNumber = undefined,
  refreshData = () => {},
  latestNumberRefetching = false,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useDevice();
  const { currentPeriod } = useGameWebsocket();
  const [localPeriod, setLocalPeriod] = useState("-");

  const navigate = useNavigate();
  const { error: walletError, data: walletData, refetch, isFetching } = useGetUserWallet({});

  const onHandleDepositOpen = () => {
    if (isMobile) {
      navigate("/exchange", {
        state: {
          tabIndex: "deposit",
          backURL: "/lotwin",
        },
      });
    } else {
      navigate("/account?tab=deposit");
    }

    onHandleDepositOpenCallback();
  };

  const onHandleRuleOpen = () => {
    setRuleVisible(true);
  };

  const handleReloadBalance = () => {
    refetch();
  };

  // useEffect(() => {
  //   if (refetchData === true) {
  //     setTimeout(() => {
  //       handleReloadBalance();
  //     }, [2000]);
  //   }
  // }, [refetchData]);

  useEffect(() => {
    if (walletData) {
      setWalletAmount(walletData.money);
    }
  }, [walletData]);

  useEffect(() => {
    setLocalPeriod(currentPeriod?.period || (latestNumber && latestNumber[0]?.period) || "-");
  }, [currentPeriod.period, latestNumber]);

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <div className={styles.balanceContainer}>
          <div className={styles.balanceTitle}>{t("win.normal.mybalance")}</div>
          <div className={styles.balanceInnerContainer}>
            <div className={styles.balance}>{`${t(
              `currency.symbol.${CURRENCY.toUpperCase()}`,
            )} ${moneyFormat(walletData?.money)}`}</div>
            <div className={styles.reload} onClick={handleReloadBalance} spin={isFetching}>
              <SyncOutlined className={styles.icon} spin={isFetching} />
            </div>
          </div>
        </div>
        <div className={styles.periodTitle}>{t("win.normal.period")}</div>
        <div className={styles.period}>{localPeriod}</div>
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.buttonContainer}>
          <div className={styles.buttonDeposit} onClick={onHandleDepositOpen}>
            {t("normal.deposit")}
          </div>
          <div className={styles.buttonRule} onClick={onHandleRuleOpen}>
            {t("win.normal.rule")}
          </div>
        </div>
        <Countdown
          setCurrentGameState={setCurrentGameState}
          currentGameState={currentGameState}
          winColorCountdown={styles.winColorCountdown}
          winColorCountdownNumbers={styles.countdownNumbers}
          winColorCountdownBox={styles.countdownBox}
          winColorPunctuation={styles.punctuation}
          selectedGameList={selectedGameList}
          refreshData={refreshData}
          latestNumber={latestNumber}
          latestNumberRefetching={latestNumberRefetching}
          recordTimer={WIN_RECORD_DELAY_TIMER}
        />
      </div>
    </div>
  );
};

export default WinGameHeader;
