import styles from "./style.module.scss";

const HeaderButton = ({
  icon = false,
  text = "",
  containerStyle = "",
  textStyle = "",
  iconStyle = "",
  onClick = () => {},
  children,
}) => {
  return (
    <div onClick={onClick} className={`${styles.buttonContainer} ${containerStyle}`}>
      {icon !== false ? (
        <img className={`${styles.buttonIcon} ${iconStyle}`} src={icon} alt={text} />
      ) : null}
      <div className={`${styles.buttonText} ${textStyle}`}>{text}</div>
      {children}
    </div>
  );
};

export default HeaderButton;
