import { moneyFormat } from "../../../../../plugins/numberFormat";
import { chart_record } from "../../enumeration/chartRecordEnum";
import { useGetKLineHistory } from "../KLineChart/hooks/kline.hooks";
import styles from "./style.module.scss";

const LastPriceViewer = ({ ticker = "BTCUSDT" }) => {
  const { data } = useGetKLineHistory({
    params: {
      symbol: ticker,
      limit: 30,
      interval: "3m",
    },
    options: {},
  });

  const getLastPrice = () => {
    if (!data) return;
    const timeList = Object.keys(data);
    const lastKLineData = data[timeList[timeList.length - 1]];

    return moneyFormat(lastKLineData[chart_record.CLOSE_PRICE]);
  };

  return <div className={styles.container}>{getLastPrice()}</div>;
};

export default LastPriceViewer;
