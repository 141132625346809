import Violet_0 from "../image/0_violet.png";
import Yellow_0 from "../image/0_yellow.png";

import Green_1 from "../image/1_green.png";
import Yellow_1 from "../image/1_yellow.png";

import Red_2 from "../image/2_red.png";
import Yellow_2 from "../image/2_yellow.png";

import Green_3 from "../image/3_green.png";
import Yellow_3 from "../image/3_yellow.png";

import Red_4 from "../image/4_red.png";
import Yellow_4 from "../image/4_yellow.png";

import Violet_5 from "../image/5_violet.png";
import Yellow_5 from "../image/5_yellow.png";

import Red_6 from "../image/6_red.png";
import Yellow_6 from "../image/6_yellow.png";

import Green_7 from "../image/7_green.png";
import Yellow_7 from "../image/7_yellow.png";

import Red_8 from "../image/8_red.png";
import Yellow_8 from "../image/8_yellow.png";

import Green_9 from "../image/9_green.png";
import Yellow_9 from "../image/9_yellow.png";

const ballImageEnum = {
  rand: [
    Yellow_0,
    Yellow_1,
    Yellow_2,
    Yellow_3,
    Yellow_4,
    Yellow_5,
    Yellow_6,
    Yellow_7,
    Yellow_8,
    Yellow_9,
  ],
  result: [Violet_0, Green_1, Red_2, Green_3, Red_4, Violet_5, Red_6, Green_7, Red_8, Green_9],
};

export default ballImageEnum;
