import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Element } from "react-scroll";
import localStorageKey from "../../enumerations/localStorageKey";
import { useGetUserInfo } from "../../hooks/personal/personal.hook";
import styles from "./style.pc.module.scss";

import { Layout } from "antd";
import { PCCSButton } from "../../components";
import { useBackground } from "../../context/BackgroundContext";
import { FooterComponent, HeaderComponent, SiderComponent } from "../components/PC";

const { Header, Sider, Content, Footer } = Layout;

const PCLayout = ({ navType, children }) => {
  const [params] = useSearchParams();
  const location = useLocation();
  const [siderCollapsed, setSiderCollapsed] = useState(false);
  const [prevPath, setPrevPath] = useState();
  const [hidden, setHidden] = useState(false);
  const { maxBodyWidth } = useBackground();

  const dontGetUserPages = [
    "/signin",
    "/signup",
    "/",
    "/emailVerify",
    "/forgotPassword",
    "/accessDenied",
    "/inMaintenance",
    "/download",
  ];
  const dontReloadPages = [
    "/signin",
    "/signup",
    "/",
    "/emailVerify",
    "/forgotPassword",
    "/accessDenied",
    "/inMaintenance",
    "/download",
  ];
  const HIDE_LAYOUTS_PATH = ["/inMaintenance", "/accessDenied", "/connectionFailed"];

  const { data } = useGetUserInfo({
    enabled: !dontGetUserPages.includes(location.pathname),
  });
  let navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/sport" || HIDE_LAYOUTS_PATH.includes(location.pathname)) {
      setSiderCollapsed(true);
    } else {
      if (prevPath === "/sport") setSiderCollapsed(false);
    }

    if (HIDE_LAYOUTS_PATH.includes(location.pathname)) {
      setHidden(true);
    } else {
      setHidden(false);
    }

    setPrevPath(location.pathname);
  }, [location, prevPath]);

  const renderContent = useCallback(() => {
    return (
      <Content className={styles.contentContainer}>
        <Element className={styles.container} id="containerElement">
          <div className={styles.pageContainer} style={{ maxWidth: maxBodyWidth }}>
            {children}
          </div>
        </Element>
        <FooterComponent hidden={hidden} />
        <PCCSButton />
      </Content>
    );
  }, [maxBodyWidth]);

  useEffect(() => {
    if (data) {
      if (
        data.email_status == 0 &&
        !dontReloadPages.includes(location.pathname) &&
        data.mobile_status == 0
      ) {
        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
      }
    }
  }, [data, location.pathname]);

  useEffect(() => {
    if (params.get("app")) {
      window.localStorage.setItem(localStorageKey.IS_APP, true);
    }
  }, [params]);

  return (
    // <BackgroundContextProvider>
    <div
      className={styles.main}
      style={{
        overflow: "hidden",
        maxWidth: "1920px",
        height: navType.doNotInitWindowHeight ? "" : "calc(var(--vh, 1vh) * 100)", //vh * 100,
        margin: "0 auto",
      }}
    >
      <Layout className={styles.layout}>
        <Header className={`${styles.headerContainer} ${hidden ? styles.hide : ""}`}>
          <HeaderComponent siderCollapsed={siderCollapsed} setSiderCollapsed={setSiderCollapsed} />
        </Header>
        <Layout className={styles.layout}>
          <Sider
            width={240}
            trigger={null}
            collapsible
            collapsed={siderCollapsed}
            className={styles.siderContainer}
            collapsedWidth={0}
          >
            <SiderComponent />
          </Sider>
          <Layout className={`${styles.layout} ${styles.content}`}>{renderContent()}</Layout>
        </Layout>
      </Layout>
    </div>
    // </BackgroundContextProvider>
  );
};
export default PCLayout;
