import { RightOutlined } from "@ant-design/icons";
import { Image } from "antd";
import React from "react";
import styles from "./style.module.scss";
import "./style.scss";

const EventItem = ({
  imgUrl = "",
  startTime = "",
  endTime = "",
  onClick = () => {},
  title,
  pc = false,
}) => {
  return (
    <div onClick={onClick} className={`${styles.container} ${pc ? styles.pc : ""}`}>
      <Image
        wrapperStyle={{
          flex: 1,
        }}
        className="event-item-img"
        width={"100%"}
        style={{
          flex: 1,
          height: `${startTime !== "" && endTime !== "" ? "142px" : "100%"}`,
          borderTopRightRadius: "8px",
          borderTopLeftRadius: "8px",
          borderBottomLeftRadius: `${startTime !== "" && endTime !== "" ? "0" : "8px"}`,
          borderBottomRightRadius: `${startTime !== "" && endTime !== "" ? "0" : "8px"}`,
          // maxHeight: "97px",
          objectFit: "cover",
          objectPosition: "top",
        }}
        src={imgUrl}
        preview={false}
      />
      <div className={styles.dateContainer}>
        <div className={styles.textContainer}>
          {title ? <div className={styles.title}>{`${title}`}</div> : null}
          <div className={styles.date}>
            {startTime !== "" && endTime !== "" ? `${startTime}~${endTime}` : null}
          </div>
        </div>
        {pc ? null : <RightOutlined style={{ color: "var(--color-radio-inactive)" }} />}
      </div>
    </div>
  );
};

export default EventItem;
