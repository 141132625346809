import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { copyText } from "../../../../../plugins/utils";

import { VIPAvatar } from "../../../../../components/VIP";
import styles from "./style.module.scss";

const AccountArea = ({
  userInfo,
  isLoading,
  customContainerStyle = "",
  customAvatarStyle = "",
  customAccountTextStyle = "",
  customCopyStyle = "",
}) => {
  const { t } = useTranslation();
  const [userIconId, setUserIconId] = useState();
  const findAvatar = (userId) => {
    const iconId = parseInt(userId) % 20;
    setUserIconId(iconId + 1);
  };

  const handleCopy = (uid) => {
    copyText(uid, t("normal.copy.complete"));
  };

  useEffect(() => {
    if (userInfo?.account_id) {
      findAvatar(userInfo.account_id);
    }
  }, [userInfo]);

  return (
    <div className={`${styles.pcAccountArea} ${customContainerStyle}`}>
      <VIPAvatar customContainerStyle={customAvatarStyle} />
      <div className={styles.accountBox}>
        <div className={`${styles.account} ${customAccountTextStyle}`}>{userInfo?.account}</div>
        <div className={styles.uidContainer}>
          <div className={`${styles.uid}`}>{`ID: ${userInfo?.account_id}`}</div>
          <div
            className={`${styles.copyIcon} ${customCopyStyle}`}
            onClick={() => handleCopy(userInfo?.account_id)}
          >
            {t("normal.copy")}
            <div id="copy-group" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountArea;
