import { PlusOutlined } from "@ant-design/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { PRODUCTION_NAME_DEV } from "../../../../../config";
import localStorageKey from "../../../../../enumerations/localStorageKey";
import { useGetUserWallet } from "../../../../../hooks/wallet/wallet.hooks";
import { moneyFormat } from "../../../../../plugins/numberFormat";
import styles from "./style.module.scss";

function BalanceHeader({ isLogin, checkIfLogin, style = {} }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const CURRENCY = localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);

  const { error: walletError, data: walletData } = useGetUserWallet({
    enabled: !!isLogin,
  });

  const toLogin = () => {
    navigate("/signin");
  };
  const onHandleDepositOpen = () => {
    if (!checkIfLogin()) return;
    navigate("/exchange", {
      state: {
        tabIndex: "deposit",
        backURL: "/",
      },
    });
  };
  return (
    <div className={`${styles.container}`} style={{ ...style }}>
      <div className={`${styles.logoArea}`}>
        <img src={`/images/logo/${PRODUCTION_NAME_DEV}/balance_header_logo.png`} alt="logo" />
      </div>
      <div className={`${styles.rightArea}`}>
        {isLogin ? (
          <div className={styles.balanceArea}>
            <span className={styles.moneySymbol}>
              {t(`currency.symbol.${CURRENCY.toUpperCase()}`)}
            </span>
            <div className={`${styles.moneyGroup}`}>
              <span className={`${styles.money}`}>
                {walletData?.money ? moneyFormat(walletData.money, 2, 2).split(".")[0] : "0"}
              </span>
              <span className={`${styles.moneyFraction}`}>
                .{walletData?.money ? moneyFormat(walletData.money, 2, 2).split(".")[1] : "00"}
              </span>
            </div>
            <div className={styles.depositButton} onClick={onHandleDepositOpen}>
              <PlusOutlined style={{ color: "#fff" }} />
            </div>
          </div>
        ) : (
          <span className={`${styles.loginText}`} onClick={toLogin}>
            {t("normal.login")}
          </span>
        )}
      </div>
    </div>
  );
}

export default BalanceHeader;
