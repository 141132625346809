import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomInput from "../CustomInput/index.jsx";

import { useGetUserForgetSendVerify, usePostEmailSend } from "./hooks/bind.hook";
const COOLDOWN_SEC = 60;
let cooldownNumber;
let cooldownInterval;
function PCEmailForm({ isForgetPassword, account, formRef, isHideLabel = false }) {
  const { mutate: emailSend } = usePostEmailSend();
  const { mutate: forgetEmailSend } = useGetUserForgetSendVerify();
  const [cooldown, setCooldown] = useState();
  const { t } = useTranslation();
  const initCoolDown = () => {
    setCooldown(COOLDOWN_SEC);
    cooldownNumber = COOLDOWN_SEC;
    cooldownInterval = setInterval(() => {
      setCooldown(cooldownNumber - 1);
      cooldownNumber = cooldownNumber - 1;
      if (cooldownNumber <= 0) {
        setCooldown(undefined);
        cooldownNumber = undefined;
        clearInterval(cooldownInterval);
      }
    }, 1000);
  };

  const getCode = async () => {
    try {
      await formRef.validateFields(["email"]);
      const params = { email: formRef.getFieldValue("email") };
      if (isForgetPassword) {
        forgetEmailSend({
          ...params,
          type: "email",
          account,
        });
      } else {
        emailSend(params);
      }
    } catch (err) {}
  };

  const renderEmailInput = () => {
    if (isForgetPassword === true) return null;
    return (
      <CustomInput
        type="pc"
        name="email"
        label={isHideLabel ? "" : t("normal.email")}
        placeholder={
          isHideLabel
            ? t("form.pleaseEnter", { name: t("normal.email") })
            : t("form.holder.pleaseEnter")
        }
        rules={[
          {
            type: "email",
            message: t("signup.emailFormatError"),
          },
          {
            required: true,
            message: t("form.required", { name: t("normal.email") }),
          },
        ]}
      />
    );
  };

  const renderForgetPasswordForm = () => {
    if (isForgetPassword === false) return null;
    return (
      <>
        <CustomInput
          type="pc"
          name="new_password"
          label={isHideLabel ? "" : t("signup.newPassword")}
          placeholder={
            isHideLabel
              ? t("form.pleaseEnter", { name: t("signup.newPassword") })
              : t("form.holder.pleaseEnter")
          }
          password={true}
          autoComplete={"false"}
          rules={[
            {
              required: true,
              message: t("form.required", { name: t("signup.newPassword") }),
            },
            {
              min: 6,
              max: 16,
              message: t("normal.validation.length.error", {
                name: t("signup.password"),
                min: 6,
                max: 16,
              }),
            },
          ]}
        />
        <CustomInput
          type="pc"
          name="confirm_new_password"
          label={isHideLabel ? "" : t("signup.confirmNewPassword")}
          dependencies={["password"]}
          placeholder={
            isHideLabel
              ? t("form.pleaseEnter", { name: t("signup.confirmNewPassword") })
              : t("form.holder.pleaseEnter")
          }
          password={true}
          rules={[
            {
              required: true,
              message: t("form.required", {
                name: t("signup.confirmNewPassword"),
              }),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("new_password") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error(t("signup.confirmPasswordError")));
              },
            }),
          ]}
        />
      </>
    );
  };

  return (
    <>
      {renderEmailInput()}
      <CustomInput
        type="pc"
        name="code"
        label={isHideLabel ? "" : t("forgot.verifyCode")}
        placeholder={
          isHideLabel
            ? t("form.pleaseEnter", { name: t("forgot.verifyCode") })
            : t("form.holder.pleaseEnter")
        }
        addonAfter={
          <div
            style={{
              color: cooldown === undefined ? "var(--button-link-base)" : "var(--text-p1)",
              fontWeight: 500,
              cursor: "pointer",
            }}
            onClick={async () => {
              // await formRef.validateFields(["email"]);
              initCoolDown();
              if (cooldown === undefined) {
                getCode();
              }
            }}
          >
            {cooldown === undefined
              ? t("forgot.sendCode")
              : t("forgotPassword.cooldown", { sec: cooldown })}
          </div>
        }
        rules={[
          {
            required: true,
            message: t("form.required", {
              name: t("forgot.verifyCode"),
            }),
          },
        ]}
      />
      {renderForgetPasswordForm()}
    </>
  );
}

export default PCEmailForm;
