import { RightOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useGetCustomerServiceNoCurrency } from "../../../../../../../../hooks/customerService/customerService.hook";
import { checkUrlReg } from "../../../../../../../../plugins/utils";
import styles from "./style.module.scss";

const CSCurrencyPicker = ({ onOpenCS = () => {}, setHideFooter = () => {} }) => {
  const { t } = useTranslation();
  const { data: csCurrencyList } = useGetCustomerServiceNoCurrency({});

  const handleOpenCS = (csLink) => {
    checkUrlReg(csLink, window.open(csLink));
    onOpenCS();
  };

  useEffect(() => {
    setHideFooter(true);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.title}>{t("pc.cs.chooseLanguage")}</div>
      {csCurrencyList?.data.map((cs, index) => {
        return (
          <div
            className={styles.cardItem}
            key={`${cs?.id}_${cs?.name}_${index}`}
            onClick={() => {
              handleOpenCS(cs.customer_service_link);
            }}
          >
            <div className={styles.cardTitle}>{cs?.name}</div>
            <RightOutlined className={styles.arrow} />
          </div>
        );
      })}
    </div>
  );
};

export default CSCurrencyPicker;
