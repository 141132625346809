import React from "react";
import Spritesheet from "react-responsive-spritesheet";
import RocketImage from "./images/rocket.png";
import styles from "./style.module.scss";

const Rocket = ({ loop = true, endFrame = 14, visible = true }, ref) => {
  return (
    <div className={`${styles.container} ${visible === false ? styles.hidden : ""}`}>
      <Spritesheet
        ref={ref}
        image={RocketImage}
        widthFrame={500}
        heightFrame={500}
        steps={23}
        fps={20}
        loop={true}
        startAt={0}
        endAt={14}
        onLoopComplete={(spritesheet) => {
          if (loop === false) {
            spritesheet.goToAndPause(endFrame);
          }
        }}
      />
    </div>
  );
};

const forwaredRef = React.forwardRef(Rocket);

export default forwaredRef;
