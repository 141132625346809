import { useQuery } from "@tanstack/react-query";
import queryKey from "../../../../../../enumerations/queryKey";
import { chart_record } from "../../../enumeration/chartRecordEnum";
import { getKLINEHistory } from "../service";

// 1499040000000,      // Kline open time
// "0.01634790",       // Open price
// "0.80000000",       // High price
// "0.01575800",       // Low price
// "0.01577100",       // Close price
// "148976.11427815",  // Volume
// 1499644799999,      // Kline Close time
// "2434.19055334",    // Quote asset volume
// 308,                // Number of trades
// "1756.87402397",    // Taker buy base asset volume
// "28.46694368",      // Taker buy quote asset volume
// "0"                 // Unused field, ignore.

const MAPPING = {
  OPEN_TIME: 0,
  OPEN_PRICE: 1,
  HIGH_PRICE: 2,
  LOW_PRICE: 3,
  CLOSE_PRICE: 4,
  CLOSE__TIME: 6,
};

const processBinanceAPIData = (rawData) => {
  let obj = {};
  if (rawData) {
    rawData.forEach((_data) => {
      obj = {
        ...obj,
        [_data[MAPPING.OPEN_TIME]]: {
          [chart_record.OPEN_PRICE]: _data[MAPPING.OPEN_PRICE],
          [chart_record.CLOSE_PRICE]: _data[MAPPING.CLOSE_PRICE],
          [chart_record.LOW_PRICE]: _data[MAPPING.LOW_PRICE],
          [chart_record.HIGH_PRICE]: _data[MAPPING.HIGH_PRICE],
        },
      };
    });
  }
  return obj;
};

export const useGetKLineHistory = ({ params, options }) => {
  return useQuery(
    [queryKey.WIN_BINANCE_KLINE, params.symbol],
    () =>
      getKLINEHistory(params).then((res) => {
        const process = processBinanceAPIData(res.data);
        return process;
      }),
    {
      ...options,
    },
  );
};
