import React from "react";
import "./style.scss";
import { Radio } from "antd";

function ButtonGroup({
  chosenVal,
  setChosenVal,
  options,
  amountInOneRow = "one",
  buttonOnChange = () => {},
}) {
  const classDic = {
    one: "",
    two: "two-in-one-row",
    three: "three-in-one-row",
  };
  return (
    <div className="custom-button-group">
      <Radio.Group
        value={chosenVal}
        buttonStyle="solid"
        options={options}
        optionType="button"
        onChange={(e) => {
          setChosenVal(e.target.value);
          buttonOnChange(e);
        }}
        className={classDic[amountInOneRow]}
      />
    </div>
  );
}

export default ButtonGroup;
