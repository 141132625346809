import { ConfigProvider, Empty, Spin, Table } from "antd";
import { DotLoading, InfiniteScroll } from "antd-mobile";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./style.module.scss";
import "./style.scss";

const PCCustomTable = ({
  minHeight = "30px",
  maxHeight = "70vh",
  isLoading = false,
  scrollX = 1200,
  ...props
}) => {
  const { t } = useTranslation();
  //! must set every column width when you define columns

  const InfiniteScrollContent = ({ hasMore }) => {
    return (
      <>
        {hasMore ? (
          <>
            <DotLoading />
          </>
        ) : (
          <span>{t("normal.infiniteScroll.bottom")}</span>
        )}
      </>
    );
  };

  return (
    <Spin spinning={isLoading} wrapperClassName={"pc-custom-table-spin"}>
      <ConfigProvider
        renderEmpty={() => (
          <Empty
            description={<div className={styles.emptyDesc}>{t("normal.noInformation")}</div>}
            imageStyle={{ display: "none" }}
          />
        )}
      >
        <div className="pc-table-container" style={{ minHeight: minHeight, maxHeight: maxHeight }}>
          <Table
            pagination={false}
            sticky={{ offsetHeader: 0 }}
            scroll={{
              x: scrollX,
            }}
            size={"small"}
            {...props}
          />
          {props.showInifineScroll ? (
            <InfiniteScroll threshold={20} loadMore={props.loadMore} hasMore={props.hasMore}>
              <InfiniteScrollContent hasMore={props.hasMore} />
            </InfiniteScroll>
          ) : (
            <></>
          )}
        </div>
      </ConfigProvider>
    </Spin>
  );
};

export default PCCustomTable;
