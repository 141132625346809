import { useQuery } from "@tanstack/react-query";
import queryKey from "../../../enumerations/queryKey";
import { apiGetAllLangList, apiGetLangListByCurrency } from "../service";

export const useGetAllLangList = (options) => {
  return useQuery(
    [queryKey.ALL_LANG_LIST],
    () => apiGetAllLangList().then((res) => res.data.data),
    {
      ...options,
    },
  );
};
export const useGetLangListByCurrency = (options, params = {}) => {
  return useQuery(
    [queryKey.LANG_LIST_BY_CURRENCY],
    () => apiGetLangListByCurrency(params).then((res) => res.data.data),
    {
      ...options,
    },
  );
};
