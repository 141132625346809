import { Suspense, useContext, useEffect, useState } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { LoadingView } from "../../components";
import {
  DeviceContextProvider,
  GameLoginContextProvider,
  LoginContextProvider,
  RecentPlayGamesContextProvider,
  SearchContextProvider,
} from "../../context";
import { BackgroundContextProvider } from "../../context/BackgroundContext";
import { CategoryContextProvider } from "../../context/CategoryContext";
import { DailyCheckContextProvider } from "../../context/DailyCheckContext";
import { SerialNumberContextProvider } from "../../context/SerialNumberContext";
import { WheelContextProvider } from "../../context/WheelContext";
import layouts from "../layouts";
import RouterContext from "./RouterContext";
import RouterList from "./router";

const findRouteConfig = (currentPath = "") => {
  let target = RouterList.find((route) => {
    return route.path === currentPath ?? window.location.pathname;
  });
  if (!target) return "default";

  return !!target ? target : {}; //background
};

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

const RouterRender = ({ router, match, showTransform }) => {
  let { location } = router;
  const { changeLayout, changeNavType } = useContext(RouterContext);

  useEffect(() => {
    // console.log(location);
    changeLayout(findRouteConfig(location.pathname)?.layout);
    changeNavType(findRouteConfig(location.pathname)?.navType);
  }, [location]);

  return (
    <TransitionGroup>
      <CSSTransition classNames={"fade"} in={match != null} timeout={0} key={location?.key} appear>
        <Suspense fallback={<LoadingView />}>
          <Routes>
            {RouterList.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={<route.component {...route.props} />}
                />
              );
            })}
          </Routes>
        </Suspense>
      </CSSTransition>
    </TransitionGroup>
  );
};

const RouterRenderWithRouter = withRouter(RouterRender);

const ProcessRouter = () => {
  const [layout, setLayout] = useState(findRouteConfig()?.layout ?? "pc");
  const [navType, setNavType] = useState("");
  const actions = {
    changeLayout: (layout = "pc") => {
      setLayout(layout ?? "pc");
    },
    changeNavType: (navType = {}) => {
      setNavType(navType ?? {});
    },
  };

  var LayoutComponet = layouts[layout];

  return (
    <RouterContext.Provider value={actions}>
      <Router>
        <DeviceContextProvider>
          <LoginContextProvider>
            <RecentPlayGamesContextProvider>
              <GameLoginContextProvider>
                <CategoryContextProvider>
                  <WheelContextProvider>
                    <DailyCheckContextProvider>
                      <SerialNumberContextProvider>
                        <SearchContextProvider>
                          <BackgroundContextProvider>
                            <LayoutComponet navType={navType ? navType : { type: "pc" }}>
                              <RouterRenderWithRouter />
                            </LayoutComponet>
                          </BackgroundContextProvider>
                        </SearchContextProvider>
                      </SerialNumberContextProvider>
                    </DailyCheckContextProvider>
                  </WheelContextProvider>
                </CategoryContextProvider>
              </GameLoginContextProvider>
            </RecentPlayGamesContextProvider>
          </LoginContextProvider>
        </DeviceContextProvider>
      </Router>
    </RouterContext.Provider>
  );
};

export default ProcessRouter;
