import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetGameFeeAndOdds } from "../../../../../hooks/winBet/wingame.hooks";
import { moneyFormat } from "../../../../../plugins/numberFormat";

const RuleContent = ({ gameCode = "", selectedGameList = undefined }) => {
  const { t } = useTranslation();
  const { data: gameFeeAndOdds } = useGetGameFeeAndOdds({});
  const [nowOddsSetting, setNowOddsSetting] = useState({});
  const totalBet = 100;
  const feeRate = gameFeeAndOdds?.fee ? gameFeeAndOdds?.fee * 100 : 0;
  const fee = totalBet * (feeRate / 100);
  const betAmount = totalBet - fee;
  const optionsEnum = {
    B: "big",
    S: "small",
    O: "odd",
    E: "even",
    BO: "big_odd",
    SO: "small_odd",
    BE: "big_even",
    SE: "small_even",
  };

  useEffect(() => {
    if (!!gameFeeAndOdds && selectedGameList) {
      const nowOdds = gameFeeAndOdds[selectedGameList] ?? {};
      setNowOddsSetting(nowOdds);
    }
  }, [selectedGameList, gameFeeAndOdds]);

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: t("win.rule.bitgame.desc"),
        }}
      />
      <div
        style={{ color: "red" }}
        dangerouslySetInnerHTML={{
          __html: t("win.rule.feeExample", {
            totalBet: moneyFormat(totalBet),
            feeRate: feeRate,
            fee: moneyFormat(fee),
            betAmount: moneyFormat(betAmount),
          }),
        }}
      />
      {Object.keys(optionsEnum)
        .filter((option) => nowOddsSetting[optionsEnum[option]] !== undefined)
        .map((option, index) => {
          console.log(option);
          return (
            <div
              key={index}
              dangerouslySetInnerHTML={{
                __html: t(`win.rule.bitgame.desc.${option}`, {
                  index: index + 1,
                  betAmount: moneyFormat(betAmount),
                  odd: nowOddsSetting[optionsEnum[option]] ?? 0,
                  total: moneyFormat(betAmount * (nowOddsSetting[optionsEnum[option]] ?? 0)),
                }),
              }}
            />
          );
        })}
    </>
  );
};

export default RuleContent;
