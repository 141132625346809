import { useQuery } from "@tanstack/react-query";
import queryKey from "../../enumerations/queryKey";
import { apiGetCustomerService, apiGetCustomerServiceNoCurrency } from "./service";

export const useGetCustomerService = (options) => {
  return useQuery([queryKey.CS_LINK], () => apiGetCustomerService().then((res) => res.data.data), {
    ...options,
    retry: false,
  });
};

export const useGetCustomerServiceNoCurrency = (options) => {
  return useQuery(
    [queryKey.CS_LINK_NO_CURRENCY],
    () =>
      apiGetCustomerServiceNoCurrency().then((res) => {
        const filtered = res.data.data.filter((_data) => _data.customer_service_link !== "");
        return { data: filtered };
      }),
    {
      ...options,
    },
  );
};
