const gameStateEnum = {
  BET_PHASE: {
    key: "BET_PHASE",
    disableBet: false,
    showCountDown: true,
    showResult: false,
  },
  DISABLE_BET: {
    key: "DISABLE_BET",
    disableBet: true,
    showCountDown: true,
    showResult: false,
  },
  PENDING_PHASE: {
    key: "PENDING_PHASE",
    disableBet: true,
    showCountDown: true,
    showResult: false,
  },
  START_PHASE: {
    key: "START_PHASE",
    disableBet: true,
    showCountDown: false,
    showResult: false,
  },
  PAUSE_RESULT_PHASE: {
    key: "PAUSE_RESULT_PHASE",
    disableBet: true,
    showCountDown: false,
    showResult: false,
  },
  RESULT_PHASE: {
    key: "RESULT_PHASE",
    disableBet: true,
    showCountDown: false,
    showResult: true,
  },
};

export default gameStateEnum;
