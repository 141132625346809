const channelSettingsEnum = {
  LOTGOPHERS1: {
    maxTimer: 60,
  },
  LOTGOPHERS2: {
    maxTimer: 60 * 2,
  },
  LOTGOPHERS3: {
    maxTimer: 60 * 3,
  },
  LOTGOPHERS5: {
    maxTimer: 60 * 5,
  },
};

export default channelSettingsEnum;
