import { Checkbox, Form } from "antd";
import "./style.scss";

const CustomCheckBox = ({
  name = "",
  valuePropName = "",
  onChange = () => {},
  checked = true,
  title = "",
  type = "mobile",
}) => {
  return (
    <div className={`checkBoxStyle ${type === "pc" ? "pc-style" : ""}`}>
      <Form.Item name={name} valuePropName={valuePropName}>
        <Checkbox onChange={onChange} checked={checked}>
          {title}
        </Checkbox>
      </Form.Item>
    </div>
  );
};

export default CustomCheckBox;
