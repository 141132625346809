import { Divider } from "antd-mobile";
import { useTranslation } from "react-i18next";
import { useDailyCheck } from "../../../../context/DailyCheckContext";
import { thousandFormat } from "../../../../plugins/numberFormat";
import { coinStatusEnum } from "../../enumeration/coinStatueEnum";
import styles from "./style.module.scss";

const Steps = () => {
  const { t } = useTranslation();
  const { setting, activeDay } = useDailyCheck();

  const getStepStatus = (day) => {
    const targetIndex = setting.findIndex((item) => item.day === day);
    if (day < activeDay) {
      setting[targetIndex].enum = coinStatusEnum.SUCCESS;
    } else if (day === activeDay) {
      setting[targetIndex].enum = coinStatusEnum.ACTIVE;
    } else setting[targetIndex].enum = coinStatusEnum.INACTIVE;
    return true;
  };

  const renderCoin = (step, index) => {
    return (
      <div className={styles.step} key={index}>
        <div
          className={`${styles.amount} ${activeDay > step.day && styles.success} ${
            activeDay < step.day && styles.inactive
          }`}
        >
          +{thousandFormat(step?.bonus_amount)}
        </div>
        <div className={styles.coin}>
          {getStepStatus(step.day) && (
            <img src={step.enum.src} alt={step.enum.name} className={styles.coinInactive} />
          )}
          {step.day !== setting.length && (
            <Divider className={`${styles.divider} ${activeDay > step.day && styles.active}`} />
          )}
        </div>
        <div className={`${styles.day} ${activeDay === step.day && styles.active}`}>
          {`${t("dailyCheck.step.day", { day: step.day })}`}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.steps}>
        {setting.map((step, index) => {
          return renderCoin(step, index);
        })}
      </div>
    </div>
  );
};

export default Steps;
