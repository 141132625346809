import { tip } from "../apis/utils";

export const copyText = (text, successMessageContent) => {
  if (!text) return;
  navigator.clipboard.writeText(text);
  tip({ content: successMessageContent, type: "success" });
};

export const checkUrlReg = (url, customEvent, notCorrectEvent = () => {}) => {
  const expression =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
  const relativeExp = /\/[-a-zA-Z0-9@:%._\/+~#=]*/;
  const regex = new RegExp(expression);
  const relativeRegex = new RegExp(relativeExp);
  if (url.match(regex) || url.match(relativeRegex)) {
    if (!!customEvent) {
      customEvent();
    } else {
      window.location.href = url;
    }
  } else {
    notCorrectEvent();
    return;
  }
};

export const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
