import { Badge } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BalanceViewer, VIPDropdown } from "../../../../../../components";
import { PRODUCTION_NAME_DEV } from "../../../../../../config";
import { useLogin } from "../../../../../../context/LoginContext";
import { useGetMessage } from "../../../../../../hooks/Home/home.hook";
import styles from "./style.module.scss";

const AccountContainer = () => {
  const navigate = useNavigate();
  const { checkIfLogin, isLogin } = useLogin();
  const [isHaveNewMessage, setIsHaveNewMesssage] = useState(false);
  const [newMessageCount, setNewMessageCount] = useState(0);

  const { data: message } = useGetMessage({
    enabled: !!isLogin,
  });

  const checkNewMessage = () => {
    if (!message) return;
    const found = message.filter((r) => r.read_status === 0);
    setNewMessageCount(found.length);
  };

  useEffect(() => {
    checkNewMessage();
  }, [message]);

  return (
    <div className={styles.container}>
      <BalanceViewer
        showReload={true}
        containerStyle={styles.pcBalanceContainer}
        showDeposit={true}
      />
      <VIPDropdown />

      <div
        onClick={() => {
          if (!checkIfLogin()) return;
          navigate("/messages?tab=notification");
        }}
        style={{ display: "flex", alignItems: "center", padding: 0, width: 22, cursor: "pointer" }}
      >
        <Badge
          // offset={[-4, 3]}
          style={{ fontSize: "var(--fontSetting-small)", boxShadow: "none", fontWeight: "700" }}
          count={newMessageCount}
        >
          <img
            src={`/images/logo/${PRODUCTION_NAME_DEV}/notification_icon.svg`}
            alt="notification"
          />
        </Badge>
      </div>
    </div>
  );
};

export default AccountContainer;
