import { requestGlobalSettingData, requestMain } from "../../apis";
import localStorageKey from "../../enumerations/localStorageKey";

const defaultCurrency = localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);
export const apiGetPromotionType = () =>
  requestGlobalSettingData("get", `/${defaultCurrency}/promotion/type`, {}, true, false);

export const apiGetPromotionList = ({ params, page_size, page }) =>
  requestMain("get", `/${defaultCurrency}/promotion`, { ...params, page_size, page }, true, false);

export const apiGetPromotionDetail = ({ promotionId, params }) =>
  requestMain("get", `/${defaultCurrency}/promotion/${promotionId}`, params, true, false);

export const apiGetGameCategory = ({ params }) =>
  requestGlobalSettingData(
    "get",
    `/${defaultCurrency}/game/category/promotion`,
    params,
    false,
    false,
  );

export const apiGetGameProduct = ({ categoryId, params }) =>
  requestGlobalSettingData(
    "get",
    `/${defaultCurrency}/game/${categoryId}/promotion`,
    params,
    false,
    false,
  );
