import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PCWheelPopup } from "../components";

const WheelContext = createContext({
  openWheel: ({ wheelId, replace }) => {},
  handleShowWheelPopup: ({ wheelId }) => {},
});

export const WheelContextProvider = ({ children }) => {
  const [showWheelPopup, setShowWheelPopup] = useState(false);
  const [promotionId, setPromotionId] = useState();
  const navigate = useNavigate();

  const openWheel = ({ wheelId, replace = false }) => {
    if (replace === true) {
      navigate(`/bonusWheel?id=${wheelId}`, {
        replace: true,
      });
    } else {
      navigate(`/bonusWheel?id=${wheelId}`);
    }
  };

  const handleShowWheelPopup = ({ wheelId }) => {
    setPromotionId(wheelId);
    setShowWheelPopup(true);
  };

  return (
    <WheelContext.Provider
      value={{
        openWheel,
        handleShowWheelPopup,
      }}
    >
      {children}
      <PCWheelPopup
        visible={showWheelPopup}
        setVisible={setShowWheelPopup}
        promotionId={promotionId}
      />
    </WheelContext.Provider>
  );
};
export const useWheel = () => {
  const WheelValue = useContext(WheelContext);
  return WheelValue;
};
