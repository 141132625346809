import { CenterPopup } from "antd-mobile";
import { CloseCircleOutline } from "antd-mobile-icons";
import React from "react";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import { moneyFormat } from "../../../plugins/numberFormat";
import styles from "./style.module.scss";
import "./style.scss";

function SpinRecordPopup({ visible, onClose = () => {}, data = [] }) {
  const { t } = useTranslation();

  const renderAward = (type, name) => {
    const awardTypeDict = {
      bonus: moneyFormat(name),
      text: t("bonusWheel.award.text"),
      default: name,
    };
    return awardTypeDict[type] ?? awardTypeDict.default;
  };

  return (
    <CenterPopup
      visible={visible}
      onMaskClick={onClose}
      destroyOnClose={true}
      bodyClassName={"spin-record-custom-popup"}
    >
      <div className={styles.popupHeader}>
        <div>{t("bonusWheel.spinRecord")}</div>
        <CloseCircleOutline style={{ color: "var(--text-p1)" }} onClick={onClose} />
      </div>
      {/* <Scrollbars> */}
      <Scrollbars autoHide className={styles.popupBody}>
        <div className={styles.spinRecordPopup}>
          {data?.map((r) => {
            return (
              <div className={styles.spinRecordTitle}>
                <div className={styles.spinRecordItem}>
                  <div>{t("bonusWheel.spinRecord.number")}</div>
                  <div className={styles.spinRecordValue}>{r.trans_id}</div>
                </div>
                <div className={styles.spinRecordItem}>
                  <div>{t("bonusWheel.spinRecord.time")}</div>
                  <div className={styles.spinRecordValue}>{r.created_at}</div>
                </div>
                <div className={styles.spinRecordItem}>
                  <div>{t("bonusWheel.spinRecord.result")}</div>
                  <div className={styles.spinRecordResult}>{renderAward(r.award_type, r.name)}</div>
                </div>
              </div>
            );
          })}
        </div>
      </Scrollbars>
      {/* </Scrollbars> */}
    </CenterPopup>
  );
}

export default SpinRecordPopup;
