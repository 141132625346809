import { SpinLoading } from "antd-mobile";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Spritesheet from "react-responsive-spritesheet";

import { useQueryClient } from "@tanstack/react-query";
import queryKey from "../../../enumerations/queryKey";
import { useSpinTheWheelNew } from "../../../hooks/bonusWheel/bonusWheel.hook";
import { moneyFormat } from "../../../plugins/numberFormat";
import ResultModal from "../ResultModal";
import "./style.scss";

function Wheel({
  customWheelContainerStyle = "",
  promotionId,
  prizeList,
  animateStyle = {},
  setAnimateStyle = () => {},
  spinng = false,
  setSpinng = () => {},
  prizeDetail,
  setPrizeDetail = () => {},
  setWinner = () => {},
  csLink = "",
  remaingTimes = 0,
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const wheelFrame = useRef();
  const [showResultModal, setShowResultModal] = useState(false);
  const { mutate: spinTheWheel, isLoading: isSpinLoading } = useSpinTheWheelNew();

  const prizeRender = (type, name) => {
    const awardTypeDict = {
      bonus: moneyFormat(name),
      text: t("bonusWheel.award.text"),
      default: name,
    };
    return awardTypeDict[type] ?? awardTypeDict.default;
  };
  const prizeIconRender = (type) => {
    const awardTypeDict = {
      bonus: <img alt={type} src={`/images/bonusWheel/money.png`} />,
      item: <img alt={type} src={`/images/bonusWheel/gift.png`} />,
      text: <img alt={type} src={`/images/bonusWheel/cry.png`} />,
      default: "",
    };
    return awardTypeDict[type] ?? awardTypeDict.default;
  };

  const spinOnClick = async () => {
    if (spinng || isSpinLoading || remaingTimes < 1) return;
    await spinTheWheel(
      { params: { promotion_id: promotionId } },
      {
        onSuccess: async (success) => {
          const currentChurnData = queryClient.getQueryData([
            queryKey.PRIZE_LIST,
            promotionId.toString(),
          ]);
          if (currentChurnData) {
            queryClient.setQueryData([queryKey.PRIZE_LIST, promotionId.toString()], (oldData) => {
              return {
                ...oldData,
                total_used_churn: success.total_used_churn,
              };
            });
          }

          const prizeIndex = prizeList.findIndex((item) => item.id === success.id);
          if (!!prizeIndex || prizeIndex === 0) {
            setWinner(prizeIndex);
            setSpinng(true);
          }
        },
      },
    );
  };

  useEffect(() => {
    if (spinng) {
      wheelFrame.current.goToAndPause(2);
    }
  }, [spinng]);

  useEffect(() => {
    if (!!prizeDetail) {
      wheelFrame.current.pause();
      wheelFrame.current.setStartAt(3);
      wheelFrame.current.setEndAt(4);
      wheelFrame.current.setFps(16);
      wheelFrame.current.goToAndPlay(3);
      setShowResultModal(true);
    } else {
      wheelFrame.current.pause();
      wheelFrame.current.setStartAt(1);
      wheelFrame.current.setEndAt(2);
      wheelFrame.current.setFps(2);
      wheelFrame.current.goToAndPlay(1);
    }
  }, [prizeDetail]);

  // useEffect(() => {
  //   console.log("isSpinLoading", isSpinLoading);
  // }, [isSpinLoading]);

  return (
    <div
      className={`wheel-container ${customWheelContainerStyle}`}
      style={{ "--numberOfSlices": prizeList.length }}
    >
      <div className="board">
        <div className="wheel-frame">
          <Spritesheet
            ref={wheelFrame}
            image={`/images/bonusWheel/all_wheel_frame.png`}
            widthFrame={578}
            heightFrame={578}
            loop={true}
            startAt={1}
            endAt={2}
            steps={4}
            autoplay={true}
            fps={2}
            direction={"forward"}
          />
        </div>
        <div className="spinner-table">
          <div className="dial" style={{ ...animateStyle }}>
            {prizeList?.map((item, index) => {
              const listLength = prizeList.length;
              return (
                <div
                  key={`${item.id}_${index}`}
                  className={`slice ${item?.is_top_prize === 1 ? "big-prize" : ""}`}
                  style={{ transform: `rotate(${index * (360 / listLength)}deg)` }}
                >
                  <div className="label">
                    <div className={`text ${item.award_type === "bonus" ? "bonus" : "item"}`}>
                      {prizeRender(item.award_type, item.name)}
                    </div>
                    {prizeIconRender(item.award_type)}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="spin-button" onClick={spinOnClick}>
          <img
            src={`/images/bonusWheel/${
              spinng || isSpinLoading || remaingTimes < 1 ? "spin_btn_disable.png" : "spin_btn.gif"
            }`}
            alt="spin button"
          />
          {isSpinLoading ? (
            <SpinLoading className="spin-text" style={{ "--size": "24px" }} color="primary" />
          ) : (
            <span className={`spin-text ${spinng || remaingTimes < 1 ? "disable" : "active"}`}>
              {t("bonusWheel.spin")}
            </span>
          )}
        </div>
        <div className="spin-frame">
          <img src={"/images/bonusWheel/Spin_frame.png"} alt="spin frame" />
        </div>
      </div>
      <ResultModal
        prizeDetail={prizeDetail}
        isModalVisible={showResultModal}
        setIsModalVisible={setShowResultModal}
        onCloseModal={() => {
          setPrizeDetail();
          // setAnimateStyle({});
        }}
        csLink={csLink}
      />
    </div>
  );
}

export default Wheel;
