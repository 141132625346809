import { createContext, useContext, useState } from "react";
import PCSerialNumberPopup from "../components/PCSerialNumberPopup";

const SerialNumberContext = createContext({
  handleShowSerialNumberPopup: () => {},
});

export const SerialNumberContextProvider = ({ children }) => {
  const [showSerialNumberPopup, setShowSerialNumberPopup] = useState(false);

  const handleShowSerialNumberPopup = () => {
    setShowSerialNumberPopup(true);
  };

  return (
    <SerialNumberContext.Provider
      value={{
        handleShowSerialNumberPopup,
      }}
    >
      {children}
      <PCSerialNumberPopup visible={showSerialNumberPopup} setVisible={setShowSerialNumberPopup} />
    </SerialNumberContext.Provider>
  );
};
export const useSerialNumber = () => {
  const SerialNumberValue = useContext(SerialNumberContext);
  return SerialNumberValue;
};
