import { Form, Modal } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PCButton from "../PCButton";
import CloseCircle from "./icons/CloseCircle";
import styles from "./style.module.scss";
import "./style.scss";

function PCModal({
  isModalVisible,
  setIsModalVisible,
  maskClosable = false,
  title = "",
  closable = true,
  onOk = () => {
    setIsModalVisible(false);
  },
  okButtonText,
  onCloseModal = () => {},
  isHaveConfirmButton = true,
  children,
  isForm = false,
  formRef,
  formProps = {},
  width,
  customBodyStyle = {},
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const handleCloseModal = () => {
    setIsModalVisible(false);
    onCloseModal();
  };
  useEffect(() => {
    if (isModalVisible && isForm) {
      formRef ? formRef.resetFields() : form.resetFields();
    }
  }, [isModalVisible, isForm]);

  return (
    <Modal
      visible={isModalVisible}
      onCancel={handleCloseModal}
      footer={null}
      width={width ? width : 532}
      centered
      closable={closable}
      closeIcon={<CloseCircle />}
      maskClosable={maskClosable}
      destroyOnClose
      className="pc-custom-modal"
      title={title}
      bodyStyle={{
        borderRadius: "16px",
        padding: "0",
        maxHeight: "80vh",
        background: "var(--popup-background)",
        ...customBodyStyle,
      }}
    >
      <div className={`${styles.customModal}`}>
        {isForm ? (
          <Form
            form={formRef ?? form}
            onFinish={(values) => {
              onOk(values);
            }}
            autoComplete="off"
            requiredMark={false}
            layout="vertical"
            style={{ padding: "0 24px" }}
            {...formProps}
          >
            {children}
            <PCButton block size="large" type="primary" htmlType="submit">
              {okButtonText ?? t("normal.confirm")}
            </PCButton>
          </Form>
        ) : (
          <>
            {children}
            {isHaveConfirmButton ? (
              <PCButton block size="large" type="primary" onClick={onOk}>
                {okButtonText ?? t("normal.confirm")}
              </PCButton>
            ) : (
              ""
            )}
          </>
        )}
      </div>
    </Modal>
  );
}

export default PCModal;
