import { CloseCircleOutlined } from "@ant-design/icons";
import { CenterPopup, Popup } from "antd-mobile";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDevice } from "../../context/DeviceContext";
import localStorageKey from "../../enumerations/localStorageKey";
import AppBar from "../AppBar";
import styles from "./style.module.scss";

const CURRENCY = localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);

const WinRulePopup = (
  {
    onClose = () => {},
    visible = false,
    destroyOnClose = false,
    afterClose = () => {},
    content = "",
  },
  ...props
) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobile } = useDevice();

  const renderPopup = () => {
    if (isMobile) {
      return (
        <Popup
          {...props}
          visible={visible}
          destroyOnClose={destroyOnClose}
          afterClose={afterClose}
          position="right"
          bodyStyle={{ width: "100vw", backgroundColor: "transparent" }}
        >
          <div className={styles.rulePopupContainer}>
            <AppBar
              title={t("win.rule.title")}
              showBackButton={true}
              iconClosePadding={"4px"}
              onClickEvent={() => {
                onClose();
              }}
            />
            <div className={styles.container}>
              {typeof content === "function" ? (
                <div className={styles.itemContent}>{content()}</div>
              ) : (
                <div className={styles.itemContent} dangerouslySetInnerHTML={{ __html: content }} />
              )}
            </div>
          </div>
        </Popup>
      );
    }
    return (
      <CenterPopup
        {...props}
        visible={visible}
        destroyOnClose={destroyOnClose}
        afterClose={afterClose}
        // bodyStyle={{ width: "100vw", backgroundColor: "transparent" }}
      >
        <div className={styles.pcRulePopupContainer}>
          <div className={styles.headerContainer}>
            <div className={styles.title}>{t("win.rule.title")}</div>
            <div className={styles.closeButton} onClick={onClose}>
              <CloseCircleOutlined className={styles.close} />
            </div>
          </div>
          {/* <AppBar
            title={t("win.rule.title")}
            showBackButton={true}
            iconClosePadding={"4px"}
            onClickEvent={() => {
              onClose();
            }}
          /> */}
          <div className={styles.container}>
            {typeof content === "function" ? (
              <div className={styles.itemContent}>{content()}</div>
            ) : (
              <div className={styles.itemContent} dangerouslySetInnerHTML={{ __html: content }} />
            )}
          </div>
        </div>
      </CenterPopup>
    );
  };

  return renderPopup();
};

export default WinRulePopup;
