import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";
import styles from "./style.module.scss";

const Rule = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.ruleContainer}>
      <Element name="description">
        <div id="description" className={styles.titleContainer}>
          <div className={styles.title}>{t("dailyCheck.desc.title")}</div>
        </div>
        <div className={styles.desc} dangerouslySetInnerHTML={{ __html: t("dailyCheck.desc") }} />
      </Element>
    </div>
  );
};

export default Rule;
