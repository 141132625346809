import { forwardRef, useImperativeHandle, useRef } from "react";
import Spritesheet from "react-responsive-spritesheet";
import styles from "./style.module.scss";
const Gophers = forwardRef(({ image, top = "0", left = "0" }, ref) => {
  const gopherRef = useRef();

  useImperativeHandle(ref, () => ({
    hide() {
      hideGopher();
    },
    show() {
      showGopher();
    },

    toggle() {
      toggleGopher();
    },
    getCurrentFrame() {
      return getCurrentGopherFrame();
    },
  }));
  const hideGopher = () => {
    setTimeout(() => {
      gopherRef.current.pause();
      gopherRef.current.setStartAt(7);
      gopherRef.current.setEndAt(13);
      gopherRef.current.goToAndPlay(7);
    }, [200]);
  };
  const showGopher = () => {
    setTimeout(() => {
      gopherRef.current.pause();
      gopherRef.current.setStartAt(1);
      gopherRef.current.setEndAt(7);
      gopherRef.current.goToAndPlay(1);
    }, [200]);
  };

  const toggleGopher = () => {
    setTimeout(() => {
      gopherRef.current.pause();
      gopherRef.current.setStartAt(0);
      gopherRef.current.setEndAt(13);
      gopherRef.current.goToAndPlay(1);
    }, [200]);
  };

  const getCurrentGopherFrame = () => {
    return gopherRef.current.getInfo("frame");
  };

  return (
    <div className={styles.container} style={{ top: top, left: left }}>
      <Spritesheet
        ref={gopherRef}
        image={image}
        widthFrame={500}
        heightFrame={500}
        startAt={0}
        endAt={13}
        steps={13}
        fps={16}
        loop={true}
        autoplay={false}
        onLoopComplete={(spritesheet) => {
          spritesheet.pause();
        }}
      />
    </div>
  );
});
export default Gophers;
