const WarningIcon = (props) => {
  const { size } = { props };
  return (
    <svg
      width={"28"}
      height={"28"}
      viewBox="0 0 56 56"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="var(--ant-warning-color)" fillRule="nonzero">
        <path d="M28 3.5C14.47 3.5 3.5 14.47 3.5 28S14.47 52.5 28 52.5 52.5 41.53 52.5 28 41.53 3.5 28 3.5zm0 44.844C16.767 48.344 7.656 39.233 7.656 28S16.767 7.656 28 7.656 48.344 16.767 48.344 28 39.233 48.344 28 48.344z" />
        <path d="M25.375 37.625a2.625 2.625 0 1 0 5.25 0 2.625 2.625 0 0 0-5.25 0zM26.688 31.5h2.625c.24 0 .437-.197.437-.438V16.188a.439.439 0 0 0-.438-.437h-2.625a.439.439 0 0 0-.437.438v14.875c0 .24.197.437.438.437z" />
      </g>
    </svg>
  );
};
export default WarningIcon;
