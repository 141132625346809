import vars from "../../../../../../assets/styles/_variables.scss";

const fullWidth = vars.maxWidthInMobile > window.innerWidth ? "100vw" : vars.maxWidthInMobile;

const positionEnum = {
  0: {
    top: `calc(0% - ((${fullWidth} * 0.33 - 16px) / 2) + 30px)`,
    left: "0",
  },
  1: {
    top: `calc(0% - ((${fullWidth} * 0.33 - 16px) / 2) + 30px)`,
    left: "calc(50% - 54px)",
  },
  2: {
    top: `calc(0% - ((${fullWidth} * 0.33  - 16px) / 2) + 30px)`,
    left: "calc(100% - 108px)",
  },
  3: {
    top: `calc((100% - 50px) / 2 - ((${fullWidth} * 0.33 - 16px) / 2))`,
    left: "0",
  },
  4: {
    top: `calc((100% - 50px) / 2 - ((${fullWidth} * 0.33  - 16px) / 2))`,
    left: "calc(50% - 54px)",
  },
  5: {
    top: `calc((100% - 50px) / 2 - ((${fullWidth} * 0.33  - 16px) / 2))`,
    left: "calc(100% - 108px)",
  },
  6: {
    top: `calc(100% - 25px - (${fullWidth} * 0.33 - 16px))`,
    left: "0",
  },
  7: {
    top: `calc(100% - 25px - (${fullWidth} * 0.33 - 16px))`,
    left: "calc(50% - 54px)",
  },
  8: {
    top: `calc(100% - 25px - (${fullWidth} * 0.33 - 16px))`,
    left: "calc(100% - 108px)",
  },
};
export default positionEnum;
