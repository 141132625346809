import { useQuery } from "@tanstack/react-query";
import queryKey from "../../enumerations/queryKey";
import { apiGetSettings } from "./service";

export const useGetSettings = ({ options }) => {
  return useQuery(
    [queryKey.SETTINGS],
    () =>
      apiGetSettings({}).then((res) => {
        const timezone = res.data.result.timezone;
        return { ...res.data.data, timezone };
      }),
    {
      ...options,
    }
  );
};
