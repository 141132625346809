//跳轉、錯誤處理、提示等方法放在這裡
// import {Message} from 'element-ui';
// import router from '../router/index';
import vars from "../assets/styles/_variables.scss";
import { toast } from "../components";

const ALLOWED_PATH = ["/signin", "/signup", "/forgotPassword", "/"];
const isMobile = Boolean(window.innerWidth <= vars.maxWidthInMobile.replace("px", ""));

const isAllowedWithoutCurrency = (path) => {
  return ALLOWED_PATH.includes(path);
};

const tip = ({ content = "", type = "" }) => {
  toast({ content: content, type: type, isMobile: isMobile });
};

const toLogin = () => {
  if (isMobile) {
    if (window.location.pathname !== "/signin") window.location.href = "/signin";
  } else {
    let params = new URL(document.location).searchParams;
    if (!params.get("showLogin")) window.location.replace("/?showLogin=true");
  }
};

const to403Page = () => {
  window.location.pathname = "/accessDenied";
};

const toMaintenancePage = () => {
  window.location.pathname = "/inMaintenance";
};

const toInternetErrorPage = () => {
  window.location.pathname = "/connectionFailed";
};

export {
  isAllowedWithoutCurrency,
  tip,
  to403Page,
  toInternetErrorPage,
  toLogin,
  toMaintenancePage,
};
